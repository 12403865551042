import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import Moment from "react-moment";
import url from "../../../../../constants/url";
import ReviewForm from "./ReviewForm";
import { replaceWord } from "../../../../../utils/utils";
let rating = [1, 2, 3, 4, 5];

const OrderDetails = () => {
    let now = new Date().setHours(0, 0, 0, 0);
    const history = useHistory();
    const [openPopup, setOpenPopup] = useState({});
    const [active, setActive] = useState({});
    const [data, setData] = useState({});
    const [intodata, setIntodata] = useState();
    const param = useParams();
    const getAllCourse = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(
            `${url.apiUrl}student/class-course-detail/${param.id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getAllCourse();
    }, []);
    const activeHandler = (id) => {
        setActive((prev) => ({ [id]: !prev[id] }));
    };
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/student-dashboard">Dashboard</Link>
                    </li>

                    <li className="breadcrumb-item">
                        <Link to="/student-dashboard/OrderCourse">Classroom Course</Link>
                    </li>
                    <li className="breadcrumb-item active">Classroom Course Detail</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="product-slider">
                                <ul className="list-unstyled video-list-thumbs">
                                    <li className="mb-0">
                                        <div className="item-card7-img">
                                            <img alt={data?.title} className="cover-image" src={data?.thumbnail} />
                                            {(
                                                data?.price == "paid" && (
                                                    data?.is_discount == 1 && (
                                                        (data?.is_limited_offer == 1 && (new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(data?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                        (data?.is_limited_offer == 0 && new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                )
                                            ) && (
                                                    <Link to="#" className="bg-danger top left">{data?.discount_price}%  off</Link>
                                                )}
                                            <Link to="#" className="bg-danger right top">
                                                Classroom Course
                                            </Link>
                                            {data?.class_type === "school" ? (
                                                <Link to="#" className="bg-danger right bottom">
                                                    {data?.board}
                                                </Link>
                                            ) : (data?.class_type === "university" ? (
                                                <Link to="#" className="bg-danger right bottom">
                                                    {data?.universities?.name}
                                                </Link>
                                            ) : (data?.class_type === "tuition" && (
                                                <>
                                                    {data?.tuition_type === "school" ? (
                                                        <Link to="#" className="bg-danger right bottom">
                                                            {data?.board}
                                                        </Link>
                                                    ) : (data?.tuition_type === "university" && (
                                                        <Link to="#" className="bg-danger right bottom">
                                                            {data?.universities?.name}
                                                        </Link>
                                                    ))}
                                                </>
                                            )))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-4 mb-4 text-center">
                                <div className="mb-2">
                                    {data?.price === "paid" && (
                                        <span className="font-weight-semibold h2 mb-0">
                                            ₹ {Math.round(data?.payable_price)} {(
                                                data?.price == "paid" && (
                                                    data?.is_discount == 1 && (
                                                        (data?.is_limited_offer == 1 && (new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(data?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                        (data?.is_limited_offer == 0 && new Date(data?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                )
                                            ) &&
                                                (<del className="fs-14 text-muted">₹ {Math.round(data?.regular_price)} </del>
                                                )}
                                        </span>
                                    )}
                                    {data?.price === "free" && (
                                        <span className="font-weight-semibold h2 mb-0">
                                            Free
                                        </span>
                                    )}
                                </div>
                            </div>
                            {(data?.lecture_type === "both" || data?.lecture_type === "video_conference") ? (
                                <a target="_blank"
                                    className="btn btn-block btn-primary mb-3 mb-xl-0"
                                    href={data?.video_conference_link}
                                >Join Now
                                </a>
                            ) : ("")}
                        </div>
                        <div className="card-header">
                            <h4 className="card-title">Classroom Course Info :</h4>
                        </div>
                        <table className="table table-hover dataTable">
                            <tbody>
                                <tr>
                                    <td className="font-weight-semibold">Institute Name</td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end text-capitalize">
                                        <Link className="text-primary" to={`/institute-detail/${data?.institute?.institute_id}`}>
                                            {data?.institute?.name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Branch Name</td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end text-capitalize">
                                        <Link className="text-primary" to={`/branch-detail/${data?.institute_branch?.branch_id}`}>
                                            {data?.institute_branch?.name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">Class Type</td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end text-capitalize">
                                        {replaceWord(data?.class_type)}
                                    </td>
                                </tr>
                                {data?.class_type === "school" ? (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Board</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.board}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Section</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.section}
                                            </td>
                                        </tr>
                                    </>
                                ) : (data?.class_type === "university" ? (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">University</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.universities?.name}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td className="text-nowrap font-weight-semibold">Semester</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.semester}
                                            </td>
                                        </tr> */}
                                    </>
                                ) : (data?.class_type === "tuition" && (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Tuition Type</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">{replaceWord(data?.tuition_type)}</td>
                                        </tr>
                                        {
                                            data?.tuition_type === "school" ? (
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">Board</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">{data?.board}</td>
                                                </tr>
                                            ) : (data?.tuition_type === "university" && (
                                                <>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">University</td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end text-capitalize">{data?.universities?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">Semester</td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end text-capitalize">{data?.semester}</td>
                                                    </tr>
                                                </>
                                            ))}
                                    </>
                                )))}
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">Medium</td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end"> {data?.medium}</td>
                                </tr>
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">Classroom Type</td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end"> {replaceWord(data?.lecture_type)}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Last Update</td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end">
                                        <Moment format="DD-MM-YYYY hh:mm A">{data?.last_update}</Moment>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Class Name</h4>
                        </div>
                        <div className="card-body">
                            {data?.title}
                        </div>
                    </div>
                    <div className="card">
                        <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
                            <Tab eventKey="overview" title="overview">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Class Description</h4>
                                    </div>
                                    <div className="card-body education-mandal"
                                        dangerouslySetInnerHTML={{
                                            __html: data?.description,
                                        }}>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">What will students learn in this classroom?</h4>
                                    </div>
                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.curriculum }}>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Requirements</h4>
                                    </div>
                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.requirements }}>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="timetable" title="Time Table">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Time Table</h4>
                                    </div>

                                    <div className="card-body">
                                        <table className="table table-bordered table-hover dataTable">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Sr. No.</th>
                                                    <th className="text-center">Subject</th>
                                                    <th className="text-center">Teacher Detail</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.subjects?.length > 0 ?
                                                    data?.subjects.map((subject, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">{subject?.subject}</td>
                                                                {subject?.teacher_schedule?.length != 0 ? (
                                                                    <table className="table table-bordered table-hover dataTable">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Teacher Detail</th>
                                                                                <th className="text-center">Date</th>
                                                                                {/* <th className="text-center">Start Date</th>
                                                                                <th className="text-center">End Date</th> */}
                                                                                <th className="text-center">Start Time</th>
                                                                                <th className="text-center">End Time</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {subject?.teacher_schedule?.map((teacher_schedule, index) => (
                                                                                <tr key={index}>
                                                                                    <td> {teacher_schedule?.teacher && (
                                                                                        <div className="media">
                                                                                            <img
                                                                                                src={teacher_schedule?.teacher?.image}
                                                                                                alt={teacher_schedule?.teacher?.name}
                                                                                                className="me-3 rounded-circle"
                                                                                            />
                                                                                            <div className="media-body">
                                                                                                <Link to={`/teacher-detail/${teacher_schedule?.teacher?.teacher_id}`} className="font-weight-semibold" >
                                                                                                    {teacher_schedule?.teacher?.name}
                                                                                                </Link>
                                                                                                <p>{teacher_schedule?.teacher?.email}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}</td>
                                                                                    <td className="text-center">
                                                                                        <Moment format="DD-MM-YYYY">
                                                                                            {teacher_schedule?.date}
                                                                                        </Moment>
                                                                                    </td>
                                                                                    {/* <td className="text-center">
                                                                                        <Moment format="DD-MM-YYYY">
                                                                                            {teacher_schedule?.start_date}
                                                                                        </Moment>
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <Moment format="DD-MM-YYYY">
                                                                                            {teacher_schedule?.end_date}
                                                                                        </Moment>
                                                                                    </td> */}
                                                                                    <td className="text-center">{moment(teacher_schedule?.start_time, ["HH:mm"]).format("hh:mm A")}</td>
                                                                                    <td className="text-center">{moment(teacher_schedule?.end_time, ["HH:mm"]).format("hh:mm A")}</td>
                                                                                </tr>

                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                ) : (
                                                                    <p className="text-center mb-0">Timeslot for teacher is not added</p>
                                                                )}
                                                            </tr>
                                                        );
                                                    })
                                                    : <tr>
                                                        <td className="text-center" colSpan={9}>No Data Available !</td>
                                                    </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="reviews" title="reviews">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Review</h4>
                                    </div>
                                    <div className="card-body">
                                        <ReviewForm data={data} />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderDetails;
