import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Education from "./Education";
import Experience from "./Experience";
import Achievement from "./Achievement";
import Review from "./Review";
import AboutProfile from "./AboutProfile";

const TeacherDetails = (props) => {
    const [form, setForm] = useState(false);
    const [data, setAboutInfo] = useState(props.data);

    useEffect(() => {
        if (props.data && props.data)
            setAboutInfo(props.data);
    }, [props.data]);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Teacher Details</h4>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <Tabs defaultActiveKey="about" id="uncontrolled-tab-example">
                                    <Tab eventKey="about" title="about">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-header justify-content-between">
                                                        <h4 className="card-title">About the Teacher</h4>
                                                        <div
                                                            className="d-flex align-items-center"
                                                            onClick={() => {
                                                                setForm(true);
                                                            }}
                                                        >
                                                            <Link
                                                                to="#"
                                                                className="btn btn-primary"
                                                                title="Edit"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#aboutus"
                                                            >
                                                                <i className="fa-solid fa-pen-to-square"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.about ? data?.about : "No Data Available !" }}>
                                                    </div>
                                                    <div
                                                        className={
                                                            form
                                                                ? "modal fade show"
                                                                : "modal fade"
                                                        }
                                                        id="aboutus"
                                                        tabIndex={-1}
                                                        aria-labelledby="myModalLabel33"
                                                        aria-hidden="true"
                                                        style={
                                                            form ? { display: "block" } : { display: "none" }
                                                        }
                                                    >
                                                        <div className="modal-dialog modal-dialog-centered modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h4 className="modal-title" id="myModalLabel33">
                                                                        About the Teacher
                                                                    </h4>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        id="about-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        onClick={() => setForm(false)}
                                                                    />
                                                                </div>
                                                                {form && (
                                                                    <AboutProfile setForm={setForm} getTeacherGetProfiles={props?.getTeacherGetProfiles} data={data?.about} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="education" title="education">
                                        <Education />
                                    </Tab>
                                    <Tab eventKey="experience" title="experience">
                                        <Experience />
                                    </Tab>
                                    <Tab eventKey="achievement" title="achievement">
                                        <Achievement />
                                    </Tab>
                                    <Tab eventKey="reviews" title="reviews">
                                        <Review />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
};

export default TeacherDetails;
