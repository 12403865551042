import React from "react";
import { Link } from "react-router-dom";

const RegsiterHere = () => {
    return (
        <>
            <section className="sptb">
                <div className="container">
                    <div className="match-height row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body text-center">
                                    <img className="mb-3" src="assets/images/video-player.png" alt="img" />
                                    <h3>Register as Institute / Teacher</h3>
                                    <p className="text-center">
                                        Teacher's registration will be initiated by Institutes.
                                    </p>
                                    <Link to="/Institute" className="btn btn-danger">
                                        <i className="fa-solid fa-arrow-right-to-bracket me-2"></i> Sign up
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body text-center">
                                    <img className="mb-3" src="assets/images/brain.png" alt="img" />
                                    <h3>Register as Student / Parent</h3>
                                    <p className="text-center">Make education easy, with just one registration.</p>
                                    <Link to="/Student" className="btn btn-danger">
                                        <i className="fa-solid fa-arrow-right-to-bracket me-2"></i>Sign up
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RegsiterHere;
