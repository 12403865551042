import { Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { educationSchema } from "./education.helper";
import { toast } from "react-toastify";
import useToken from "../../../../../../hooks/useToken";
import url from "../../../../../../constants/url";
import { useEffect } from "react";
import SubmitLoader from "../../../../../common/SubmitLoader"

const EditEducation = ({ show, setShow, data, getAllEducations }) => {
    const { token } = useToken();
    const [loader, setLoader] = useState(false);
    const [initialState, setInitialState] = useState({
        education_id: "",
        is_present: 0,
        school_college: "",
        degree: "",
        field_of_study: "",
        start_date: "",
        end_date: "",
        status: "",
    });
    useEffect(() => {
        setInitialState({
            education_id: data?.id,
            is_present: data?.is_present,
            school_college: data?.school_college,
            degree: data?.degree,
            field_of_study: data?.field_of_study,
            start_date: data?.start_date,
            end_date: data.is_present ? "" : data?.end_date,
            status: data?.status,
        });
    }, [data]);

    const handleClose = () => setShow(false);
    function onSubmit(payload, actions) {

        setLoader(true);
        fetch(`${url.apiUrl}institute-teacher/edit-education-by-id`, {
            method: "POST",
            headers: { "Content-Type": "application/json", AUTHTOKEN: token },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    actions.resetForm({
                        education_id: "",
                        is_present: 0,
                        school_college: "",
                        degree: "",
                        field_of_study: "",
                        start_date: "",
                        end_date: "",
                        status: "",
                    });
                    // get-all Education api
                    getAllEducations();
                    // close the modal from the Dom
                    setLoader(false);
                    handleClose();
                    toast.success("Education Edited Successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setLoader(false);
                    toast.error(result.status, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => {
                toast.error(
                    error?.message || error?.data?.message || "Something want wrong",
                    {
                        position: toast.POSITION.TOP_RIGHT,
                    }
                );
            });
    }
    return (
        <React.Fragment>
            <Formik
                initialValues={initialState}
                onSubmit={onSubmit}
                validationSchema={educationSchema}
                enableReinitialize={true}
            >
                {(formProps) => {
                    return (
                        <React.Fragment>
                            <Modal
                                id='contained-modal-title-vcenter'
                                aria-labelledby='contained-modal-title-vcenter'
                                size='lg'
                                show={show}
                                centered
                                onHide={() => {
                                    formProps.handleReset();
                                    handleClose();
                                }}
                                animation={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id='contained-modal-title-vcenter'>
                                        Edit Teacher's Education Details
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='row'>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='school-name'>
                                                School / College
                                                <span className='text-danger'> *</span>
                                            </label>
                                            <input
                                                type='text'
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.school_college}
                                                name='school_college'
                                                autoComplete='off'
                                                id='school-name'
                                                className='form-control'
                                                placeholder='Enter school/college'
                                            />
                                            {formProps.touched.school_college &&
                                                formProps.errors.school_college ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.school_college}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='degree-name'>
                                                Degree
                                                <span className='text-danger'> *</span>
                                            </label>
                                            <input
                                                type='text'
                                                name='degree'
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.degree}
                                                autoComplete='off'
                                                id='degree-name'
                                                className='form-control'
                                                placeholder='Enter degree'
                                            />
                                            {formProps.touched.degree &&
                                                formProps.errors.degree ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.degree}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='field-name'>
                                                Field of Study
                                                <span className='text-danger'> *</span>
                                            </label>
                                            <input
                                                type='text'
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.field_of_study}
                                                name='field_of_study'
                                                autoComplete='off'
                                                id='field-name'
                                                className='form-control'
                                                placeholder='Enter field of study'
                                            /><p className="fs-12"><span className="text-danger">Note: </span> Eg. Computer Science is Field of Study for Engineering</p>

                                            {formProps.touched.field_of_study &&
                                                formProps.errors.field_of_study ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.field_of_study}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='start-date'>
                                                Start Date
                                                <span className='text-danger'> *</span>
                                            </label>
                                            <input
                                                type='date'
                                                max={moment().format("YYYY-MM-DD")}
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.start_date}
                                                name='start_date'
                                                id='start-date'
                                                placeholder='MM/DD/YYYY'
                                                className='form-control'
                                            />
                                            {formProps.touched.start_date &&
                                                formProps.errors.start_date ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.start_date}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <div className='form-check form-check-inline mt-6 p-0'>
                                                <label className='custom-control form-checkbox'>
                                                    <input
                                                        type='checkbox'
                                                        onChange={(e) => {
                                                            formProps.setFieldValue(
                                                                "is_present",
                                                                e.target.checked ? 1 : 0
                                                            );
                                                            formProps.setFieldValue("end_date", "");
                                                        }}
                                                        onBlur={formProps.handleBlur}
                                                        className='custom-control-input'
                                                        name='is_present'
                                                        id='company-names'
                                                        checked={formProps?.values?.is_present === 1}
                                                        value={formProps?.values?.is_present}
                                                    />
                                                    <span className='custom-control-label'>
                                                        Currently studying in
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        {formProps?.values?.is_present === 0 && (
                                            <div className='col-md-6 form-group'>
                                                <label htmlFor='end-date'>End Date</label>
                                                <input
                                                    type='date'
                                                    min={moment(formProps?.values?.start_date).format(
                                                        "YYYY-MM-DD"
                                                    )}
                                                    max={moment().format("YYYY-MM-DD")}
                                                    onChange={formProps.handleChange}
                                                    onBlur={formProps.handleBlur}
                                                    value={formProps?.values?.end_date}
                                                    name='end_date'
                                                    id='end-date'
                                                    placeholder='MM/DD/YYYY'
                                                    className='form-control'
                                                />
                                                {formProps.touched.end_date &&
                                                    formProps.errors.end_date ? (
                                                    <div className='bg-error'>
                                                        {formProps.errors.end_date}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    {loader ? (
                                        <SubmitLoader />
                                    ) : (
                                        <>
                                            <button
                                                className='btn btn-primary'
                                                type='button'
                                                onClick={formProps.handleSubmit}
                                            >
                                                Submit
                                            </button>
                                            <button
                                                className='btn btn-danger'
                                                type='button'
                                                onClick={() => {
                                                    formProps.handleReset();
                                                    handleClose();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    )}

                                </Modal.Footer>
                            </Modal>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
};

export default EditEducation;
