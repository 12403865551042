import React from 'react';

const Institutes = () => {
    return (
        <>
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title">
                        <h2>Benefits for Students</h2>
                    </div>
                    <div className="row match-height">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-primary mb-3">
                                            <i className="fa fa-database text-white" />
                                        </div>
                                        <h3>Comprehensive Database</h3>
                                        <p>Our platform provides students with a comprehensive
                                            database of institutions, teachers, and courses, making it easier for them to
                                            make informed decisions about their education.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack  fea-icon bg-success mb-3">
                                            <i className="fa fa-user-group text-white" />
                                        </div>
                                        <h3>User-friendly Interface</h3>
                                        <p>Our platform is designed with a user-friendly interface
                                            that makes it easy for students to navigate and find the information they need.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-secondary mb-3">
                                            <i className="fa fa-piggy-bank text-white" />
                                        </div>
                                        <h3>Cost-effective</h3>
                                        <p>Students can save time and money as they can compare different
                                            educational programs and institutions before making a decision.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center match-height">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-primary mb-3">
                                            <i className="fa fa-handshake-angle text-white" />
                                        </div>
                                        <h3>Career Guidance</h3>
                                        <p>Our platform provides students with detailed information on
                                            different careers, including salary information, job outlook, and more, to help
                                            them make informed decisions about their futures.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-success mb-3">
                                            <i className="fa fa-indian-rupee-sign text-white" />
                                        </div>
                                        <h3>Education Loan</h3>
                                        <p>We offer a third-party education loan to assist students in financing their education
                                            thereby providing them with the financial support they need to pursue their academic
                                            goals and overcome financial obstacles.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    );
};

export default Institutes;