import React from "react";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import EducationalAggregator from "./EducationalAggregator";
import LearningManagementSystem from "./LearningManagementSystem";
import CareerGuidance from "./CareerGuidance";
import EducationLoan from "./EducationLoan";
import Curve from '../../Header/Curve';

const Services = () => {
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Services</h1>
                </div>
            </div>
            <Curve/>

            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <p>
                            Our services are designed to support you in different stages of
                            your education journey and to help you achieve your goals. From
                            finding the right resources to managing your progress, from
                            personalizing your learning experience to guiding you in your
                            career path, Education Mandal provides a comprehensive solution
                            for all your education needs.
                        </p>

                        <p>
                            Whether you're just starting out or looking for a change, it gives
                            you a clear overview of what we have to offer, so you can make
                            informed decisions about your future. Browse our Services today
                            and discover how Education Mandal can support you in your
                            education journey.
                        </p>
                    </div>
                </div>
            </section>
            <EducationalAggregator />
            <LearningManagementSystem />
            <CareerGuidance />
            <EducationLoan />
            <Footer />
        </>
    );
};

export default Services;
