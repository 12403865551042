import React from 'react';
import Footer from "../../Footer/Footer";
import Headerinner from '../../Header/Headerinner';
import { Link } from "react-router-dom";
import Curve from '../../Header/Curve';

const AllPolicy = () => {
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>All Policy Here !</h1>
                </div>
            </div>
            <Curve/>
            <section className="sptb">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="wrap-details">
                                        <h3>Privacy Policy</h3>
                                        <Link to="/privacy-policy" className="btn btn-danger btn-lg">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="wrap-details">
                                        <h3>Terms & Conditions</h3>
                                        <Link to="/terms-offer" className="btn btn-danger btn-lg">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="wrap-details">
                                        <h3>Disclaimer</h3>
                                        <Link to="/disclaimer" className="btn btn-danger btn-lg">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default AllPolicy;