import { useState } from "react";

const useToken = () => {
  const [token, setToken] = useState(() => {
    const localData = JSON.parse(localStorage.getItem("common-user-info"));
    return localData?.auth_token || null;
  });

  return { token, setToken };
};

export default useToken;
