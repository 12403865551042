import React, { useEffect, useState } from "react";
import {
    getAeacherAddAchievement,
    getTeacherAchievementList,
    getTeacherEditAchievementById,
    getTeacherGetAchievementById,
} from "../../../../apis";
import { Link } from "react-router-dom";
import moment from "moment";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import NoDataAvailable from "../../../common/NoDataAvailable";

const Achievement = () => {
    const current = new Date();
    let month = `${current.getMonth() + 1}`;
    if (month.length < 2) {
        month = "0" + month;
    }
    let cu_date = `${current.getFullYear()}-${month}-${current.getDate()}`;
    const [data, setData] = useState([]);
    const [addChivmentModal, setAddChivmentModal] = useState(false);
    const initialValidation = {
        title: "",
        date: "",
        description: "",
    };
    const [achivementValidation, setAchivementValidation] = useState(initialValidation);
    const initialValues = {
        title: "",
        date: "",
        description: "",
    };
    const [addachivement, setAddAchivement] = useState(initialValues);

    const [isError, setIsError] = useState(false);
    const [isLegnth, setIsLegnth] = useState(0);

    const handleChangeAche = (e, key) => {
        e.preventDefault();
        const value = e.target.value;
        if (e.target.name === "description") {
            if (value.length > 500) {
                setIsError(true);
            } else {
                setIsError(false);
            }
            setIsLegnth(value.length);
        }
        setAddAchivement({ ...addachivement, [key]: value });
    };
    const handleAddAche = async () => {
        let errors = {};
        if (addachivement.title === undefined || addachivement.title === "") {
            errors.title = "Required";
        }
        if (addachivement.date === undefined || addachivement.date === "") {
            errors.date = "Required";
        }
        if (addachivement.description === undefined || addachivement.description === "") {
            errors.description = "Required";
        } else if (addachivement.description && addachivement.description.length > 500) {
            errors.description = "Please write less than 500 characters";
        }
        if (Object.keys(errors).length === 0) {
            if (addachivement.id) {
                addachivement.achievement_id = addachivement.id;
                var res = await getTeacherEditAchievementById(addachivement);

            } else {
                var res = await getAeacherAddAchievement(addachivement);

            }
            if (res.status === "success") {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getTeacherAchievement();
                handleAddAcheModal(false);
            } else {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            setAchivementValidation(errors);
        }
    };
    const handleAddAcheModal = (val) => {
        setAddAchivement(initialValues);
        setAchivementValidation(initialValidation);
        setAddChivmentModal(val);
    };
    const handleEditAche = async (id) => {
        handleAddAcheModal(true)
        let reqData = {
            achievement_id: id,
        };
        let res = await getTeacherGetAchievementById(reqData);
        if (res.status === "success") {
            setAddAchivement(res.data);
        }
    };
    const getTeacherAchievement = async () => {
        let res = await getTeacherAchievementList();
        if (res.status === "success") {
            setData(res.data);
        }
    };

    useEffect(() => {
        getTeacherAchievement();
    }, []);
    return (
        <>
            <div className="row ">

                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header justify-content-between">
                            <h4 className="card-title">Achievement Details</h4>
                            <div className="d-flex align-items-center">
                                <Link
                                    to="#"
                                    className="btn btn-primary"
                                    title="Add"
                                    onClick={() => handleAddAcheModal(true)}
                                >
                                    <i className="fa-solid fa-plus"></i>
                                </Link>
                            </div>
                            <div
                                className="modal fade"
                                id="addachivement"
                                tabIndex={-1}
                                aria-labelledby="myModalLabel33"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-lg"></div>
                            </div>
                        </div>
                        {data?.length > 0 ? (
                            data?.map((item) => (
                                <div className="card-body">
                                    <ul className="d-flex mb-0">
                                        <li>
                                            <div className="activity1 bg-primary">
                                                <i className="fe fe-shopping-bag"></i>
                                            </div>
                                        </li>
                                        <li className="font-weight-semibold">
                                            {item?.title}
                                        </li>
                                        <div className="text-muted ms-auto">
                                            <Moment format="DD-MM-YYYY">{item?.date}</Moment>
                                            <Link
                                                to="#"
                                                onClick={() => handleEditAche(item?.id)}
                                                className="btn btn-primary ms-3 "
                                                title="Edit"
                                            >
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </Link>
                                        </div>
                                    </ul>

                                    <p className="text-muted mb-0 margin-left education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                    </p>
                                </div>
                            ))
                        ) : (
                            <NoDataAvailable />
                        )}

                    </div>
                </div>
            </div>
            {addChivmentModal && (
                <div
                    className={"modal fade show"}
                    id="editachivement"
                    tabIndex={-1}
                    aria-labelledby="myModalLabel33"
                    aria-hidden="true"
                    style={{ display: "block" }}
                >
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel33">
                                    Add Achievement Details
                                </h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    id="achivement-edit-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => handleAddAcheModal(false)}
                                />
                            </div>
                            <div className="modal-body">
                                <form action="#">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="title-name">
                                                Title <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => handleChangeAche(e, "title")}
                                                name="title"
                                                value={addachivement.title}
                                                id="title-name"
                                                className="form-control"
                                                placeholder="Enter title"
                                            />
                                            <p className="bg-error">{achivementValidation.title}</p>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="start-date">
                                                Date
                                            </label>
                                            <input
                                                type="date"
                                                max={moment().format("YYYY-MM-DD")}
                                                onChange={(e) => handleChangeAche(e, "date")}
                                                name="date"
                                                defaultValue={addachivement.date}
                                                id="start-date"
                                                className="form-control"
                                            />
                                            <p className="bg-error">{achivementValidation.date}</p>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label htmlFor="Description">
                                                Description
                                            </label>
                                            <Editor
                                                textareaName='description'
                                                placeholder='Enter description'
                                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                init={{
                                                    plugins: [
                                                        "advlist",
                                                        "autolink",
                                                        "lists",
                                                        "link",
                                                        "image",
                                                        "charmap",
                                                        "preview",
                                                        "anchor",
                                                        "searchreplace",
                                                        "visualblocks",
                                                        "code",
                                                        "fullscreen",
                                                        "insertdatetime",
                                                        "media",
                                                        "table",
                                                        "help",
                                                        "wordcount",
                                                        "spellchecker",
                                                    ],

                                                    toolbar:
                                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                }}
                                                value={addachivement.description}
                                                onEditorChange={(e) =>
                                                    setAddAchivement({
                                                        ...addachivement, description: e,
                                                    })
                                                }
                                            />
                                            <p className="bg-error">{achivementValidation.description}</p>
                                            <p style={{ position: "absolute", right: "15px" }}>
                                                {addachivement.description.length} / 500 Characters
                                            </p>
                                        </div>
                                        <div className="col-md-12 form-group text-end mt-5">
                                            <button
                                                type="button"
                                                onClick={handleAddAche}
                                                className="btn btn-primary me-2"
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="reset"
                                                className="btn btn-danger "
                                                onClick={() => handleAddAcheModal(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <ToastContainer />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </>
    );
};

export default Achievement;
