import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { replaceWord } from "../../../../utils/utils";
import { getTeacherBranchGetAllBranches } from "../../../../apis";
import { Formik } from "formik";
import Select from "react-select";
import SubmitLoader from "../../../common/SubmitLoader";

const Attendance = () => {
    const [classData, setclassData] = useState([]);
    const [classoffset, setClassOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [branch, setBranch] = useState([]);
    const [loader, setLoader] = useState(false);
    const [classSearch, setClassSearch] = useState({
        start: 0,
        perPage: 10,
        query: "",
        searchField: "",
        class_type: "",
        lecture_type: "",
        branch_id: "",
    });

    const [values, setValues] = useState({
        branch: "",
        class_type: ""
    });
    const [classcourseCount, setClassCourseCount] = useState();
    const apiCallForLecture = async (activity) => {
        setLoading(true)
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}teacher-course/get-all-class-courses`,
                activity,
                config
            );
            if (response) {
                setLoading(false)
                setLoader(false)
                setclassData(response.data?.data);
                setClassCourseCount(response.data?.count / classSearch.perPage);
            }
        } catch (err) {
            throw err;
        }
    };
    const getAllLectureData = async () => {
        const activity = {
            status: "all",
            limit: classSearch.perPage,
            offset: classSearch.start,
            query: classSearch.query,
        };

        await apiCallForLecture(activity);
    };
    const handlePageClickForClass = async (data) => {
        let currentPage = data.selected * classSearch.perPage;
        setClassOffset(currentPage);
        setClassSearch({
            ...classSearch,
            limit: classSearch.limit,
            offset: 0,
        })
        const activity = {
            status: "all",
            limit: classSearch.perPage,
            offset: currentPage,
            query: classSearch.query,
            branch_id: classSearch.branch?.value || undefined,
            class_type: classSearch.class_type || undefined,
            lecture_type: classSearch.lecture_type || undefined,
        };
        await apiCallForLecture(activity);
    };

    const handleSearch = (value) => {
        setClassSearch({
            ...classSearch,
            query: value,
        })
        apiCallForLecture({
            status: "all",
            limit: classSearch.perPage,
            offset: 0,
            query: value,
            branch_id: classSearch.branch?.value || undefined,
            class_type: classSearch.class_type || undefined,
            lecture_type: classSearch.lecture_type || undefined,
        });
    }

    const FormSubmit = async (values) => {
        setLoader(true)
        setClassSearch({
            ...classSearch,
            perPage: classSearch.perPage,
            offset: 0,
        })
        apiCallForLecture({
            status: "all",
            limit: classSearch.perPage,
            offset: 0,
            query: classSearch.query,
            branch_id: classSearch.branch?.value || undefined,
            class_type: classSearch.class_type || undefined,
            lecture_type: classSearch.lecture_type || undefined,
        });

    }

    const getAllBraches = async () => {
        let res = await getTeacherBranchGetAllBranches();
        if (res.status === "success") {

            const arr =
                res.data &&
                res.data.map((v) => {
                    return { value: v.id, label: v.name }
                });
            setBranch(arr);
        }
    };


    useEffect(() => {
        getAllLectureData();
        getAllBraches();
    }, []);

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Attendance</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Attendance Filter</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={FormSubmit}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Branch<span className="text-danger">*</span></label>
                                                <Select
                                                    onChange={(value) => {
                                                        props.setFieldValue("branch", value)
                                                        setClassSearch({ ...classSearch, branch: value })
                                                    }
                                                    }
                                                    options={branch}
                                                    value={props.values?.branch}
                                                    name="branch"
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Class Type </label>
                                                <select
                                                    className="form-select"
                                                    value={props.values.class_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "class_type",
                                                            e.target.value
                                                        );
                                                        setClassSearch({ ...classSearch, class_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select class type</option>
                                                    <option value="school">School (Till 12th Standard)</option>
                                                    <option value="university">University</option>
                                                    <option value="preparation">Entrance Exam / Professional Courses</option>
                                                    <option value="tuition">Tuition</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Classroom Type <span className="text-danger">*</span></label>
                                                <select className="form-select"
                                                    value={props.values.lecture_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "lecture_type",
                                                            e.target.value
                                                        );
                                                        setClassSearch({ ...classSearch, lecture_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select classroom type</option>
                                                    <option value='video_conference'>Online</option>
                                                    <option value='manual'>Offline</option>
                                                    <option value='both'>Hybrid (Online + Offline)</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group open-button">
                                                {loader ? (
                                                    <SubmitLoader />
                                                ) :
                                                    (
                                                        <button type="submit" title="Search" className="btn btn-primary">
                                                            <i className="fe fe-search" /> Search
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Attendance List</h4>
                        </div>
                        <div className="card-body">
                            <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </label>
                                </div>
                                <table className="table table-bordered table-hover dataTable table-responsive-xl table-responsive-xxxl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Class Details</th>
                                            <th>Branch Details</th>
                                            <th className="text-center text-nowrap">Class Type</th>
                                            <th className="text-center text-nowrap">Board/University</th>
                                            <th className="text-center text-nowrap">Section/Semester</th>
                                            <th className="text-center text-nowrap">Classroom Type</th>
                                            <th className="text-center">Medium</th>
                                            <th className="text-center">Overall Past</th>
                                            <th className="text-center">Today Attendance</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">View Subject</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td className='text-center' colSpan={12}>
                                                    Loading..
                                                </td>
                                            </tr>
                                        ) : classData.length === 0 ? (
                                            <tr>
                                                <td className='text-center' colSpan={12}>No Data Available !</td>
                                            </tr>
                                        ) : (
                                            classData.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th className="text-center">{i + classoffset + 1}</th>
                                                        <td>
                                                            <div className="media">
                                                                <img src={item?.thumbnail} alt={item?.class_name} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <Link to={"/teacher-dashboard/class-detail/" + item?.branch_id} className="font-weight-semibold">
                                                                        {item?.class_name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="media">
                                                                <img src={item?.institute_branch?.image} alt={item?.class_name} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <Link to={"/teacher-dashboard/branch-detail/" + item?.institute_branch?.id} className="font-weight-semibold">
                                                                        {item?.institute_branch?.name}
                                                                    </Link>
                                                                    <p className="mb-0">{item?.institute_branch?.email}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center text-capitalize">{replaceWord(item?.class_type)} {item?.class_type == "tuition" ? " : " + (replaceWord(item?.tuition_type)) : ""}</td>
                                                        <td className="text-center text-capitalize">{item?.board || item?.universities?.name || '---'}</td>
                                                        <td className="text-center text-capitalize">{item?.section || item?.semester || '---'}</td>
                                                        <td className="text-center">{replaceWord(item?.lecture_type)}</td>
                                                        <td className="text-center text-capitalize">{item?.medium || '---'}</td>
                                                        <td className='text-center text-capitalize'>
                                                            {item.marked_time_slot}/{item.time_slot}
                                                        </td>
                                                        <td className="text-center text-capitalize">
                                                            {item.today_marked_time_slot}/{item.today_time_slot}
                                                        </td>
                                                        <td className="text-capitalize">
                                                            {item?.status}
                                                        </td>
                                                        <td className="text-center">
                                                            <Link
                                                                className="btn btn-primary me-2"
                                                                to={`/teacher-dashboard/attendance-view-subject/${item?.id}`}
                                                            >
                                                                <i className="fa fa-eye"></i> View
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )}
                                    </tbody>
                                </table>
                                {classData.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={classcourseCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickForClass}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Attendance;
