import Utils from "../utils/utils";
export default class CartService {
  deleteCart(id) {
    return Utils.sendApiRequest(`remove-cart/${id}`, "DELETE", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
