import React from "react";
import { useEffect, useState } from "react";
import url from "../../../../../constants/url";
import moment from "moment";
import Moment from "react-moment";
const BookedSlotView = ({ id, aboutHandler, props, auth_token }) => {
    const [data, setData] = useState([]);
    const { start_date, end_date } = props?.values;
    const bookedSlotResult = data.map((item) => ({
        ...item,
        start_date: new Date(item.start_date + "T" + item.start_time),
    }));

    const [filteredData, setFilteredData] = useState(bookedSlotResult);

    const filterData = () => {
        const filtered = bookedSlotResult.filter((item) => {
            const itemDate = item.start_date.getTime();
            const start = start_date ? new Date(start_date).getTime() : 0;
            const end = end_date
                ? new Date(end_date).getTime()
                : Number.MAX_SAFE_INTEGER;
            return itemDate >= start && itemDate <= end;
        });
        const result = filtered.map((item) => ({
            ...item,
            start_date: moment(item.start_date).format("YYYY-MM-DD"),
        }));
        setFilteredData(result);
    };
    const getTeachersBookedSlot = async () => {
        if (auth_token) {
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", auth_token);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: "GET",
                headers: myHeaders,
            };
            fetch(
                url.apiUrl +
                `institute/get-teacher-time-schedule/${props?.values?.teachers || id
                }`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result && result?.data) {
                        setData(result?.data);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };
    useEffect(() => {
        filterData();
    }, [start_date, end_date]);
    useEffect(() => {
        if (id) {
            getTeachersBookedSlot();
        }
    }, [id]);
    const [newData, setNewData] = useState(
        isNaN(start_date) ? filteredData : data
    );
    useEffect(() => {
        setNewData(isNaN(start_date) ? filteredData : data);
    }, [newData, start_date, end_date, data, filteredData]);
    return (
        <div className='card'>
            <div className='card-header'>
                <h4 className='card-title'>Teacher Slot - Booked</h4>
            </div>
            <div className='card-body h-400 overflow-auto'>
                {newData.length === 0 ? (
                    <div className='d-flex justify-content-center'>
                        <p>No Data Available !</p>
                    </div>
                ) : (
                    <div className="row">
                        {newData.map((TimeTableValue, index) => {
                            const { date, start_date, end_date, start_time, end_time } = TimeTableValue;
                            return (
                                <div className="col-md-6" key={index}>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h4 className='font-weight-semibold text-center mb-0'>
                                                Date : <Moment format="DD-MM-YYYY">{date}</Moment>
                                            </h4>
                                            {/* <h4 className='font-weight-semibold text-center mb-0'>
                                                Date : <Moment format="DD-MM-YYYY">{start_date}</Moment> to <Moment format="DD-MM-YYYY">{end_date}</Moment>
                                            </h4> */}
                                            <p className="text-center mb-0">
                                                {moment(start_time, ["HH:mm"]).format("hh:mm A")}{" "}
                                                - {moment(end_time, ["HH:mm"]).format("hh:mm A")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
export default BookedSlotView;