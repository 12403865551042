import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import moment from "moment";
import CourseVideoService from "../../../../../Services/CourseVideoServiceTeacher";
import SubmitLoader from "../../../../common/SubmitLoader";
import Popup from "../../../../common/Popup";
import NoDataAvailable from "../../../../common/NoDataAvailable";

export default function Section({
    sectionId,
    sectionList,
    SectionlistApi,
    getSectionDetailApi,
    setSectionList,
}) {


    const [loader, setLoader] = useState(false);
    const serve = new CourseVideoService();
    const [openPreviewPopup, setOpenPreviewPopup] = useState({});
    const [openVideoPopup, setOpenVideoPopup] = useState({});

    const [videoDuration, setVideoduration] = useState("");
    const [previewDuration, setPreviewDuration] = useState("");
    const [data, setData] = useState([]);
    const [sectionListid, setSectionListId] = useState(null);
    const [active, setActive] = useState({});
    const [fileName, setFileName] = useState("");
    const [previewFileName, setPreviewFileName] = useState("");
    const [values, setValues] = useState({
        subheadline: "",
        video_type: "",
        uploadvideo: "",
        is_preview: "",
        preview_video_type: "",
        previewVideo: "",
    });

    const VoucherSchema = Yup.object().shape({
        subheadline: Yup.string().required("Required"),
        uploadvideo: Yup.mixed()
            .when("id", {
                is: (id) => !id,
                then: Yup.mixed().required("Required").test(
                    "fileFormat",
                    "Unsupported Format",
                    value => value && value.type === "video/mp4"
                )
            }),
        is_preview: Yup.string().required("Required"),
        previewVideo: Yup.mixed()
            .when(["is_preview", 'id'], {
                is: (is_preview, id) => is_preview && is_preview == "1" && !id,
                then: Yup.mixed().required("Required").test(
                    "fileFormat",
                    "Unsupported Format",
                    value => value && value.type === "video/mp4"
                ),
            })
            .nullable(),
    });

    const onSubmit = async (values) => {


        const formData = new FormData();
        if (values.id) {
            formData.set("id", values.id);
        }
        formData.set("course_id", sessionStorage.getItem("course_id"));
        formData.set("section_id", sectionListid);
        formData.set("title", values.subheadline);
        formData.set("video", values.uploadvideo);
        formData.set("file_name", values.file_name);
        formData.set("video_duration", values.video_duration);
        formData.set("is_preview", values.is_preview);
        formData.set("is_main_course", sessionStorage.getItem("is_main"));
        if (values.is_preview === "1" || values.is_preview === 1) {
            formData.set("preview_file_name", values.preview_file_name);
            formData.set("preview_video", values.previewVideo);
            formData.set("preview_duration", values.preview_duration);
        }

        let auth = JSON.parse(localStorage.getItem("common-user-info"));
        const config = {
            headers: {
                content: "multipart/form-data",
                AUTHTOKEN: auth && auth?.auth_token,
            },
        };
        if (values.id) {
            setLoader(true);
            try {

                const response = await axios.post(
                    process.env.REACT_APP_API_BASEURL + `teacher-course/update-video`,
                    formData,
                    config
                );

                if (response.data?.status === "success") {
                    setLoader(false);
                    toast.success("Video Edited Successfully");
                    SectionlistApi(sessionStorage.getItem("course_id"));

                    setActive((prev) => ({ [sectionListid]: !prev[sectionListid] }));
                } else {
                    setLoader(false);
                    toast.error(response.data?.message);
                }
            } catch (err) {
                throw err;
            }
        } else {
            setLoader(true);
            axios
                .post(
                    process.env.REACT_APP_API_BASEURL + "teacher-course/create-video",
                    formData,
                    config
                )
                .then((res) => {
                    if (res.data?.status === "success") {
                        setLoader(false);
                        toast.success("Video Added Successfully");
                        SectionlistApi(sessionStorage.getItem("course_id"));

                        setActive((prev) => ({ [sectionListid]: false }));
                        setValues({
                            subheadline: "",
                            uploadvideo: "",
                            is_preview: "",
                            previewVideo: "",
                        });
                    } else {
                        setLoader(false);
                        toast.error(res.data?.data?.video);
                    }
                })
                .catch((err) => {
                    throw err;
                });
        }
    };

    const getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = () => resolve(media.duration);
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });

    const handleChange = async (e, name, props) => {
        const duration = await getVideoDuration(e.target.files[0]);
        if (name === "uploadvideo") {
            props.setFieldValue("video_duration", duration);
        } else if (name === "previewVideo") {
            props.setFieldValue("preview_duration", duration);
        }
    };

    useEffect(() => {
        if (sectionId) {
            getDetailData();
        }
    }, []);

    const getDetailData = async () => {
        try {
            let response = await serve.sectiondetail(sectionId);
            if (response) {
                let title = "";
                let video = "";
                let preview_video = "";
                let is_preview = "";
                for (const i of response.data.lectures) {
                    title = i.title;
                    preview_video = i.preview_video;
                    is_preview = i.is_preview;
                }
                setValues({
                    id: response.data.id,
                    subheadline: title,
                    uploadvideo: "",
                    is_preview: is_preview,
                    previewVideo: preview_video,
                });
            }
        } catch (err) {
            throw err;
        }
    };

    const deleteHandle = async (dataId) => {
        try {
            if (window.confirm("Are you sure you want to delete this record?")) {
                let response = await serve.delete(
                    sessionStorage.getItem("is_main"),
                    dataId
                );
                if (response) {
                    toast.success("Successfully deleted");
                    let dataTemp = [...sectionList];

                    const remainingData = dataTemp.filter((v) => {
                        return v._id !== dataId;
                    });
                    setSectionList(remainingData);
                    SectionlistApi(sessionStorage.getItem("course_id"));
                } else {
                    toast.error("Some went wrong!");
                }
            }
        } catch (err) {
            throw err;
        }
    };

    const activeHandler = async (id, lectureId) => {

        try {
            let response = await serve.editvideo(
                sessionStorage.getItem("is_main"),
                lectureId
            );

            if (response) {

                setValues({
                    id: response.data.id,
                    subheadline: response.data.title,
                    uploadvideo: response.data.video,
                    is_preview: response.data.is_preview,
                    previewVideo: response.data.preview_video,

                    preview_file_name: response.data.preview_file_name,
                    preview_duration: response.data.preview_duration,
                    file_name: response.data.file_name,
                    video_duration: response.data.video_duration,
                });
            }
        } catch (err) {
            throw err;
        }
        setSectionListId(id);
        setActive((prev) => ({ [id]: true }));
    };

    const deleteSectionHandle = async (dataId) => {
        try {
            if (window.confirm("Are you sure you want to delete this record?")) {
                let response = await serve.deletesection(
                    sessionStorage.getItem("is_main"),
                    dataId
                );
                if (response) {
                    toast.success(response.message);
                    let dataTemp = [...sectionList];

                    const remainingData = dataTemp.filter((v) => {
                        return v._id !== dataId;
                    });
                    setSectionList(remainingData);
                    SectionlistApi(sessionStorage.getItem("course_id"));
                } else {
                    //toast.error("Some went wrong!");
                }
            }
        } catch (err) {
            throw err;
        }
    };

    const toggleHandler = async (data) => {
        const obj = {
            id: data.id,
            status: data.status === "active" ? "inactive" : "active",
            is_main_course: sessionStorage.getItem("is_main"),
        };

        try {
            const res = await serve.status(obj);

            if (res.status === "success") {
                SectionlistApi();
                toast.success(res.message);
            } else {
                toast.error(res.message);
            }
        } catch (err) {
            toast.error("something went wrong!");
            throw err;
        }
    };
    return (
        <Formik
            initialValues={values}
            validationSchema={VoucherSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {(props) => {
                return (
                    <form onSubmit={props.handleSubmit}>
                        {sectionList.map((v, i) => (
                            <div key={i}>
                                <div className='card mb-4'>
                                    <div className='card-header d-flex justify-content-between'>
                                        <h5 className='mb-0'>
                                            Chapter {i + 1} : {v.title}
                                            <button
                                                className='btn btn-outline-primary me-2'
                                                style={{ marginLeft: "20px" }}
                                                type='button'
                                                onClick={() => {
                                                    getSectionDetailApi(v.id);
                                                }}
                                            >
                                                <i className='fa-solid fa-pen-to-square'></i>
                                            </button>
                                            <Link
                                                to='#'
                                                className='btn btn-outline-danger'
                                                onClick={() => deleteSectionHandle(v.id)}
                                            >
                                                <i className='fa-solid fa-trash-can'></i>
                                            </Link>
                                        </h5>
                                        <div
                                            className='buttons'
                                            onClick={() => {
                                                props.resetForm();
                                                setActive((prev) => ({ [v.id]: true }));
                                                setSectionListId(v.id);
                                                setValues({
                                                    subheadline: "",
                                                    uploadvideo: "",
                                                    is_preview: "",
                                                    previewVideo: "",
                                                });
                                            }}
                                        >
                                            <Link className='btn btn-outline-primary' to='#'>
                                                <i className='fa fa-plus'></i> Add Video
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        {active[v.id] && (
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='form-group col-lg-6'>
                                                            <label>
                                                                Video Title
                                                                <span className='text-danger'>*</span>
                                                            </label>
                                                            <input
                                                                className='form-control'
                                                                name='subheadline'
                                                                value={props.values.subheadline}
                                                                onChange={props.handleChange}
                                                                onBlur={props.handleBlur}
                                                                type='text'
                                                                placeholder='Enter video title'
                                                            />
                                                            {props.touched.subheadline &&
                                                                props.errors.subheadline ? (
                                                                <div className='bg-error'>
                                                                    {props.errors.subheadline}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className='form-group col-lg-6'>
                                                            <label>Video
                                                                <span className='text-danger'>*</span></label>
                                                            <input
                                                                type='file'
                                                                accept='video/*'
                                                                className='form-control'
                                                                name='uploadvideo'
                                                                onChange={(e) => {
                                                                    props.setFieldValue(
                                                                        "uploadvideo",
                                                                        e.target.files[0]
                                                                    );
                                                                    props.setFieldValue(
                                                                        "file_name",
                                                                        e.target.files?.[0]?.name
                                                                    );
                                                                    handleChange(e, "uploadvideo", props);
                                                                }}
                                                            />

                                                            {props.values.uploadvideo &&
                                                                typeof props.values.uploadvideo ===
                                                                "string" ? (
                                                                <div className='form-text text-muted'>
                                                                    {props.values.uploadvideo
                                                                        .split("/")
                                                                        .at(-1)}
                                                                </div>
                                                            ) : null}
                                                            {props.touched.uploadvideo && props.errors.uploadvideo ? (
                                                                <div className='bg-error'>
                                                                    {props.errors.uploadvideo}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className='form-group col-lg-6'>
                                                            <label>
                                                                Whether free preview is allowed?{" "}
                                                                <span className='text-danger'>*</span>
                                                            </label>

                                                            <select
                                                                className='form-select'
                                                                onChange={props.handleChange}
                                                                value={props.values.is_preview}
                                                                name='is_preview'
                                                            >
                                                                <option value='' disabled>
                                                                    Select level
                                                                </option>
                                                                <option value='1'>Yes</option>
                                                                <option value='0'>No</option>
                                                            </select>
                                                            {props.touched.is_preview &&
                                                                props.errors.is_preview ? (
                                                                <div className='bg-error'>
                                                                    {props.errors.is_preview}
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {props.values.is_preview === "1" ||
                                                            props.values.is_preview === 1 ? (
                                                            <>
                                                                <div className='form-group col-lg-6'>
                                                                    <label>
                                                                        Upload Preview Video
                                                                        <span className='text-danger'>*</span>
                                                                    </label>
                                                                    <input
                                                                        className='form-control'
                                                                        name='previewVideo'
                                                                        onChange={(e) => {
                                                                            props.setFieldValue(
                                                                                "previewVideo",
                                                                                e.target.files[0]
                                                                            );
                                                                            props.setFieldValue(
                                                                                "preview_file_name",
                                                                                e.target.files?.[0]?.name
                                                                            );
                                                                            handleChange(e, "previewVideo", props);
                                                                        }}
                                                                        onBlur={props.handleBlur}
                                                                        type='file'
                                                                        accept='video/*'
                                                                    />

                                                                    {props.values.previewVideo &&
                                                                        typeof props.values.previewVideo ===
                                                                        "string" ? (
                                                                        <div className='form-text text-muted'>
                                                                            {props.values.previewVideo
                                                                                .split("/")
                                                                                .at(-1)}
                                                                        </div>
                                                                    ) : null}
                                                                    {props.touched.previewVideo &&
                                                                        props.errors.previewVideo ? (
                                                                        <div className='bg-error'>
                                                                            {props.errors.previewVideo}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </>
                                                        ) : null}
                                                        <div className='col-lg-12 text-end'>
                                                            {loader ? (
                                                                <SubmitLoader />
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        className='btn btn-primary me-2'
                                                                        type='submit'
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                    <button
                                                                        className='btn btn-danger' onClick={() => {
                                                                            props.resetForm();
                                                                            setActive((prev) => ({ [v.id]: false }));
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {v?.lectures?.length > 0 ? ( 
                                            <div className='card'>
                                                <div className='card-body'>
                                                    <table className='table table-bordered table-hover dataTable table-responsive-xl'>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">Sr. No.</th>
                                                                <th>Video Title</th>
                                                                <th className="text-center">Preview Video</th>
                                                                <th className="text-center">Live Video</th>
                                                                <th className='text-center'>Is Live</th>
                                                                <th className='text-center'>Date</th>
                                                                <th className='text-center'>Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {(v?.lectures?.map)((lecture, i) => (
                                                                <tr key={i}>
                                                                    <th className="text-center">{i + 1}</th>
                                                                    <td>
                                                                        <h6 className='mb-0'>
                                                                            {lecture?.title}
                                                                        </h6>
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {lecture?.is_preview ? (
                                                                            <Link
                                                                                to="#"
                                                                                onClick={() => { setOpenPreviewPopup({ [lecture.id]: true }); }}
                                                                                className="btn btn-primary">View Video
                                                                            </Link>
                                                                        ) : ("No preview available !")}
                                                                        {openPreviewPopup[lecture.id] && (
                                                                            <Popup
                                                                                className="videoIntor"
                                                                                title={lecture?.title}
                                                                                data={lecture?.preview_video}
                                                                                openPopup={openPreviewPopup}
                                                                                setOpenPopup={setOpenPreviewPopup}
                                                                                id={lecture.id}
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        <Link
                                                                            to="#"
                                                                            onClick={() => { setOpenVideoPopup({ [lecture.id]: true }); }}
                                                                            className="btn btn-primary">View Video
                                                                        </Link>
                                                                        {openVideoPopup[lecture.id] && (
                                                                            <Popup
                                                                                className="videoIntor"
                                                                                title={lecture?.title}
                                                                                data={lecture?.video}
                                                                                openPopup={openVideoPopup}
                                                                                setOpenPopup={setOpenVideoPopup}
                                                                                id={lecture.id}
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        <div className='checkbox checkbox-primary me-0'>
                                                                            <input
                                                                                id={lecture?.id}
                                                                                type='checkbox'
                                                                                checked={lecture?.status === "active"}
                                                                                onChange={() =>
                                                                                    toggleHandler(lecture)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {moment(lecture?.updated_at).format("DD-MM-YYYY")}
                                                                    </td>
                                                                    <td className='text-center text-nowrap'>
                                                                        <Link
                                                                            className='btn btn-outline-primary me-2'
                                                                            to='#'
                                                                            onClick={() =>
                                                                                activeHandler(v?.id, lecture?.id)
                                                                            }
                                                                        >
                                                                            <i className='fa-solid fa-pen-to-square'></i>
                                                                        </Link>
                                                                        <Link
                                                                            to='#'
                                                                            className='btn btn-outline-danger'
                                                                            onClick={() => deleteHandle(lecture?.id)}
                                                                        >
                                                                            <i className='fa-solid fa-trash-can'></i>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <ToastContainer />
                                            </div>
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </form>
                );
            }}
        </Formik>
    );
}
