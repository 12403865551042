import React, { useEffect, useState, useRef } from "react";
import Footer from '../../Footer/Footer';
import { Link, useParams, useHistory } from "react-router-dom";
import Headerinner from '../../Header/Headerinner';
import Curve from '../../Header/Curve';
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import SubmitLoader from "../../../common/SubmitLoader";
import * as Yup from "yup";
import axios from "axios";
import url from "../../../../constants/url";
import Moment from "react-moment";
import { replaceWord } from "../../../../utils/utils";

const CareerDetails = () => {
    const history = useHistory();
    const inputFile = useRef(null);
    const param = useParams();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);
    const [isApplyPopup, setIsApplyPopup] = useState(false);
    const initialValues = {
        name: "",
        email: "",
        contact: "",
        qualification: "",
        experience: "",
        resume: "",
        file: "",
        current_ctc: "",
        expected_ctc: "",
        notice_period: "",
    };
    const [value, setValue] = useState(initialValues);

    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Please enter valid email ID").required("Required"),
        contact: Yup.string()
            .required("Required")
            .min(13, "Please enter valid contact number")
            .max(13, "Please enter valid contact number"),
        qualification: Yup.string().required("Required"),
        experience: Yup.string().required("Required"),
        resume: Yup.mixed().required("Required"),
        current_ctc: Yup.mixed().required("Required"),
        expected_ctc: Yup.mixed().required("Required"),
        notice_period: Yup.mixed().required("Required"),
    });

    const onSubmit = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.set("slug", param.slug);
        formData.set("name", values.name);
        formData.set("email", values.email);
        formData.set("contact", values.contact);
        formData.set("qualification", values.qualification);
        formData.set("experience", values.experience);
        formData.set("current_ctc", values.current_ctc);
        formData.set("expected_ctc", values.expected_ctc);
        formData.set("notice_period", values.notice_period);
        formData.set("resume", values.resume);
        setLoader(true);
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASEURL + `apply-career`,
                formData,
                config
            );
            if (response.data.status === "success") {
                toast.success(response.data.message);
                resetForm();
                manageFormPopup(false)
                setLoader(false);
                if (inputFile.current) {
                    inputFile.current.value = "";
                }
            } else {
                toast.error(response.data.message);
                setLoader(false);
            }
        } catch (err) {
            throw err;
        }
    };

    const manageFormPopup = (value) => {
        setValue(initialValues);
        setIsApplyPopup(value);
    }

    const GetCareerDetail = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}career/${param.slug}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                } else {
                    // history.push('/404');
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        GetCareerDetail();
    }, []);

    return (
        <>
            <Headerinner />
            <div className="cover-image bg-background-1" data-bs-image-src="/assets/images/banners/banner1.webp" style={{ background: 'url("/assets/images/banners/banner1.webp") center center' }}>
                <div className="container header-text">
                    <h1>
                        {data?.title}
                    </h1>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="product-slider">
                                        <ul className="list-unstyled video-list-thumbs">
                                            <li className="mb-0">
                                                <div className="item-card7-img">
                                                    <img className="cover-image" src={data?.thumb_image}
                                                        alt={data?.title} />
                                                    <a className="bg-danger right top" href="#">{data?.employee_type}</a>
                                                    <a className="bg-danger right bottom" href="/"> {replaceWord(data?.class_type)} {data?.position}</a>
                                                    {data?.position === "other" && (
                                                        <a className="bg-danger right bottom" href="/">  {data?.other_position}</a>
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <Link className="btn btn-block btn-primary mb-3 mb-xl-0" to="#" onClick={() => manageFormPopup(true)} >Apply Now</Link>
                                    </div>
                                </div>
                                <div className="card-header"><h4 className="card-title">Job Info :</h4></div>
                                <table className="table table-hover dataTable">
                                    <tbody>
                                        {data?.job_by === "institute" && (
                                            <>
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">
                                                        Institute Name
                                                    </td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end">
                                                        <Link className="text-primary" to={`/institute-detail/${data?.institute?.institute_id}`}>
                                                            {data?.institute?.name}
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">
                                                        Branch Name
                                                    </td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end">
                                                        <Link className="text-primary" to={`/branch-detail/${data?.institute_branch?.branch_id}`}>
                                                            {data?.institute_branch?.name ? data?.institute_branch?.name : "---"}
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        {data?.job_by === "admin" && (
                                            <>
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">
                                                        Posted by
                                                    </td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end">
                                                        Education Mandal
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        <tr>
                                            <td className="font-weight-semibold">Location</td>
                                            <td className="w-0 text-center">:</td>
                                            <td className="text-end text-capitalize">{data?.location} {data?.city}, {data?.state}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">Salary</td>
                                            <td className="w-0 text-center">:</td>
                                            {data?.is_disclosed_salary === 1 ? (
                                                <td className="text-end text-capitalize">₹ {data?.salary}</td>
                                            ) : (
                                                <td className="text-end text-capitalize">Not Disclosed</td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">Experience</td>
                                            <td className="w-0 text-center">:</td>
                                            <td className="text-end text-capitalize">{data?.experience}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">Applicants</td>
                                            <td className="w-0 text-center">:</td>
                                            <td className="text-end text-capitalize">{data?.applications_count} Applicants</td>
                                        </tr>
                                        {data?.job_by === "institute" && (
                                            <tr>
                                                <td className="font-weight-semibold">Class Type</td>
                                                <td className="w-0 text-center">:</td>
                                                <td className="text-end text-capitalize"> {replaceWord(data?.class_type)}</td>
                                            </tr>
                                        )}
                                        {data?.job_by === "admin" && (
                                            <tr>
                                                <td className="font-weight-semibold">Postion</td>
                                                <td className="w-0 text-center">:</td>
                                                <td className="text-end text-capitalize"> {data?.position}</td>
                                            </tr>
                                        )}
                                        {data?.position === "other" && (
                                            <tr>
                                                <td className="font-weight-semibold">Other Position</td>
                                                <td className="w-0 text-center">:</td>
                                                <td className="text-end text-capitalize"> {data?.other_position}</td>
                                            </tr>
                                        )}
                                        {data?.class_type === "school" ? (
                                            <tr>
                                                <td className="text-nowrap font-weight-semibold">Board</td>
                                                <td className="w-0 text-center"> : </td>
                                                <td className="text-end text-capitalize">
                                                    {data?.board}
                                                </td>
                                            </tr>
                                        ) : (data?.class_type === "university" ? (
                                            <tr>
                                                <td className="text-nowrap font-weight-semibold">University</td>
                                                <td className="w-0 text-center"> : </td>
                                                <td className="text-end text-capitalize">
                                                    {data?.university}
                                                </td>
                                            </tr>
                                        ) : (data?.class_type === "tuition" && (
                                            <>
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">Tuition Type</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">
                                                        {replaceWord(data?.tuition_type)}
                                                    </td>
                                                </tr>
                                                {data?.tuition_type === "school" ? (
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">Board</td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end text-capitalize">
                                                            {data?.board}
                                                        </td>
                                                    </tr>
                                                ) : (data?.tuition_type === "university" && (
                                                    <>
                                                        <tr>
                                                            <td className="text-nowrap font-weight-semibold">University</td>
                                                            <td className="w-0 text-center"> : </td>
                                                            <td className="text-end text-capitalize">
                                                                {data?.university}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </>
                                        )))}
                                        {data?.job_by === "institute" && (
                                            <tr>
                                                <td className="font-weight-semibold">Medium </td>
                                                <td className="w-0 text-center">:</td>
                                                <td className="text-end text-capitalize">{data?.medium ? data?.medium : "---"}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td className="font-weight-semibold">Start Date</td>
                                            <td className="w-0 text-center">:</td>
                                            <td className="text-end"><Moment format="DD-MM-YYYY">{data?.start_date}</Moment></td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">End Date</td>
                                            <td className="w-0 text-center">:</td>
                                            {data?.is_end_job === 1 ? (
                                                <td className="text-end"><Moment format="DD-MM-YYYY">{data?.end_date}</Moment></td>
                                            ) : (
                                                <td className="text-end text-capitalize">Not Disclosed</td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">Last Update</td>
                                            <td className="w-0 text-center">:</td>
                                            <td className="text-end"><Moment format="DD-MM-YYYY hh:mm A">{data?.updated_at}</Moment></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12">
                            <div className="card">
                                <div className="card-header"><h4 className="card-title">Description</h4></div>
                                <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.description }}>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header"><h4 className="card-title">Requirements</h4></div>
                                <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.requirment }}>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header"><h4 className="card-title">Job Responsibilities</h4></div>
                                <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.job_responsibility }}>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header"><h4 className="card-title">About Company</h4></div>
                                <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.about_company }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <div
                className={isApplyPopup ? "modal fade show" : "modal fade"}
                id='apply-now'
                tabIndex={-1}
                aria-labelledby='InstituteAbout'
                aria-hidden='true'
                style={isApplyPopup ? { display: "block" } : { display: "none" }}
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h4 className='modal-title' id='myModalLabel33'>
                                Apply Now
                            </h4>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={() => {
                                    manageFormPopup(false)
                                }}
                            />
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={value}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                                validationSchema={ValidateSchema}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>Applicant Name <span className="text-danger">*</span></label>
                                                <input type="text"
                                                    value={props.values.name}
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    onChange={props.handleChange}
                                                    placeholder="Enter applicant name" />
                                                {props.touched.name && props.errors.name ? (
                                                    <div className="bg-error">
                                                        {props.errors.name}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Email ID <span className="text-danger">*</span></label>
                                                <input type="text" value={props.values.email} className="form-control" id="email"
                                                    name="email"
                                                    onChange={props.handleChange}
                                                    placeholder="Enter email id" />
                                                {props.touched.email && props.errors.email ? (
                                                    <div className="bg-error">
                                                        {props.errors.email}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Contact No <span className="text-danger">*</span></label>
                                                <PhoneInputWithCountrySelect
                                                    name="contact"
                                                    defaultCountry="IN"
                                                    className="form-control"
                                                    placeholder="Please enter contact no"
                                                    value={props.values.contact}
                                                    onChange={(e) => {
                                                        props.setFieldValue("contact", e);
                                                    }}
                                                />
                                                {props.touched.contact && props.errors.contact ? (
                                                    <div className="bg-error">
                                                        {props.errors.contact}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Highest Qualification <span className="text-danger">*</span></label>
                                                <input type="text" value={props.values.qualification} className="form-control"
                                                    id="qualification"
                                                    name="qualification"
                                                    onChange={props.handleChange}
                                                    placeholder="Enter highest qualification" />
                                                {props.touched.qualification && props.errors.qualification ? (
                                                    <div className="bg-error">
                                                        {props.errors.qualification}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Experience <span className="text-danger">*</span></label>
                                                <input type="text" value={props.values.experience} className="form-control"
                                                    id="experience"
                                                    name="experience"
                                                    onChange={props.handleChange}
                                                    placeholder="Enter experience" />
                                                <p className="mb-0">Eg.: 8 Months or 3 Years</p>
                                                {props.touched.experience && props.errors.experience ? (
                                                    <div className="bg-error">
                                                        {props.errors.experience}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-6 form-group">
                                                <label>Notice Period <span className="text-danger">*</span></label>
                                                <select className="form-select" value={props.values.notice_period}
                                                    name="notice_period"
                                                    onChange={props.handleChange}>
                                                    <option defaultValue="">Select notice period</option>
                                                    <option>Immediate</option>
                                                    <option>1 Month</option>
                                                    <option>2 Months</option>
                                                    <option>3 Months</option>
                                                    <option>More then 3 Months</option>
                                                </select>
                                                {props.touched.notice_period && props.errors.notice_period ? (
                                                    <div className="bg-error">
                                                        {props.errors.notice_period}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Current CTC (Per annum) <span className="text-danger">*</span></label>
                                                <input type="number" value={props.values.current_ctc} className="form-control"
                                                    id="current_ctc"
                                                    name="current_ctc"
                                                    onChange={props.handleChange}
                                                    placeholder="Enter current ctc (per annum)" />
                                                {props.touched.current_ctc && props.errors.current_ctc ? (
                                                    <div className="bg-error">
                                                        {props.errors.current_ctc}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Expected CTC (Per annum) <span className="text-danger">*</span></label>
                                                <input type="number" value={props.values.expected_ctc} className="form-control"
                                                    id="expected_ctc"
                                                    name="expected_ctc"
                                                    onChange={props.handleChange}
                                                    placeholder="Enter expected ctc (per annum)" />
                                                {props.touched.expected_ctc && props.errors.expected_ctc ? (
                                                    <div className="bg-error">
                                                        {props.errors.expected_ctc}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Upload Resume <span className="text-danger">*</span></label>
                                                <input type="file" className="form-control" name="file" accept=".doc,.docx,.pdf"
                                                    ref={inputFile}
                                                    onChange={(e) =>
                                                        props.setFieldValue("resume", e.target.files[0])
                                                    } />
                                                {props.touched.resume && props.errors.resume ? (
                                                    <div className="bg-error">
                                                        {props.errors.resume}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-12 text-end">
                                                {loader ? (
                                                    <SubmitLoader />
                                                ) : (
                                                    <>
                                                        <button type="submit" className="btn btn-primary me-2">
                                                            Submit
                                                        </button>
                                                        <button className="btn btn-danger"
                                                            type="button"
                                                            onClick={() => {
                                                                props.resetForm()
                                                                manageFormPopup(false)
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Footer />
        </>
    );
};
export default CareerDetails;