import React from 'react';
import Footer from "../Footer/Footer";
import Headerinner from '../Header/Headerinner';
import RegsiterHere from '../Homepage/RegsiterHere';
import Curve from '../Header/Curve';

const Registration = () => {
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Register Here !</h1>
                </div>
            </div>
            <Curve/>
            <RegsiterHere />
            <Footer />
        </>
    );
};

export default Registration;