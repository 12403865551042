import utils from "../utils/utils";
export default class CourseVideoService {
  sectionlist(is_main, id) {
    return utils
      .sendApiRequest(
        "institute-course/get-section-list/" + is_main + "/" + id,
        "GET",
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  sectiondetail(is_main, id) {
    return utils
      .sendApiRequest(
        "institute-course/get-section/" + is_main + "/" + id,
        "GET",
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  deletesection(is_main, id) {
    return utils
      .sendApiRequest("institute-course/delete-section/" + is_main + "/" + id, "DELETE", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  delete(is_main, id) {
    return utils
      .sendApiRequest("institute-course/delete-video/" + is_main + "/" + id, "DELETE", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  editvideo(is_main, id) {
    return utils
      .sendApiRequest("institute-course/get-video-detail/" + is_main + "/" + id, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  status(payload) {
    return utils
      .sendApiRequest(
        "institute-course/video-change-status",
        "POST",
        true,
        payload
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
