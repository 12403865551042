import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import url from "../../../../../constants/url";
import Moment from "react-moment";
import NoDataAvailable from "../../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const StudentDetails = () => {
    const params = useParams();
    const id = params?.id;
    const [data, setData] = useState([]);

    const GetTeacherProfile = async () => {
        var localdata = localStorage.getItem("common-user-info");
        var jsondata = JSON.parse(localdata);
        var token_authorization = jsondata?.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token_authorization);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute-teacher/get-profile/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result?.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        GetTeacherProfile();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Teacher Details</h4>
                        </div>

                        <div className="card-body">
                            <div className="card">
                                <Tabs defaultActiveKey="about" id="uncontrolled-tab-example">
                                    <Tab eventKey="about" title="about">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">About</h4>
                                            </div>
                                            <div className="card-body" dangerouslySetInnerHTML={{ __html: data?.about ? data?.about : " No Data Available ! " }}></div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="branch" title="branch">
                                        <div className="row">
                                            {data?.branches?.length > 0 ? (
                                                data?.branches.map((item, i) => (
                                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
                                                        <Link to={`/institute-dashboard/branch-detail/${item?.id}`}>
                                                            <div className="card">
                                                                <div className="card-body text-center">
                                                                    <div className="item-card7-img">
                                                                        <img src={item?.image} className="cover-image" alt={item?.name} />
                                                                    </div>
                                                                    <h4 className="mt-4 mb-2 leading-tight">
                                                                        {item?.name}
                                                                    </h4>
                                                                    <p className="text-center mb-0">
                                                                        Year of Incorporation <strong>{item?.year_of_startup ? item?.year_of_startup : "---"}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            ) : (
                                                <NoDataAvailable />
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="education" title="education">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Education</h4>
                                            </div>
                                            {data?.education?.length > 0 ? (
                                                data?.education?.map((item, key) => (
                                                    <div className="card-body" key={key}>
                                                        <ul className="d-flex mb-0">
                                                            <li>
                                                                <div className="activity1 bg-primary">
                                                                    <i className="fe fe-shopping-bag"></i>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="font-weight-semibold">
                                                                    {item?.degree} - ( {item?.field_of_study} )
                                                                </div>
                                                                <p className="text-muted mb-0">
                                                                    {item?.school_college}
                                                                </p>
                                                            </li>
                                                            <div className="text-muted ms-auto">
                                                                <Moment format="DD-MM-YYYY">
                                                                    {item?.start_date}
                                                                </Moment> -  {item?.is_present === 1 ? (
                                                                    "Present"
                                                                ) : (
                                                                    <Moment format="DD-MM-YYYY">
                                                                        {item?.end_date}
                                                                    </Moment>
                                                                )}
                                                            </div>
                                                        </ul>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="card-body">
                                                    <NoDataAvailable />
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="experience" title="experience">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Experience</h4>
                                            </div>
                                            {
                                                data?.experience?.length > 0 ? (
                                                    data?.experience?.map((item, key) => (
                                                        <div className="card-body" key={key}>
                                                            <ul className="d-flex mb-0">
                                                                <li>
                                                                    <div className="activity1 bg-primary">
                                                                        <i className="fe fe-shopping-bag"></i>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="font-weight-semibold">
                                                                        {item?.title} - ({item?.employement_type === "full_time" ? "Full Time" : (item?.employement_type === "part_time" ? "Part Time" : item?.employement_type)} )
                                                                    </div>
                                                                    <p className="text-muted mb-0">
                                                                        {item?.location}
                                                                    </p>
                                                                    <p className="text-muted mb-0">
                                                                        {item?.company_name}
                                                                    </p>
                                                                </li>
                                                                <div className="text-muted ms-auto">
                                                                    <Moment format="DD-MM-YYYY">
                                                                        {item?.start_date}
                                                                    </Moment> - {item?.is_present === 1 ? (
                                                                        "Present"
                                                                    ) : (
                                                                        <Moment format="DD-MM-YYYY">
                                                                            {item?.end_date}
                                                                        </Moment>
                                                                    )}
                                                                </div>
                                                            </ul>
                                                            <p className="text-muted mb-0 margin-left education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                            </p>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="card-body">
                                                        <NoDataAvailable />
                                                    </div>
                                                )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="achievement" title="achievement">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Achievement</h4>
                                            </div>
                                            {
                                                data?.achievement?.length > 0 ? (
                                                    data?.achievement?.map((item, key) => (
                                                        <div className="card-body" key={key}>
                                                            <ul className="d-flex mb-0">
                                                                <li>
                                                                    <div className="activity1 bg-primary">
                                                                        <i className="fe fe-shopping-bag"></i>
                                                                    </div>
                                                                </li>
                                                                <li className="font-weight-semibold">
                                                                    {item?.title}
                                                                </li>
                                                                <div className="text-muted ms-auto">
                                                                    <Moment format="DD-MM-YYYY">
                                                                        {item?.date}
                                                                    </Moment>
                                                                </div>
                                                            </ul>
                                                            <p className="text-muted mb-0 margin-left1 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                            </p>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="card-body">
                                                        <NoDataAvailable />
                                                    </div>
                                                )}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="reviews" title="reviews">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Review</h4>
                                            </div>
                                            {
                                                data?.course_review?.length > 0 ? (
                                                    data?.course_review?.map((item, key) => (
                                                        <div className="card-body" key={key}>
                                                            <div className="media mt-0 p-4 border br-5 review-media">
                                                                <div className="d-flex me-3">
                                                                    <a href="#">
                                                                        <img
                                                                            className="border-radius-50 avatar-lg"
                                                                            alt={item?.name}
                                                                            src={item?.student?.image}
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h4 className="mt-0 mb-1 font-weight-semibold">
                                                                        {item?.name}
                                                                        <small className="text-muted float-end">
                                                                            <Moment format="DD-MM-YYYY hh:mm A">
                                                                                {item?.created_at}
                                                                            </Moment>
                                                                        </small>
                                                                    </h4>
                                                                    <span className="d-inline-flex">
                                                                        <div className="br-widget">
                                                                            {rating.map((el, i) => (
                                                                                <i
                                                                                    data-rating-value={el}
                                                                                    data-rating-text={el}
                                                                                    className={
                                                                                        el <= item?.average_review
                                                                                            ? "br-selected"
                                                                                            : ""
                                                                                    }
                                                                                    key={i}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </span>
                                                                    <p className="mb-2 mt-2 education-mandal" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="card-body">
                                                        <NoDataAvailable />
                                                    </div>
                                                )}

                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default StudentDetails;
