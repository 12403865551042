import * as Yup from "yup";

// achievement Validation
export const achievementSchema = Yup.object().shape({
    title: Yup.string().min(2, "Please write more than 2 characters !").required("Required"),
    date: Yup.string().min(2, "Please write more than 2 characters !").required("Required"),
    description: Yup.string()
      .min(2, "Please write more than 2 characters !")
      .max(500, "Please write less than 500 characters !")
      .required("Required"),
  });
  