import React from "react";
import { Link, useHistory } from "react-router-dom";
import { replaceWord } from "../../../../utils/utils";
let rating = [1, 2, 3, 4, 5];

const ClassCard = ({ item, key, deleteCartApi }) => {
    const history = useHistory()
    let now = new Date().setHours(0, 0, 0, 0);
    return (
        <div className="col-md-6">
            <div className="card">
                <div className="card-body">
                    <Link to={`/classroom-course-detail/${item?.id}`}>
                        <div className="item-card7-img">
                            <img
                                src={item?.class_course?.thumbnail} alt={item?.class_course?.title}
                                className="cover-image"
                            />
                            {(
                                item?.class_course?.price == "paid" && (
                                    item?.class_course?.is_discount == 1 && (
                                        (item?.class_course?.is_limited_offer == 1 && (new Date(item?.class_course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.class_course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                        (item?.class_course?.is_limited_offer == 0 && new Date(item?.class_course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                )
                            ) && (
                                    <Link to="#" className="bg-danger top left">
                                        {item?.class_course?.discount_price}% Off
                                    </Link>

                                )}
                            <Link to="#" className="bg-danger top right">
                                Classroom Course
                            </Link>
                            {item?.class_type === "school" ? (
                                <Link to="#" className="bg-danger right bottom">
                                    {item?.board}
                                </Link>
                            ) : (item?.class_type === "university" ? (
                                <Link to="#" className="bg-danger right bottom">
                                    {item?.universities?.name}
                                </Link>
                            ) : (item?.class_type === "tuition" && (
                                <Link to="#" className="bg-danger right bottom">
                                    {replaceWord(item?.tuition_type)}
                                </Link>
                            )))}
                        </div>
                        <div className="item-card2-desc">
                            <div className="d-inline-flex mb-2">
                                <div className="br-widget">
                                    {rating.map((el, i) => (
                                        <i
                                            data-rating-value={el}
                                            data-rating-text={el}
                                            className={
                                                el <= item?.class_course?.average_review
                                                    ? "br-selected"
                                                    : ""
                                            }
                                            key={i}
                                        />
                                    ))}
                                </div>
                                <span>({item?.class_course?.review_count} Reviews)</span>
                            </div>
                            <h4 className="mb-2 leading-tight2">
                                {item?.class_course?.title}
                            </h4>
                            <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.class_course?.description }}>
                            </p>

                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                <div className="d-flex justify-content-start">
                                    <i className="fa fa-shop me-2" />
                                    <span className="text-muted text-data-over-cut">
                                        {item?.class_course?.institute?.name}
                                    </span>
                                </div>
                                <div className="float-end d-flex justify-content-start">
                                    <i className="fa fa-building me-2" />
                                    <span className="text-muted text-capitalize text-data-over-cut">
                                        {replaceWord(item?.class_course?.class_type)}
                                    </span>
                                </div>
                            </div>
                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                <div className="d-flex justify-content-start">
                                    <i className="fa fa-file me-2" />
                                    <span className="text-muted text-data-over-cut">
                                        {replaceWord(item?.class_course?.lecture_type)}
                                    </span>
                                </div>
                                <div className="float-end">
                                    {item?.class_course?.price === "paid" && (
                                        <h4 className="mb-0"> ₹ {Math.round(item?.class_course?.payable_price)} {(
                                            item?.class_course?.price == "paid" && (
                                                item?.class_course?.is_discount == 1 && (
                                                    (item?.class_course?.is_limited_offer == 1 && (new Date(item?.class_course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.class_course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                    (item?.class_course?.is_limited_offer == 0 && new Date(item?.class_course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                            )
                                        ) && (<del className="fs-14 text-muted">₹ {Math.round(item?.class_course?.regular_price)} </del>
                                            )}
                                        </h4>
                                    )}
                                    {item?.class_course?.price === "free" && (
                                        <h4 className="mb-0">Free</h4>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div className="item-card2-desc text-center">
                        <Link onClick={() => deleteCartApi(item?.id)}
                            to="#"
                            className="btn btn-danger"
                        > <i className="fe fe-trash"></i> Delete
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClassCard;