import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Loader from "../Pages/common/loader";

import InstituteApp from "../Pages/InstituteDashboard/InstituteApp";
import TeacherApp from "../Pages/TeacherDashboard/TeacherApp";
import StudentApp from "../Pages/StudentDashboard/StudentApp";

import Registration from "../Pages/Web/Registration/Registration";
import Institute from "../Pages/Web/Registration/Institute/Institute";
import Student from "../Pages/Web/Registration/Student/Student";
import Login from "../Pages/LoginArea/Login";
import ForgotPassword from "../Pages/LoginArea/ForgotPassword";
import ResetPassword from "../Pages/LoginArea/ResetPassword";

import HomePage from "../Pages/Web/Homepage/HomePage";
import About from "../Pages/Web/WebSitePages/About/About";
import Services from "../Pages/Web/WebSitePages/Services/Services";
import ContactUs from "../Pages/Web/WebSitePages/ContactUs/ContactUs";
import WorkWithUs from "../Pages/Web/WebSitePages/WorkWithUs/WorkWithUs";

import Blog from "../Pages/Web/WebSitePages/Blog/Blog";
import BlogDetails from "../Pages/Web/WebSitePages/Blog/BlogDetails/BlogDetails";

import Teacher from "../Pages/Web/WebSitePages/Teacher/Teacher";
import TeacherDetail from "../Pages/Web/WebSitePages/Teacher/TeacherDetail";

import Class from "../Pages/Web/WebSitePages/Class/Class";
import ClassDetail from "../Pages/Web/WebSitePages/Class/ClassDetail";

import Explore from "../Pages/Web/Homepage/ExploreSearch/Explore";
import VideoCourseList from "../Pages/Web/WebSitePages/VideoCourse/VideoCourseList";
import VideoCourseDetails from "../Pages/Web/WebSitePages/VideoCourse/VideoCourseDetails";

import Institutes from "../Pages/Web/WebSitePages/Institutes/Institutes";
import InstituteProfile from "../Pages/Web/WebSitePages/Institutes/InstituteProfile";
import BranchProfile from "../Pages/Web/WebSitePages/Branch/BranchProfile";

import Cart from "../Pages/Web/WebSitePages/Cart/Cart";
import Success from "../Pages/Web/WebSitePages/Success/Success";
import StudentDetail from "../Pages/Web/WebSitePages/Students/StudentDetail";

import AllPolicy from "../Pages/Web/WebSitePages/Policy/AllPolicy";
import PrivacyPolicy from "../Pages/Web/WebSitePages/Policy/PrivacyPolicy/PrivacyPolicy";
import TermsOffer from "../Pages/Web/WebSitePages/Policy/TermsOffer/TermsOffer";
import Disclaimer from "../Pages/Web/WebSitePages/Policy/Disclaimer/Disclaimer";

import Careers from "../Pages/Web/WebSitePages/Careers/Careers";
import CareerDetails from "../Pages/Web/WebSitePages/Careers/CareerDetails";
import NotFound from "../Pages/Web/WebSitePages/NotFound/NotFound";

import StudentAlumni from "../Pages/Web/WebSitePages/Alumni/StudentAlumni";
import Alumni from "../Pages/Web/WebSitePages/Alumni/Alumni";
import AlumniDetail from "../Pages/Web/WebSitePages/Alumni/AlumniDetail";


import Pricing from "../Pages/Web/WebSitePages/Pricing/Pricing";
import PricingDetails from "../Pages/Web/WebSitePages/Pricing/PricingDetails";

const Routing = () => {
    const [isLoader, setisLoader] = useState(true);
    const { pathname } = useLocation();

    useEffect(() => {
        setisLoader(false);
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }, [pathname]);

    return (
        <>
            {isLoader ? <Loader />
                :
                <Switch>
                    <Route path="/institute-dashboard" component={InstituteApp} />
                    <Route path="/teacher-dashboard" component={TeacherApp} />
                    <Route path="/student-dashboard" component={StudentApp} />

                    <Route path="/registration" component={Registration} />
                    <Route path="/Institute" component={Institute} />
                    <Route path="/institute-resubmission/:token/:email" component={Institute} />
                    <Route path="/Student" component={Student} />
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/reset-password/:token/:type/:email" component={ResetPassword} />

                    <Route exact path="/" component={HomePage} />
                    <Route path="/about-us" component={About} />
                    <Route exact path="/services" component={Services} />
                    <Route path="/contact-us" component={ContactUs} />
                    <Route path="/work-with-us" component={WorkWithUs} />

                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/blog/:id" component={Blog} />
                    <Route path="/blog-detail/:id" component={BlogDetails} />

                    <Route path="/teacher" component={Teacher} />
                    <Route path="/teacher-detail/:id" component={TeacherDetail} />

                    <Route path="/classroom-course" component={Class} />
                    <Route path="/classroom-course-detail/:id" component={ClassDetail} />

                    <Route exact path="/explore" component={Explore} />
                    <Route path="/search" component={Explore} />
                    <Route path="/video-course" component={VideoCourseList} />
                    <Route exact path="/video-course-detail/:id" component={VideoCourseDetails} />

                    <Route path="/institutes" component={Institutes} />
                    <Route path="/institute-detail/:id" component={InstituteProfile} />
                    <Route path="/branch-detail/:id" component={BranchProfile} />
                    <Route path="/student-detail/:id" component={StudentDetail} />

                    <Route path="/cart" component={Cart} />
                    <Route path="/success" component={Success} />

                    <Route path="/all-policy" component={AllPolicy} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-offer" component={TermsOffer} />
                    <Route path="/disclaimer" component={Disclaimer} />

                    <Route path="/careers-list" component={Careers} />
                    <Route path="/career-detail/:slug" component={CareerDetails} />
                    <Route path="/404" component={NotFound} />

                    <Route path="/student-alumni" component={StudentAlumni} />
                    <Route path="/alumni-list" component={Alumni} />
                    <Route path="/alumni-detail" component={AlumniDetail} />

                    <Route path="/pricing" component={Pricing} />
                    <Route path="/pricing-details" component={PricingDetails} />
                </Switch>
            }
        </>
    );
};

export default Routing;