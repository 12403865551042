import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import CardStatistics from "./CardStatistics";
import Course from "./Course";
import { Context } from "../../../../Context";
import PasswordPopup from "../../../LoginArea/PasswordPopup";

const ProfileDashboard = () => {
    const { activities } = useContext(Context);
    const [active, setActive] = activities;
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/student-dashboard">Dashboard</Link>
                    </li>
                </ol>
            </div>
            <div className="row">
                <CardStatistics />
            </div>
            <div className="row match-height">
                <Course />
            </div>
            {active ? <PasswordPopup /> : ""}
        </>
    );
};

export default ProfileDashboard;
