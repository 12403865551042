import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./Events";
import {
    getAllSubjects,
    getInstituteBranchGetAllBranches,
} from "../../../../../apis";
import url from "../../../../../constants/url";
import TimeSlotFilterComponents from "./TimeSlotFilterComponents";
import BookedSlotView from "./BookedSlotView";
import BookedClassSlotView from "./BookedClassSlotView";
import { toast } from "react-toastify";
import SubmitLoader from "../../../../common/SubmitLoader";
import moment from "moment";

export default function SlotDetails({ props, timeslots, setTimeslots, loader }) {
    const [branch, setBranch] = useState([]);
    const [boards, setboards] = useState([]);
    const [universities, setuniversities] = useState([]);
    const [classes, setClasses] = useState([]);
    const [sections, setsections] = useState([]);
    const [semester, setsemester] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [teachers, setteachers] = useState([]);
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [currentEvents, setCurrentEvents] = useState([]);

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    const getAllBraches = async () => {
        let res = await getInstituteBranchGetAllBranches();
        if (res.status === "success") {
            const arr =
                res.data &&
                res.data.map((v) => {
                    return { name: v.text, id: v.id };
                });
            setBranch(arr);
        }
    };

    const getBoards = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type: props?.values?.lectureSelected === "tuition"
                    ? props?.values?.tuitionType
                    : props?.values?.tuitionType,
                required_field: "board",
            }),
        };
        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    let found = result.data.find(
                        (cls) => cls.board === props?.values?.boardSelected
                    );
                    if (!found) {
                        props.setFieldValue(`boardSelected`, "");
                    }
                    setboards(result.data);
                } else {
                    props.setFieldValue(`boardSelected`, "");
                    setboards([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getUniversities = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type: props?.values?.lectureSelected === "tuition"
                    ? props?.values?.tuitionType
                    : props?.values?.tuitionType,
                required_field: "university",
            }),
        };
        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    let found = result.data.find(
                        (cls) => cls.id === props?.values?.universitySelected
                    );
                    if (!found) {
                        props.setFieldValue(`universitySelected`, "");
                    }
                    setuniversities(result.data);
                } else {
                    props.setFieldValue(`universitySelected`, "");
                    setuniversities([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getClass = async (body) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(body),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status === "success") {
                    let found = result.data.find(
                        (cls) => cls.class_name === props?.values?.classSelected
                    );
                    if (!found) {
                        props.setFieldValue(`classSelected`, "");
                    }
                    setClasses(result.data);
                } else {
                    props.setFieldValue(`classSelected`, "");
                    setClasses([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getsections = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: props?.values?.branchSelected,
                class_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.lectureSelected,
                required_field: "section",
                class_name: props?.values?.classSelected,
            }),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setsections(
                        result.data.map((section) => ({
                            name: section.section,
                            id: section.section,
                        }))
                    );
                } else {
                    props.setFieldValue(`sectionsSelected`, []);
                    setsections([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getsemesters = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "semester",
                class_name: props?.values?.classSelected,
                university: props?.values?.universitySelected,

            }),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setsemester(
                        result.data.map((section) => ({
                            name: section.semester,
                            id: section.semester,
                        }))
                    );
                } else {
                    props.setFieldValue(`semesterSelected`, "");
                    setsemester([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getSubjects = async (data) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(data),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status === "success") {
                    setSubjects(result.data);
                } else {
                    props.setFieldValue(`subjectSelected`, "");
                    setSubjects([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getTeacher = async (data) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(data),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    let exists = result.data.find(
                        (item) => item.subject === props?.values?.subjectSelected
                    );
                    if (!exists) {
                        props.setFieldValue(`teacher`, "");
                    }
                    setteachers(result.data);
                } else {
                    props.setFieldValue(`teacher`, "");
                    setteachers([]);
                    // toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getTimeSlots = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_id: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type: props?.values?.tuitionType,
                class_name: props?.values?.classSelected,
                section: props?.values?.sectionsSelected,
                board: props?.values?.boardSelected,
                university: props?.values?.universitySelected,
                semester: props?.values?.semesterSelected,
                subject: props?.values?.subjectSelected,
            }),
        };
        fetch(url.apiUrl + "institute/get-class-time-slot", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setTimeslots(result.data);
                } else {
                    setTimeslots([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    function renderEventContent(eventInfo) {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        );
    }

    const handleDateSelect = (selectInfo) => {
        let title = prompt("Please enter a new title for your event");
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay,
            });
        }
    };

    const handleEventClick = (clickInfo) => {
        if (
            window.confirm(
                `Are you sure you want to delete the event '${clickInfo.event.title}'`
            )
        ) {
            clickInfo.event.remove();
        }
    };

    const handleEvents = (events) => {
        setCurrentEvents(events);
    };

    useEffect(() => {
        getAllBraches();
    }, []);

    useEffect(() => {
        setClasses([]);
        setSubjects([]);
        props.setFieldValue(`subjectSelected`, "");

        props.setFieldValue(`semesterSelected`, "");
        if (props?.values?.lectureSelected === "school" || (props?.values?.lectureSelected === "tuition" && props?.values?.tuitionType === "school")) {
            props.setFieldValue(`universitySelected`, "");
            props.setFieldValue(`semesterSelected`, "");
            getBoards();
        }
        if (props?.values?.lectureSelected === "university" || (props?.values?.lectureSelected === "tuition" && props?.values?.tuitionType === "university")) {
            props.setFieldValue(`boardSelected`, "");
            props.setFieldValue(`sectionsSelected`, "");

            getUniversities();
        }
        if (props?.values?.lectureSelected == "preparation" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "preparation")) {
            props.setFieldValue(`classSelected`, "");
            setClasses([]);
            getClass({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "class_name",
            });
        }
    }, [props?.values?.lectureSelected, props?.values?.tuitionType]);

    useEffect(() => {
        setClasses([]);
        if (props?.values?.boardSelected && (props?.values?.lectureSelected == "school" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "school"))) {
            props.setFieldValue(`classSelected`, "");
            setClasses([]);
            getClass({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "class_name",
                section: props?.values?.sectionsSelected,
                board: props?.values?.boardSelected,
            });
        }
        if (props?.values?.universitySelected && (props?.values?.lectureSelected == "university" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "university"))) {
            props.setFieldValue(`classSelected`, "");
            setClasses([]);
            getClass({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "class_name",
                university: props?.values?.universitySelected,
                semesters: props?.values?.semesterSelected,
            });
        }

    }, [props?.values?.boardSelected, props?.values?.universitySelected])

    useEffect(() => {
        if (props?.values?.boardSelected != "" && props?.values?.classSelected != "" && props?.values?.lectureSelected === "school") {
            getsections();
        }
        if (props?.values?.universitySelected != "" && props?.values?.classSelected != "" && (props?.values?.lectureSelected === "university" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "university"))) {
            getsemesters();
        }
        if (props?.values?.lectureSelected === "preparation" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "preparation")) {
            setSubjects([]);
            getSubjects({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,

                required_field: "subject",
                class_name: props?.values?.classSelected,
            });
        }

        if ((props?.values?.sectionsSelected && props?.values?.sectionsSelected != "" && props?.values?.lectureSelected == "school") || (props?.values?.classSelected && (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "school"))) {
            getSubjects({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,

                required_field: "subject",
                class_name: props?.values?.classSelected,
                section: props?.values?.sectionsSelected,
                board: props?.values?.boardSelected,
            });
        }
        if (props?.values?.semesterSelected && props?.values?.semesterSelected != "" && (props?.values?.lectureSelected == "university" || props?.values?.tuitionType == "university")) {
            getSubjects({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,

                required_field: "subject",
                class_name: props?.values?.classSelected,
                university: props?.values?.universitySelected,
                semesters: props?.values?.semesterSelected,
            });
        }
    }, [props?.values?.classSelected, props?.values?.sectionsSelected, props?.values?.semesterSelected]);

    useEffect(() => {
        if (props?.values?.subjectSelected && props?.values?.subjectSelected != "" && (props?.values?.lectureSelected == "school" || props?.values?.tuitionType == "school")) {
            getTeacher({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,

                required_field: "teacher",
                class_name: props?.values?.classSelected,
                section: props?.values?.sectionsSelected,
                subject: props?.values?.subjectSelected,
                board: props?.values?.boardSelected,
            })
        }
        if (props?.values?.subjectSelected && props?.values?.subjectSelected != "" && (props?.values?.lectureSelected == "university" || props?.values?.tuitionType == "university")) {
            getTeacher({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,

                required_field: "teacher",
                class_name: props?.values?.classSelected,
                subject: props?.values?.subjectSelected,
                university: props?.values?.universitySelected,
                semesters: props?.values?.semesterSelected,
            })
        }
        if (props?.values?.subjectSelected && props?.values?.subjectSelected != "" && (props?.values?.lectureSelected == "preparation" || props?.values?.tuitionType == "preparation")) {
            getTeacher({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "teacher",
                class_name: props?.values?.classSelected,
                subject: props?.values?.subjectSelected,
            })
        }
    }, [
        props?.values?.classSelected,
        props?.values?.boardSelected,
        props?.values?.sectionsSelected,
        props?.values?.universitySelected,
        props?.values?.semesterSelected,
        props?.values?.tuitionType,
        props?.values?.teachers,
        props?.values?.subjectSelected,
    ]);

    return (
        <>
            <div className='col-md-3 form-group'>
                <label>
                    Branch <span className='text-danger'>*</span>
                </label>
                <select
                    className='form-select'
                    onChange={props.handleChange}
                    name={`branchSelected`}
                    value={props?.values?.branchSelected}
                >
                    <option value=''>Select branch</option>
                    {branch &&
                        branch.map((b) => (
                            <option key={b.id} value={b.id}>
                                {b.name}
                            </option>
                        ))}
                </select>
                {props.errors.branchSelected && props.touched.branchSelected && (
                    <p className='text-danger mb-0'>{props.errors.branchSelected}</p>
                )}
            </div>
            {props.values.branchSelected && (
                <>
                    <div className='col-md-3 form-group'>
                        <label>
                            Class Type <span className='text-danger'>*</span>
                        </label>
                        <select
                            className='form-select'
                            onChange={props.handleChange}
                            name={`lectureSelected`}
                            value={props?.values?.lectureSelected}
                        >
                            <option value="">Select class type</option>
                            <option value="school">School (Till 12th Standard)</option>
                            <option value="university">University</option>
                            <option value="preparation">Entrance Exam / Professional Courses</option>
                            <option value="tuition">Tuition</option>
                        </select>
                        {props.errors.lectureSelected && props.touched.lectureSelected && (
                            <p className='text-danger mb-0'>{props.errors.lectureSelected}</p>
                        )}
                    </div>
                    {props?.values?.lectureSelected === "tuition" && (
                        <div className='col-md-3 form-group'>
                            <label>
                                Tuition Type <span className='text-danger'>*</span>
                            </label>
                            <select
                                className='form-select'
                                onChange={props.handleChange}
                                name={`tuitionType`}
                                value={props?.values?.tuitionType}
                            >
                                <option value=''>Select tuition type</option>
                                <option value="school">School (Till 12th Standard)</option>
                                <option value="university">University</option>
                                <option value="preparation">Entrance Exam / Professional Courses</option>
                            </select>
                            {props.errors.tuitionType && props.touched.tuitionType && (
                                <p className='text-danger mb-0'>{props.errors.tuitionType}</p>
                            )}
                        </div>
                    )}
                    {(props?.values?.lectureSelected === "school" ||
                        props?.values?.tuitionType === "school") && (
                            <div className='col-md-3 form-group'>
                                <label>
                                    Board Name <span className='text-danger'>*</span>
                                </label>
                                <select
                                    onChange={props.handleChange}
                                    name={`boardSelected`}
                                    value={props?.values?.boardSelected}
                                    className='form-select'
                                >
                                    <option value=''>Select board name</option>
                                    {boards.map((cls, idx) => (
                                        <option key={idx} value={cls.board}>
                                            {cls.board}
                                        </option>
                                    ))}
                                </select>
                                {props.errors.boardSelected && props.touched.boardSelected && (
                                    <p className='text-danger mb-0'>{props.errors.boardSelected}</p>
                                )}
                            </div>
                        )}
                    {(props?.values?.lectureSelected === "university" ||
                        props?.values?.tuitionType === "university") && (
                            <div className='col-md-3 form-group'>
                                <label>
                                    University Name <span className='text-danger'>*</span>
                                </label>
                                <select
                                    onChange={props.handleChange}
                                    name={`universitySelected`}
                                    value={props?.values?.universitySelected}
                                    className='form-select'
                                >
                                    <option value=''>Select university name</option>
                                    {universities.map((cls, idx) => (
                                        <option key={idx} value={cls.id}>
                                            {cls.name}
                                        </option>
                                    ))}
                                </select>
                                {props.errors.universitySelected &&
                                    props.touched.universitySelected && (
                                        <p className='text-danger mb-0'>{props.errors.universitySelected}</p>
                                    )}
                            </div>
                        )}
                    <div className='col-md-3 form-group'>
                        <label>
                            Class Name <span className='text-danger'>*</span>
                        </label>
                        <select
                            onChange={props.handleChange}
                            name={`classSelected`}
                            value={props?.values?.classSelected}
                            className='form-select'
                        >
                            <option value=''>Select class name</option>
                            {classes.map((cls, idx) => (
                                <option key={idx} value={cls.class_name}>
                                    {cls.class_name}
                                </option>
                            ))}
                        </select>
                        {props.errors.classSelected && props.touched.classSelected && (
                            <p className='text-danger mb-0'>{props.errors.classSelected}</p>
                        )}
                    </div>
                    {props?.values?.lectureSelected === "school" && (
                        <div className='col-md-3 form-group'>
                            <label>
                                Section <span className='text-danger'>*</span>
                            </label>
                            <select
                                className='form-select'
                                onChange={props.handleChange}
                                name={`sectionsSelected`}
                                value={props?.values?.sectionsSelected}
                            >
                                <option value=''>Select Section</option>
                                {sections &&
                                    sections.map((b) => (
                                        <option key={b.id} value={b.id}>
                                            {b.name}
                                        </option>
                                    ))}
                            </select>
                            {props.errors.sectionsSelected &&
                                props.touched.sectionsSelected && (
                                    <p className='text-danger mb-0'>{props.errors.sectionsSelected}</p>
                                )}
                        </div>
                    )}
                    {(props?.values?.lectureSelected === "university" ||
                        props?.values?.tuitionType === "university") && (
                            <div className='col-md-3 form-group'>
                                <label>
                                    Semester <span className='text-danger'>*</span>
                                </label>
                                <select
                                    className='form-select'
                                    onChange={props.handleChange}
                                    name={`semesterSelected`}
                                    value={props?.values?.semesterSelected}
                                >
                                    <option value=''>Select Semester</option>
                                    {semester &&
                                        semester.map((b) => (
                                            <option key={b.id} value={b.id}>
                                                {b.name}
                                            </option>
                                        ))}
                                </select>
                                {props.errors.semesterSelected &&
                                    props.touched.semesterSelected && (
                                        <p className='text-danger mb-0'>{props.errors.semesterSelected}</p>
                                    )}
                            </div>
                        )}

                    <div className='col-md-3 form-group'>
                        <label>
                            Subject <span className='text-danger'>*</span>
                        </label>
                        <select
                            onChange={props.handleChange}
                            name={`subjectSelected`}
                            value={props?.values?.subjectSelected}
                            className='form-select'
                        >
                            <option value=''>Select subject</option>
                            {subjects.map((sub, idx) => (
                                <option key={idx} value={sub.subject}>
                                    {sub.subject}
                                </option>
                            ))}
                        </select>
                        {props.errors.subjectSelected && props.touched.subjectSelected && (
                            <p className='text-danger mb-0'>{props.errors.subjectSelected}</p>
                        )}
                    </div>
                    <div className='col-md-3 form-group'>
                        <label>
                            Teacher <span className='text-danger'>*</span>
                        </label>
                        <select
                            className='form-select'
                            onChange={props.handleChange}
                            name={`teachers`}
                            value={props?.values?.teachers}
                        >
                            <option value=''>Select Teacher</option>
                            {teachers &&
                                teachers.map((b) => (
                                    <option key={b.id} value={b.id}>
                                        {b.name}
                                    </option>
                                ))}
                        </select>
                        {props.errors.teachers && props.touched.teachers && (
                            <p className='text-danger mb-0'>{props.errors.teachers}</p>
                        )}
                    </div>
                    <div className='col-md-3 form-group'>
                        <label>
                            Start Date <span className='text-danger'>*</span>
                        </label>
                        <input
                            type='date'
                            className='form-control'
                            id='start_date'
                            name='start_date'
                            onChange={props.handleChange}
                            min={moment().format("YYYY-MM-DD")}
                        />
                        {props.errors.start_date && props.touched.start_date && (
                            <p className='text-danger mb-0'>{props.errors.start_date}</p>
                        )}
                    </div>
                    <div className='col-md-3 form-group'>
                        <label>
                            {" "}
                            End Date <span className='text-danger'>*</span>
                        </label>
                        <input
                            type='date'
                            className='form-control'
                            id='end_date'
                            name='end_date'
                            onChange={props.handleChange}
                            min={props.values.start_date || moment().format("YYYY-MM-DD")}
                        />
                        {props.errors.end_date &&  props.touched.end_date &&(
                            <p className='text-danger mb-0'>{props.errors.end_date}</p>
                        )}
                    </div>
                    <div className='col-md-12 form-group'>
                        <div className="row">
                            <div className='col-md-6'>
                                <BookedClassSlotView
                                    props={props}
                                    auth_token={auth_token}
                                />
                            </div>
                            <div className='col-md-6'>
                                <BookedSlotView
                                    id={props?.values.teachers}
                                    props={props}
                                    auth_token={auth_token}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='card'>
                            <TimeSlotFilterComponents props={props} />
                        </div>
                    </div>
                </>
            )}
            <div className='col-md-12 text-end'>
                {loader ? (
                    <SubmitLoader />
                ) : (
                    <>
                        <button type='submit' className='btn btn-primary me-2'>
                            Submit
                        </button>
                        <Link
                            to='/institute-dashboard/slot'
                            className='btn btn-danger'
                        >
                            Cancel
                        </Link>
                    </>
                )}
            </div>
        </>
    );
}
