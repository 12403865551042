import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BranchList from './BranchList';

const Branch = () => {

    const [filterstatus, setFilterstatus] = useState([]);
    const [filterloc, setFilterloc] = useState([]);
    function parentMethod(data) {
        setFilterstatus(data);
        setFilterloc(data)
    }

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Branch List
                    </li>
                </ol>
            </div>

            <div className="row">
                <BranchList statusFilter={filterstatus} locFilter={filterloc} />
            </div>
        </>
    );
};

export default Branch;