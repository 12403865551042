import StudentRoutes from './StudentRoutes/Routes';
function StudentApp() {
  return (
    <>
      <StudentRoutes />
    </>
  );
}

export default StudentApp;
