import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomePageService from "../../../Services/HomePageService";
import url from "../../../constants/url";
import NoDataAvailable from "../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const Institute = () => {
    const serve = new HomePageService();
    const [institutesList, setInstitutesList] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });

    useEffect(() => {
        InstituteListApi();
    }, []);

    const apiCall = async (limit, offset) => {
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.get(
                url.apiUrl + `institute-list/${limit}/${offset}`,

                config
            );
            if (response) {
                localStorage.setItem("institutelist", JSON.stringify(response));
                setInstitutesList(response.data?.data);
                setTotalPages(response.count / 10);
                setTotalCount(response.count);
            }
        } catch (err) {
            throw err;
        }
    };

    const InstituteListApi = async () => {
        await apiCall(search.perPage, search.start);
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    return (
        <>
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title">
                        <h2>Top Rated Institutes</h2>
                        <div className="ms-auto">
                            <Link to="/institutes" className="btn btn-primary">
                                View More <i className="fe fe-arrow-right" />
                            </Link>
                        </div>
                    </div>
                    <>
                        {institutesList?.length >= 3 ? (
                            <Slider {...settings}>
                                {institutesList.map((item, i) => (
                                    <div className="col-md-12" key={i}>
                                        <Link to={`/institute-detail/${item?.institute_id}`} >
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="item-card7-img">
                                                        <img
                                                            src={item?.image}
                                                            alt={item?.name}
                                                            className="cover-image"
                                                        />
                                                    </div>
                                                    <div className="item-card2-desc">
                                                        <div className="d-inline-flex mb-2">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span>({item?.review_count} Reviews)</span>
                                                        </div>
                                                        <h4 className="mb-2 leading-tight">
                                                            {item?.name}
                                                        </h4>
                                                        <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.about }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="row">
                                {institutesList?.length > 0 ? (
                                    institutesList.map((item, i) => (
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                            <Link to={`/institute-detail/${item?.institute_id}`} >
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="item-card7-img">
                                                            <img
                                                                src={item?.image}
                                                                alt={item?.name}
                                                                className="cover-image"
                                                            />
                                                        </div>
                                                        <div className="item-card2-desc">
                                                            <div className="d-inline-flex mb-2">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= item?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <span>({item?.review_count} Reviews)</span>
                                                            </div>
                                                            <h4 className="mb-2 leading-tight">
                                                                {item?.name}
                                                            </h4>
                                                            <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.about }}></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <NoDataAvailable />
                                )}
                            </div>
                        )}
                    </>
                </div>
            </section >
        </>
    );
};

export default Institute;
