import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import "react-phone-number-input/style.css";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import SubmitLoader from "../../../common/SubmitLoader";
import SocialLinks from "../ContactUs/SocialLinks";
import Curve from '../../Header/Curve';

const WorkWithUs = () => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState({
        name: "",
        email: "",
        contact_number: "",
        position: "",
        other_name: "",
        resume: "",
        how_did_you_hear: "",
        referral_name: "",
        other_hear_name: "",
        social_media: "",
        message: "",
    });
    const [value, setValue] = useState({
        name: "",
        email: "",
        contact_number: "",
        position: "",
        other_name: "",
        resume: "",
        how_did_you_hear: "",
        referral_name: "",
        other_hear_name: "",
        social_media: "",
        message: "",
    });
    const onSubmit = async (values, { resetForm }) => {
        const formData = new FormData();
        formData.set("name", values.name);
        formData.set("contact_number", values.contact_number);
        formData.set("email", values.email);

        if (values.position === "other") {
            formData.set("position", values.other_name);
        } else {
            formData.set("position", values.position);
        }
        formData.set("resume", values.resume);
        if (values.how_did_you_hear === "referral") {
            formData.set("how_did_you_hear", values.how_did_you_hear);
            formData.set("referral_code", values.referral_name);
        } else if (values.how_did_you_hear === "social_media") {
            formData.set("how_did_you_hear", values.social_media);
        } else if (values.how_did_you_hear === "other") {
            formData.set("how_did_you_hear", values.other_hear_name);
        } else {
            formData.set("how_did_you_hear", values.how_did_you_hear);
        }
        formData.set("discription", values.message);
        setLoader(true);
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASEURL + `apply-job`,
                formData,
                config
            );
            if (response.data.status === "success") {
                setValue({
                    name: "",
                    email: "",
                    contact_number: "",
                    position: "",
                    other_name: "",
                    resume: "",
                    how_did_you_hear: "",
                    referral_name: "",
                    other_hear_name: "",
                    social_media: "",
                    message: "",
                });
                toast.success("Submitted Successfully");
                resetForm();
                setTimeout(() => {
                    document.getElementById("work_with_us").reset();
                }, 5000);
                setLoader(false);
            } else {
                setError({
                    name: response.data?.data?.name,
                    email: response.data?.data?.email,
                    contact_number: response.data?.data?.contact_number,
                    position: response.data?.data?.position,
                    other_name: response.data?.data?.other_name,
                    resume: response.data?.data?.resume,
                    how_did_you_hear: response.data?.data?.how_did_you_hear,
                    referral_name: response.data?.data?.referral_code,
                    other_hear_name: response.data?.data?.other_hear_name,
                    social_media: response.data?.data?.social_media,
                    message: response.data?.data?.discription,
                });
                setLoader(false);
            }
        } catch (err) {
            throw err;
        }
    };
    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required("Required"),

        email: Yup.string().email("Please enter valid email ID").required("Required"),

        contact_number: Yup.string()
            .required("Required")
            .min(13, "Please enter valid contact number")
            .max(13, "Please enter valid contact number"),
        position: Yup.string().required("Required"),
        other_name: Yup.string()
            .when("position", {
                is: (position) => position && position === "other",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        how_did_you_hear: Yup.string().required("Required"),
        referral_name: Yup.string()
            .when("how_did_you_hear", {
                is: (how_did_you_hear) =>
                    how_did_you_hear && how_did_you_hear === "referral",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        social_media: Yup.string()
            .when("how_did_you_hear", {
                is: (how_did_you_hear) =>
                    how_did_you_hear && how_did_you_hear === "social_media",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        other_hear_name: Yup.string()
            .when("how_did_you_hear", {
                is: (how_did_you_hear) =>
                    how_did_you_hear && how_did_you_hear === "other",
                then: Yup.string().required("Required"),
            })
            .nullable(),
        resume: Yup.mixed().required("Required"),
        message: Yup.string()
            .required("Required")
            .max(500, "Please write less than 500 characters !"),
    });
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Work with us</h1>
                </div>
            </div>
            <Curve/>
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center mb-6">
                                Education Mandal is always looking for talented and motivated
                                individuals to join our team. If you are passionate about the
                                education industry and want to make a positive impact on the
                                lives of others, we would love to hear from you. Please fill
                                out the form below to apply.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-5 text-center">
                                        <h5 className="fs-25">Apply Now</h5>
                                    </div>
                                    <Formik
                                        initialValues={value}
                                        onSubmit={onSubmit}
                                        enableReinitialize={true}
                                        validationSchema={ValidateSchema}
                                    >
                                        {(props) => (
                                            <form onSubmit={props.handleSubmit} id="work_with_us" className="row">
                                                <div className="form-group col-lg-6 col-md-12">
                                                    <label>
                                                        Name <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        name="text"
                                                        placeholder="Please enter name"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            props.setFieldValue("name", event.target.value);
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.name}
                                                    />
                                                    {props.touched.name && props.errors.name ? (
                                                        <div className="bg-error">
                                                            {props.errors.name}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div className="form-group col-lg-6 col-md-12">
                                                    <label>
                                                        Conatct No <span className="text-danger">*</span>
                                                    </label>
                                                    <PhoneInputWithCountrySelect
                                                        name="contact_number"
                                                        defaultCountry="IN"
                                                        className="form-control"
                                                        placeholder="Please enter contact no"
                                                        value={props.values.contact_number}
                                                        onChange={(e) => {
                                                            props.setFieldValue("contact_number", e);
                                                        }}
                                                    />
                                                    {props.touched.contact_number && props.errors.contact_number ? (
                                                        <div className="bg-error">
                                                            {props.errors.contact_number}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>
                                                        Email ID <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        name="email"
                                                        placeholder="Please enter email id"
                                                        type="email"
                                                        className="form-control"
                                                        value={props.values.email}
                                                        onChange={props.handleChange}
                                                    />
                                                    {props.touched.email && props.errors.email ? (
                                                        <div className="bg-error">
                                                            {props.errors.email}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>
                                                        Which position are you interested in? <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="position"
                                                        value={props.values.position}
                                                        onChange={(e) => {
                                                            props.setFieldValue("position", e.target.value);
                                                        }}
                                                    >
                                                        <option value="">Select position</option>
                                                        <option value="administration">Administration</option>
                                                        <option value="marketing">Marketing</option>
                                                        <option value="it">IT</option>
                                                        <option value="sale">Sales</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                    {props.touched.position && props.errors.position ? (
                                                        <div className="bg-error">
                                                            {props.errors.position}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {props.values.position === "other" && (
                                                    <div className="form-group col-md-12">
                                                        <label>
                                                            Other <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            name="other_name"
                                                            placeholder="Please enter name"
                                                            type="text"
                                                            className="form-control"
                                                            value={props.values.other_name}
                                                            onChange={props.handleChange}
                                                        />
                                                        {props.touched.other_name &&
                                                            props.errors.other_name ? (
                                                            <div className="bg-error">
                                                                {props.errors.other_name}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}

                                                <div className="form-group col-md-12">
                                                    <label>
                                                        Upload Resume <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        name="resume"
                                                        type="file"
                                                        className="form-control"
                                                        accept=".doc,.docx,.pdf"
                                                        onChange={(e) =>
                                                            props.setFieldValue("resume", e.target.files[0])
                                                        }
                                                    />
                                                    {props.touched.resume && props.errors.resume ? (
                                                        <div className="bg-error">
                                                            {props.errors.resume}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>
                                                        How did you hear about Education Mandal? <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="how_did_you_hear"
                                                        value={props.values.how_did_you_hear}
                                                        onChange={(e) => {
                                                            props.setFieldValue(
                                                                "how_did_you_hear",
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option value="">Select option</option>
                                                        <option value="referral">Referral</option>
                                                        <option value="social_media">Social Media</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                    {props.touched.how_did_you_hear &&
                                                        props.errors.how_did_you_hear ? (
                                                        <div className="bg-error">
                                                            {props.errors.how_did_you_hear}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {props.values.how_did_you_hear === "referral" && (
                                                    <div className="form-group col-md-12">
                                                        <label>
                                                            Referral Code <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            name="referral_name"
                                                            placeholder="Please enter referral code"
                                                            type="text"
                                                            className="form-control"
                                                            value={props.values.referral_name}
                                                            onChange={props.handleChange}
                                                        />
                                                        {props.touched.referral_name &&
                                                            props.errors.referral_name ? (
                                                            <div className="bg-error">
                                                                {props.errors.referral_name}
                                                            </div>
                                                        ) : null}
                                                        {props.values.referral_name?.length > 0 && <div className="bg-error">
                                                            {error.referral_name}
                                                        </div>}
                                                    </div>
                                                )}
                                                {props.values.how_did_you_hear === "social_media" && (
                                                    <div className="form-group col-md-12">
                                                        <label>
                                                            Referral's Name
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            name="social_media"
                                                            value={props.values.social_media}
                                                            onChange={(e) => {
                                                                props.setFieldValue(
                                                                    "social_media",
                                                                    e.target.value
                                                                );
                                                            }}
                                                        >
                                                            <option value="">Select option</option>
                                                            <option value="instagram">Instagram</option>
                                                            <option value="whatsApp">WhatsApp</option>
                                                            <option value="facebook">Facebook</option>
                                                            <option value="twitter">Twitter</option>
                                                            <option value="linkedin">Linkedin</option>
                                                        </select>
                                                        {props.touched.social_media &&
                                                            props.errors.social_media ? (
                                                            <div className="bg-error">
                                                                {props.errors.social_media}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                {props.values.how_did_you_hear === "other" && (
                                                    <div className="form-group col-md-12">
                                                        <label>
                                                            Other <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            name="other_hear_name"
                                                            placeholder="Please enter name"
                                                            type="text"
                                                            className="form-control"
                                                            value={props.values.other_hear_name}
                                                            onChange={props.handleChange}
                                                        />
                                                        {props.touched.other_hear_name &&
                                                            props.errors.other_hear_name ? (
                                                            <div className="bg-error">
                                                                {props.errors.other_hear_name}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                <div className="form-group col-md-12">
                                                    <label>
                                                        Brief summary about yourself and why you are
                                                        interested in working with Education Mandal <span className="text-danger">*</span>
                                                    </label>
                                                    <textarea
                                                        name="message"
                                                        placeholder="Please enter brief summary"
                                                        type="text"
                                                        className="form-control"
                                                        value={props.values.message}
                                                        onChange={(e) =>
                                                            props.setFieldValue("message", e.target.value)
                                                        }
                                                    ></textarea>
                                                    {props.touched.message && props.errors.message ? (
                                                        <div className="bg-error">
                                                            {props.errors.message}
                                                        </div>
                                                    ) : null}
                                                    <p className="text-end mb-0">
                                                        {props.values.message?.length} / 500 Characters
                                                    </p>
                                                </div>

                                                <div className="col-md-12 text-end">
                                                    {loader ? (
                                                        <SubmitLoader />
                                                    ) : (
                                                        <button type="submit" className="btn btn-primary">
                                                            Submit
                                                        </button>
                                                    )}
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <img src="/assets/images/work.jpg" alt="Work with us" className="mb-6" />
                                </div>
                            </div>
                            <SocialLinks />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </section>

            <Footer />
        </>
    );
};

export default WorkWithUs;
