import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Moment from "react-moment";
import NoDataAvailable from "../../../common/NoDataAvailable";

const StudentDtails = (props) => {
    const [data, setAboutInfo] = useState(props.data);

    useEffect(() => {
        if (props.data && props.data) setAboutInfo(props.data);
    }, [props.data]);
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Teacher Details</h4>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <Tabs defaultActiveKey="about" id="uncontrolled-tab-example">
                                    <Tab eventKey="about" title="about">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">About the Teacher</h4>
                                                    </div>
                                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.about ? data?.about : "No Data Available !" }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="education" title="Education">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Education Details</h4>
                                                    </div>
                                                    {data?.length > 0 ? (
                                                        data?.map((item) => (
                                                            <div className='card-body' key={item?.id}>
                                                                <ul className='d-flex mb-0'>
                                                                    <li>
                                                                        <div className='activity1 bg-primary'>
                                                                            <i className='fe fe-shopping-bag'></i>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className='font-weight-semibold'>
                                                                            {item?.degree} - ( {item?.field_of_study} )
                                                                        </div>
                                                                        <p className='text-muted mb-0'>
                                                                            {item?.school_college}
                                                                        </p>
                                                                    </li>
                                                                    <div className='ms-auto'>
                                                                        <Moment format='DD-MM-YYYY'>{item?.start_date}</Moment> to {item?.is_present === 1 ? (
                                                                            "Present "
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <Moment format='DD-MM-YYYY'>{item?.end_date}</Moment>{" "}
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <NoDataAvailable/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="experience" title="Experience">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Experience Details</h4>
                                                    </div>
                                                    {data?.length > 0 ? (
                                                        data?.map((experience) => {
                                                            return (
                                                                <div className='card-body' key={experience?.id}>
                                                                    <ul className='d-flex mb-0'>
                                                                        <li>
                                                                            <div className='activity1 bg-primary'>
                                                                                <i className='fe fe-shopping-bag'></i>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='font-weight-semibold'>
                                                                                {experience?.title} - ( {experience?.employement_type === "full_time" ? "Full Time" : "Part Time"} )
                                                                            </div>
                                                                            <p className='text-muted mb-0'>
                                                                                {experience?.company_name}
                                                                            </p>
                                                                            <p className='text-muted mb-2'>
                                                                                {experience?.location}
                                                                            </p>
                                                                        </li>
                                                                        <div className='ms-auto'>
                                                                            <Moment format='DD-MM-YYYY'>
                                                                                {experience?.start_date}
                                                                            </Moment> to {experience?.is_present === 1 ? (
                                                                                "Present "
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <Moment format='DD-MM-YYYY'>
                                                                                        {experience?.end_date}
                                                                                    </Moment>{" "}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </div>
                                                                    </ul>
                                                                    <p className='text-muted mb-0 margin-left education-mandal'>
                                                                        {experience?.description}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <NoDataAvailable/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="achievements" title="Achievements">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Achievements Details</h4>
                                                    </div>
                                                    {data?.length > 0 ? (
                                                        data?.map((achievement, index) => {
                                                            const { title, description, date } = achievement;
                                                            return (
                                                                <div className='card-body' key={achievement.id}>
                                                                    <ul className='d-flex mb-0'>
                                                                        <li>
                                                                            <div className='activity1 bg-primary'>
                                                                                <i className='fe fe-shopping-bag'></i>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className='font-weight-semibold'>
                                                                                {title}
                                                                            </div>
                                                                        </li>
                                                                        <div className='ms-auto mt-3'>
                                                                            <Moment format='DD-MM-YYYY'>{date}</Moment>
                                                                        </div>
                                                                    </ul>
                                                                    <p className='text-muted mb-0 margin-left education-mandal'>{description}</p>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <NoDataAvailable/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="reviews" title="Reviews">
                                        <div className="row ">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Reviews</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default StudentDtails;
