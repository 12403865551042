import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import url from "../../../../../constants/url";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import ApproveClassPopup from "../ApproveClassPopup";
import { replaceWord } from "../../../../../utils/utils";
import CommonService from "../../../Services/CommonService";

const Subject = () => {

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const serve = new CommonService();
    const [LiveBranch, setLiveBranch] = useState([]);
    const [values, setValues] = useState({});
    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
        branch: '',
        lecture_type: '',
        status: '',
        query: '',
        list_type: 'subject'
    })
    var activity = {
        is_main_course: '1',
        list_type: filters.list_type,
        limit: filters.limit,
        offset: filters.offset,
        branch_id: filters.branch || undefined,
        class_type: filters.lecture_type || undefined,
        status: filters.status || undefined,
        query: filters.query || undefined
    };
    const [lectures, setLectures] = useState([])
    const [loading, setLoading] = useState(false)
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState();
    const [lectureId, setLectureId] = useState(0);
    const [aboutForm, setAboutForm] = useState(false);
    const ValidateSchema = Yup.object().shape({
    });

    const onSubmit = async (values) => {
        getLectures({
            is_main_course: '1',
            list_type: filters.list_type,
            limit: filters.limit,
            offset: filters.offset,
            branch_id: filters.branch || undefined,
            class_type: filters.lecture_type || undefined,
            status: filters.status || undefined,
            query: filters.query || undefined
        });
    };

    const getchBranch = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "institute-branch/get-branches", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr =
                        result.data &&
                        result.data.map((v) => {
                            return { value: v.id, label: v.text }
                        });
                    setLiveBranch(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getLectures = async (activity) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(activity)
        };

        fetch(url.apiUrl + "institute/class-lecture-list", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLectures(result.data);
                    setCount(result.count);
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => {
                setLoading(false)
            })
    };

    const handlePageClick = async (lectures) => {
        let currentPage = lectures.selected * filters.limit;
        setOffset(currentPage);
        setFilters({
            ...filters,
            limit: filters.limit,
            offset: currentPage,
        })
        await getLectures({
            is_main_course: '1',
            list_type: filters.list_type,
            limit: filters.limit,
            offset: currentPage,
            branch_id: filters.branch || undefined,
            class_type: filters.lecture_type || undefined,
            status: filters.status || undefined,
            query: filters.query || undefined
        });
    };


    const searchQuery = (e) => {
        getLectures({
            is_main_course: '1',
            list_type: filters.list_type,
            limit: filters.limit,
            offset: filters.offset,
            branch_id: filters.branch || undefined,
            class_type: filters.lecture_type || undefined,
            status: filters.status || undefined,
            query: e.target.value || undefined
        });
    }
    useEffect(() => {
        getchBranch();
    }, []);

    useEffect(() => {
        getLectures({
            is_main_course: '1',
            list_type: filters.list_type,
            limit: filters.limit,
            offset: filters.offset,
            branch_id: filters.branch || undefined,
            class_type: filters.lecture_type || undefined,
            status: filters.status || undefined,
            query: filters.query || undefined
        })
    }, [])

    const aboutHandler = (id) => {
        setLectureId(id);
        setAboutForm(true);
    };

    const handleStatus = async (id, status) => {
        try {
            let response = await serve.changeClassStatus({ class_id: id, status: (status === "active") ? "inactive" : "active" });
            if (response) {
                if (response.status === "success") {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    getLectures(activity)
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        } catch (err) {
            throw err;
        }
    }

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Classroom Course Subject</li>
                </ol>
                <Link
                    className="btn btn-primary"
                    to="/institute-dashboard/subject-Add"
                >
                    <i className="fa fa-plus"></i> Add Subject
                </Link>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Classroom Course Subject Filter</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <label>Branch</label>
                                                <Select
                                                    onChange={(value) => {
                                                        props.setFieldValue("branch", value)
                                                        setFilters({ ...filters, branch: value })
                                                    }
                                                    }
                                                    options={LiveBranch}
                                                    value={props.values?.branch}
                                                    name="branch"
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Class Type</label>
                                                <select
                                                    className="form-select"
                                                    value={props.values.lecture_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "lecture_type",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, lecture_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select class type</option>
                                                    <option value="school">School (Till 12th Standard)</option>
                                                    <option value="university">University</option>
                                                    <option value="preparation">Entrance Exam / Professional Courses</option>
                                                    <option value="tuition">Tuition</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Status</label>
                                                <select
                                                    className="form-select"
                                                    name="status"
                                                    onChange={e => {
                                                        props.setFieldValue(
                                                            "status",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, status: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select status</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="decline">Decline</option>
                                                </select>
                                            </div>
                                            <div className="open-button col-md-3">
                                                <button type="submit" className="btn btn-primary" title="Search"><i className="fe fe-search"></i> Search</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Classroom Course Subject List</h4>
                        </div>
                        <div className="card-body">
                            <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            placeholder="Search..."
                                            onChange={(e) => searchQuery(e)}
                                        />
                                    </label>
                                </div>
                                <table className="table table-bordered table-hover dataTable table-responsive-xxxl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Class Details</th>
                                            <th>Branch Details</th>
                                            <th className="text-center">Class Type</th>
                                            <th className="text-center">Board/University</th>
                                            <th className="text-center">Section/Semester</th>
                                            <th className='text-center'>Medium</th>
                                            <th className="text-center">Classroom Type</th>
                                            <th className="text-center">VC Link</th>
                                            <th className="text-center">View Subject</th>
                                            <th className='text-center'>Status</th>
                                            <th className="text-center text-nowrap">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td className="text-center" colSpan={12}>Loading..</td>
                                            </tr>
                                        ) : (
                                            !lectures.length ? (
                                                <tr>
                                                    <td className="text-center" colSpan={12}>No Data Available !</td>
                                                </tr>
                                            )
                                                : (
                                                    lectures.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <th className="text-center">{index + offset + 1}</th>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.thumbnail} alt={item?.title} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to={"/institute-dashboard/class-detail/1/" + item?.id} className="font-weight-semibold">
                                                                            {item?.title}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.institute_branch?.image} alt={item?.institute_branch?.name} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to={"/institute-dashboard/branch-detail/" + item?.branch_id} className="font-weight-semibold">
                                                                            {item?.institute_branch?.name}
                                                                        </Link>
                                                                        <p className="mb-0">{item?.institute_branch?.email}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center text-capitalize">{replaceWord(item?.class_type)} {item?.class_type == "tuition" ? " : " + (replaceWord(item?.tuition_type)) : ""}</td>
                                                            <td className="text-center">{item?.board || item?.universities?.name || '---'}</td>
                                                            <td className="text-center text-capitalize">{item?.section || item?.semester || '---'}</td>
                                                            <td className="text-center text-capitalize">{item?.medium || '---'}</td>
                                                            <td className="text-center text-capitalize">{replaceWord(item?.lecture_type)}</td>
                                                            <td className="text-center">
                                                                {item?.video_conference_link ? (
                                                                    <a href={item?.video_conference_link} className="btn btn-primary" title="Join Google Meet" target="_blank" rel="noreferrer">
                                                                        Join Now
                                                                    </a>
                                                                ) : ("---")}
                                                            </td>
                                                            <td className="text-center">
                                                                <Link className="btn btn-primary" to={`/institute-dashboard/subject-view/${item?.id}`}>View</Link>
                                                            </td>
                                                            <td className="text-center text-capitalize align-items-center">
                                                                <div className="d-flex">
                                                                    {(item?.status === "active" || item?.status === 'inactive') && (
                                                                        <label className="custom-control form-checkbox mb-0">
                                                                            <input type="checkbox"
                                                                                className="custom-control-input"
                                                                                name="checkbox"
                                                                                value="checkbox"
                                                                                checked={item?.status === "active"}
                                                                                onChange={() => {
                                                                                    handleStatus(item.id, item?.status)
                                                                                }}
                                                                            /> <span className="custom-control-label"></span>
                                                                        </label>
                                                                    )}
                                                                    {(item?.status === "decline" || item?.status === 'pending') &&
                                                                        item?.is_submit == 1
                                                                        ? "Submitted"
                                                                        : item?.status} {item?.status === "decline" && `(${item?.reason?.reason})`}
                                                                </div>
                                                            </td>
                                                            <td className="text-center text-nowrap">
                                                                {item?.status === "pending" &&
                                                                    item?.is_submit == 1 ? (
                                                                    <Link
                                                                        disabled
                                                                        className="btn btn-outline-grey btn-sm me-2"
                                                                        onClick={() => {
                                                                            alert(
                                                                                "You can't edit this video course, since your video course is pending for approval from our administrator. Thank you for your patience."
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-pen-to-square fs-16"></i>
                                                                    </Link>
                                                                ) : (
                                                                    (item?.status === "decline" || item?.status === 'pending') &&
                                                                        item?.is_submit == 1 ? (
                                                                        <Link
                                                                            disabled
                                                                            className="btn btn-primary btn-sm me-2"
                                                                            onClick={() => {
                                                                                alert(
                                                                                    "You can't update the course until it gets approved !"
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-pen-to-square fs-16"></i>
                                                                        </Link>
                                                                    ) : (
                                                                        <Link className="btn btn-primary btn-sm me-2" title="Edit Classroom Subject" to={`/institute-dashboard/subject-view/${item?.id}`}>
                                                                            <i className="fa fa-pen-to-square fs-16"></i>
                                                                        </Link>
                                                                    )
                                                                )}

                                                                <a
                                                                    className='btn btn-primary btn-sm'
                                                                    href='#' title="Change Status"
                                                                    onClick={() => aboutHandler(item.id)}
                                                                >
                                                                    <i className='fa fa-eye fs-16'></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                        )}
                                    </tbody>
                                </table>
                                {lectures.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(count / filters.limit)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination d-flex mt-3 justify-content-end"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <ApproveClassPopup getLectures={getLectures} activity={activity} setAboutForm={setAboutForm} aboutForm={aboutForm} lectureId={lectureId} setLectureId={setLectureId} isMain={1} />
            <ToastContainer />
        </>
    );
};

export default Subject;
