import React, { useEffect, useState } from "react";
import Curve from '../../Header/Curve';
const VideoCourseHeader = (props) => {
    const [data, setCourseDataDetails] = useState(props.data);
    useEffect(() => {
        if (props.data && props.data) setCourseDataDetails(props.data);
    }, [props.data]);

    return (
        <>
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="/assets/images/banners/banner1.webp"
                style={{
                    background: 'url("/assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>{data?.title}</h1>
                </div>
            </div>
            <Curve/>
        </>
    );
};

export default VideoCourseHeader;
