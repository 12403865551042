import React, { useEffect, useState } from 'react';
import { Switch, Routes, Route } from 'react-router-dom';
import Footer from '../../common/Footer';
import Loader from "../../common/loader";
import SidebarNav from '../component/SidebarNav';
import ProfileDashboard from '../StudentDashboard/ProfileDashboard/ProfileDashboard';
import Profile from '../StudentDashboard/Profile/Profile';

import Course from '../StudentDashboard/Course/Course';
import CourseDetails from '../StudentDashboard/Course/CourseDetails/CourseDetails';
import CourseStudy from '../StudentDashboard/Course/CourseStudy/CourseStudy';

import Class from '../StudentDashboard/Class/Class';
import ClassDetails from '../StudentDashboard/Class/ClassDetails/ClassDetails';

import Payment from '../StudentDashboard/Payment/Payment';
import PaymentHistory from '../StudentDashboard/Payment/PaymentHistory';
import Invoice from '../StudentDashboard/Payment/Invoice';

import Attendance from "../StudentDashboard/Attendance/Attendance";
import AttendanceViewSubject from "../StudentDashboard/Attendance/AttendanceViewSubject";
import AttendanceViewTimeSlot from "../StudentDashboard/Attendance/AttendanceViewTimeSlot";

import Notifications from "../StudentDashboard/Notifications/Notifications";
const Routess = () => {
    const [isLoader, setisLoader] = useState(true);

    const data_local = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(data_local);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    if (local_storage === null || local_storage === undefined) {
        window.location.replace("/");
    }
    useEffect(() => {
        setisLoader(false);
    }, []);

    return (
        <>
            {isLoader ? <Loader />
                :
                <>
                    <div className={`page ` + (!isSidebarOpen ? "app sidenav-toggled" : '')}>
                        <div className="page-main h-100">
                            <SidebarNav isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                            <div className="app-content">
                                <div className="side-app">
                                    <Switch>
                                        <Route exact path="/student-dashboard" component={ProfileDashboard} />
                                        <Route exact path="/student-dashboard/student-profile" component={Profile} />

                                        <Route exact path="/student-dashboard/class" component={Class} />
                                        <Route exact path="/student-dashboard/class-detail/:id" component={ClassDetails} />

                                        <Route exact path="/student-dashboard/course" component={Course} />
                                        <Route exact path="/student-dashboard/video-course-detail/:id" component={CourseDetails} />
                                        <Route exact path="/student-dashboard/course-study/:id" component={CourseStudy} />

                                        <Route exact path="/student-dashboard/payment" component={Payment} />
                                        <Route exact path="/student-dashboard/payment-history/:order_id" component={PaymentHistory} />
                                        <Route exact path="/student-dashboard/invoice/:order_id" component={Invoice} />

                                        <Route path="/student-dashboard/attendance" component={Attendance} />
                                        <Route path="/student-dashboard/attendance-view-subject/:id" component={AttendanceViewSubject} />
                                        <Route path="/student-dashboard/attendance-view-time-slot/:id/:subject" component={AttendanceViewTimeSlot} />

                                        <Route path="/student-dashboard/notifications" component={Notifications} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </>
            }
        </>
    );
};

export default Routess;