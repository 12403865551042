import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "./Card";
import RevenueFilter from "./RevenueFilter";
import RevenueList from "./RevenueList"
import axios from "axios";
import url from "../../../../constants/url";

const Revenue = () => {
    const [data, setData] = useState([])
    const [count, setCount] = useState(0)
    const [offset, setOffset] = useState(0)
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [total, setTotal] = useState({
        "price": 0,
        "admin_commission": 0,
        "gst": 0,
        "selling_price": 0,
        "net_price": 0,
    });
    useEffect(() => {
        getAllCourseData();
    }, []);
    useEffect(() => {
        handleSearch();
    }, [search]);
    const apiCall = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}institute/revenue-list`,
                activity,
                config
            );
            if (response.data?.status === "success") {
                setData(response.data?.data);
                setCount(response.data?.count / search.perPage);
                var price = 0, admin_commission = 0, selling_price = 0, gst = 0, net_price = 0;
                response.data?.data.map((item) => {
                    price = price + Number((item?.total_price) ? item?.total_price : 0);
                    admin_commission = admin_commission + Number((item?.admin_commission) ? item?.admin_commission : 0);
                    selling_price = selling_price + Number((item?.total_payable_price) ? item?.total_payable_price : 0);
                    gst = gst + Number((item?.gst) ? item?.gst : 0);
                    net_price = net_price + Number((item?.total_payable_price) ? (item?.total_payable_price - item?.admin_commission) : 0);
                    setTotal({
                        "price": price,
                        "admin_commission": admin_commission,
                        "selling_price": selling_price,
                        "gst": gst,
                        "net_price": net_price,
                    });
                });
            }
        } catch (err) {
            throw err;
        }
    };
    const getAllCourseData = async () => {
        let activity = {
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
        };
        await apiCall(activity);
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
            branch_id: search.branch_id,
            course_type: search.course_type,
            class_type: search.class_type,
            start_date: search.start_date,
            end_date: search.end_date,

        };
        await apiCall(activity);
    };
    const handleSearch = async () => {
        const activity = {
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
            branch_id: search.branch_id,
            course_type: search.course_type,
            class_type: search.class_type,
            start_date: search.start_date,
            end_date: search.end_date,

        };
        await apiCall(activity);
    };
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Revenue</li>
                </ol>
            </div>

            <div className="row">
                <Card />
                <RevenueFilter setSearch={setSearch} search={search} apiCall={apiCall} />
                <RevenueList data={data} offset={offset} total={total} count={count} handlePageClick={handlePageClick} handleSearch={handleSearch} search={search} setSearch={setSearch} />
            </div>
        </>
    );
};

export default Revenue;
