import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Moment from "react-moment";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import { replaceWord } from "../../../../utils/utils";
const Success = () => {
    let now = new Date().setHours(0, 0, 0, 0);
    let localStorage_ = localStorage.getItem("data");
    let data = JSON.parse(localStorage_);
    let comman_user_info = localStorage.getItem("common-user-info");
    let user_data = JSON.parse(comman_user_info);

    const [user, setUser] = useState(user_data);
    const [payment, setPayment] = useState(data);
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text" style={{ padding: "3rem 0" }}>
                </div>
            </div>
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center mb-5">
                            <h1 className="mb-4 font-weight-semibold">Thanks for Payment</h1>
                            <p className="lead text-center">
                                Your order has been submitted Successfully.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <table className="table table-bordered table-hover text-nowrap">
                                        <tbody>
                                            <tr>
                                                <td className="font-weight-semibold text-start">
                                                    <strong>Name :</strong> {user?.name}
                                                </td>
                                                <td className="text-end">
                                                    <strong>Date :</strong> <Moment format="DD-MM-YYYY hh:mm A">{payment?.created_at}</Moment>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-semibold text-start">
                                                    <strong>Email ID :</strong> {user?.email}
                                                </td>
                                                <td className="text-end">
                                                    <strong>Order ID : </strong> {payment?.orderId}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-semibold text-start">
                                                    <strong>Contact No :</strong> {user?.mobile}
                                                </td>
                                                <td className="text-end">
                                                    <strong>Invoice No :</strong> {payment?.invice_id}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="row">
                                                {payment?.courses?.length > 0 &&
                                                    payment?.courses?.map((item, key) => (
                                                        <div className="col-md-6" key={key}>
                                                            <div className="card overflow-hidden">
                                                                {item?.course_type == "online_course" ? (
                                                                    <div className="card-body">
                                                                        <div className="item-card7-img">
                                                                            <img className="cover-image" src={item?.online_course?.teaser_thumb_image} alt={item?.online_course?.title} />
                                                                            {(
                                                                                item?.online_course?.price == "paid" && (
                                                                                    item?.online_course?.is_discount == 1 && (
                                                                                        (item?.online_course?.is_limited_offer == 1 && (new Date(item?.online_course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.online_course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                        (item?.online_course?.is_limited_offer == 0 && new Date(item?.online_course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                )
                                                                            ) && (
                                                                                    <Link to="#" className="bg-danger top left">
                                                                                        {item?.online_course?.discount_price}% off
                                                                                    </Link>
                                                                                )}
                                                                            <Link to="#" className="bg-danger right top">
                                                                                Video Course
                                                                            </Link>
                                                                        </div>
                                                                        <div className="item-card2-desc">
                                                                            <h4 className="mb-2 leading-tight2" >
                                                                                {item?.online_course?.title}
                                                                            </h4>
                                                                            <p className="leading-tight3 education-mandal">
                                                                                {item?.online_course?.short_discription}
                                                                            </p>
                                                                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                                <div className="d-flex justify-content-start">
                                                                                    <i className="fa fa-shop me-2" />
                                                                                    <span className="text-muted text-data-over-cut">
                                                                                        {item?.online_course?.institute?.name}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="float-end">
                                                                                    <i className="fa fa-signal me-2" />
                                                                                    <span className="text-muted text-capitalize">
                                                                                        {item?.online_course?.course_level}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-2">
                                                                                <i className="fa fa-microphone me-2" />
                                                                                <span className="text-muted">
                                                                                    {item?.online_course?.audio_language}
                                                                                </span>
                                                                                <div className="float-end">
                                                                                    {item?.online_course?.price === "paid" && (
                                                                                        <h4 className="mb-0"> ₹ {Math.round(item?.online_course?.payable_price)} {(
                                                                                            item?.online_course?.price == "paid" && (
                                                                                                item?.online_course?.is_discount == 1 && (
                                                                                                    (item?.online_course?.is_limited_offer == 1 && (new Date(item?.online_course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.online_course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                                    (item?.online_course?.is_limited_offer == 0 && new Date(item?.online_course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                            )
                                                                                        ) && (<del className="fs-14 text-muted">₹ {Math.round(item?.online_course?.regular_price)} </del>
                                                                                            )}
                                                                                        </h4>
                                                                                    )}
                                                                                    {item?.online_course?.price === "free" && (
                                                                                        <h4 className="mb-0">Free</h4>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="card-body">
                                                                        <div className="item-card7-img">
                                                                            <img
                                                                                src={item?.class_course?.thumbnail} alt={item?.class_course?.title}
                                                                                className="cover-image"
                                                                            />
                                                                            {(
                                                                                item?.class_course?.price == "paid" && (
                                                                                    item?.class_course?.is_discount == 1 && (
                                                                                        (item?.class_course?.is_limited_offer == 1 && (new Date(item?.class_course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.class_course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                        (item?.class_course?.is_limited_offer == 0 && new Date(item?.class_course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                )
                                                                            ) && (
                                                                                    <Link to="#" className="bg-danger top left">
                                                                                        {item?.class_course?.discount_price}% Off
                                                                                    </Link>
                                                                                )}
                                                                            <Link to="#" className="bg-danger right top">
                                                                                Classroom Course
                                                                            </Link>
                                                                        </div>
                                                                        <div className="item-card2-desc">
                                                                            <h4 className="mb-2 leading-tight2" >
                                                                                {item?.class_course?.title}
                                                                            </h4>
                                                                            <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.class_course?.description }}>
                                                                            </p>
                                                                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                                <div className="d-flex justify-content-start">
                                                                                    <i className="fa fa-shop me-2" />
                                                                                    <span className="text-muted text-data-over-cut">
                                                                                        {item?.class_course?.institute?.name}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="float-end d-flex justify-content-start">
                                                                                    <i className="fa fa-building me-2" />
                                                                                    <span className="text-muted text-capitalize text-data-over-cut">
                                                                                        {replaceWord(item?.class_course?.class_type)}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-2 d-flex justify-content-between">
                                                                                <div className="d-flex justify-content-start">
                                                                                    <i className="fa fa-file me-2" />
                                                                                    <span className="text-muted">
                                                                                        {replaceWord(item?.class_course?.lecture_type)}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="float-end">
                                                                                    {item?.class_course?.price === "paid" && (
                                                                                        <h4 className="mb-0"> ₹ {Math.round(item?.class_course?.payable_price)} {(
                                                                                            item?.class_course?.price == "paid" && (
                                                                                                item?.class_course?.is_discount == 1 && (
                                                                                                    (item?.class_course?.is_limited_offer == 1 && (new Date(item?.class_course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.class_course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                                    (item?.class_course?.is_limited_offer == 0 && new Date(item?.class_course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                            )
                                                                                        ) && (<del className="fs-14 text-muted">₹ {Math.round(item?.class_course?.regular_price)} </del>
                                                                                            )}
                                                                                        </h4>
                                                                                    )}
                                                                                    {item?.price === "free" && (
                                                                                        <h4 className="mb-0">Free</h4>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <table className="table table-bordered table-hover text-nowrap">
                                                        <tbody>
                                                            <tr>
                                                                <td className="font-weight-semibold text-start">
                                                                    MRP :
                                                                </td>
                                                                <td className="text-end">₹ {payment?.price}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-semibold text-start">
                                                                    Discount :
                                                                </td>
                                                                <td className="text-end">₹ {payment?.discount}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-semibold text-start">
                                                                    Price :
                                                                </td>
                                                                <td className="text-end">
                                                                    ₹ {payment?.payable_price}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-semibold text-start">
                                                                    GST :
                                                                </td>
                                                                <td className="text-end">₹ {payment?.gst}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-semibold text-start">
                                                                    <strong>Grand Total :</strong>
                                                                </td>
                                                                <td className="text-end">
                                                                    <strong>₹ {payment?.txnamount}</strong>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="text-center">
                                                        <Link className="btn btn-primary" to="/student-dashboard">View More <i className="fe fe-arrow-right" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Success;
