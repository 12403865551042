import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Curve from '../../../Header/Curve';

const TermsContent = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0;
    }, [pathname]);
    return (
        <>
            <div className="cover-image bg-background-1" data-bs-image-src="assets/images/banners/banner1.webp" style={{ background: 'url("assets/images/banners/banner1.webp") center center' }}>
                <div className="container header-text">
                    <h1>Terms & Conditions</h1>
                </div>
            </div>
            <Curve/>
            <section className="sptb">
                <div className="container">
                    <p>
                        These terms of offer for sale (“Terms of Offer For Sale”) between Education Mandal (hereinafter referred to as “Education Mandal” or “Us” or “We” or “Our”) and the users of the Website ( “You” or “Your” or
                        “Yourself” or “User”) describe, inter alia, the terms of offer for sale, purchase of goods and services for consideration in the form of cash/cash equivalents or otherwise on the Website, www.educationmandal.com
                        (“Website”).
                    </p>
                    <p>
                        Please read the Terms of Offer for Sale carefully before purchasing any product or availing any service on the Website. Any purchase made by you through the Products/Services website represents your acceptance of the
                        terms of the Offer for Sale and your agreement to be legally bound by the same.
                    </p>
                    <p>
                        In addition to the foregoing, you will also be bound by the Terms of Use of the Website, the Terms of Offer for Sale issued by the Provider or the Additional Terms of Service that are displayed with the selection of
                        a Product (“Product, displayed,”) on the Website. If there is a conflict between the Terms of the Offer for Sale and the Additional Terms, the Additional Terms shall take precedence in relation to that Sale.
                    </p>
                    <p>The term product or service is used interchangeably in this document and therefore wherever the term product is used, it would also mean service as well wherever applicable and vice versa.</p>
                    <h3>IF YOU DO NOT AGREE TO THE TERMS OF OFFER SALE, PLEASE DO NOT VISIT THE WEBSITE FOR ANY PURCHASE.</h3>
                    <h3>1. Business</h3>
                    <p>
                        1.1 The Website is a platform that facilitates the online sale and purchase of Products and services (“Services”) offered by Education Mandal various affiliates/ registered merchants/ vendors/ service providers
                        (“Providers”). Providers are the sellers of products and services on the Website and will be solely responsible to You for the products or services sold.
                    </p>
                    <p>
                        1.2 You acknowledge that We have the right to change or discontinue any Service at any time, without notice. You further acknowledge that We may add or modify the procedures, modes, processes or conditions of
                        purchase at any time to adapt to changes that We may make to the or Services. You agree that We shall not be liable to You or to any third party for any modification, suspension or discontinuance of any aspect of the
                        Services.
                    </p>
                    <h3>2. Product</h3>
                    <p>
                        2.1 Education Mandal, through the Website also makes available a variety of branded products and merchandise at an agreed price to You. Purchase of such products will be subject to the Terms of Offer For Sale and
                        such other additional terms if any, as specified by the Providers.
                    </p>

                    <p>
                        2.2 We (through Sellers) offers SHIPPING for all the products on the Website as per the company’s policy, which may be changed by Us without any notice to You. GST or any other form of tax or charges, wherever
                        applicable, is to be borne by the User.
                    </p>
                    <p>
                        2.3 All the Products are governed by the terms of warranties provided by the respective Provider. However, in case any product is covered under seller warranty, it shall be specifically mentioned in the product
                        details.
                    </p>
                    <p>
                        2.4 The price of products offered on the Website is either equal to or lesser than the Maximum Retail Price (“MRP”) i.e., the discounted rate prescribed for those products. The MRP and other statutory declarations
                        shall be mentioned on the products and/or on its packaging in accordance with applicable laws.
                    </p>
                    <h3>3. Services</h3>
                    <p>3.1 You should take all responsibility for your own actions in utilizing the services purchased by You and We shall not be liable for any such action.</p>
                    <p>3.2 You represents that You are of legal age to form a binding contract with Us and are not a person barred from receiving services under the laws as applicable in India.</p>
                    <h3>4. Pricing Information</h3>
                    <p>
                        4.1 We strives to provide You with the best prices possible on products and services You buy or avail of from the Website. However, We does not guarantee that the price will be the lowest in the city, region or
                        geography. Prices and availability are subject to change without notice or any consequential liability to You.
                    </p>
                    <p>
                        4.2 While We strives to provide accurate products, services and pricing information, typographical and other errors may occur. In the event that a product or service is listed at an incorrect price or with incorrect
                        information due to an error in pricing or product or service information, We may, at its discretion, either contact You for instructions or cancel Your order and notify You of such cancellation. We will have the
                        right to modify the price of the product or service and contact You for further instructions via the e-mail address provided by You at the time of registration, or cancel the order and notify You of such
                        cancellation. If We cancels the order after the payment has been processed, the said amount will be returned to Your account from which the payment was originally made.
                    </p>
                    <h3>5. Delivery of the Product and services</h3>
                    <p>
                        We collect information relating to taking Courses, including, interchanges with the Professor, teaching assistants, counsellors, representatives of course providers or Education Mandal and other Students, answers to
                        questions and other items submitted to satisfy the Course requirements, (“Course Information”).
                    </p>
                    <p>
                        5.1 In the event Your order is not serviceable by our delivery partners or the Provider or the area is not covered, we would request You to provide us with an alternate shipping address which we expect to have on Our
                        partner/Provider’s delivery list. In case there is any dispute regarding the shipment of the product or services for the area not covered by Us, in such cases, We will not be responsible for the non-delivery of the
                        product or service.
                    </p>

                    <p>
                        5.2 However, You can make purchases on the website from anywhere in the world but at the same time ensuring the shipping address is within India. In case You book multiple Products and Services in one transaction/
                        order, We would endeavor to ship all Products together. However, this may not always be possible due to some product characteristics and/or logistics’ issues. If You purchase multiple Products in a single
                        transaction, then all the Products would be dispatched to a single shipping address given by You. If You wish to ship Products to different addresses, then You should book the orders separately based on the delivery
                        addresses.
                    </p>
                    <h3>Payment Information:</h3>
                    <p>5.3 Services capable of being delivered online will be delivered online by Us or by any of its Providers whose services have been purchased on the website.</p>
                    <h3>6. Return Policy for Products or services</h3>
                    <p>
                        6.1 In the event You receive a damaged / defective product or a product that does not comply with the specifications as per your original order, You are required to get in touch with the customer service team by
                        emailing Us on info@educationmandal.com.
                    </p>
                    <p>
                        6.2 We do not offer any refunds against any services purchased from the Website unless an error has occurred during the purchase or redemption of such services which is directly attributable to Us or if the product
                        delivered is Damaged/ Non-working/ Wrong Product, and is irreplaceable or irreparable within a reasonable time.
                    </p>
                    <p>
                        6.3 Replacements are available for the Users who receive a Damaged, Non-working or Wrong Product from the Seller. You can raise a replacement request to Our Support Team (info@educationmandal.com), within 3 days of
                        delivery, at no extra cost. The 3 Days Replacement Policy is applicable to only CD/DVDs/Pen Drives/Study Materials/SD Cards/Tablets sold on our Website.
                    </p>
                    <p>
                        6.4 In case of Online Products, a user can claim for replacement only in the case where a Wrong/Non-functional product is being delivered to the User or the User has not received the appropriate details within 48
                        hours of the purchase. Such a request also must be raised within 3 days of the order date.
                    </p>
                    <p>
                        6.5 Once the claim is approved by Seller/ Our Support Team, as the case may be, all the Tangible Products should be returned in the original condition you had received them to the address given by Our Support Team.
                        After shipping the product User has to furnish the tracking code to Our Support Team within 48 hours of the dispatch. The cost of reverse shipment will be reimbursed to the User, within 7 days of the delivery of
                        replaced tangible product. It is advised that the return packets should be strongly and adequately packaged so that there is no further damage to products during transit.
                    </p>
                    <p>
                        In the event of frivolous and baseless complaints regarding the quality and content of the products, We reserves the right to take necessary legal actions against You and You will be solely liable for all costs
                        incurred by Us in this regard. You expressly acknowledge that the Provider selling the defective product/ service will be solely responsible to You for any claims that You may have in relation to such defective
                        product/ service and We shall not in any manner be held liable for the same.
                    </p>
                    <p>6.6 Please understand that we cannot accept returns in certain instances:</p>
                    <ul className="education-mandal-data">
                        <li>• Damages due to misuse of Tangible Products</li>
                        <li>• Incidental damage due to malfunctioning of Tangible Products</li>
                        <li>• Any Tangible Product that is returned without all original packaging and accessories, including the box.</li>
                        <li>• Any other instance specifically mentioned on the packaging of the product</li>
                    </ul>
                    <p>
                        6.7 Replacement can be for the entire product or part(s) of the product subject to availability of the same at Seller’s end. In case of non-availability of the product, Users can claim replacements for Software
                        failures as well. However, User has to first report an issue via email (info@educationmandal.com). If the issue cannot be corrected remotely then the replacement request can be accepted.
                    </p>
                    <p>
                        6.8 All the claims for replacement of Damaged Product/s, made by the Customer/s on Our website will be notified to sellers by the Our seller support team. The Seller will then check the legitimacy of the claim and
                        approve/ reject the claim and decide the subsequent course of action (refund/ replacement) depending upon the availability of the product. If the Seller fails to take any action within 3 working days from the date
                        the claim is raised, then the matter would get escalated to Our Support Team. We would then hold all the rights whether to refund the order in all the above cases/claims or replace the products with the
                        new/fresh/genuine products.
                    </p>
                    <p>6.9 It is Seller’s responsibility to ship the fresh/new/genuine product to the Customer after receiving the replacement request from Our seller support team.</p>
                    <p>6.10 Any physical damage to the Tangible Product by the User while using it or due to mishandling of the product will not be covered for replacements or returns under this policy.</p>
                    <p>
                        6.11 Cancellation by the User: All the cancellation request by the Customer/s should be made within 24 hours of the purchase or before the Tangible Product has been shipped or order has been processed. We have the
                        full right to decide whether an order has been processed or not. You agree not to dispute the decision made by Us and accept Our decision regarding the cancellation.
                    </p>
                    <p>In the case of cancellation, the entire amount will be refunded to the Customer.</p>
                    <p>Order cancellation is not allowed in cases, where the shipping or the delivery of the product to the Customer has already been initiated by the Seller.</p>
                    <p>
                        6.12 Cancellation by Education Mandal: There may be certain orders that We would be unable to accept and must cancel. We reserves the right, at Our sole discretion, to refuse or cancel any order for any reason
                        whatsoever. Some situations that may result in Your order being cancelled include, without limitation, non-availability of the product or quantities ordered by You, non-availability of the service, inaccuracies or
                        errors in pricing information, or problems identified by Our credit and fraud avoidance department. We may also require additional verifications or information before accepting any order. We will contact You if all
                        or any portion of Your order is cancelled or if additional information is required to accept Your order.
                    </p>
                    <p>
                        We reserves the right to cancel any orders that classify as ‘Bulk Order’ as determined by Us as per certain criteria. Any Education Mandal cash or Promo Code or Discount Coupon Code used for placing the ‘Bulk Order’
                        will not be refunded as per this cancellation policy. An order can be classified as ‘Bulk Order’ if it meets the below-mentioned criteria, which may not be exhaustive, viz:
                    </p>
                    <ul className="education-mandal-data">
                        <li>• Products ordered are not for self-consumption but for commercial resale</li>
                        <li>• Multiple orders placed for same product at the same address</li>
                        <li>• Bulk quantity of the same product ordered</li>
                        <li>• Invalid address given in order details</li>
                        <li>• Any malpractice used to place the order</li>
                    </ul>
                    <h3>7. Failure to Sell by the Provider</h3>
                    <p>
                        Unless due to Your negligence, in the event the Provider fails to complete a sale of a product or a service when presented by You, You acknowledge that the Provider will be solely liable to reimburse such amount as
                        has already been paid by You for the purchase of such order. Further, You agree that We will not be held liable for the failure of the Provider to make available any product or service therein.
                    </p>
                    <h3>8. Disclaimer</h3>
                    <p>
                        8.1 We do not control, endorse or accept responsibility for any products (including but not limited to product catalogs) or services provided by third parties accessible through the Website or any linked site. We
                        make no representations or warranties regarding, and shall not be liable for, the provider or any such third party, their products or services, including representations relating to non-infringement. Any transaction
                        that you may have with such third parties is at your own risk. The products will be subject to the provider's terms and conditions for warranty, service and maintenance, and we accept no responsibility for the same.
                        We do not accept any responsibility for your use of the products.
                    </p>
                    <p>
                        8.2 We specifically disclaim any liability with respect to any defective or counterfeit products purchased or serviced by you from the Provider and we will not assume any liability relating to the sale of the product
                        as a substitute for the same. Purchase confirmation order.
                    </p>
                    <h3>9. Indemnification and Limitation of Liability</h3>
                    <p>
                        9.1 You agree to indemnify, defend and hold harmless Us from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith
                        and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or may be payable by virtue of, any breach of any representation, warranty, covenant or agreement made or obligation
                        to be performed by You pursuant to these Terms of Offer For Sale or any Additional Terms applicable to the purchase of products and services on the Website.
                    </p>
                    <p>
                        9.2 In no event shall Education Mandal, its officers, directors/partners, employees, partners or vendors be liable to You, the Provider or any third party for any special, incidental, indirect, consequential or
                        punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable or whether or not We have been advised of the possibility of such damages, or based on any theory
                        of liability, including breach of contract or warranty, negligence or other tortious action, or any other claim arising out of or in connection with Your purchase of the products and services herein. Notwithstanding
                        anything to contrary, Our entire liability to You under this Terms of Offer For Sale or otherwise shall be the refund of the money charged from You for any specific Voucher or product or service, under which the
                        unlikely liability arises.
                    </p>
                    <p>
                        9.3 We shall not assume any liability for the non-availability of the product, delivery of the product and the installation of the product where required or any action or inaction that might be taken by the Provider.
                    </p>
                    <h3>10. Governing Law</h3>
                    <p>10.1 These Terms of Offer For Sale and the relationship between You and Education Mandal shall be governed in accordance with the laws of India without reference to conflict of laws principles.</p>
                    <p>
                        10.2 You agree that all claims, differences and disputes arising under or in connection with or in relation to the Terms of Offer For Sale or any transactions entered into on or through the Website or the
                        relationship between You and Education Mandal shall be subject to the exclusive jurisdiction of the courts at Mumbai and You hereby accede to and accept the jurisdiction of such courts.
                    </p>
                </div>
            </section>
        </>
    );
};

export default TermsContent;
