import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import NoDataAvailable from "../../../../common/NoDataAvailable";

export default function PopularBlogs({ param }) {
    const [popularBlogs, setPopularBlogs] = useState([]);
    const getPopularBlogsData = async (id) => {
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_BASEURL + `get-populer-blog/${param}`,
                config
            );
            if (response.data?.status === "success") {
                setPopularBlogs(response.data?.data);
            }
        } catch (err) {
            throw err;
        }
    };
    useEffect(() => {
        getPopularBlogsData();
    }, [param]);
    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Popular Blogs</h4>
            </div>
            <div className="card-body overflow-auto h-600 pt-3 list-category1">
                <div className="item-list">
                    <ul>
                        {popularBlogs?.length > 0 ? (
                            popularBlogs.map((item, i) => (
                                <li className="item list-group-item d-flex p-2" key={i}>
                                    <img
                                        src={item?.thumb_image}
                                        className="img-thumbnail avatar-lg me-3"
                                        alt={item?.name}
                                    />
                                    <div>
                                        <small className="d-block">{item?.category?.name}</small>
                                        <Link to={`/blog-detail/${item?.id}`} className="leading-tight2">
                                            {item?.name}
                                        </Link>
                                        <small className="d-block text-muted">{moment(item?.created_at).fromNow()}</small>
                                    </div>
                                </li>
                            ))

                        ) : (
                            <NoDataAvailable/>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}
