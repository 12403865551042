import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import Curve from "../../Header/Curve";
import { Form } from "react-bootstrap";

const Alumni = () => {
    return (
        <>
            <Headerinner />
            <div className="cover-image bg-background-1" data-bs-image-src="assets/images/banners/banner1.webp" style={{ background: 'url("assets/images/banners/banner1.webp") center center' }}>
                <div className="container header-text">
                    <div className="row">
                        <div className="col-xl-9 col-lg-12 col-md-12 d-block mx-auto">
                            <h1> Alumni </h1>
                            <div className="search-background bg-transparent">
                                <form>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Form.Group>
                                                        <Form.Control
                                                            name="query"
                                                            className="mb-0"
                                                            placeholder="Search alumni"
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-3">
                                                    <select className="form-select">
                                                        <option>Batch start years</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-3">
                                                    <select className="form-select">
                                                        <option>Batch end years</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-2 text-start">
                                                    <button type="submit" className="btn btn-primary" title="Search">
                                                        <i className="fe fe-search" /> Search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="mb-0">
                                        Showing 1 to 1 of 1 entries
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <Link to="/alumni-detail">
                                <div className="card">
                                    <div className="card-body" >
                                        <div className="item-card2-desc">
                                            <div className="item-card7-img">
                                                <img src="#" className="cover-image teacher-student-image" alt="Student image" />
                                                {/* {item?.class_type === "school" ? (
                                                    <Link to="#" className="bg-danger right bottom">
                                                        {item?.board}
                                                    </Link>
                                                ) : (item?.class_type === "university" ? (
                                                    <Link to="#" className="bg-danger right bottom">
                                                        {item?.universities?.name}
                                                    </Link>
                                                ) : (item?.class_type === "tuition" && (
                                                    <>
                                                        {item?.tuition_type === "school" ? (
                                                            <Link to="#" className="bg-danger right bottom">
                                                                {item?.board}
                                                            </Link>
                                                        ) : (item?.tuition_type === "university" && (
                                                            <Link to="#" className="bg-danger right bottom">
                                                                {item?.universities?.name}
                                                            </Link>
                                                        ))}
                                                    </>
                                                )))} */}
                                            </div>
                                            <h4 className="mt-4 mb-2 leading-tight">
                                                Student Name
                                            </h4>
                                            <p className="leading-tight3 education-mandal">d</p>
                                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                <div className="d-flex justify-content-start">
                                                    <i className="fa fa-shop me-2" />
                                                    <span className="text-muted text-data-over-cut">
                                                        Institute Name
                                                    </span>
                                                </div>
                                                <div className="float-end">
                                                    <i className="fa fa-building me-2" />
                                                    <span className="text-muted text-capitalize">
                                                        School
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="pt-2">
                                                <i className="fa fa-microphone me-2" />
                                                <span className="text-muted text-data-over-cut">
                                                    Current Company
                                                </span>
                                                <div className="float-end">
                                                    <i className="fa fa-calendar me-2" />
                                                    <span className="text-muted text-capitalize">
                                                        2014 - 2018
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Alumni;