import React, { useEffect, useState } from "react";
import url from "../../../../constants/url";
import { toast } from "react-toastify";

const ApproveCareerPopup = ({ getLectures, setAboutForm, aboutForm, lectureId, setLectureId, isMain, activity }) => {
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const [approveStatus, setApproveStatus] = useState(0);

    const sendForApproval = () => {
        // sendForApproval(e.target.value)
        if (approveStatus == 1) {
            approveClass(lectureId);
            setAboutForm(false);
        } else {
            setLectureId(0);
            setAboutForm(false);
        }
    };

    const approveClass = async (id) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(url.apiUrl + `institute/submit-career/${isMain}/` + id, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    toast.success(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    getLectures(activity);
                } else {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => console.log("error", error));
    };
    return (
        <div
            className={aboutForm ? "modal fade show" : "modal fade"}
            id='about'
            tabIndex={-1}
            aria-labelledby='InstituteAbout'
            aria-hidden='true'
            style={aboutForm ? { display: "block" } : { display: "none" }}
        >
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title' id='InstituteAbout'>
                            Career Change Status
                        </h4>
                        <button
                            type='button'
                            className='btn-close'
                            id='about-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={() => setAboutForm(false)}
                        />
                    </div>
                    {aboutForm ? (
                        <form>
                            <div className='modal-body'>
                                <div className='row'>
                                    <div className='col-md-12 mt-2'>
                                        <label htmlFor='name'>
                                            Change Status<span className='text-danger'>*</span>
                                        </label>
                                        <select
                                            className='form-select'
                                            onChange={(e) => setApproveStatus(e.target.value)}
                                        >
                                            <option>Select status</option>
                                            <option value={1}>Send to approval</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button
                                    className='btn btn-primary'
                                    onClick={() => {
                                        sendForApproval();
                                    }}
                                >
                                    Submit
                                </button>
                                <button
                                    type='reset'
                                    className='btn btn-danger '
                                    data-bs-dismiss='modal'
                                    onClick={() => {
                                        setAboutForm(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    )
}

export default ApproveCareerPopup;

