import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function UpdatedCourseDetails(props) {
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-header"><h4 className="card-title">Video Course List</h4></div>
                    <div className="card-body">
                        <div
                            id="basic-1_wrapper"
                            className="dataTables_wrapper no-footer"
                        >
                            <div id="basic-1_filter" className="dataTables_filter">
                                <label>
                                    Search:
                                    <input
                                        type="search"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            props.setSearch({
                                                ...props.search,
                                                searchTxt: e.target.value,
                                            });
                                        }}
                                    />
                                </label>
                            </div>
                            <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                <thead>
                                    <tr>
                                        <th className="text-center">Sr. No.</th>
                                        <th>Course Details</th>
                                        <th className="text-center">Category Name</th>
                                        <th>Branch Details</th>
                                        <th>Teachers Details</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data?.length > 0 ? (
                                        props.data.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th className="text-center text-nowrap">
                                                        {i + props.offset + 1}
                                                    </th>
                                                    <td
                                                        className="text-capitalize"
                                                        style={{ width: "250px" }}
                                                    >
                                                        <div className="media">
                                                            <img
                                                                src={item?.teaser_thumb_image}
                                                                alt={item?.name}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link className="font-weight-semibold" to={"/institute-dashboard/video-course-detail/0/" + item?.id}>
                                                                    {item?.title}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        {item?.category?.title}
                                                    </td>
                                                    <td>
                                                        <div className="media">
                                                            <img
                                                                src={item?.institute_branch?.image}
                                                                alt={item?.institute_branch?.name}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link className="font-weight-semibold"
                                                                    to={`/institute-dashboard/branch-detail/${item?.institute_branch_id}`}
                                                                >
                                                                    {item?.institute_branch?.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                    {item?.institute_branch?.email} </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="media">
                                                            <img
                                                                src={item?.teacher?.image}
                                                                alt={item?.teacher?.name}
                                                                className="me-3 rounded-circle"
                                                            />
                                                            <div className="media-body">
                                                                <Link className="font-weight-semibold"
                                                                    to={`/institute-dashboard/teacher-detail/${item?.id}`}
                                                                >
                                                                    {item?.teacher?.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                    {item?.teacher?.email}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className={`text-center text-capitalize ${item?.status === "decline" && " text-danger"}`}>
                                                        {item?.status}   {item?.status === "decline" && `(${item?.reason?.reason})`}
                                                    </td>
                                                    <td className="text-center text-nowrap">
                                                        {item?.status === "pending" &&
                                                            item?.is_submit == 1 ? (
                                                            <Link
                                                                disabled
                                                                className="btn btn-outline-grey btn-sm me-2"
                                                                onClick={() => {
                                                                    alert(
                                                                        "You can't update the course until it gets approved !"
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fa fa-pen-to-square fs-16"></i>
                                                            </Link>
                                                        ) : (
                                                            <>
                                                                {item?.is_update == 1 &&
                                                                    item?.is_submit == 1 ? (
                                                                    <Link
                                                                        disabled
                                                                        className="btn btn-primary btn-sm me-2"
                                                                        onClick={() => {
                                                                            alert(
                                                                                "You can't update the course until it gets approved !"
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-pen-to-square fs-16"></i>
                                                                    </Link>
                                                                ) : (
                                                                    <Link
                                                                        className="btn btn-primary btn-sm me-2"
                                                                        to={
                                                                            "/institute-dashboard/updated-course-edit/0/" +
                                                                            item?.id
                                                                        } title="Change Status"
                                                                    >
                                                                        <i className="fa fa-pen-to-square fs-16"></i>
                                                                    </Link>
                                                                )}
                                                            </>
                                                        )}
                                                        <button
                                                            type="button" title="Change Status"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => props.aboutHandler(item?.id)}
                                                        >
                                                            <i className='fa fa-eye fs-16'></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td className="text-center" colSpan={7}>No Data Available !</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {props.data?.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(props?.count)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={props?.handlePageClick}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </>
    );
}
