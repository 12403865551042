import React from "react";
import { useState, useEffect, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import About from "./About";
import Education from "./Education";
import Experience from "./Experience";
import Achivement from "./Achivement";
import Reviews from "./Reviews";
import { getStudentGetProfile } from "../../../../apis";
import url from "../../../../constants/url";
import { ToastContainer } from "react-toastify";

const StudentDetails = (props) => {
    const [studentdata, setStudentdata] = useState([]);
    const [studentachivementdata, setStudentAchivementdata] = useState({});

    const getStudentGetProfiles = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}student/get-profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setStudentdata(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        getStudentGetProfiles();
    }, []);

    const [studentexperiencedata, setStudentExperiencedata] = useState({});
    const getStudentExperienceGetProfiles = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            per_page: 10,
            order_column: "id",
            order_by: "asc",
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        fetch(`${url.apiUrl}student/get-all-experiences`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setStudentExperiencedata(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        getStudentExperienceGetProfiles();
    }, []);

    const getStudentAchivementGetProfiles = async () => {
        let res = await getStudentGetProfile();
        if (res.status === "success") {
            setStudentAchivementdata(res.data);
        }
    };
    useEffect(() => {
        getStudentAchivementGetProfiles();
    }, []);
    const { data } = props;
    return (
        <>
            <section>
                <div className="row match-height">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Student Details</h4>
                            </div>
                            <div className="card-body">
                                <div className="card">
                                    <Tabs defaultActiveKey="about" id="uncontrolled-tab-example" >
                                        <Tab eventKey="about" title="about">
                                            <About data={studentdata && studentdata} setStudentdata={setStudentdata} />
                                        </Tab>
                                        <Tab eventKey="education" title="education">
                                            <Education data={studentdata && studentdata} />
                                        </Tab>
                                        <Tab eventKey="experience" title="experience">
                                            <Experience data={studentexperiencedata && studentexperiencedata}/>
                                        </Tab>
                                        <Tab eventKey="achievement" title="achievement" >
                                            <Achivement data={studentachivementdata && studentachivementdata} />
                                        </Tab>
                                        <Tab eventKey="review" title="review">
                                            <Reviews />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />

        </>
    );
};

export default StudentDetails;
