import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InstituteProfile from "./InstituteProfile/InstituteProfile";
import InstituteDetails from "./InstituteDetails/InstituteDetails";
import url from "../../../../constants/url";
import useToken from "../../../../hooks/useToken";

const Profile = () => {
    const { token } = useToken()
    const [data, setData] = useState({});
    const [detail, setDetail] = useState({
        about: "",
    });

    const GetProfileData = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token);
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };
        fetch(`${url.apiUrl}institute/get-profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {

                    setData(result.data);
                    setDetail({
                        about: result.data?.institute_teacher?.about,
                    });
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        GetProfileData();
    }, []);

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Institute Profile</li>
                </ol>
            </div>
            <InstituteProfile />
            <InstituteDetails data={data} detail={detail} setDetail={setDetail} GetProfileData={GetProfileData} setData={setData} />
        </>
    );
};

export default Profile;
