import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import url from "../../../../../constants/url";
import Moment from "react-moment";
import { calculateExperience } from "../../../../../utils/utils";
let rating = [1, 2, 3, 4, 5];

const TeacherProfile = () => {
    const params = useParams();
    const id = params?.id;
    const [data, setData] = useState([]);

    const getAllusersData = async () => {
        var localdata = localStorage.getItem("common-user-info");
        var jsondata = JSON.parse(localdata);
        var token_authorization = jsondata?.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token_authorization);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url?.apiUrl}institute-teacher/get-profile/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result?.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getAllusersData();
    }, []);
    return (
        <>
            <section className="app-user-view">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Teacher Profile</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-12">
                                        <div className="item-card7-img">
                                            <img className="cover-image" src={data?.image} alt={data?.name} />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-3 col-12 mt-5 mt-xl-0">
                                        <h4>{data?.name}</h4>
                                        <p className="card-text mb-2">{data?.email}</p>
                                        <p className="card-text mb-2">{data?.contact}</p>
                                        <p className="card-text"><i className="fa fa-location-dot me-1"></i>{data?.address ? data?.address : "---"}</p>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 mt-5 mt-xl-0">
                                        <table className="user-details table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Username / Referral Code
                                                    </td>
                                                    <td className="py-1 px-0 text-uppercase">
                                                        {data?.user_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Experience
                                                    </td>
                                                    <td className="py-1 px-0">
                                                        {data?.total_experiance != 0 ? calculateExperience(data?.total_experiance) : "---"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Rating
                                                    </td>
                                                    <td className="py-1 px-0">
                                                        <div className="d-inline-flex">
                                                            <div className="br-theme-fontawesome-stars me-2">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= data?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <span>({data?.review_count} Reviews)</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Last Update
                                                    </td>
                                                    <td className="py-1 px-0">
                                                        <Moment format='DD-MM-YYYY hh:mm:A'>{data?.updated_at}</Moment>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TeacherProfile;
