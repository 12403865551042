import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import url from "../../constants/url";
import { Form } from "react-form-elements";
import Select from "react-select";
import { Context } from "../../Context";
import { toast } from "react-toastify";

function Login() {
    const { activities } = useContext(Context);
    const [active, setActive] = activities;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newUser, getUser] = useState([]);
    const [validationError, setValidationError] = useState({
        type: "",
        email: "",
        password: "",
    });
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const history = useHistory();

    const get_course_id = localStorage.getItem("course");
    const options = [
        {
            label: "Institute",
            value: "institute",
        },
        {
            label: "Teacher",
            value: "teacher",
        },
        {
            label: "Student",
            value: "student",
        },
    ];

    const onChangeHandler = (change) => {
        getUser({
            ...newUser,
            businessSector: change,
        });
    };

    const FormSubmit = async (e) => {
        const errors = {};

        if (email === undefined || email === "") {
            errors.email = "Required";
        }
        if (password === undefined || password === "") {
            errors.password = "Required";
        }
        if (newUser?.businessSector === undefined) {
            errors.type = "Required";
        }

        if (Object.keys(errors).length === 0) {
            var formdata = new FormData();
            formdata.append("email", email);
            formdata.append("password", password);
            formdata.append("type", newUser?.businessSector?.value);

            var requestOptions = {
                method: "POST",
                body: formdata,
            };

            fetch(url.apiUrl + "login", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        var userdata = { 
                            name: result.data.name,
                            email: result.data.email,
                            id: result.data.id,
                            type: result.data.type,
                            auth_token: result.data.auth_token,
                            mobile: result.data.contact,
                        };

                        localStorage.setItem("common-user-info", JSON.stringify(userdata));
                        if (get_course_id) {
                            history.goBack();
                            localStorage.setItem("courseid", get_course_id);
                        } else {
                            if (result.data.type === "institute") {
                                if (result?.data?.is_change_password == 0) {
                                    setActive(true);
                                }
                                history.push("institute-dashboard");
                            } else if (result.data.type === "teacher") {
                                history.push("teacher-dashboard");
                                if (result?.data?.is_change_password == 0) {
                                    setActive(true);
                                }
                            } else if ("student") {
                                history.push("/");
                            }
                        }
                    } else {
                        setValidationError(result.data);
                        toast.error(result.message);
                    }
                })
                .catch((error) => console.log("error", error));
        } else {
            setValidationError(errors);
        }
    };

    const toggleChange = useEffect(() => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage?.auth_token;
        if (auth_token !== null && auth_token !== undefined) {
            history.push("/");
        }
    }, []);
    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-xl-12 p-0 background-image-login">
                        <div className="login-card">
                            <div className="theme-form login-form">
                                <div className="logo-section">
                                    <Link to="/">
                                        <img src="/assets/images/em.png" alt="Education Mandal Logo" />
                                    </Link>
                                </div>
                                <hr></hr>
                                <div className="mb-6">
                                    <h5 className="fs-25">Login</h5>
                                </div>
                                <div className="customer-page">
                                    <Form name="loginForm" onSubmit={FormSubmit} className="row">
                                        <div className="col-lg-12 form-group">
                                            <label>
                                                User Type <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={options}
                                                name="type"
                                                className="select_box form-control form-select"
                                                onChange={onChangeHandler}
                                                value={newUser.businessSector}
                                            ></Select>
                                            <p className="bg-error">{validationError?.type}</p>
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            <label htmlFor="login-email">
                                                Email ID <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="email"
                                                name="mail"
                                                className="form-control"
                                                placeholder="Please enter email id"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <p className="bg-error">{validationError?.email}</p>
                                        </div>
                                        
                                        <div className="col-lg-12 form-group">
                                            <label className="form-label" htmlFor="email">Password <span className="text-danger">*</span></label>
                                            <div className="input-group input-group-merge">
                                                <input className="form-control form-control-merge" type={passwordType}
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    name="password" placeholder="Please enter password" aria-describedby="password" tabIndex="2" />
                                                <span className="input-group-text cursor-pointer" onClick={togglePassword}>
                                                    {passwordType === "password" ? <i className="fa-regular fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                                                </span>
                                            </div>
                                            <p className="bg-error" style={{ bottom: "-20px" }}>
                                                    {validationError?.password}
                                                </p>
                                        </div>
                                        <div className="d-flex">
                                            <p className=" mb-0 ms-auto">
                                                <Link
                                                    to="/forgot-password"
                                                    className="text-primary ms-1"
                                                >
                                                    Forgot password?
                                                </Link>
                                            </p>
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            <button
                                                type="submit"
                                                className="btn btn-primary w-100 mt-2"
                                            ><i className="fa-solid fa-arrow-right-to-bracket me-2"></i>Sign in
                                            </button>
                                        </div>
                                        <p className="mb-0 text-end">
                                            Don't have account?
                                            <Link
                                                to="/registration"
                                                className="text-primary ms-1"
                                            >
                                                Signup
                                            </Link>
                                        </p>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
