import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ContextProvider } from "./Context";
import App from "./App";

window.tinyAPIKEY = "9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d";
window.user = JSON.parse(localStorage.getItem("common-user-info"))
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ContextProvider>
      <App />
    </ContextProvider>
  </BrowserRouter>
);
