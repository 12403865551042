import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import Curve from "../../Header/Curve";
import HomePageService from "../../../../Services/HomePageService";
import ReactPaginate from "react-paginate";
import axios from "axios";
import url from "../../../../constants/url";
import { Formik } from "formik";
import Select from "react-select";
import SubmitLoader from "../../../common/SubmitLoader";
import NoDataAvailable from "../../../common/NoDataAvailable";
import { replaceWord } from "../../../../utils/utils";

const Careers = () => {
    const serve = new HomePageService();
    const [careerList, setCareerList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [offset, setOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [values, setValues] = useState({
        state: "",
        city: "",
        query: "",
    });
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 12,
        state: "",
        city: "",
        query: "",
    });
    useEffect(() => {
        CareerListApi();
        StateListApi();
    }, []);

    const StateListApi = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}state/101`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setState(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const CityListApi = async (id) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}city/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setCity(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };


    const apiCall = async (activity) => {
        const config = {
            headers: {
                content: "multipart/form-data",
            },
        };
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASEURL + `careers`,
                activity,
                config
            );
            if (response) {
                setLoader(false)

                setCareerList(response.data?.data);
                setTotalPages(response.data?.count / search.perPage);
                setTotalCount(response.data?.count);
            }
        } catch (err) {
            throw err;
        }
    };

    const onSubmit = async (values) => {
        setLoader(true)

        setSearch({ ...search, state: values.state.label, city: values.city.label, query: values.query });
        apiCall({
            limit: search.perPage,
            offset: search.start,
            state: values.state.label,
            city: values.city.label,
            query: values.query
        });

    }

    const CareerListApi = async () => {
        await apiCall({
            limit: search.perPage,
            offset: search.start,
        });
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search?.perPage;
        setOffset(currentPage);

        await apiCall({
            limit: search.perPage,
            offset: currentPage,
            state: values.state.label,
            city: values.city.label,
            query: values.query
        });
    };


    return (
        <>
            <Headerinner />
            <div className="cover-image bg-background-1" data-bs-image-src="assets/images/banners/banner1.webp" style={{ background: 'url("assets/images/banners/banner1.webp") center center' }}>
                <div className="container header-text">
                    <div className="row">
                        <div className="col-xl-9 col-lg-12 col-md-12 d-block mx-auto">
                            <h1>
                                Careers / Jobs
                            </h1>
                            <div className="search-background bg-transparent">
                                <Formik
                                    initialValues={values}
                                    onSubmit={onSubmit}
                                >
                                    {(props) => (
                                        <form onSubmit={props.handleSubmit}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <Select
                                                                value={props.values.state}
                                                                name="state"
                                                                onChange={(e) => {
                                                                    CityListApi(e.value);
                                                                    props.setFieldValue("state", e);
                                                                }}
                                                                options={state}
                                                            />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Select
                                                                value={props.values.city}
                                                                name="city"
                                                                onChange={(e) => {
                                                                    props.setFieldValue("city", e);
                                                                }}
                                                                options={city}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <Form.Group>
                                                                <Form.Control
                                                                    name="query"
                                                                    className="mb-0"
                                                                    placeholder="Search jobs"
                                                                    onChange={props.handleChange}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-2 text-start">
                                                            {loader ? (
                                                                <SubmitLoader />
                                                            ) :
                                                                (
                                                                    <button type="submit" className="btn btn-primary" title="Search">
                                                                        <i className="fe fe-search" /> Search
                                                                    </button>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        {careerList?.length > 0 ? (
                            <>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                Showing {offset + 1} to {offset + careerList?.length} of {totalCount} entries
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                {careerList.map((item, i) => (
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                        <Link to={`/career-detail/${item?.slug}`} >
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="item-card7-img">
                                                        <img className="cover-image" src={item?.thumb_image}
                                                            alt={item?.title} />
                                                        <Link to="#" className="bg-danger right top">
                                                            {item?.employee_type}
                                                        </Link>
                                                        <a className="bg-danger right bottom" href="/"> {replaceWord(item?.class_type)} {item?.position}</a>
                                                        {item?.position === "other" && (
                                                            <a className="bg-danger right bottom" href="/">  {item?.other_position}</a>
                                                        )}
                                                    </div>
                                                    <div className="item-card2-desc">
                                                        <h4 className="mb-2 leading-tight2">
                                                            {item?.title}
                                                        </h4>
                                                        <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                        </p>
                                                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                            <div className="d-flex justify-content-start">
                                                                <i className="fa fa-shop me-2" />
                                                                <span className="text-muted text-data-over-cut">
                                                                    {item?.institute_branch?.name ? item?.institute_branch?.name : "Education Mandal"}
                                                                </span>
                                                            </div>
                                                            <div className="float-end d-flex justify-content-start">
                                                                <i className="fa fa-signal me-2" />
                                                                <span className="text-muted text-capitalize text-data-over-cut">
                                                                    {item?.job_type}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-2 pb-2 border-bottom">
                                                            <i className="fa-solid fa-indian-rupee-sign me-2"></i>
                                                            {item?.is_disclosed_salary === 1 ? (
                                                                <span className="text-muted">
                                                                    {item?.salary}
                                                                </span>
                                                            ) : (
                                                                <span className="text-muted">Not Disclosed</span>
                                                            )}
                                                            <div className="float-end">
                                                                <i className="fa fa-clock-o me-1" />
                                                                <span className="text-muted text-capitalize">
                                                                    {item?.experience}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-2">
                                                            <i className="fa-solid fa-location-dot me-2"></i>
                                                            <span className="text-muted">
                                                                {item?.location}, {item?.city}, {item?.state}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                Showing {0} to {offset + careerList?.length} of {totalCount} entries
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <NoDataAvailable />
                            </>
                        )
                        }
                    </div>
                    {careerList?.length > 0 && (
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(totalPages)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Careers;