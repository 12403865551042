import React from 'react';

const WhyChooseEdu = () => {
    return (
        <>
            <section className="sptb">
                <div className="container">
                    <div className="section-title">
                        <h2>Why Choose Education Mandal?</h2>
                    </div>
                    <div className="row match-height">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-primary mb-3">
                                            <i className="fa fa-shop text-white"></i>
                                        </div>
                                        <h3>One-stop-shop</h3>
                                        <p>With Education Mandal, you don't have to spend hours searching
                                            for educational resources. Everything you need is in one place.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-success mb-3">
                                            <i className="fa fa-database text-white"></i>
                                        </div>
                                        <h3>Institutions Database</h3>
                                        <p>A comprehensive database of educational institutions with
                                            detailed information on programs, courses, syllabus, fees, and more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-secondary mb-3">
                                            <i className="fa fa-person-chalkboard text-white" />
                                        </div>
                                        <h3>Access to top-notch Teachers</h3>
                                        <p>Our platform provides students with access to
                                            top-notch teachers who are experts in their respective fields.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center match-height">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-primary mb-3">
                                            <i className="fa fa-star text-white" />
                                        </div>
                                        <h3>Institution and Teacher Ratings</h3>
                                        <p>Students can rate institutions and teachers,
                                            making it easier for other students to find quality resources.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-success mb-3">
                                            <i className="fa fa-fax text-white" />
                                        </div>
                                        <h3>Student Community</h3>
                                        <p>A platform for students to connect, share information, and
                                            support each other as they pursue their education.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};

export default WhyChooseEdu;