import React, { useEffect, useState } from "react";
import Footer from "../../../Footer/Footer";
import Headerinner from "../../../Header/Headerinner";
import BlogDetailsshow from "./BlogDetailsshow";
import { useParams } from "react-router-dom";

import { getBlogDetails } from "../../../../../apis_web";
import axios from "axios";
const BlogDetails = () => {
    const param = useParams();
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage?.auth_token;
    const [blogDataDetails, setBlogData] = useState();
    const getBlogDetailsData = async () => {
        let config;

        config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            let response = await axios.get(
                process.env.REACT_APP_API_BASEURL + `get-blog/${param.id}`,
                config
            );
            if (response) {
                setBlogData(response.data?.data);
            }
        } catch (err) {
            throw err;
        }
    };
    useEffect(() => {
        getBlogDetailsData(param.id);
    }, [param]);
    return (
        <>
            <Headerinner />
            <BlogDetailsshow
                data={blogDataDetails}
                getBlogDetailsData={getBlogDetailsData}
                param={param?.id}
            />
            <Footer />
        </>
    );
};

export default BlogDetails;
