const webroutes = {
    institute_register: 'institute/register',
    teacher_register: 'teacher/register',
    student_register: 'student/register',

    get_online_course: 'get-online-course',
    get_online_course_id: "get-online-course-id",
    get_review: 'get-review',

    get_best_teacher: 'best-teacher',
    get_get_teacher: 'get-teacher',
    get_teacher_profile_id: "get-teacher-profile-id",
    get_latest_review: 'latest-review',
    get_cart: 'get-cart',
    get_class: 'upcoming-classes',
    get_class_course: 'get-class-course',
    get_online_class_id: "get-class-course-id",
    get_counter: "counter",
    get_latest_blog: "latest-blog",
    get_blog_list: "get-blog-list/20/0",
    get_blog_list_id: "get-blog",
    top_rated_intitutes: "get-blog",
}
export default webroutes;