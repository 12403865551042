import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "../../../../common/Popup";
import VideoPopup from "../../../../common/VideoPopup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import utils, { calculateExperience } from "../../../../../utils/utils";
import axios from "axios";
import ReactPaginate from "react-paginate";
import NoDataAvailable from "../../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const CourseDetails = () => {
    const [openPopup, setOpenPopup] = useState({});
    const [openVideoPopup, setOpenVideoPopup] = useState({});
    const [intodata, setIntodata] = useState();
    const [active, setActive] = useState(false);
    const [accordionActive, setAccordionActive] = useState({});

    const activeHandler = (id) => {
        setAccordionActive((prev) => ({ [id]: !prev[id] }));
    };

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("AUTHTOKEN", auth_token);
    const [course, SetCourse] = useState({});
    const [sections, SetSections] = useState([]);
    const [reviews, SetReviews] = useState([]);
    const { id, is_main } = useParams();
    const [search, setSearch] = useState({
        start: 0,
        perPage: 1,
        course_type: 'online_course',
        course_id: id
    });
    const [totalPages, setTotalPages] = useState(0);
    const [offset, setOffset] = useState(0);
    let now = new Date().setHours(0, 0, 0, 0);

    const getCourseData = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };

            fetch(process.env.REACT_APP_API_BASEURL + `institute-course/get-online-course/${is_main}/${id}`, requestOptions)
                .then(response => response.json())
                .then((result) => {

                    SetCourse(result.data);
                })
                .catch(error => console.log('error', error));

        } catch (err) {
            throw err;
        }
    }
    const getCourseSection = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };
            fetch(process.env.REACT_APP_API_BASEURL + `institute-course/get-section-list/${is_main}/${id}`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    SetSections(result.data);
                })
                .catch(error => console.log('error', error));

        } catch (err) {
            throw err;
        }
    }

    const getReviews = async (activity) => {
        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASEURL + `institute/course-review`,
                activity,
                config
            );
            if (response) {
                SetReviews(response.data?.data);
                setTotalPages(response.data?.count / search.perPage);
            }

        } catch (err) {
            throw err;
        }
    }

    const getAllReviews = async () => {
        setOffset(search.start);
        let activity = {
            limit: search.perPage,
            offset: search.start,
            course_type: search.course_type,
            course_id: search.course_id
        };
        await getReviews(activity);
    };

    const handlePageClick = async (data) => {

        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            course_type: search.course_type,
            course_id: search.course_id,
            limit: search.perPage,
            offset: currentPage,
        };
        await getReviews(activity);
    };

    useEffect(() => {
        getCourseData();
        getCourseSection();
    }, []);
    useEffect(() => {
        getAllReviews();
    }, [search]);


    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/video-course">Video Course</Link>
                    </li>
                    <li className="breadcrumb-item active">Video Course Details</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="product-slider">
                                <ul className="list-unstyled video-list-thumbs">
                                    <li className="mb-0">
                                        <div className="item-card7-img">
                                            <img alt={course?.title} className="cover-image" src={course?.teaser_thumb_image} />
                                            {(
                                                course?.price == "paid" && (
                                                    course?.is_discount == 1 && (
                                                        (course?.is_limited_offer == 1 && (new Date(course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                        (course?.is_limited_offer == 0 && new Date(course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                )
                                            ) && (
                                                    <Link to="#" className="bg-danger top left">{course?.discount_price}%  off</Link>
                                                )}
                                            <Link to="#" className="bg-danger right top">
                                                Video Course
                                            </Link>
                                            {course?.is_professional === 0 && (
                                                <Link to="#" className="bg-danger right bottom">
                                                    {course?.board}
                                                </Link>
                                            )}
                                            <span className="fa fa-play-circle text-white class-icon" onClick={() => { setActive(true); }}></span>
                                        </div>
                                        {active && (
                                            <VideoPopup
                                                className="videoIntor"
                                                active={active}
                                                setActive={setActive}
                                                data={course}
                                            />
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-4 mb-0 text-center">
                                {course?.price === "paid" && (
                                    <span className="font-weight-semibold h2 mb-0">
                                        ₹ {Math.round(course?.payable_price)} {(
                                            course?.price == "paid" && (
                                                course?.is_discount == 1 && (
                                                    (course?.is_limited_offer == 1 && (new Date(course?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(course?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                    (course?.is_limited_offer == 0 && new Date(course?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                            )
                                        ) && (<del className="fs-14 text-muted">₹ {Math.round(course?.regular_price)} </del>
                                            )}
                                    </span>
                                )}
                                {course?.price === "free" && (
                                    <span className="font-weight-semibold h2 mb-0">
                                        Free
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="card-header">
                            <h4 className="card-title">Video Course Info :</h4>
                        </div>
                        <table className="table table-hover dataTable">
                            <tbody>
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">
                                        Branch Name
                                    </td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end">
                                        <Link className="text-primary" to={`/institute-dashboard/branch-detail/${course?.institute_branch?.id}`}>
                                            {course?.institute_branch?.name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">
                                        Course Level
                                    </td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end font-weight-normal text-capitalize">{course?.course_level}</td>
                                </tr>
                                {course?.is_professional === 0 && (
                                    <>
                                        <tr>
                                            <td className="font-weight-semibold">
                                                Board
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {course?.board}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">
                                                Standard
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {course?.standard}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-semibold">
                                                Medium
                                            </td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {course?.medium}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <td className="font-weight-semibold">Audio Language</td>
                                    <td className="w-0 text-center">
                                        :
                                    </td>
                                    <td className="text-end">{course?.audio_language}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Videos</td>
                                    <td className="w-0 text-center">
                                        :
                                    </td>
                                    <td className="text-end font-weight-normal">{course?.lectures_count} Videos</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Duration</td>
                                    <td className="w-0 text-center">
                                        :
                                    </td>
                                    <td className="text-end">
                                        {course?.total_duration ? utils.toHHMMSS(course?.total_duration) : 0} Hours
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Last Update</td>
                                    <td className="w-0 text-center">
                                        :
                                    </td>
                                    <td className="text-end">
                                        {moment(course?.updated_at).format("DD-MM-YYYY hh:mm A")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Course Name</h4>
                        </div>
                        <div className="card-body">
                            {course?.title}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Short Description</h4>
                        </div>
                        <div className="card-body">
                            <p>{course?.short_discription}</p>
                        </div>
                        <div className="card-body">
                            <div className="row ">
                                <div className="col-xl-4 col-lg-4 col-md-4 ">
                                    <div className="card mb-0 border-0 shadow-none">
                                        <div className="d-flex pb-0">
                                            <img src={course?.teacher?.image} className="border-radius-50 d-md-flex avatar-lg me-3" alt={course?.teacher?.name} />
                                            <div>
                                                <span className="icons fs-16">Teacher</span>
                                                <Link to={`/institute-dashboard/teacher-detail/` + course?.teacher_id} className="d-block h4">
                                                    {course?.teacher?.name}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card mb-0 border-0 shadow-none">
                                        <div className="pb-0">
                                            <span className="icons fs-16">Category</span>
                                            <span className="d-block h4">{course?.category?.title}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4">
                                    <div className="card mb-0 border-0 shadow-none">
                                        <span className="icons fs-16 ">Reviews</span>
                                        <span className="d-inline-flex">
                                            <div className="br-widget">
                                                {rating.map((el, i) => (
                                                    <i
                                                        data-rating-value={el}
                                                        data-rating-text={el}
                                                        className={
                                                            el <= course?.average_review
                                                                ? "br-selected"
                                                                : ""
                                                        }
                                                        key={i}
                                                    />
                                                ))}
                                            </div>
                                            <span>({course?.review_count} Reviews)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <Tabs
                            defaultActiveKey="overview"
                            id="uncontrolled-tab-example"

                        >
                            <Tab eventKey="overview" title="overview">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Course Description</h4>
                                    </div>
                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: course?.course_discription }}></div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">What will students learn in this course?</h4>
                                    </div>
                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: course?.student_learn_in_cutse }}></div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Requirements</h4>
                                    </div>
                                    <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: course?.requirements }}></div>
                                </div>
                            </Tab>
                            <Tab eventKey="curriculum" title="curriculum">
                                <div className="accordion" id="accordionExample">
                                    {sections.length != 0 ? (sections.map((section, i) => (
                                        <div className="accordion-item mb-3" key={i}>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={
                                                        accordionActive[i]
                                                            ? "accordion-button"
                                                            : "accordion-button collapsed"
                                                    }
                                                    type="button"
                                                    onClick={() => activeHandler(i)}
                                                >
                                                    Chapter {i + 1}: {section.title}
                                                </button>
                                            </h2>
                                            {accordionActive[i] && (
                                                <div
                                                    id="collapseOne"
                                                    className={
                                                        accordionActive[i]
                                                            ? "accordion-collapse collapse show"
                                                            : "accordion-collapse collapse"
                                                    }
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        {section?.lectures?.length > 0 ? (
                                                            section?.lectures.map((lecture, key) => (
                                                                <div className="d-flex align-items-center justify-content-between cursor-pointer mb-4" key={key}  >
                                                                    <div className="d-flex align-items-center">
                                                                        <i className="fa-solid fa-video"></i>
                                                                        <div className="ms-3">{lecture.title}</div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        {lecture?.is_preview == 1 && (
                                                                            <Link
                                                                                className="text-danger d-flex align-items-center me-5"
                                                                                to="#"
                                                                                onClick={() => {
                                                                                    setOpenPopup({ [key]: true });
                                                                                }}
                                                                            > <i className="fa-solid fa-play me-1"></i> preview </Link>
                                                                        )}

                                                                        <Link
                                                                            className="text-danger d-flex me-5"
                                                                            to="#"
                                                                            onClick={() => {
                                                                                setOpenVideoPopup({ [key]: true });
                                                                            }}
                                                                        >
                                                                            <i className="fa-solid fa-play"></i>
                                                                        </Link>
                                                                        <div className="badge btn-primary width-55px">
                                                                            {utils.toHHMMSS(
                                                                                lecture?.video_duration
                                                                            )}
                                                                        </div>

                                                                        {openPopup[key] && (
                                                                            <Popup
                                                                                className="videoIntor"
                                                                                title={lecture.title}
                                                                                data={lecture.preview_video}
                                                                                openPopup={openPopup}
                                                                                setOpenPopup={setOpenPopup}
                                                                                id={key}
                                                                            />
                                                                        )}
                                                                        {openVideoPopup[key] && (
                                                                            <Popup
                                                                                className="videoIntor"
                                                                                title={lecture.title}
                                                                                data={lecture.video}
                                                                                openPopup={openVideoPopup}
                                                                                setOpenPopup={setOpenVideoPopup}
                                                                                id={key}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <NoDataAvailable />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                    ) : (
                                        <NoDataAvailable />
                                    )}
                                </div>

                            </Tab>
                            <Tab eventKey="Teacher" title="Teacher">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <Link to={`/institute-dashboard/teacher-detail/` + course?.teacher_id}>
                                                    <div className="text-center">
                                                        <div className="item-card7-img">
                                                            <img
                                                                src={course?.teacher?.image}
                                                                className="cover-image teacher-student-image"
                                                                alt={course?.teacher?.name}
                                                            />
                                                        </div>
                                                        <h4 className="mt-4 leading-tight">
                                                            {course?.teacher?.name}
                                                        </h4>
                                                        <p className="mb-2 text-center">{course?.teacher?.email}</p>
                                                        <h6 className="team-section badge mb-0">
                                                            {course?.teacher?.total_experiance != 0 ? calculateExperience(course?.teacher?.total_experiance) : "---"} Experience
                                                        </h6>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="reviews" title="reviews">
                                {reviews.length != 0 ? (
                                    <>
                                        {reviews.map((item, i) => (
                                            <div className="media mt-0 p-4 border br-5 review-media" key={i} >
                                                <div className="d-flex me-3">
                                                    <Link to="#">
                                                        <img
                                                            className="border-radius-50 avatar-lg"
                                                            alt={item?.name}
                                                            src={item?.student?.image}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="media-body">
                                                    <h4 className="mt-0 mb-1 font-weight-semibold">
                                                        {item?.name}
                                                        <small className="text-muted float-end">
                                                            {moment(item?.created_at).format("DD-MM-YYYY hh:mm A")}
                                                        </small>
                                                    </h4>
                                                    <span className="d-inline-flex">
                                                        <div className="br-widget">
                                                            {rating.map((el, i) => (
                                                                <i
                                                                    data-rating-value={el}
                                                                    data-rating-text={el}
                                                                    className={
                                                                        el <= item?.average_review
                                                                            ? "br-selected"
                                                                            : ""
                                                                    }
                                                                    key={i}
                                                                />
                                                            ))}
                                                        </div>
                                                    </span>
                                                    <p className="mb-2 mt-2 education-mandal" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={Math.ceil(totalPages)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </>
                                ) : (<NoDataAvailable />)}
                            </Tab>
                        </Tabs >
                    </div >
                </div>
            </div>

        </>
    );
};

export default CourseDetails;
