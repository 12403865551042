import React from 'react';
import { Link } from 'react-router-dom';
import CourseList from './courseList';
import CourseFilter from './courseFilter';

const CourseApprove = () => {
    return (
        <>

            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/institute-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Video Course Approvel</li>
                </ol>
            </div>

            <div className="row">
                <CourseFilter />
                <CourseList />
            </div>
        </>
    );
};

export default CourseApprove;