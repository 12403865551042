import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import url from "../../../../../constants/url";
import { Experience } from "./experience/index";
import { Achievement } from "./achievement/index";
import { Education } from "./education/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AboutProfile from "./AboutProfile";


const InstituteDetails = (props) => {
    const [data, setAboutInfo] = useState();
    const current = new Date();
    let month = `${current.getMonth() + 1}`;
    if (month.length < 2) {
        month = "0" + month;
    }

    let cu_date = `${current.getFullYear()}-${month}-${current.getDate()}`;
    const [aboutForm, setAboutForm] = useState(false);
    const [review, setReview] = useState([]);
    const [isCharacter, setIsCharacter] = useState(0);


    const CardDetails = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/dashboard`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setReview(result?.review);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        setAboutInfo(props?.data);
        setIsCharacter(props?.data?.about?.length);
    }, [props]);

    useEffect(() => {
        CardDetails();
    }, []);

    const aboutHandler = () => {
        setAboutForm(true);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4 className='card-title'>Institute Details</h4>
                        </div>

                        <div className='card-body'>
                            <div className='card'>
                                <Tabs defaultActiveKey='about' id='uncontrolled-tab-example'>
                                    <Tab eventKey='about' title='about'>
                                        <div className='card'>
                                            <div className='card-header justify-content-between'>
                                                <h4 className='card-title'>Institute About</h4>
                                                <div
                                                    className='d-flex align-items-center'
                                                    onClick={aboutHandler}
                                                >
                                                    <Link
                                                        to='#'
                                                        className='btn btn-primary'
                                                    >
                                                        <i className='fa fa-edit'></i>
                                                    </Link>
                                                </div>
                                                <div
                                                    className={
                                                        aboutForm
                                                            ? "modal fade show"
                                                            : "modal fade"
                                                    }
                                                    id='about'
                                                    tabIndex={-1}
                                                    aria-labelledby='InstituteAbout'
                                                    aria-hidden='true'
                                                    style={
                                                        aboutForm ? { display: "block" } : { display: "none" }
                                                    }
                                                >
                                                    <div className='modal-dialog modal-dialog-centered modal-lg'>
                                                        <div className='modal-content'>
                                                            <div className='modal-header'>
                                                                <h4 className='modal-title' id='InstituteAbout'>
                                                                    About the Institute
                                                                </h4>
                                                                <button
                                                                    type='button'
                                                                    className='btn-close'
                                                                    id='about-close'
                                                                    data-bs-dismiss='modal'
                                                                    aria-label='Close'
                                                                    onClick={() => setAboutForm(false)}
                                                                />
                                                            </div>
                                                            {aboutForm ? (
                                                                <AboutProfile
                                                                    getProfileData={props.GetProfileData}
                                                                    setAboutForm={setAboutForm}
                                                                    data={data?.about}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-body education-mandal' dangerouslySetInnerHTML={{ __html: data?.about }}></div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey='education' title='education'>
                                        <Education />
                                    </Tab>
                                    <Tab eventKey='experience' title='experience'>
                                        <Experience />
                                    </Tab>
                                    <Tab eventKey='achievement' title='achievement'>
                                        <Achievement />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default InstituteDetails;
