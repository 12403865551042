import utils from "../../../utils/utils";

export default class Attendance {
    getSubjectTeacherList(id) {
        return utils.sendApiRequest(`teacher/class-subjects/${id}`, "GET", true, [])
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    getSubjectList(payload) {
        return utils.sendApiRequest(`class-attendance/subject-list`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    getSlotList(payload) {
        return utils.sendApiRequest(`class-attendance/slot-list`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    getStudentAttendanceList(payload) {
        return utils.sendApiRequest(`class-attendance/student-attendance-list`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    markStudentAttendance(payload) {
        return utils.sendApiRequest(`class-attendance/mark-student-attendance`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
}