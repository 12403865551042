import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import Curve from '../../Header/Curve';

const Services = () => {
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Pricing</h1>
                </div>
            </div>
            <Curve />

            <section className="sptb">
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                                <div className="card text-center mb-md-0">
                                    <div className="card-header text-center">
                                        <h3 className="card-title font-weight-bold mx-auto">Basic</h3>
                                    </div> <div className="card-body">
                                        <h1 className="pricing-card-title mb-5">$12 <small className="text-muted fs-20">/ mo</small></h1>
                                        <p className="mb-5">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                                        <Link className="btn btn-primary" to="/pricing-details">Explore Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="card text-center mb-md-0 overflow-hidden">
                                    <div className="card-header text-center">
                                        <h3 className="card-title font-weight-bold mx-auto">Pro</h3>
                                    </div>
                                    <div className="card-body">
                                        <h1 className="pricing-card-title mb-5">$25 <small className="text-muted fs-20">/ mo</small></h1>
                                        <p className="mb-5">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                                        <Link className="btn btn-primary" to="/pricing-details">Explore Now</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="card text-center mb-0">
                                    <div className="card-header text-center">
                                        <h3 className="card-title font-weight-bold mx-auto">Unlimited</h3>
                                    </div> <div className="card-body">
                                        <h1 className="pricing-card-title mb-5">$89 <small className="text-muted fs-20">/ mo</small></h1>
                                        <p className="mb-5">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                                        <Link className="btn btn-primary" to="/pricing-details">Explore Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Services;
