import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import VideoCourseHeader from "./VideoCourseHeader";
import VideoCourseLeft from "./VideoCourseLeft";
import VideoCourseRight from "./VideoCourseRight";
import RelatedCourse from "./RelatedCourse"
import Headerinner from "../../Header/Headerinner";
import { getCourseReview } from "../../../../apis_web";
import url from "../../../../constants/url";
import { useParams } from "react-router-dom";

const VideoCourseDetails = () => {
    const params = useParams()
    const [courseDataDetails, setCourseDataDetails] = useState();
    const [relatedCourse, setRelatedCourse] = useState();
    const [reviewData, setReviewData] = useState();
    const [offset, setOffset] = useState();
    const [cart, setCart] = useState(null);
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const [search, setSearch] = useState({
        perPage: 10,
        start: 0,
    });
    const auth_token = local_storage?.auth_token;
    const getCourseDetailsData = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if (auth_token) {
            myHeaders.append("AUTHTOKEN", auth_token);
        }

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}get-online-course-id/${params.id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    getCourseReivewData(result.data);
                    setRelatedCourse(result.related_course);
                    setCourseDataDetails(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const apiCall = async (activity) => {
        let res = await getCourseReview(activity);
        if (res.status === "success") {
            setReviewData(res.data);
        }
    };
    const getCourseReivewData = async (course_detail) => {
        let reqData = new FormData();
        reqData.append(`course_id`, course_detail?.id);
        reqData.append(`course_type`, course_detail?.type);
        reqData.append(`limit`, search.perPage);
        reqData.append(`offset`, search.start);
        await apiCall(reqData);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        let activity = {
            course_id: courseDataDetails?.id,
            course_type: courseDataDetails?.type,
            limit: search.perPage,
            offset: currentPage,
        };
        await getCourseReivewData(activity);
    };
    useEffect(() => {
        getCourseDetailsData();
    }, []);

    return (
        <>
            <Headerinner />
            <VideoCourseHeader data={courseDataDetails && courseDataDetails} />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12">
                            <VideoCourseLeft
                                data={courseDataDetails && courseDataDetails}
                                cart={cart}
                            />
                        </div>

                        <div className="col-xl-8 col-lg-8 col-md-12">
                            <VideoCourseRight
                                reviewData={reviewData && reviewData}
                                data={courseDataDetails && courseDataDetails}
                                handlePageClick={handlePageClick}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <RelatedCourse relatedCourse={relatedCourse} />
            <Footer />
        </>
    );
};

export default VideoCourseDetails;
