import utils from "../../../utils/utils";

export default class NotificationData {
    getNotificatinList(payload) {
        return utils.sendApiRequest(`notifications`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }

    getNotificatinStatusCheck(id) {
        return utils.sendApiRequest(`notifications-status/${id}`, "GET", true, [])
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
}