import React, { useState } from "react";
import { Link } from "react-router-dom";
import utils from "../../../../utils/utils";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

export default function VideoCourse({
    VideoCourseList,
    searchs,
    setSearch,
    recordedVideoCount,
    type,
    classroomType
}) {
    let now = new Date().setHours(0, 0, 0, 0);

    const loadMorehandle = () => {
        setSearch({ ...searchs, perpage: searchs.perpage + 9 });
    };
    return (
        <>
            {((type !== 'all' && classroomType === "video_course") && VideoCourseList?.length == 0) && (
                <NoDataAvailable/>
            )}
            {VideoCourseList?.length > 0 && (
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Video Course List</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {VideoCourseList?.length > 0 ? (
                                    VideoCourseList.map((item, i) => (
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                            <Link to={`/video-course-detail/${item?.id}`}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="item-card7-img">
                                                            <img src={item?.teaser_thumb_image}
                                                                className="cover-image"
                                                                alt={item?.title}
                                                            />
                                                            {(
                                                                item?.price == "paid" && (
                                                                    item?.is_discount == 1 && (
                                                                        (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                        (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                )
                                                            ) && (
                                                                    <Link to="#" className="bg-danger top left">
                                                                        {item?.discount_price}% Off
                                                                    </Link>
                                                                )}
                                                            <Link to="#" className="bg-danger right top">
                                                                Video Course
                                                            </Link>
                                                            {item?.is_professional === 0 && (
                                                                <Link to="#" className="bg-danger right bottom">
                                                                    {item?.board}
                                                                </Link>
                                                            )}
                                                        </div>
                                                        <div className="item-card2-desc">
                                                            <div className="d-inline-flex mb-2">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= item?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <span>({item?.review_count} Reviews)</span>
                                                            </div>
                                                            <h4 className="mb-2 leading-tight2">
                                                                {item?.title}
                                                            </h4>
                                                            <p className="leading-tight3 education-mandal">
                                                                {item?.short_discription}
                                                            </p>

                                                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                <div className="d-flex justify-content-start">
                                                                    <i className="fa fa-shop me-2" />
                                                                    <span className="text-muted text-data-over-cut">
                                                                        {item?.institute?.name}
                                                                    </span>
                                                                </div>
                                                                <div className="float-end">
                                                                    <i className="fa fa-signal me-2" />
                                                                    <span className="text-muted text-capitalize">
                                                                        {item?.course_level}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="pt-2 pb-2 border-bottom">
                                                                <i className="fa fa-video me-2" />
                                                                <span className="text-muted">
                                                                    {item?.lectures_count} Videos
                                                                    {item?.lectures_count > 1 ? "s" : ""}
                                                                </span>
                                                                <div className="float-end ">
                                                                    <i className="fa fa-clock-o me-2" />
                                                                    <span className="text-muted">
                                                                        {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="pt-2 d-flex justify-content-between">
                                                                <div className="d-flex justify-content-start">
                                                                    <i className="fa fa-microphone me-2" />
                                                                    <span className="text-muted text-data-over-cut">
                                                                        {item?.audio_language}
                                                                    </span>
                                                                </div>
                                                                <div className="float-end">
                                                                    {item?.price === "paid" ? (
                                                                        <h4 className="mb-0">
                                                                            ₹ {Math.round(item?.payable_price)}  {(
                                                                                item?.price == "paid" && (
                                                                                    item?.is_discount == 1 && (
                                                                                        (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                        (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                )
                                                                            ) && (
                                                                                    <del className="fs-14 text-muted">
                                                                                        ₹ {Math.round(item?.regular_price)}
                                                                                    </del>
                                                                                )}
                                                                        </h4>
                                                                    ) : (
                                                                        <h4 className="mb-0">Free</h4>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <NoDataAvailable/>
                                )}
                            </div>
                        </div>
                        {VideoCourseList?.length < recordedVideoCount && (
                            <div className="card-footer" onClick={loadMorehandle}>
                                <Link to="#" className="w-100">
                                    Load More Video Course
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
