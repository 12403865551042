import React, { useEffect, useState } from "react";
import BannerSection from "./BannerSection";
import Header from "../Header/Header";
import LatestBlogNews from "./LatestBlogNews";
import LatestCourses from "./LatestCourses";
import RegsiterHere from "./RegsiterHere";
import Institute from "./Institute";
import Subscribe from "./Subscribe";
import TotalSection from "./TotalSection";
import BestTeachers from "./BestTeachers";
import Footer from "../Footer/Footer";
import url from "../../../constants/url";
import ComingUpClasses from "./ComingUpClasses"
import {
    getBestTeacher,
    getLatestReview,
    getClass,
    getTotal,
    getLatestBlog,
    getOnlineAllCourse,
} from "../../../apis_web";

const HomePage = () => {
    const [courseData, setCourseData] = useState();
    const [isLoader, setisLoader] = useState(true);

    const getAllOnlineCourseData = async (e) => {
        let res = await getOnlineAllCourse(10, 0);
        if (res.status === "success") {
            setCourseData(res.data);
        }
        
    };
    useEffect(() => {
        getAllOnlineCourseData();
        setTimeout(() => {
            setisLoader(false);
        }, 5000);
    }, []);

    const [teacherData, setTeacherData] = useState();
    const getAllBestTeacherData = async () => {
        let res = await getBestTeacher("", 0);
        if (res.status === "success") {
            setTeacherData(res.data);
        }
    };

    useEffect(() => {
        getAllBestTeacherData();
    }, []);

    const [reviewData, setReviewData] = useState();
    const getAllReviewData = async () => {
        let res = await getLatestReview("", 0);
        if (res.status === "success") {
            setReviewData(res.course_review);
        }
    };
    useEffect(() => {
        getAllReviewData();
    }, []);

    const [classData, setClassData] = useState();
    const getAllClassData = async () => {
        let res = await getClass(10, 0);
        if (res.status === "success") {
            setClassData(res.data);
        }
    };
    useEffect(() => {
        getAllClassData();
    }, []);

    const [totalData, setTotalData] = useState();
    const getAllTotalData = async () => {
        let res = await getTotal("", 0);
        if (res.status === "success") {
            setTotalData(res.data);
        }
    };
    useEffect(() => {
        getAllTotalData();
    }, []);

    const [blogData, setBlogData] = useState();
    const getAllBlogData = async () => {
        let res = await getLatestBlog("", 0);
        if (res.status === "success") {
            setBlogData(res.data);
        }
    };
    useEffect(() => {
        getAllBlogData();
    }, []);
    return (
        <>
            <Header />
            <BannerSection />
            <LatestCourses data={courseData && courseData} />
            <ComingUpClasses data={classData && classData} />
            <Institute />
            <BestTeachers data={teacherData && teacherData} />
            <TotalSection data={totalData && totalData} />
            <RegsiterHere />
            <LatestBlogNews data={blogData && blogData} />
            <Subscribe />
            <Footer />
        </>
    );
};

export default HomePage;
