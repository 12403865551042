import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Headerinner from "../../Header/Headerinner";
import Footer from "../../Footer/Footer";
import { useParams, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import url from "../../../../constants/url";
import Curve from '../../Header/Curve';
import { calculateExperience } from '../../../../utils/utils';
import NoDataAvailable from "../../../common/NoDataAvailable";

function StudentDetail() {
    const history = useHistory();
    const param = useParams();
    const [data, setData] = useState({});
    const GetTrainnerDetail = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}student-detail/${param.id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result.data);
                } else {
                    history.push('/404');
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        GetTrainnerDetail();
    }, []);

    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="/assets/images/banners/banner1.webp"
                style={{
                    background: 'url("/assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>{data?.name}</h1>
                </div>
            </div>
            <Curve />

            <section className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="wideget-user">
                                        <div className="wideget-user-desc text-center">
                                            <div className="item-card7-img">
                                                <img
                                                    className="cover-image teacher-student-image"
                                                    src={data?.image}
                                                    alt={data?.name}
                                                />
                                            </div>
                                            <div className="user-wrap wideget-user-info">
                                                <h4>
                                                    {data?.name}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="card">
                                <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
                                    <Tab eventKey="overview" title="Profile">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Personal Details</h4>
                                            </div>
                                            <div className="card-body">
                                                <p>
                                                    <span className="font-weight-semibold">Full Name : </span>
                                                    <span className="user-1">{data?.name}</span>
                                                </p>
                                                <p>
                                                    <span className="font-weight-semibold">Experience : </span>
                                                    <span className="user-1">{data?.total_experience != 0 ? calculateExperience(data?.total_experience) : "---"}</span>
                                                </p>
                                                <p>
                                                    <span className="font-weight-semibold">Languages : </span>
                                                    <span className="user-1">{data?.languages ? data?.languages : "---"}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">About the Student</h4>
                                            </div>
                                            <div className='card-body education-mandal' dangerouslySetInnerHTML={{ __html: data?.about ? data?.about : "No Data Available !" }}></div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="education" title="education">
                                        {data?.education?.length > 0 ? (
                                            data?.education.map((item, i) => (
                                                <div className="card" key={i}>
                                                    <div className="card-body">
                                                        <ul className="d-flex mb-0">
                                                            <li>
                                                                <div className="activity1 bg-primary">
                                                                    <i className="fe fe-shopping-bag"></i>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="font-weight-semibold">
                                                                    {item?.degree} - ( {item?.field_of_study} )
                                                                </div>
                                                                <p className="text-muted mb-0">
                                                                    {item?.school_college}
                                                                </p>
                                                            </li>
                                                            <div className="text-muted ms-auto">
                                                                <Moment format="DD-MM-YYYY">
                                                                    {item?.start_date}
                                                                </Moment> to {item?.is_present === 1 ? (
                                                                    "Present"
                                                                ) : (
                                                                    <Moment format="DD-MM-YYYY">
                                                                        {item?.end_date}
                                                                    </Moment>
                                                                )}
                                                            </div>
                                                        </ul>
                                                    </div>

                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </Tab>
                                    <Tab eventKey="experience" title="experience">
                                        {data?.experience?.length > 0 ? (
                                            data?.experience.map((item, i) => (
                                                <div className="card" key={i}>
                                                    <div className="card-body">
                                                        <ul className="d-flex mb-0">
                                                            <li>
                                                                <div className="activity1 bg-primary">
                                                                    <i className="fe fe-shopping-bag"></i>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="font-weight-semibold">
                                                                    {item?.title} - ( {item?.employement_type === "full_time" ? "Full Time" : "Part Time"} )
                                                                </div>
                                                                <p className="text-muted mb-0 ">
                                                                    {item?.company_name}
                                                                </p>
                                                                <p className="text-muted mb-2">
                                                                    {item?.location}
                                                                </p>
                                                            </li>
                                                            <div className="text-muted ms-auto">
                                                                <Moment format="DD-MM-YYYY">
                                                                    {item?.start_date}
                                                                </Moment> to {item?.is_present === 1 ? (
                                                                    "Present"
                                                                ) : (
                                                                    <Moment format="DD-MM-YYYY">
                                                                        {item?.end_date}
                                                                    </Moment>
                                                                )}
                                                            </div>
                                                        </ul>
                                                        <p className="text-muted mb-0 margin-left education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </Tab>
                                    <Tab eventKey="achievement" title="achievement">
                                        {data?.achievement?.length > 0 ? (
                                            data?.achievement.map((item, i) => (
                                                <div className="card" key={i}>
                                                    <div className="card-body">
                                                        <ul className="d-flex mb-0">
                                                            <li>
                                                                <div className="activity1 bg-primary">
                                                                    <i className="fe fe-shopping-bag"></i>
                                                                </div>
                                                            </li>
                                                            <li className="font-weight-semibold">
                                                                {item?.title}
                                                            </li>
                                                            <div className="text-muted ms-auto">
                                                                <Moment format="DD-MM-YYYY">
                                                                    {item?.date}
                                                                </Moment>
                                                            </div>
                                                        </ul>
                                                        <p className="text-muted mb-0 margin-left education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default StudentDetail;
