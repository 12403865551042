import React from 'react';
import Footer from '../../../Footer/Footer';
import PrivacyContent from './PrivacyContent';
import Headerinner from '../../../Header/Headerinner';

const PrivacyPolicy = () => {
    return (
        <>
            <Headerinner/>
            <PrivacyContent/>
            <Footer/>
        </>
    );
};

export default PrivacyPolicy;