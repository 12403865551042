import * as Yup from "yup";

//Validation
export const educationSchema = Yup.object().shape({
    school_college: Yup.string().min(2, "Too Short").required("Required"),
    degree: Yup.string().min(2, "Too Short").required("Required"),
    field_of_study: Yup.string().min(2, "Too Short").required("Required"),
    start_date: Yup.string().required("Required"),
    is_present: Yup.number().required("Required"), // Assuming is_present is a number
    end_date: Yup.string().when("is_present", {
        is: (is_present) => is_present === 0,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().nullable(),
    }),
});
