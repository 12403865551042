import React, { useEffect, useState } from "react";
import url from "../../../../../src/constants/url";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import moment from "moment";

const RevenueFilter = (props) => {
    const [branchData, setBranchdata] = useState([]);
    const [values, setValues] = useState({});
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;

    const getBranchData = async () => {
        let result = await fetch(`${url.apiUrl}institute-branch/get-branches`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                AUTHTOKEN: token_authorization,
            },
        });
        if (result?.status === 200) {
            let varResult = await result.json();
            if (varResult?.status == "success") {
                setBranchdata(varResult?.data);
            }
        }
    };

    const FormSubmit = async (values) => {
        try {
            props.setSearch({
                ...props.search,
                ...values
            })
            props.apiCall({
                limit: props.search.perPage,
                offset: props.search.start,
                query: props.search.searchTxt,
                ...values
            })

        } catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        getBranchData();
    }, []);

    return (
        <>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Revenue Filter</h4>
                    </div>
                    <div className="card-body">
                        <Formik
                            initialValues={values}
                            onSubmit={FormSubmit}
                            enableReinitialize={true}
                        >
                            {(props) => {
                                return (
                                    <form noValidate='novalidate' onSubmit={props.handleSubmit} >
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 col-sm-12 col-12 form-group">
                                                <label>Branch Name</label>
                                                <Form.Select
                                                    aria-label="Default select example"
                                                    onChange={props.handleChange}
                                                    name="branch_id"
                                                >
                                                    <option selected="false" disabled>
                                                        Select branch
                                                    </option>
                                                    {branchData &&
                                                        branchData.map((v, i) => (
                                                            <option value={v.id} key={i}>
                                                                {v.text}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-12 col-12 form-group">
                                                <label>Course Type</label>
                                                <select name="course_type" id="course_type" className="form-select" onChange={props.handleChange}>
                                                    <option value=""> Select course type </option>
                                                    <option value="online_course">Video Course</option>
                                                    <option value="class_course">Classroom Course</option>
                                                </select>
                                            </div>
                                            {props.values.course_type === "class_course" && (
                                                <div className="col-lg-3 col-md-4 col-sm-12 col-12 form-group">
                                                    <label>Classroom Course Type</label>
                                                    <select name="class_type" id="class_type" className="form-select" onChange={props.handleChange}>
                                                        <option value=""> Select classroom course type </option>
                                                        <option value="video_conference">Online</option>
                                                        <option value="manual">Offline</option>
                                                        <option value="both">Hybrid (Online + Offline)</option>
                                                    </select>
                                                </div>
                                            )}
                                            <div className="col-lg-3 col-md-4 col-sm-12 col-12 form-group">
                                                <label>Start Date</label>
                                                <input type="date" name="start_date" id="start_date" className="form-control" max={moment().format("YYYY-MM-DD")} onChange={props.handleChange} />
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-12 col-12 form-group">
                                                <label>End Date</label>
                                                <input type="date" name="end_date" id="end_date" className="form-control" max={moment().format("YYYY-MM-DD")} onChange={props.handleChange} />
                                            </div>
                                            <div className="open-button col-lg-3 col-md-4 col-sm-12 col-12">
                                                <button type="submit" className="btn btn-primary" title="Search"><i className="fe fe-search"></i> Search</button>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RevenueFilter;
