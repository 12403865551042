import React from 'react';
import Footer from '../../Footer/Footer';
import Headerinner from '../../Header/Headerinner';
import ContactCard from './ContactCard';
import ContactHelp from './ContactHelp';

const ContactUs = () => {
    return (
        <>
            <Headerinner />
            <ContactCard />
            <ContactHelp />
            <Footer />
        </>
    );
};

export default ContactUs;