import React from "react";

const Institutes = () => {
    return (
        <>
            <section className="sptb bg-white">
                <div className="container">
                    <div className="section-title">
                        <h2>Benefits for Institutes</h2>
                    </div>
                    <div className="row match-height">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-primary mb-3">
                                            <i className="fa fa-eye text-white" />
                                        </div>
                                        <h3>Increased Visibility</h3>
                                        <p>By partnering with Education Mandal, institutes can
                                            increase their visibility and reach a wider audience of
                                            potential students.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-success mb-3">
                                            <i className="fa fa-building-circle-check text-white"></i>
                                        </div>
                                        <h3>Branch Management</h3>
                                        <p>
                                            The ability to manage multiple branches of the
                                            institution, including information on location, faculty,
                                            and resources.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-secondary mb-3">
                                            <i className="fa fa-people-roof  text-white" />
                                        </div>
                                        <h3>Teacher Management</h3>
                                        <p>
                                            A centralized repository of teacher information, including
                                            contact details, qualifications, and experience, making it
                                            easy to manage and track teacher information.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-primary mb-3">
                                            <i className="fa fa-fax text-white" />
                                        </div>
                                        <h3>Student Acquisition</h3>
                                        <p>
                                            Our platform provides institutes with the opportunity to
                                            connect with students who are actively seeking education
                                            resources, helping to drive student acquisition.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-success mb-3">
                                            <i className="fa fa-line-chart text-white" />
                                        </div>
                                        <h3>Data Insights</h3>
                                        <p>
                                            By partnering with Education Mandal, institutes can access
                                            data insights about student preferences, helping them to
                                            improve their offerings and attract more students.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="feature">
                                        <div className="fa-stack fea-icon bg-secondary mb-3">
                                            <i className="fa fa-piggy-bank text-white" />
                                        </div>
                                        <h3> Cost-effective Solution</h3>
                                        <p>
                                            Our platform provides institutes with a cost-effective
                                            solution for marketing and reaching potential students,
                                            saving them time and money compared to traditional
                                            marketing methods.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Institutes;
