import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Footer from '../../Footer/Footer';
import Headerinner from '../../Header/Headerinner';
import utils from "../../../../utils/utils";
import Curve from '../../Header/Curve';
import ReactPaginate from "react-paginate";
import { getOnlineAllCourse } from "../../../../apis_web";
import NoDataAvailable from "../../../common/NoDataAvailable";

const VideoCourseList = (props) => {

    let rating = [1, 2, 3, 4, 5];
    let now = new Date().setHours(0, 0, 0, 0);
    const history = useHistory();
    const [data, setCourseData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState({
        perPage: 12,
        offset: 0
    });

    const getAllOnlineCourseData = async (limit, offset) => {
        let result = await getOnlineAllCourse(limit, offset);
        if (result.status === "success") {
            setCourseData(result.data);
            setTotalPages(result.count / search.perPage)
            setTotalCount(result.count);
        }
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage)
        await getAllOnlineCourseData(search.perPage, currentPage);
    };

    useEffect(() => {
        getAllOnlineCourseData(search.perPage, search.offset)
    }, []);
    return (
        <>
            <Headerinner />
            <div className="cover-image bg-background-1" data-bs-image-src="assets/images/banners/banner1.webp" style={{ background: 'url("assets/images/banners/banner1.webp") center center' }}>
                <div className="container header-text">
                    <h1>Best-Selling Video Courses</h1>
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="row">
                        {data?.length > 0 ? (
                            <>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                Showing {offset + 1} to {offset + data?.length} of {totalCount} entries
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                {data.map((item, i) => (
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                        <Link to={`/video-course-detail/${item?.id}`}>
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="item-card7-img">
                                                        <img
                                                            src={item?.teaser_thumb_image}
                                                            alt={item?.title}
                                                            className="cover-image"
                                                        />
                                                        {(
                                                            item?.price == "paid" && (
                                                                item?.is_discount == 1 && (
                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                            )
                                                        ) && (
                                                                <Link to="#" className="bg-danger top left">
                                                                    {item?.discount_price}% Off
                                                                </Link>
                                                            )}
                                                        <Link to="#" className="bg-danger right top">
                                                            Video Course
                                                        </Link>
                                                        {item?.is_professional === 0 && (
                                                            <Link to="#" className="bg-danger right bottom">
                                                                {item?.board}
                                                            </Link>
                                                        )}
                                                    </div>
                                                    <div className="item-card2-desc">
                                                        <div className="d-inline-flex mb-2">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span>({item?.review_count} Reviews)</span>
                                                        </div>
                                                        <h4 className="mb-2 leading-tight2">
                                                            {item?.title}
                                                        </h4>
                                                        <p className="leading-tight3 education-mandal">
                                                            {item?.short_discription}
                                                        </p>

                                                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                            <div className="d-flex justify-content-start">
                                                                <i className="fa fa-shop me-2" />
                                                                <span className="text-muted text-data-over-cut">
                                                                    {item?.institute.name}
                                                                </span>
                                                            </div>
                                                            <div className="float-end">
                                                                <i className="fa fa-signal me-2" />
                                                                <span className="text-muted text-capitalize">
                                                                    {item?.course_level}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-2 pb-2 border-bottom">
                                                            <i className="fa fa-video me-2" />
                                                            <span className="text-muted">
                                                                {item?.lectures_count} Videos
                                                            </span>
                                                            <div className="float-end">
                                                                <i className="fa fa-clock-o me-2" />
                                                                <span className="text-muted">
                                                                    {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-2">
                                                            <i className="fa fa-microphone me-2 font-weight-semibold" />
                                                            <span className="text-muted">
                                                                {item?.audio_language}
                                                            </span>
                                                            <div className="float-end">
                                                                {item?.price === "paid" && (
                                                                    <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)}  {(
                                                                        item?.price == "paid" && (
                                                                            item?.is_discount == 1 && (
                                                                                (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                        )
                                                                    ) && (<del className="fs-14 text-muted">₹ {Math.round(item?.regular_price)} </del>
                                                                        )}
                                                                    </h4>
                                                                )}
                                                                {item?.price === "free" && (
                                                                    <h4 className="mb-0">Free</h4>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="mb-0">
                                                Showing {0} to {offset + data?.length} of {totalCount} entries
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <NoDataAvailable />
                            </>
                        )}
                    </div>
                    {data.length > 0 && (
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(totalPages)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                                "pagination"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default VideoCourseList;
