import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import url from '../../../../constants/url';
import ReactPaginate from 'react-paginate';
import { Formik } from 'formik';
import Select from "react-select";
import SubmitLoader from '../../../common/SubmitLoader';
import { replaceWord } from "../../../../utils/utils";
import AttendanceApi from '../../services/AttendanceApi';

const Attendance = () => {

    const serve = new AttendanceApi();
    const [Data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [branch, setBranch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        query: "",
        searchField: "",
        class_type: "",
        lecture_type: "",
        branch_id: "",
    });

    const [values, setValues] = useState({
        branch: "",
        class_type: ""
    });

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);

    const getAllBraches = async () => {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        fetch(`${url.apiUrl}student/branch-list`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setBranch(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getAllCourse = async (activity) => {
        try {
            let response = await serve.getClassList(activity);
            if (response) {
                setLoading(false)
                setLoader(false)
                setData(response.data);
                setTotalCount(response.count / search.perPage);
            }
        } catch (err) {
            throw err;
        }
    };

    const FormSubmit = async (values) => {
        setLoader(true)
        setSearch({
            ...search,
            perPage: search.perPage,
            offset: 0,
        })
        getAllCourse({
            limit: search.perPage,
            offset: 0,
            query: search.query,
            branch_id: search.branch?.value || undefined,
            class_type: search.class_type || undefined,
            lecture_type: search.lecture_type || undefined,
        });

    }

    useEffect(() => {
        var activity = {
            limit: search.perPage,
            offset: search.start
        }
        getAllCourse(activity);
        getAllBraches()
    }, []);

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setSearch({
            ...search,
            perPage: search.perPage,
            offset: currentPage,
        })
        await getAllCourse({
            limit: search.perPage,
            offset: currentPage,
            query: search.query,
            branch_id: search.branch?.value || undefined,
            class_type: search.class_type || undefined,
            lecture_type: search.lecture_type || undefined,
        });
    };
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/student-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Attendance</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Attendance Filter</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={FormSubmit}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Branch<span className="text-danger">*</span></label>
                                                <Select
                                                    onChange={(value) => {
                                                        props.setFieldValue("branch", value)
                                                        setSearch({ ...search, branch: value })
                                                    }
                                                    }
                                                    options={branch}
                                                    value={props.values?.branch}
                                                    name="branch"
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>Class Type </label>
                                                <select
                                                    className="form-select"
                                                    value={props.values.class_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "class_type",
                                                            e.target.value
                                                        );
                                                        setSearch({ ...search, class_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select class type</option>
                                                    <option value="school">School (Till 12th Standard)</option>
                                                    <option value="university">University</option>
                                                    <option value="preparation">Entrance Exam / Professional Courses</option>
                                                    <option value="tuition">Tuition</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Classroom Type <span className="text-danger">*</span></label>
                                                <select className="form-select"
                                                    value={props.values.lecture_type}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "lecture_type",
                                                            e.target.value
                                                        );
                                                        setSearch({ ...search, lecture_type: e.target.value })
                                                    }}
                                                >
                                                    <option value="">Select classroom type</option>
                                                    <option value='video_conference'>Online</option>
                                                    <option value='manual'>Offline</option>
                                                    <option value='both'>Hybrid (Online + Offline)</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group open-button">
                                                {loader ? (
                                                    <SubmitLoader />
                                                ) :
                                                    (
                                                        <button type="submit" className="btn btn-primary" title="Search">
                                                            <i className="fe fe-search" /> Search
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Attendance List</h4>
                        </div>
                        <div className="card-body">
                            <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                <thead>
                                    <tr>
                                        <th className="text-center">Sr. No.</th>
                                        <th>Class Details</th>
                                        <th>Institute Details</th>
                                        <th className="text-center text-nowrap">Class Type</th>
                                        <th className="text-center text-nowrap">Board/University</th>
                                        <th className="text-center text-nowrap">Section/Semester</th>
                                        <th className="text-center text-nowrap">Classroom Type</th>
                                        <th className="text-center">Medium</th>
                                        <th className="text-center">Overall Past</th>
                                        <th className="text-center">Today Attendance</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">View Subject</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td className='text-center' colSpan={12}>
                                                Loading..
                                            </td>
                                        </tr>
                                    ) : Data.length === 0 ? (
                                        <tr>
                                            <td className='text-center' colSpan={12}>No Datam Available !</td>
                                        </tr>
                                    ) : (
                                        Data.map((item, index) => (
                                            <tr key={item?.id}>
                                                <th className='text-center text-nowrap'>{index + offset + 1}</th>
                                                <td>
                                                    <Link to={`/student-dashboard/class-detail/${item?.id}`} >
                                                        <div className="media">
                                                            <img src={item?.thumbnail} alt={item?.title} className="me-3 rounded-circle" />
                                                            <div className="media-body">
                                                                <p className="font-weight-semibold mb-0">{item?.title}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className='media'>
                                                        <img
                                                            src={item?.institute?.image}
                                                            alt={item?.institute?.name}
                                                            className='me-3 rounded-circle'
                                                        />
                                                        <div className='media-body'>
                                                            <Link  to={`/institute-detail/${item?.institute?.institute_id}`}
                                                                className='font-weight-semibold'
                                                            >
                                                                {item?.institute?.name}
                                                            </Link>
                                                            <p className='mb-0'>
                                                                {item?.institute?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-center text-capitalize'>
                                                    {replaceWord(item?.class_type)} {item?.class_type == "tuition" ? " : " + (replaceWord(item?.tuition_type)) : ""}
                                                </td>

                                                <td className='text-center text-capitalize'>
                                                    {item?.board || item?.universities?.name || '---'}
                                                </td>
                                                <td className='text-center text-capitalize'>
                                                    {item?.section || item?.semester || '---'}
                                                </td>
                                                <td className="text-center text-capitalize">
                                                    {replaceWord(item?.lecture_type)}
                                                </td>
                                                <td className="text-center text-capitalize">{item?.medium || '---'}</td>
                                                <td className='text-center text-capitalize'>
                                                    {item.marked_time_slot}/{item.time_slot}
                                                </td>
                                                <td className="text-center text-capitalize">
                                                    {item.today_marked_time_slot}/{item.today_time_slot}
                                                </td>
                                                <td className={`text-center text-capitalize ${item?.status === "decline" && " text-danger"}`}>
                                                    {(item?.status === "decline" || item?.status === 'pending') &&
                                                        item?.is_submit == 1
                                                        ? "Submitted"
                                                        : item?.status}  {item?.status === "decline" && `(${item?.reason?.reason})`}
                                                </td>
                                                <td className="text-center">
                                                    <Link
                                                        className="btn btn-primary me-2"
                                                        to={`/student-dashboard/attendance-view-subject/${item?.id}`}
                                                    >
                                                        <i className="fa fa-eye"></i> View
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                            {Data.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={totalCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Attendance;
