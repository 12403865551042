import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Headerinner from "../../Header/Headerinner";
import Curve from '../../Header/Curve';

const Services = () => {
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banner1.webp"
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Pricing</h1>
                </div>
            </div>
            <Curve />

            <div className="sptb pricing-3">
                <div className="container">
                    <div className="section-title">
                        <h2>Another Pricing</h2>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="fs-25 text-primary mb-3 text-center">Basic</div>
                                    <p className="lead fs-30 text-center mb-5"><strong className="font-weight-bold">$15 /</strong> month</p>
                                    <hr />
                                    <ul className="list-unstyled leading-loose">
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">2 </strong> Course posting</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">2</strong> Featured Classes</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">100%</strong> Secure</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">Customer</strong> Reviews</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">24/7</strong> Support</li>
                                    </ul>
                                    <div class="">
                                        <h4 class="mb-3">Bots &amp; Chats</h4>
                                        <p class="my-1"><i className="fa fa-check-circle text-muted me-2"></i>
                                            50 Chats per month</p>
                                        <p class="my-1"><i className="fa fa-circle-xmark text-danger me-2"></i>
                                            50 Chats per month</p>
                                        <p class="my-1"><img className="me-2" src="https://www.smatbot.com//assets/check-pricing-713b597b.svg" width="18px" />1 ChatBot</p>
                                        <p class="my-1"><img className="me-2" src="https://www.smatbot.com//assets/uncheck-pricing-a79adc2e.svg" width="18px" />1 ChatBot</p>
                                    
                                    </div>
                                    <hr />
                                    <div className="text-center mt-4"><a href="javascript:void(0)" className="btn btn-outline-primary btn-block">Buy Now</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="card overflow-hidden">
                                <div className="text-start card-body">
                                    <div className="text-start fs-25 text-primary p-0">Premium</div>
                                    <div className="display-3 my-1">
                                        <p className="lead fs-30"><strong className="fs-30 font-weight-bold">$25 /</strong> month</p>
                                    </div>
                                    <ul className="list-unstyled leading-loose">
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">4 </strong> Course posting</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">4</strong> Featured Classes</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">100%</strong> Secure</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">Customer</strong> Reviews</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">24/7</strong> Support</li>
                                    </ul>
                                    <div className="text-center mt-4"><a href="javascript:void(0)" className="btn btn-outline-primary btn-block">Buy Now</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="card overflow-hidden bg-primary text-white">
                                <div className="text-start card-body">
                                    <div className="card-category text-start fs-25 bg-transparent p-0">Gold</div>
                                    <div className="display-3 my-1">
                                        <p className="lead text-white fs-30"><strong className="fs-30 font-weight-bold">$50 /</strong> month</p>
                                    </div>
                                    <ul className="list-unstyled leading-loose text-white">
                                        <li><i className="fa fa-check-circle me-2"></i><strong className="fs-16">10 </strong> Course posting</li>
                                        <li><i className="fa fa-check-circle me-2"></i><strong className="fs-16">10</strong> Featured Classes</li>
                                        <li><i className="fa fa-check-circle me-2"></i><strong className="fs-16">100%</strong> Secure</li>
                                        <li><i className="fa fa-check-circle me-2"></i><strong className="fs-16">Customer</strong> Reviews</li>
                                        <li><i className="fa fa-check-circle me-2"></i><strong className="fs-16">24/7</strong> Support</li>
                                    </ul>
                                    <div className="text-center mt-4"><a href="javascript:void(0)" className="btn btn-secondary text-white btn-block">Buy Now</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-3">
                            <div className="card overflow-hidden">
                                <div className="text-start card-body">
                                    <div className="text-start fs-25 text-primary p-0">Silver</div>
                                    <div className="display-3 my-1">
                                        <p className="lead fs-30"><strong className="fs-30 font-weight-bold">$29 /</strong> month</p>
                                    </div>
                                    <ul className="list-unstyled leading-loose">
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">5 </strong> Course posting</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">5</strong> Featured Classes</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">100%</strong> Secure</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">Customer</strong> Reviews</li>
                                        <li><i className="fa fa-check-circle text-muted me-2"></i><strong className="fs-16">24/7</strong> Support</li>
                                    </ul>
                                    <div className="text-center mt-4"><a href="javascript:void(0)" className="btn btn-outline-primary btn-block">Buy Now</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sptb bg-white">
                <div className="container">
                    <div className="row">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Basic</th>
                                    <th>Standard</th>
                                    <th>Standard Pro</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Base Price</th>
                                    <td>FREE</td>
                                    <td>10rs</td>
                                    <td>20rs</td>
                                </tr>
                                <tr>
                                    <th>Base Price</th>
                                    <td>FREE</td>
                                    <td>10rs</td>
                                    <td>20rs</td>
                                </tr>
                                <tr>
                                    <th>Base Price</th>
                                    <td>FREE</td>
                                    <td>10rs</td>
                                    <td>20rs</td>
                                </tr>
                                <tr>
                                    <th>Base Price</th>
                                    <td>FREE</td>
                                    <td>10rs</td>
                                    <td>20rs</td>
                                </tr>
                                <tr>
                                    <th>Base Price</th>
                                    <td>FREE</td>
                                    <td>10rs</td>
                                    <td>20rs</td>
                                </tr>
                                <tr>
                                    <th>Base Price</th>
                                    <td>FREE</td>
                                    <td>10rs</td>
                                    <td>20rs</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Services;
