import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import useWindowSize from "../../../../hooks/useWindowSize";
import NotificationData from "../../Services/NotificationData";
import NoDataAvailable from "../../../common/NoDataAvailable";
import ReactPaginate from "react-paginate";

const Notifications = () => {
    const { width: windowWidth } = useWindowSize();
    const history = useHistory();
    const [notification, setNotification] = useState([]);
    const params = useParams();
    const id = params?.id;
    const serve = new NotificationData();

    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
    })

    const getNotificatinListData = async (activity) => {
        try {
            let response = await serve.getNotificatinList(activity);
            if (response) {
                setNotification(response.data);
                setCount(response.count);
            }
        } catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        getNotificatinListData(filters);
    }, [])

    const notificationProfile = (item, image_url) => {
        if (item?.type == "student") {
            var profile_url = "/institute-dashboard/payment";
            var profile_image = item?.notify?.image;
        } else if (item?.type == "class_course") {
            var profile_url = "/institute-dashboard/class-detail/1/" + item?.redirect_id + "/class_course";
            var profile_image = item?.notify?.thumbnail;
        } else if (item?.type == "updated_class_course") {
            var profile_url = "/institute-dashboard/class-detail/0/" + item?.redirect_id + "/class_course";
            var profile_image = item?.notify?.thumbnail;
        } else if (item?.type == "approve_slot") {
            var profile_url = "/institute-dashboard/slot-view/" + item?.redirect_id;
            var profile_image = item?.notify?.thumbnail;
        } else if (item?.type == "class_slot") {
            var profile_url = "/institute-dashboard/slot-view/" + item?.redirect_id;
            var profile_image = item?.notify?.thumbnail;
        } else if (item?.type == "teacher") {
            var profile_url = "/institute-dashboard/teacher-detail/" + item?.redirect_id;
            var profile_image = item?.notify?.image;
        } else if (item?.type == "career") { 
            var profile_url = "/institute-dashboard/career-detail/1/" + item?.redirect_id + "/career";
            var profile_image = item?.notify?.thumb_image;
        } else if (item?.type == "updated_career") {
            var profile_url = "/institute-dashboard/career-detail/0/" + item?.redirect_id + "/career";
            var profile_image = item?.notify?.thumb_image;
        } else if (item?.type == "career_application") {
            var profile_url = "/institute-dashboard/applicants-list/" + item?.redirect_id + "/career";
            var profile_image = item?.notify?.thumb_image;
        } else if (item?.type == "online_course") {
            var profile_url = "/institute-dashboard/video-course-detail/1/" + item?.redirect_id + "/online_course";
            var profile_image = item?.notify?.teaser_thumb_image;
        } else if (item?.type == "updated_online_course") {
            var profile_url = "/institute-dashboard/video-course-detail/0/" + item?.redirect_id + "/online_course";
            var profile_image = item?.notify?.teaser_thumb_image;
        } else if (item?.type == "approve_teacher_course") {
            var profile_url = "/institute-dashboard/video-course-approval" + item?.redirect_id + "/online_course";
            var profile_image = item?.notify;
        } else if (item?.type == "attendance") {
            var profile_url = "/institute-dashboard/attendance-edit" + item?.redirect_id;
            var profile_image = item?.notify?.thumbnail;
        }
        if (image_url == "image") {
            return profile_image;
        } else if (image_url == "url") {
            return profile_url;
        }
    } 

    const [count, setCount] = useState();
    const handlePageClick = async (data) => {
        let currentPage = data.selected * filters.limit;
        await getNotificatinListData({ limit: filters.limit, offset: currentPage });
    };

    const statusHandler = async (id, url) => {
        try {
            let response = await serve.getNotificatinStatusCheck(id);
            if (response) {
                history.push(url);
            }
        } catch (err) {
            throw err;
        }
    };
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Notifications</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Notifications</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {windowWidth >= 768 && (
                                    <>
                                        {notification?.length > 0 ? (
                                            notification?.map((item, i) => (
                                                <div className="col-xl-12" key={i} >
                                                    <Link to="#" onClick={() => { statusHandler(item?.id, notificationProfile(item, 'url')); }}>
                                                        <div className={item.is_read == 0 ? "media p-3 mb-2 border br-5 bg-gray" : "media p-3 mb-2 border br-5"}>
                                                            <div className="d-flex me-3">
                                                                <img className="rounded-circle avatar-lg" alt={item?.title} src={notificationProfile(item, 'image')} />
                                                                { }
                                                            </div>
                                                            <div className="media-body">
                                                                <h4 className="fs-14 mt-0 mb-0 leading-tight">{item?.title}
                                                                    <span className="fs-14 float-end">
                                                                        <i className="fa fa-clock-o"></i> {(item?.created_at)}
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                        {notification?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={Math.ceil(count / filters.limit)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        )}
                                    </>
                                )}
                                {windowWidth <= 768 && (
                                    <>
                                        {notification?.length > 0 ? (
                                            notification?.map((item, i) => (
                                                <div className="col-xl-12" key={i}>
                                                    <Link to="#" onClick={() => { statusHandler(item?.id, notificationProfile(item, 'url')); }}>
                                                        <div className={item.is_read == 0 ? "media p-3 mb-2 border br-5 bg-gray" : "media p-3 mb-2 border br-5"}>
                                                            <div className="d-flex me-3">
                                                                <img className="rounded-circle avatar-lg" alt={item?.title} src={notificationProfile(item, 'image')} />
                                                                { }
                                                            </div>
                                                            <div className="media-body">
                                                                <h4 className="fs-14 mt-0 mb-0 leading-tight2">{item?.title}</h4>
                                                                <span className="fs-14 float-end">
                                                                    <i className="fa fa-clock-o"></i> {(item?.created_at)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                        {notification?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={Math.ceil(count / filters.limit)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Notifications;
