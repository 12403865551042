import React from "react";
import { Link } from "react-router-dom";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

export default function InstituteList({
    instituteList,
    searchs,
    setSearch,
    instituteCount,
    type
}) {
    const loadMorehandle = () => {
        setSearch({ ...searchs, perpage: searchs.perpage + 9 });
    };
    const executeOnClick = (isExpanded) => {
    };
    return (
        <>
            {(type == 'institute' && instituteList?.length == 0) && (
                <NoDataAvailable/>
            )}
            {instituteList?.length > 0 && (
                <div className="col-md-12 ">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Institute's List</h4>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                {instituteList ? (
                                    instituteList.map((item, i) => (
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                            <Link to={`/institute-detail/${item?.institute_id}`} >
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="item-card7-img">
                                                            <img src={item?.image} className="cover-image" alt={item?.name} />
                                                        </div>
                                                        <div className="item-card2-desc">
                                                            <div className="d-inline-flex mb-2">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= item?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <span>({item?.review_count} Reviews)</span>
                                                            </div>
                                                            <h4 className="mb-2 leading-tight2">
                                                                {item?.name}
                                                            </h4>
                                                            <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.about }}>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <NoDataAvailable/>
                                )}
                            </div>
                        </div>
                        {instituteList?.length < instituteCount && (
                            <div className="card-footer" onClick={loadMorehandle}>
                                <Link to="#" className="w-100">
                                    Load More Institute's
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )
            }
        </>
    );
}
