import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { experienceSchema } from "./experience.helper";
import { toast } from "react-toastify";
import useToken from "../../../../../../hooks/useToken";
import url from "../../../../../../constants/url";
import SubmitLoader from "../../../../../common/SubmitLoader";
import { Editor } from "@tinymce/tinymce-react";

const EditExperience = ({ show, setShow, data, getAllExperience }) => {
    const { token } = useToken();
    const [loader, setLoader] = useState(false);
    const [initialState, setInitialState] = useState({
        experience_id: "",
        title: "",
        employement_type: "",
        company_name: "",
        location: "",
        is_present: 0,
        description: "",
        start_date: "",
        end_date: "",
        status: "",
    });
    useEffect(() => {
        setInitialState({
            experience_id: data?.id,
            title: data?.title,
            employement_type: data?.employement_type,
            company_name: data?.company_name,
            location: data?.location,
            is_present: data?.is_present,
            description: data?.description,
            start_date: data?.start_date,
            end_date: data.is_present ? "" : data?.end_date,
            status: data?.status,
        });
    }, [data]);

    const handleClose = () => setShow(false);
    function onSubmit(payload, actions) {
        setLoader(true);
        fetch(`${url.apiUrl}institute-teacher/edit-experience-by-id`, {
            method: "POST",
            headers: { "Content-Type": "application/json", AUTHTOKEN: token },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    actions.resetForm({
                        experience_id: "",
                        title: "",
                        employement_type: "",
                        company_name: "",
                        location: "",
                        is_present: 0,
                        description: "",
                        start_date: "",
                        end_date: "",
                        status: "",
                    });
                    getAllExperience();
                    setLoader(false);
                    handleClose();
                    toast.success("Experience Edited Successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    setLoader(false);
                    toast.error(result.status, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => {
                toast.error(
                    error?.message || error?.data?.message || "Something want wrong",
                    {
                        position: toast.POSITION.TOP_RIGHT,
                    }
                );
            });
    }
    return (
        <React.Fragment>
            <Formik
                initialValues={initialState}
                onSubmit={onSubmit}
                validationSchema={experienceSchema}
                enableReinitialize={true}
            >
                {(formProps) => {
                    return (
                        <React.Fragment>
                            <Modal
                                id='contained-modal-title-vcenter'
                                aria-labelledby='contained-modal-title-vcenter'
                                size='lg'
                                show={show}
                                centered
                                onHide={() => {
                                    formProps.handleReset();
                                    handleClose();
                                }}
                                animation={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id='contained-modal-title-vcenter'>
                                        Edit Teacher's Experience Details
                                    </Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <div className='row'>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='title'>
                                                Title <span className='text-danger'> *</span>
                                            </label>
                                            <input
                                                type='text'
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.title}
                                                name='title'
                                                id='title'
                                                className='form-control'
                                                placeholder='Enter title'
                                            />
                                            {formProps.touched.title && formProps.errors.title ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.title}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='employee-type'>
                                                Employment Type
                                                <span className='text-danger'> *</span>
                                            </label>
                                            <select
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.employement_type}
                                                className='form-select select2'
                                                name='employement_type'
                                                id='employee-type'
                                                aria-hidden='false'
                                            >
                                                <option selected='false' disabled>
                                                    Select Employee Type
                                                </option>
                                                <option value='full_time'>Full Time</option>
                                                <option value='part_time'>Part Time</option>
                                                <option value='internship'>Internship</option>
                                                <option value='contract'>Contract</option>
                                                <option value='freelancer'>Freelancer</option>
                                                <option value='seasonal'>Seasonal</option>
                                                <option value='temporary'>Temporary</option>
                                            </select>
                                            {formProps.touched.employement_type &&
                                                formProps.errors.employement_type ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.employement_type}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='company-name'>
                                                Company Name<span className='text-danger'> *</span>
                                            </label>
                                            <input
                                                type='text'
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.company_name}
                                                autoComplete='off'
                                                name='company_name'
                                                id='company-name'
                                                className='form-control'
                                                placeholder='Enter company name'
                                            />
                                            {formProps.touched.company_name &&
                                                formProps.errors.company_name ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.company_name}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='location-name'>
                                                Location<span className='text-danger'> *</span>
                                            </label>
                                            <input
                                                type='text'
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.location}
                                                autoComplete='off'
                                                name='location'
                                                id='location'
                                                className='form-control'
                                                placeholder='Enter company location'
                                            />
                                            {formProps.touched.location &&
                                                formProps.errors.location ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.location}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <label htmlFor='start-date'>
                                                Start Date<span className='text-danger'> *</span>
                                            </label>
                                            <input
                                                type='date'
                                                max={moment().format("YYYY-MM-DD")}
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                                value={formProps?.values?.start_date}
                                                name='start_date'
                                                id='start_date'
                                                placeholder='MM/DD/YYYY'
                                                className='form-control'
                                            />
                                            {formProps.touched.start_date &&
                                                formProps.errors.start_date ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.start_date}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='col-md-6 form-group'>
                                            <div className='form-check form-check-inline mt-6 p-0'>
                                                <label className='custom-control form-checkbox'>
                                                    <input
                                                        type='checkbox'
                                                        onChange={(e) => {
                                                            formProps.setFieldValue(
                                                                "is_present",
                                                                e.target.checked ? 1 : 0
                                                            );
                                                            formProps.setFieldValue("end_date", "");
                                                        }}
                                                        onBlur={formProps.handleBlur}
                                                        className='custom-control-input'
                                                        name='is_present'
                                                        checked={formProps?.values?.is_present === 1}
                                                        value={formProps?.values?.is_present}
                                                    />
                                                    <span className='custom-control-label'>
                                                        I am currently working here
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        {formProps?.values?.is_present === 0 && (
                                            <div className='col-md-6 form-group'>
                                                <label htmlFor='end-date'>End Date</label>
                                                <input
                                                    type='date'
                                                    min={moment(formProps?.values?.start_date).format(
                                                        "YYYY-MM-DD"
                                                    )}
                                                    max={moment().format("YYYY-MM-DD")}
                                                    onChange={formProps.handleChange}
                                                    value={formProps?.values?.end_date}
                                                    onBlur={formProps.handleBlur}
                                                    name='end_date'
                                                    id='end-date'
                                                    placeholder='MM/DD/YYYY'
                                                    className='form-control'
                                                />
                                                {formProps.touched.end_date &&
                                                    formProps.errors.end_date ? (
                                                    <div className='bg-error'>
                                                        {formProps.errors.end_date}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}

                                        <div className='col-md-12 form-group'>
                                            <label htmlFor='description'>
                                                Description<span className='text-danger'> *</span>
                                            </label>
                                            <Editor
                                                textareaName='description'
                                                placeholder='Enter description'
                                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                                init={{
                                                    plugins: [
                                                        "advlist",
                                                        "autolink",
                                                        "lists",
                                                        "link",
                                                        "image",
                                                        "charmap",
                                                        "preview",
                                                        "anchor",
                                                        "searchreplace",
                                                        "visualblocks",
                                                        "code",
                                                        "fullscreen",
                                                        "insertdatetime",
                                                        "media",
                                                        "table",
                                                        "help",
                                                        "wordcount",
                                                        "spellchecker",
                                                    ],

                                                    toolbar:
                                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                                }}
                                                value={formProps?.values?.description}
                                                onEditorChange={(e) =>
                                                    formProps.handleChange({
                                                        target: {
                                                            name: `description`,
                                                            value: e,
                                                        },
                                                    })
                                                }
                                            />
                                            {formProps.touched.description &&
                                                formProps.errors.description ? (
                                                <div className='bg-error'>
                                                    {formProps.errors.description}
                                                </div>
                                            ) : null}
                                            <p className='text-end'>
                                                {formProps?.values?.description?.length} / 500 Characters
                                            </p>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    {loader ? (
                                        <SubmitLoader />
                                    ) : (
                                        <>
                                            <button
                                                className='btn btn-primary'
                                                type='button'
                                                onClick={formProps.handleSubmit}
                                            >
                                                Submit
                                            </button>
                                            <button
                                                className='btn btn-danger'
                                                type='button'
                                                onClick={() => {
                                                    formProps.handleReset();
                                                    handleClose();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    )}
                                </Modal.Footer>
                            </Modal>
                        </React.Fragment>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
};

export default EditExperience;
