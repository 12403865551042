import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { aboutSchema } from './InstituteDetails.helper'
import useToken from "../../../../../hooks/useToken";
import url from "../../../../../constants/url";
import { toast } from "react-toastify";
import SubmitLoader from "../../../../common/SubmitLoader";
import { Editor } from "@tinymce/tinymce-react";

const AboutProfile = ({ setAboutForm, data, getProfileData }) => {
    const [loader, setLoader] = useState(false);
    const { token } = useToken();
    const [initialState, setInitialState] = useState({
        about: "",
    });
    useEffect(() => {
        setInitialState({
            about: data,
        });
    }, [data]);

    async function onSubmit(value) {
        try {
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", token);
            var formData = new FormData();
            formData.append("about", value.about);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formData,
            };
            setLoader(true);
            fetch(`${url.apiUrl}institute/edit-profile`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        getProfileData();
                        setAboutForm(false);
                        setLoader(false);
                        toast.success("Profile Edited Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        setLoader(false);
                        toast.error("Something went wrong", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => console.log("error", error));
        } catch (error) {
            console.log(error?.data?.message || error?.message || "Error");
        }
    }

    return (
        <Formik
            initialValues={initialState}
            onSubmit={onSubmit}
            validationSchema={aboutSchema}
            enableReinitialize={true}
        >
            {(props) => {

                return (
                    <form onSubmit={props.handleSubmit}>
                        <div className='modal-body'>
                            <Editor
                                textareaName='about'
                                placeholder='Enter about'
                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                init={{
                                    plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "help",
                                        "wordcount",
                                        "spellchecker",
                                    ],

                                    toolbar:
                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                }}
                                value={props?.values?.about}
                                onEditorChange={(e) =>
                                    props.handleChange({
                                        target: {
                                            name: `about`,
                                            value: e,
                                        },
                                    })
                                }
                            />
                            {props.errors.about ? (
                                <div className='text-danger'>
                                    {props.errors.about}
                                </div>
                            ) : null}
                            <p className='text-end'>
                                {props?.values?.about.length} / 500 Characters
                            </p>
                        </div>
                        <div className='modal-footer'>
                            {loader ? (
                                <SubmitLoader />
                            ) : (
                                <>
                                    <button type='submit' className='btn btn-primary'>
                                        Submit
                                    </button>
                                    <button
                                        type='reset'
                                        id='reset'
                                        className='btn btn-danger '
                                        data-bs-dismiss='modal'
                                        onClick={() => {
                                            props.handleReset();
                                            setAboutForm(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default AboutProfile;
