import React, { useEffect, useState } from 'react';
import { Switch, Routes, Route } from 'react-router-dom';
import Loader from "../../common/loader";
import Footer from "../../common/Footer";
import Profile from '../Dashboard/Profile/Profile';
import DashboardTeacherProfile from '../Dashboard/Dashboard/DashboardTeacherProfile';
import TeacherProfileStudent from '../Dashboard/TeacherProfileStudent/TeacherProfileStudent';
import Course from '../Dashboard/Course/Course';
import CourseAdd from '../Dashboard/Course/CreateCourse/CourseAdd';
import Payment from '../Dashboard/Payment/Payment';
import SidebarNav from '../component/SidebarNav';

import Class from '../Dashboard/Class/Class';
import ClassApproval from '../Dashboard/Class/ClassApproval';
import ClassDetail from '../Dashboard/Class/ClassDetails/ClassDetail';

import CourseDetail from '../Dashboard/Course/CourseDetails/CourseDetail';

import Branch from '../Dashboard/Branch/Branch';
import BranchProfile from '../Dashboard/Branch/BranchProfile';
import CourseUpdateList from '../Dashboard/Course/CourseUpdateList';
import ChangePassword from '../Dashboard/Setting/ChangePassword/ChangePassword';

import Student from '../Dashboard/Student/Student';
import StudentProfile from '../Dashboard/Student/Profile/StudentProfile';
import PurchaseHistory from '../Dashboard/Student/PurchaseHistory/PurchaseHistory';
import PurchaseItems from '../Dashboard/Student/PurchaseHistory/PurchaseItems';
import CourseClassStudent from '../Dashboard/Student/CourseClassStudent';

import Attendance from '../Dashboard/Attendance/Attendance';
import AttendanceViewSubject from '../Dashboard/Attendance/AttendanceViewSubject';
import AttendanceViewTimeSlot from '../Dashboard/Attendance/AttendanceViewTimeSlot';
import AttendanceEdit from '../Dashboard/Attendance/AttendanceEdit';

import Notifications from '../Dashboard/Notifications/Notifications';

const Routess = () => {
    const [isLoader, setisLoader] = useState(true);

    const data_local = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(data_local);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    if (local_storage === null || local_storage === undefined) {
        window.location.replace("/");
    }
    useEffect(() => {
        setisLoader(false);
        setTimeout(() => {
        }, 5000);
    }, []);
    return (
        <>
            {isLoader ? <Loader />
                :
                <>
                    <div className={`page ` + (!isSidebarOpen ? "app sidenav-toggled" : '')}>
                        <div className="page-main h-100">
                            <SidebarNav isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                            <div className="app-content">
                                <div className="side-app">
                                    <Switch>
                                        <Route exact path="/teacher-dashboard" component={DashboardTeacherProfile} />
                                        <Route path="/teacher-dashboard/teacher-profile" component={Profile} />
                                        <Route path="/teacher-dashboard/teacher-profile-student" component={TeacherProfileStudent} />

                                        <Route path="/teacher-dashboard/branches" component={Branch} />
                                        <Route path="/teacher-dashboard/branch-detail/:id" component={BranchProfile} />

                                        <Route path="/teacher-dashboard/video-course" component={Course} />
                                        <Route path="/teacher-dashboard/revised-video-course" component={CourseUpdateList} />
                                        <Route path="/teacher-dashboard/video-course-detail/:is_main/:id" component={CourseDetail} />
                                        <Route path="/teacher-dashboard/video-course-add" component={CourseAdd} />
                                        <Route path="/teacher-dashboard/video-course-edit/:is_main_course/:id" component={CourseAdd} />

                                        <Route path="/teacher-dashboard/payment" component={Payment} />
                                        <Route path="/teacher-dashboard/class" component={Class} />
                                        <Route path="/teacher-dashboard/class-approval" component={ClassApproval} />
                                        <Route path="/teacher-dashboard/class-detail/:id" component={ClassDetail} />
                                        <Route path="/teacher-dashboard/change-password" component={ChangePassword} />

                                        <Route path="/teacher-dashboard/student" component={Student} />
                                        <Route path="/teacher-dashboard/student-purchase-history/:student_id" component={PurchaseHistory} />
                                        <Route path="/teacher-dashboard/student-purchase-items/:payment_id/:student_id" component={PurchaseItems} />
                                        <Route path="/teacher-dashboard/student-profile/:student_id" component={StudentProfile} />
                                        <Route path="/teacher-dashboard/student-course-class/:type/:id" component={CourseClassStudent} />

                                        <Route path="/teacher-dashboard/attendance" component={Attendance} />
                                        <Route path="/teacher-dashboard/attendance-view-subject/:class_id" component={AttendanceViewSubject} />
                                        <Route path="/teacher-dashboard/attendance-view-time-slot/:class_id/:subject" component={AttendanceViewTimeSlot} />
                                        <Route path="/teacher-dashboard/attendance-add/:slot_id" component={AttendanceEdit} />
                                        <Route path="/teacher-dashboard/attendance-edit/:slot_id" component={AttendanceEdit} />

                                        <Route path="/teacher-dashboard/notifications" component={Notifications} />

                                    </Switch>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </>
            }
        </>
    );
};

export default Routess;