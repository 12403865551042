import React, { useEffect, useState } from "react";
import Footer from '../../Footer/Footer';
import Headerinner from '../../Header/Headerinner';
import CartDetails from './CartDetails';
import { getCart, deleteToCart } from "../../../../apis_web";
import url from "../../../../constants/url";

const Cart = () => {
    const [isLoader, setisLoader] = useState(true);

    const [cartData, setCartData] = useState();

    useEffect(() => {
        setisLoader(false);
    }, []);

    const getAllCartData = async () => {
        if (localStorage.getItem("common-user-info")) {
            const token = JSON.parse(localStorage.getItem("common-user-info")).auth_token
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
            };

            fetch(`${url.apiUrl}get-cart`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        setCartData(result.data);
                    }
                })
                .catch(error => console.log('error', error));
        }
    };

    useEffect(() => {
        getAllCartData();
    }, []);

    return (
        <>
            <Headerinner online_course={cartData && cartData} />
            <CartDetails online_course={cartData && cartData} call_cart={getAllCartData} />
            <Footer />
        </>
    );
};

export default Cart;