import React from "react";
import { Modal } from "react-bootstrap";
const Popup = (props) => {
  const { show, handleClose, title, className } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName={className !== undefined ? className : "defaultModal"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body()}</Modal.Body>
    </Modal>
  );
};
export default Popup;
