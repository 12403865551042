import React, { useEffect, useState } from "react";
import AddExperience from "./AddExperience";
import Button from "react-bootstrap/Button";
import useToken from "../../../../../../hooks/useToken";
import url from "../../../../../../constants/url";
import Moment from "react-moment";
import EditExperience from "./EditExperience.js";
import NoDataAvailable from "../../../../../common/NoDataAvailable";
const Experience = () => {
    const { token } = useToken();

    const [allExperiences, setAllExperiences] = useState([]);
    const [singleExperience, setSingleExperience] = useState({});
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const handleShow = () => setShow(true);
    const handleIsEditShow = () => setIsEdit(true);

    const getAllExperience = () => {
        fetch(`${url.apiUrl}institute-teacher/get-all-experiences`, {
            method: "POST",
            headers: { "Content-Type": "application/json", AUTHTOKEN: token },
            body: JSON.stringify({
                limit: 10,
                offset: 0,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setAllExperiences(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        getAllExperience();
    }, []);

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-header justify-content-between'>
                            <h4 className='card-title'>Teacher's Experience Details</h4>
                            <div className='d-flex align-items-center'>
                                <Button
                                    variant='primary'
                                    className='btn btn-primary'
                                    onClick={handleShow}
                                >
                                    <i className='fa fa-plus'></i>
                                </Button>
                                <AddExperience
                                    show={show}
                                    setShow={setShow}
                                    getAllExperience={getAllExperience}
                                />
                                <EditExperience
                                    show={isEdit}
                                    setShow={setIsEdit}
                                    data={singleExperience}
                                    getAllExperience={getAllExperience}
                                />
                            </div>
                        </div>

                        {allExperiences?.length > 0 ? (
                            allExperiences?.map((experience) => {
                                return (
                                    <div className='card-body' key={experience?.id}>
                                        <ul className='d-flex mb-0'>
                                            <li>
                                                <div className='activity1 bg-primary'>
                                                    <i className='fe fe-shopping-bag'></i>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='d-flex font-weight-semibold text-capitalize'>
                                                    {experience?.title} - ( {experience.employement_type === "full_time" ? "Full Time" : (experience.employement_type === "part_time" ? "Part Time" : experience.employement_type)} )
                                                </div>
                                                <div className='d-flex'>
                                                    {experience?.company_name}
                                                </div>
                                                <div className='d-flex mb-2'>
                                                    {experience?.location}
                                                </div>
                                            </li>
                                            <div className='text-muted ms-auto'>
                                                <Moment format='DD-MM-YYYY'>
                                                    {experience?.start_date}
                                                </Moment> to {experience?.is_present === 1 ? (
                                                    "Present "
                                                ) : (
                                                    <React.Fragment>
                                                        <Moment format='DD-MM-YYYY'>
                                                            {experience?.end_date}
                                                        </Moment>
                                                    </React.Fragment>
                                                )}
                                                <Button
                                                    variant='primary'
                                                    className='ms-2'
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSingleExperience(experience);
                                                        handleIsEditShow();
                                                    }}
                                                >
                                                    <i className='fa fa-edit'></i>
                                                </Button>
                                            </div>
                                        </ul>
                                        <p className='text-muted mb-0 margin-left education-mandal' dangerouslySetInnerHTML={{ __html: experience?.description }} >
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="card-body">
                                <NoDataAvailable />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default React.memo(Experience);
