import React, { useState } from "react";
import LearningManagementForm from "./LearningManagementForm";

const LearningManagementSystem = () => {
    const [active, setActive] = useState(false);
    return (
        <>
            <section className="sptb">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            Learning Management System
                            {/* <span>
                                <blink className="blink fs-16"> Coming Soon</blink>
                            </span> */}
                        </h2>
                        <div className="ms-auto">
                            <button
                                className="btn btn-primary"
                                onClick={() => setActive(true)}
                            >
                                View More <i className="fe fe-arrow-right" />
                            </button>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12">
                            <p>
                                Our Learning Management System (LMS) is designed to provide you
                                with a seamless and effective learning experience. Our platform
                                is user-friendly and intuitive, making it easy for you to access
                                your courses and manage your learning progress. With our LMS,
                                you can track your progress, set goals, and receive feedback
                                from your Teachers.
                            </p>
                            <div className="row match-height">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-primary mb-3">
                                                    <i className="fa fa-calendar-days text-white" />
                                                </div>
                                                <h3>
                                                    Attendance Management
                                                </h3>
                                                <p>
                                                    An Attendance Management Tool can help Institutes
                                                    streamline their attendance processes, reduce
                                                    administrative burden, and improve student
                                                    engagement and accountability
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-success mb-3">
                                                    <i className="fa fa-file-video text-white" />
                                                </div>
                                                <h3>Video Hosting</h3>
                                                <p>
                                                    Integrated video hosting capabilities, making it easy
                                                    to upload, manage, and share videos as part of your
                                                    educational content.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-secondary mb-3">
                                                    <i className="fa fa-video text-white" />
                                                </div>
                                                <h3>
                                                    Virtual Classrooms
                                                </h3>
                                                <p>
                                                    Virtual classrooms and video conferencing tools,
                                                    making it easy to attend live sessions and collaborate
                                                    with Teachers and peers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-primary mb-3">
                                                    <i className="fa-solid fa-line-chart text-white"></i>
                                                </div>
                                                <h3>
                                                    Reporting and Analytics
                                                </h3>
                                                <p>
                                                    Reporting and analytics tools to help Teachers and
                                                    administrators make data-driven decisions about course
                                                    content, delivery, and assessment.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-success mb-3">
                                                    <i className="fa-solid fa-clock text-white"></i>
                                                </div>
                                                <h3>
                                                    Alerts and Reminders
                                                </h3>
                                                <p>
                                                    Automated alerts and reminders for Students, Teachers
                                                    and Institutes to keep them informed and on track.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <div className="feature">
                                                <div className="fa-stack fea-icon bg-secondary mb-3">
                                                    <i className="fa fa-envelope-o text-white" />
                                                </div>
                                                <h3>
                                                    Collaboration and Communication
                                                </h3>
                                                <p>
                                                    Features for enabling collaboration and communication
                                                    between Students and Teachers, discussion forums,
                                                    private messaging, notes sharing, etc.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {active && (
                <LearningManagementForm
                    type="learning_management"
                    heading=" Learning Management System"
                    active={active}
                    setActive={setActive}
                />
            )}
        </>
    );
};

export default LearningManagementSystem;
