import React from 'react';
import { Link } from 'react-router-dom';
import TeacherProfile from './TeacherProfile';
import TeacherDetail from './TeacherDetail';

const Profile = () => {
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/teacher">Teacher</Link>
                    </li>
                    <li className="breadcrumb-item active">Teacher Profile</li>
                </ol>
            </div>
            <TeacherProfile />
            <TeacherDetail />
        </>
    );
};

export default Profile;