import Footer from "../../Footer/Footer";
import Curve from "../../Header/Curve";
import Headerinner from "../../Header/Headerinner";
import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <>
            <Headerinner />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="/assets/images/banners/banner1.webp"
                style={{
                    background: 'url("/assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                </div>
            </div>
            <Curve />
            <section className="sptb">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="row text-center align-items-center">
                                <div className="col-lg-6">
                                    <img src={`/assets/images/404.png`} alt="404 Image" />
                                </div>
                                <div className="col-lg-6">
                                    <h4 className="title">Oops... Page Not Found!</h4>
                                    <p className="text-center" >
                                        Please return to the site's homepage, <br />
                                        It looks like nothing was found at this location
                                    </p>

                                    <Link className="btn btn-primary" to="/">
                                        Back To Home
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );

}