import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import AddMoreSchoolData from "./AddMoreSchoolData";
import AddMoreUniversityData from "./AddMoreUniversityData";
import AddMorePreperationData from "./AddMorePreperationData";
import url from "../../../../../constants/url";
import axios from '../../../../../config/axios';
import SubmitLoader from "../../../../common/SubmitLoader";

const ClassAdd = () => {
    const history = useHistory()
    const [loader, setLoader] = useState(false);
    const [LiveBranch, setLiveBranch] = useState([]);
    const [medium, setMedium] = useState([]);
    const [values, setValues] = useState({
        branch: [],
        lecture_type: "",
        boardData: [{
            image: "",
            board: "",
            class: "",
            section: "",
            lecture_type: '',
            video_conference_link: '',
            otherBoard: '',
            otherSection: '',
            regular_price: '',
            price_type: '',
            is_discount: '',
            discount_price: '',
            discount_start_date: '',
            limited_offer: '',
            discount_end_date: '',
            description: '',
            medium: '',
            otherMedium: '',
            thumbnail: '',
            requirements: '',
            curriculum: '',
        }],
        universityData: [{
            image: "",
            university: "",
            class: "",
            semester: "",
            lecture_type: '',
            video_conference_link: '',
            otheruniversity: '',
            othersemester: '',
            regular_price: '',
            price_type: '',
            is_discount: '',
            discount_price: '',
            discount_start_date: '',
            limited_offer: '',
            discount_end_date: '',
            description: '',
            medium: '',
            otherMedium: '',
            thumbnail: '',
            requirements: '',
            curriculum: '',
        }],
        preperationData: [{
            image: "",
            class: "",
            lecture_type: '',
            video_conference_link: '',
            regular_price: '',
            price_type: '',
            is_discount: '',
            discount_price: '',
            discount_start_date: '',
            limited_offer: '',
            discount_end_date: '',
            description: '',
            medium: '',
            otherMedium: '',
            thumbnail: '',
            requirements: '',
            curriculum: '',
        }],
        tuition_type: "",
    });

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    const handleFileInputChange = (e, name, props, image_dummy_field) => {
        let { file } = e.target.files[0];

        file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 200 || width > 368) {
                    props.setFieldValue(name, "");
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );

                } else if (height < 200 || width < 368) {
                    props.setFieldValue(name, "");

                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                } else {
                    getBase64(file)
                        .then((result) => {
                            file["base64"] = "true";
                            props.setFieldValue(name, file);
                            props.setFieldValue(image_dummy_field, result);
                        })
                        .catch((err) => { });
                }

            };
        };
    };

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const ValidateSchema1 = Yup.object().shape({
        lecture_type: Yup.string().required('Lecture type is required'), // Assuming lecture_type is a string field

        tuition_type: Yup.string(), // Assuming tuition_type is a string field

        boardData: Yup.array().when(['lecture_type', 'tuition_type'], {
            is: (lecture_type, tuition_type) =>
                lecture_type &&
                (lecture_type === 'school' ||
                    (lecture_type === 'tuition' && tuition_type === 'school')),
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        otherBoard: Yup.string().required('Required'),
                    })
                )
                .min(1, 'At least one item is required in boardData'), // Ensure at least one item in the array
            otherwise: Yup.array().when('lecture_type', {
                is: (lecture_type) => lecture_type === 'college',
                then: Yup.array()
                    .of(
                        Yup.object().shape({
                            collegeBoard: Yup.string().required('Required'),
                        })
                    )
                    .min(1, 'At least one item is required in boardData'), // Ensure at least one item in the array for 'college'
                otherwise: Yup.array().nullable(), // Nullable if condition does not match
            }),
        }),
    });

    const ValidateSchema = Yup.object().shape({
        branch: Yup.array().min(1),
        lecture_type: Yup.string().required('Required'),
        tuition_type: Yup.string().when('lecture_type', {
            is: (lecture_type) => lecture_type && lecture_type === 'tuition',
            then: Yup.string().required('Required')
        }),

        boardData: Yup.array().when(['lecture_type'], {
            is: ((lecture_type) => lecture_type && (lecture_type === 'school')),
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        board: Yup.string().required('Required'),
                        class: Yup.string().required('Required'),

                        section: Yup.string().required('Required'),
                        otherBoard: Yup.string().when('board', {
                            is: (board) => board && board === 'other',
                            then: Yup.string().required('Required')
                        }),

                        price_type: Yup.string().required('Required'),
                        regular_price: Yup.string().when('price_type', {
                            is: (price_type) => price_type && price_type === 'paid',
                            then: Yup.string().required('Required')
                        }),
                        lecture_type: Yup.string().required('Required'),
                        video_conference_link: Yup.string().matches(
                            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
                            'Enter correct url!'
                        ).when('lecture_type', {
                            is: (lecture_type) => lecture_type && (lecture_type === 'both' || lecture_type === "video_conference"),
                            then: Yup.string().required('Required')
                        }),
                        is_discount: Yup.string().when('price_type', {
                            is: (price_type) => price_type && price_type === 'paid',
                            then: Yup.string().required('Required')
                        }),
                        discount_price: Yup.string().when('price_type', {
                            is: (price_type) => price_type && price_type === 'paid',
                            then: Yup.string().when('is_discount', {
                                is: (is_discount) => is_discount && is_discount === 'yes',
                                then: Yup.string()
                                    .required('Required')
                                    .test('is-between-1-to-99', 'Value must be between 1 to 99', (value) => {
                                        return value >= 1 && value <= 99;
                                    })
                            })
                        }),
                        discount_start_date: Yup.string()
                            .when('price_type', {
                                is: (price_type) => price_type && price_type === 'paid',
                                then: Yup.string().when('is_discount', {
                                    is: (is_discount) => is_discount && is_discount === 'yes',
                                    then: Yup.string().required('Required')
                                })
                            }),
                        limited_offer: Yup.string()
                            .when('price_type', {
                                is: (price_type) => price_type && price_type === 'paid',
                                then: Yup.string().when('is_discount', {
                                    is: (is_discount) => is_discount && is_discount === 'yes',
                                    then: Yup.string().required('Required')
                                })
                            }),
                        discount_end_date: Yup.string().when('price_type', {
                            is: (price_type) => price_type && price_type === 'paid',
                            then: Yup.string().when('limited_offer', {
                                is: (limited_offer) => limited_offer && limited_offer === 'yes',
                                then: Yup.string().required('Required')
                            })
                        }),
                        otherMedium: Yup.string().when('medium', {
                            is: (board) => board && board === 'other',
                            then: Yup.string().required('Required')
                        }),
                        medium: Yup.string().required('Required'),
                        description: Yup.string().required('Required'),
                        thumbnail: Yup.mixed().required('Required'),
                        requirements: Yup.string().required('Required'),
                        curriculum: Yup.string().required('Required'),
                    })).min(1),
            otherwise: Yup.array().when(['lecture_type', 'tuition_type'], {
                is: ((lecture_type, tuition_type) => lecture_type && (lecture_type === 'school' || (lecture_type === 'tuition' && tuition_type === "school"))),
                then: Yup.array()
                    .of(
                        Yup.object().shape({
                            board: Yup.string().required('Required'),
                            class: Yup.string().required('Required'),

                            otherBoard: Yup.string().when('board', {
                                is: (board) => board && board === 'other',
                                then: Yup.string().required('Required')
                            }),

                            price_type: Yup.string().required('Required'),
                            regular_price: Yup.string().when('price_type', {
                                is: (price_type) => price_type && price_type === 'paid',
                                then: Yup.string().required('Required')
                            }),
                            lecture_type: Yup.string().required('Required'),
                            video_conference_link: Yup.string().matches(
                                /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
                                'Enter correct url!'
                            ).when('lecture_type', {
                                is: (lecture_type) => lecture_type && (lecture_type === 'both' || lecture_type === "video_conference"),
                                then: Yup.string().required('Required')
                            }),
                            is_discount: Yup.string().when('price_type', {
                                is: (price_type) => price_type && price_type === 'paid',
                                then: Yup.string().required('Required')
                            }),
                            discount_price: Yup.string().when('price_type', {
                                is: (price_type) => price_type && price_type === 'paid',
                                then: Yup.string().when('is_discount', {
                                    is: (is_discount) => is_discount && is_discount === 'yes',
                                    then: Yup.string()
                                        .required('Required')
                                        .test('is-between-1-to-99', 'Value must be between 1 to 99', (value) => {
                                            return value >= 1 && value <= 99;
                                        })
                                })
                            }),
                            discount_start_date: Yup.string()
                                .when('price_type', {
                                    is: (price_type) => price_type && price_type === 'paid',
                                    then: Yup.string().when('is_discount', {
                                        is: (is_discount) => is_discount && is_discount === 'yes',
                                        then: Yup.string().required('Required')
                                    })
                                }),
                            limited_offer: Yup.string()
                                .when('price_type', {
                                    is: (price_type) => price_type && price_type === 'paid',
                                    then: Yup.string().when('is_discount', {
                                        is: (is_discount) => is_discount && is_discount === 'yes',
                                        then: Yup.string().required('Required')
                                    })
                                }),
                            discount_end_date: Yup.string().when('price_type', {
                                is: (price_type) => price_type && price_type === 'paid',
                                then: Yup.string().when('limited_offer', {
                                    is: (limited_offer) => limited_offer && limited_offer === 'yes',
                                    then: Yup.string().required('Required')
                                })
                            }),
                            otherMedium: Yup.string().when('medium', {
                                is: (board) => board && board === 'other',
                                then: Yup.string().required('Required')
                            }),
                            medium: Yup.string().required('Required'),
                            description: Yup.string().required('Required'),
                            thumbnail: Yup.mixed().required('Required'),
                            requirements: Yup.string().required('Required'),
                            curriculum: Yup.string().required('Required'),
                        })
                    )
                    .min(1, 'At least one item is required in boardData'),
                otherwise: Yup.array().nullable(), 
            }),

        }),
        universityData: Yup.array().when(['lecture_type', 'tuition_type'], {
            is: ((lecture_type, tuition_type) => lecture_type && lecture_type === 'university' || (lecture_type === 'tuition' && tuition_type === "university")),
            then: Yup.array().min(1).of(Yup.object().shape({
                semester: Yup.string().required('Required'),
                university: Yup.string().required('Required'),

                class: Yup.string().required('Required'),
                otheruniversity: Yup.string().when('university', {
                    is: (university) => university && university === 'other',
                    then: Yup.string().required('Required')
                }),
                othersemester: Yup.string().when('semester', {
                    is: (semester) => semester && semester === 'other',
                    then: Yup.string().required('Required')
                }),
                price_type: Yup.string().required('Required'),
                regular_price: Yup.string().when('price_type', {
                    is: (price_type) => price_type && price_type === 'paid',
                    then: Yup.string().required('Required')
                }),
                lecture_type: Yup.string().required('Required'),
                video_conference_link: Yup.string().matches(
                    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
                    'Enter correct url!'
                ).when('lecture_type', {
                    is: (lecture_type) => lecture_type && (lecture_type === 'both' || lecture_type === "video_conference"),
                    then: Yup.string().required('Required')
                }),
                is_discount: Yup.string().when('price_type', {
                    is: (price_type) => price_type && price_type === 'paid',
                    then: Yup.string().required('Required')
                }),
                discount_price: Yup.string().when('price_type', {
                    is: (price_type) => price_type && price_type === 'paid',
                    then: Yup.string().when('is_discount', {
                        is: (is_discount) => is_discount && is_discount === 'yes',
                        then: Yup.string()
                            .required('Required')
                            .test('is-between-1-to-99', 'Value must be between 1 to 99', (value) => {
                                return value >= 1 && value <= 99;
                            })
                    })
                }),
                discount_start_date: Yup.string()
                    .when('price_type', {
                        is: (price_type) => price_type && price_type === 'paid',
                        then: Yup.string().when('is_discount', {
                            is: (is_discount) => is_discount && is_discount === 'yes',
                            then: Yup.string().required('Required')
                        })
                    }),
                limited_offer: Yup.string()
                    .when('price_type', {
                        is: (price_type) => price_type && price_type === 'paid',
                        then: Yup.string().when('is_discount', {
                            is: (is_discount) => is_discount && is_discount === 'yes',
                            then: Yup.string().required('Required')
                        })
                    }),
                discount_end_date: Yup.string().when('price_type', {
                    is: (price_type) => price_type && price_type === 'paid',
                    then: Yup.string().when('limited_offer', {
                        is: (limited_offer) => limited_offer && limited_offer === 'yes',
                        then: Yup.string().required('Required')
                    })
                }),
                otherMedium: Yup.string().when('medium', {
                    is: (board) => board && board === 'other',
                    then: Yup.string().required('Required')
                }),
                medium: Yup.string().required('Required'),
                description: Yup.string().required('Required'),
                thumbnail: Yup.mixed().required('Required'),
                requirements: Yup.string().required('Required'),
                curriculum: Yup.string().required('Required'),
            }))
        }),
        preperationData: Yup.array().when(['lecture_type', 'tuition_type'], {
            is: ((lecture_type, tuition_type) => lecture_type && lecture_type === 'preparation' || (lecture_type === 'tuition' && tuition_type === "preparation")),
            then: Yup.array().min(1).of(Yup.object().shape({
                class: Yup.string().required('Required'),
                price_type: Yup.string().required('Required'),
                regular_price: Yup.string().when('price_type', {
                    is: (price_type) => price_type && price_type === 'paid',
                    then: Yup.string().required('Required')
                }),
                lecture_type: Yup.string().required('Required'),
                video_conference_link: Yup.string().matches(
                    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/,
                    'Enter correct url!'
                ).when('lecture_type', {
                    is: (lecture_type) => lecture_type && (lecture_type === 'both' || lecture_type === "video_conference"),
                    then: Yup.string().required('Required')
                }),
                is_discount: Yup.string().when('price_type', {
                    is: (price_type) => price_type && price_type === 'paid',
                    then: Yup.string().required('Required')
                }),
                discount_price: Yup.string().when('price_type', {
                    is: (price_type) => price_type && price_type === 'paid',
                    then: Yup.string().when('is_discount', {
                        is: (is_discount) => is_discount && is_discount === 'yes',
                        then: Yup.string()
                            .required('Required')
                            .test('is-between-1-to-99', 'Value must be between 1 to 99', (value) => {
                                return value >= 1 && value <= 99;
                            })
                    })
                }),
                discount_start_date: Yup.string()
                    .when('price_type', {
                        is: (price_type) => price_type && price_type === 'paid',
                        then: Yup.string().when('is_discount', {
                            is: (is_discount) => is_discount && is_discount === 'yes',
                            then: Yup.string().required('Required')
                        })
                    }),
                limited_offer: Yup.string()
                    .when('price_type', {
                        is: (price_type) => price_type && price_type === 'paid',
                        then: Yup.string().when('is_discount', {
                            is: (is_discount) => is_discount && is_discount === 'yes',
                            then: Yup.string().required('Required')
                        })
                    }),
                discount_end_date: Yup.string().when('price_type', {
                    is: (price_type) => price_type && price_type === 'paid',
                    then: Yup.string().when('limited_offer', {
                        is: (limited_offer) => limited_offer && limited_offer === 'yes',
                        then: Yup.string().required('Required')
                    })
                }),
                otherMedium: Yup.string().when('medium', {
                    is: (board) => board && board === 'other',
                    then: Yup.string().required('Required')
                }),
                medium: Yup.string().required('Required'),
                description: Yup.string().required('Required'),
                thumbnail: Yup.mixed().required('Required'),
                requirements: Yup.string().required('Required'),
                curriculum: Yup.string().required('Required'),
            }))
        }),
    });

    const getchBranch = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "institute-branch/get-branches", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr =
                        result.data &&
                        result.data.map((v) => {
                            return { value: v.id, label: v.text }
                        });
                    setLiveBranch(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getMedium();
        getchBranch();
    }, []);
    const getMedium = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                is_main: "1",
            }),
        };

        fetch(url.apiUrl + "get-mediums", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setMedium(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    const getSchoolData = ({ boardData, branch, lecture_type }) => {
        try {
            const data = new FormData();
            data.append('branch_ids', branch.map(b => b.value).join(','))
            boardData.map((item, index) => {
                if (lecture_type === 'tuition') {
                    data.append(`class_data[${index}][class_type]`, "tuition");
                    data.append(`class_data[${index}][tuition_type]`, "school");
                } else {
                    data.append(`class_data[${index}][class_type]`, "school");
                }
                data.append(`class_data[${index}][board]`, item.board);
                data.append(`class_data[${index}][other_board]`, item.otherBoard);
                data.append(`class_data[${index}][class_name]`, item.class);
                data.append(`class_data[${index}][section]`, item.section);
                data.append(`class_data[${index}][other_section]`, item.otherSection);
                data.append(`class_data[${index}][lecture_type]`, item.lecture_type);
                if (item.lecture_type === 'both' || item.lecture_type === "video_conference") {
                    data.append(`class_data[${index}][video_conference_link]`, item.video_conference_link);
                }
                data.append(`class_data[${index}][description]`, item.description);
                data.append(`class_data[${index}][price]`, item.price_type);
                if (item.price_type == "paid") {
                    data.append(`class_data[${index}][is_discount]`, item.is_discount === 'yes' ? 1 : 0);
                    data.append(`class_data[${index}][regular_price]`, item.regular_price);
                    data.append(`class_data[${index}][discount_price]`, item.discount_price);
                    data.append(`class_data[${index}][is_limited_offer]`, item.limited_offer === 'yes' ? 1 : 0);
                    data.append(`class_data[${index}][discount_start_date]`, item.discount_start_date);
                    data.append(`class_data[${index}][discount_end_date]`, item.discount_end_date);
                }
                data.append(`class_data[${index}][curriculum]`, item.curriculum);
                data.append(`class_data[${index}][requirements]`, item.requirements);
                data.append(`class_data[${index}][medium]`, item.medium);
                data.append(`class_data[${index}][other_medium]`, item.otherMedium);
                data.append(`class_data[${index}][thumbnail]`, item.thumbnail);
            })

            return data
        } catch (error) {
            toast.error(error.message)
            return {}
        }
    }

    const getUniversityData = ({ universityData, branch, lecture_type }) => {
        try {
            const data = new FormData();
            data.append('branch_ids', branch.map(b => b.value).join(','))
            universityData.map((item, index) => {
                if (lecture_type === 'tuition') {
                    data.append(`class_data[${index}][class_type]`, "tuition");
                    data.append(`class_data[${index}][tuition_type]`, "university");
                } else {
                    data.append(`class_data[${index}][class_type]`, "university");
                }
                data.append(`class_data[${index}][university]`, item.university);
                data.append(`class_data[${index}][other_university]`, item.otheruniversity);
                data.append(`class_data[${index}][class_name]`, item.class);
                data.append(`class_data[${index}][semester]`, item.semester === 'other' ? item.othersemester : item.semester);
                data.append(`class_data[${index}][lecture_type]`, item.lecture_type);
                if (item.lecture_type === 'both' || item.lecture_type === "video_conference") {
                    data.append(`class_data[${index}][video_conference_link]`, item.video_conference_link);
                }
                data.append(`class_data[${index}][description]`, item.description);
                data.append(`class_data[${index}][price]`, item.price_type);
                if (item.price_type == "paid") {
                    data.append(`class_data[${index}][price]`, item.price_type);
                    data.append(`class_data[${index}][is_discount]`, item.is_discount === 'yes' ? 1 : 0);
                    data.append(`class_data[${index}][regular_price]`, item.regular_price);
                    data.append(`class_data[${index}][discount_price]`, item.discount_price);
                    data.append(`class_data[${index}][is_limited_offer]`, item.limited_offer === 'yes' ? 1 : 0);
                    data.append(`class_data[${index}][discount_start_date]`, item.discount_start_date);
                    data.append(`class_data[${index}][discount_end_date]`, item.discount_end_date);
                }
                data.append(`class_data[${index}][curriculum]`, item.curriculum);
                data.append(`class_data[${index}][requirements]`, item.requirements);
                data.append(`class_data[${index}][medium]`, item.medium);
                data.append(`class_data[${index}][other_medium]`, item.otherMedium);
                data.append(`class_data[${index}][thumbnail]`, item.thumbnail);
            })
            return data
        } catch (error) {
            toast.error(error.message)
            return {}
        }
    }

    const getPreparationData = ({ preperationData, branch, lecture_type }) => {
        try {
            const data = new FormData();
            data.append('branch_ids', branch.map(b => b.value).join(','))
            preperationData.map((item, index) => {
                if (lecture_type === 'tuition') {
                    data.append(`class_data[${index}][class_type]`, "tuition");
                    data.append(`class_data[${index}][tuition_type]`, "preparation");
                } else {
                    data.append(`class_data[${index}][class_type]`, "preparation");
                }
                data.append(`class_data[${index}][class_name]`, item.class);
                data.append(`class_data[${index}][lecture_type]`, item.lecture_type);
                if (item.lecture_type === 'both' || item.lecture_type === "video_conference") {
                    data.append(`class_data[${index}][video_conference_link]`, item.video_conference_link);
                }
                data.append(`class_data[${index}][description]`, item.description);
                data.append(`class_data[${index}][price]`, item.price_type);
                if (item.price_type == "paid") {
                    data.append(`class_data[${index}][is_discount]`, item.is_discount === 'yes' ? 1 : 0);
                    data.append(`class_data[${index}][regular_price]`, item.regular_price);
                    data.append(`class_data[${index}][discount_price]`, item.discount_price);
                    data.append(`class_data[${index}][is_limited_offer]`, item.limited_offer === 'yes' ? 1 : 0);
                    data.append(`class_data[${index}][discount_start_date]`, item.discount_start_date);
                    data.append(`class_data[${index}][discount_end_date]`, item.discount_end_date);
                }
                data.append(`class_data[${index}][curriculum]`, item.curriculum);
                data.append(`class_data[${index}][requirements]`, item.requirements);
                data.append(`class_data[${index}][medium]`, item.medium);
                data.append(`class_data[${index}][other_medium]`, item.otherMedium);
                data.append(`class_data[${index}][thumbnail]`, item.thumbnail);
            })
            return data
        } catch (error) {
            toast.error(error.message)
            return {}
        }
    }

    const submitData = async (data) => {
        try {
            setLoader(true)
            const response = await axios.post('institute/create-class-lecture', data, { headers: { AUTHTOKEN: auth_token, 'Content-Type': 'multipart/form-data' } })
            if (response.status === 'success') {
                toast.success('Classroom Added Successfully')
                setTimeout(() => {
                    history.push('/institute-dashboard/class')
                }, 5000);
            } else {
                toast.error(response?.message || 'Something went wrong')
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message)
        } finally {
            setLoader(false)
        }
    }

    const getchClassnameValid = async (values, setFieldError) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");
        let payloads = []
        switch (values.lecture_type) {
            case 'school': {
                values?.boardData?.map((item, index) => {
                    payloads.push({
                        section: item.section,
                        class_name: item.class,
                        fieldName: `boardData.${index}.class`,
                        board: item.board,
                        other_board: item.otherBoard
                    })
                })
                break;
            }
            case 'university': {
                values?.universityData?.map((item, index) => {
                    payloads.push({
                        semester: item.semester,
                        class_name: item.class,
                        fieldName: `universityData.${index}.class`,
                        university: item.university,
                        other_university: item.otheruniversity
                    })
                })
                break;
            }
            case 'preparation': {
                values?.preperationData?.map((item, index) => {
                    payloads.push({
                        class_name: item.class,
                        fieldName: `preperationData.${index}.class`
                    })
                })
                break;
            }
            case 'tuition': {
                switch (values.tuition_type) {
                    case 'school': {
                        values?.boardData?.map((item, index) => {
                            payloads.push({
                                section: item.section,
                                class_name: item.class,
                                fieldName: `boardData.${index}.class`,
                                tuition_type: values.tuition_type,
                                board: item.board,
                                other_board: item.otherBoard
                            })
                        })
                        break;
                    }
                    case 'university': {
                        values?.universityData?.map((item, index) => {
                            payloads.push({
                                semester: item.semester,
                                class_name: item.class,
                                fieldName: `universityData.${index}.class`,
                                tuition_type: values.tuition_type,
                                university: item.university,
                                other_university: item.otheruniversity
                            })
                        })
                        break;
                    }
                    case 'preparation': {
                        values?.preperationData?.map((item, index) => {
                            payloads.push({
                                class_name: item.class,
                                fieldName: `preperationData.${index}.class`,
                                tuition_type: values.tuition_type
                            })
                        })
                        break;
                    }
                }
            }
        }

        var hasErrors = false
        const promises = payloads.map(pl => {
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                    branch_ids: values?.branch?.map((b) => b.value).join(","),
                    class_type: values?.lecture_type,
                    ...pl
                }),
            };

            return fetch(url.apiUrl + "institute/check-class-exist", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                    } else {
                        hasErrors = true
                        if (result.data) {
                            setFieldError(result.data);
                        } else {
                            setFieldError(pl.fieldName, result.message)
                        }
                        toast.error(result.message);
                    }
                }).catch(error => { setFieldError(pl.fieldName, 'Classname not available.'); hasErrors = true; })
        })
        await Promise.all(promises)
        return hasErrors
    };
    const onSubmit = async (values, { setFieldError }) => {

        const hasErrors = await getchClassnameValid(values, setFieldError);
        if (hasErrors) {
            return
        }
        switch (values?.lecture_type) {
            case 'school': {
                const data = getSchoolData(values)
                submitData(data)
                break;
            }
            case 'university': {
                const data = getUniversityData(values)
                submitData(data)
                break;
            }
            case 'preparation': {
                const data = getPreparationData(values)
                submitData(data)
                break;
            }
            case 'tuition': {
                var data
                switch (values.tuition_type) {
                    case 'school': {
                        data = getSchoolData(values)
                        break;
                    }
                    case 'university': {
                        data = getUniversityData(values)
                        break;
                    }
                    case 'preparation': {
                        data = getPreparationData(values)
                        break;
                    }
                    default: {
                        data = null
                    }
                }
                if (data) {
                    data.delete('class_type')
                    data.append('class_type', 'tuition')
                    data.append('tuition_type', values.tuition_type)
                    submitData(data)
                } else {
                    toast.error('Please select Tuition type')
                }
                break;
            }
            default: {
                toast.error('Please select class type')
                return
            }
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/class">Classroom Course</Link>
                    </li>
                    <li className="breadcrumb-item active">Add Classroom Course</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Add Classroom Course</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                                validationSchema={ValidateSchema}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>

                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Branch <span className="text-danger">*</span>
                                                </label>
                                                <Select
                                                    onChange={(value) =>
                                                        props.setFieldValue("branch", value)
                                                    }
                                                    options={LiveBranch}
                                                    value={props.values?.branch}
                                                    name="branch"
                                                    isMulti
                                                />
                                                {props.errors.branch && props.touched.branch && (<p className="bg-error">{props.errors.branch}</p>)}
                                            </div>
                                            {props.values?.branch.length != 0 && (
                                                <div className="col-md-3 form-group">
                                                    <label>
                                                        Class Type <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        value={props.values.lecture_type}
                                                        onChange={(e) => {
                                                            props.setFieldValue("lecture_type", e.target.value);
                                                        }}
                                                    >
                                                        <option value="">Select class type</option>
                                                        <option value="school">School (Till 12th Standard)</option>
                                                        <option value="university">University</option>
                                                        <option value="preparation">Entrance Exam / Professional Courses</option>
                                                        <option value="tuition">Tuition</option>
                                                    </select>
                                                    {props.errors.lecture_type && props.touched.lecture_type && (<p className="bg-error">{props.errors.lecture_type}</p>)}
                                                </div>
                                            )}
                                        </div>
                                        {props.values.lecture_type === "school" && (
                                            <AddMoreSchoolData props={props} handleFileInputChange={handleFileInputChange} medium={medium} isTuitionType="false" />
                                        )}

                                        {props.values.lecture_type === "university" && (
                                            <AddMoreUniversityData props={props} handleFileInputChange={handleFileInputChange} medium={medium} />
                                        )}

                                        {props.values.lecture_type === "preparation" && (
                                            <AddMorePreperationData props={props} handleFileInputChange={handleFileInputChange} medium={medium} />
                                        )}
                                        {props.values.lecture_type === "tuition" && (
                                            <div className="row">
                                                <div className="col-md-3 form-group">
                                                    <label>
                                                        Tuition Type <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        value={props.values.tuition_type}
                                                        onChange={(e) => {
                                                            props.setFieldValue(
                                                                "tuition_type",
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option value="">Select tuition type</option>
                                                        <option value="school">School (Till 12th Standard)</option>
                                                        <option value="university">University</option>
                                                        <option value="preparation">Entrance Exam / Professional Courses</option>
                                                    </select>
                                                    {props.errors.tuition_type && props.touched.tuition_type && (<p className="text-danger">{props.errors.tuition_type}</p>)}
                                                </div>
                                            </div>
                                        )}
                                        {props.values.tuition_type === "school" && (
                                            <AddMoreSchoolData props={props} handleFileInputChange={handleFileInputChange} medium={medium} isTuitionType="true" />
                                        )}
                                        {props.values.tuition_type === "university" && (
                                            <AddMoreUniversityData props={props} handleFileInputChange={handleFileInputChange} medium={medium} />
                                        )}
                                        {props.values.tuition_type === "preparation" && (
                                            <AddMorePreperationData props={props} handleFileInputChange={handleFileInputChange} medium={medium} />
                                        )}

                                        <div className="col-md-12 text-end">
                                            {loader ? (
                                                <SubmitLoader />
                                            ) : (<>
                                                <button type="submit" className="btn btn-primary me-2">
                                                    Submit
                                                </button>
                                                <Link
                                                    to="/institute-dashboard/class"
                                                    className="btn btn-danger"
                                                >
                                                    Cancel
                                                </Link>
                                            </>
                                            )}
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ClassAdd;
