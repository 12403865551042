import utils from "../../../utils/utils";


export default class AlumniService {
    getStudentList() {
        return utils.sendApiRequest(`institute/student-list`, "GET", true, [])
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    createAlumni(payload) {
        return utils.sendApiRequest(`institute/create-alumni`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    updateAlumni(payload) {
        return utils.sendApiRequest(`institute/update-alumni`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    alumniList(payload) {
        return utils.sendApiRequest(`institute/alumni-list`, "POST", true, payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    alumniDetail(id) {
        return utils.sendApiRequest(`institute/update-alumni/` + id, "GET", true, [])
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }
    alumniDelete(id) {
        return utils.sendApiRequest(`institute/alumni-delete` + id, "DELETE", true, [])
            .then((response) => {
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }

}