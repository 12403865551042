import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import url from "../../../../../constants/url";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";

const PurchaseHistory = () => {
    const { student_id } = useParams();
    const [list, setList] = useState([]);
    const [count, setCount] = useState();
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        student_id: student_id
    });

    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;

    const apiCall = async (activity) => {
        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: token_authorization,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}institute-student/student-order-history`,
                activity,
                config
            );
            if (response) {
                setList(response.data?.data);
                setCount(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };

    const GetAllStudentsHistory = async () => {
        const activity = {
            student_id: student_id,
            limit: search.perPage,
            offset: search.start,
        };

        await apiCall(activity);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            student_id: student_id,
            limit: search.perPage,
            offset: currentPage,
        };
        await apiCall(activity);
    };

    useEffect(() => {
        GetAllStudentsHistory();
    }, []);
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/student">Students</Link>
                    </li>
                    <li className="breadcrumb-item active">Student Purchase History</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header ">
                            <h4 className="card-title">Student Purchase History </h4>
                        </div>
                        <div className="card-body">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th className="text-center">Order ID</th>
                                            <th>Student Details</th>
                                            <th className="text-center">Purchase Date </th>
                                            <th className="text-center">Amount </th>
                                            <th className="text-center">Item Details </th>
                                            <th className="text-center">Payment Status </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.length > 0 ?
                                            list.map((item, i) => (
                                                <tr key={i}>
                                                    <th className="text-center">{i + 1}</th>
                                                    <td className="text-center">{item?.payment?.orderId}</td>
                                                    <td>
                                                        <div className="media">
                                                            <img
                                                                src={item?.student?.image}
                                                                className="me-3 rounded-circle" alt={item?.student?.name}
                                                            />
                                                            <div className="media-body">
                                                                <Link className="font-weight-semibold"
                                                                    to={`/institute-dashboard/student-profile/${item?.student?.id}`}
                                                                >
                                                                    {item?.student?.name}
                                                                </Link>
                                                                <p className="mb-0 text-muted">
                                                                    {item?.student?.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{moment(item?.payment?.txndate).format("DD-MM-YYYY hh:mm A")}</td>
                                                    <td className="text-center">₹ {Math.round(item?.total_payable_price_new)}</td>
                                                    <td className="text-center">
                                                        <Link
                                                            className="btn btn-primary btn-sm"
                                                            to={`/institute-dashboard/student-purchase-items/${item?.payment?.id}/${item?.student?.id}`}
                                                        >
                                                            {item?.total_order}
                                                        </Link>
                                                    </td>
                                                    <td className="text-center text-capitalize">{item?.payment?.status}</td>
                                                </tr>
                                            )) : (
                                                <tr><td className="text-center" colSpan={7}>No Data Available !</td></tr>
                                            )}
                                    </tbody>
                                </table>
                                {list?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(count)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseHistory;
