import React from 'react';
import { Link } from 'react-router-dom';
import CourseList from './CourseList';

const Course = () => {
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/student-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Course</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Video Course List</h4>
                        </div>
                        <div className="card-body">
                            <CourseList />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Course;