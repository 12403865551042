import React from 'react';
import {Link} from 'react-router-dom';

const Navbar = () => {
    return (
        <>
            <div className="horizontal-main header-style1 clearfix p-0">
                <div className="container clearfix">
                    <div className="desktoplogo">
                        <Link to="/">
                            <img src="/assets/images/EduM_White_New.png" alt="img"/>
                        </Link>
                    </div>
                    <nav className="horizontalMenu clearfix d-md-flex">
                        <ul className="horizontalMenu-list">
                            <li aria-haspopup="true"><Link to="/">Home</Link></li>
                            <li aria-haspopup="true"><Link to="/about-us">About us </Link></li>
                            <li aria-haspopup="true"><Link to="/explore">Explore</Link></li>
                            <li aria-haspopup="true"><Link to="/services">Services</Link></li>
                            <li aria-haspopup="true"><Link to="/blog">Blogs</Link></li>
                            <li aria-haspopup="true"><Link to="/contact-us">Contact us</Link></li>
                            <li aria-haspopup="true"><Link to="/work-with-us">Work with us</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
};

export default Navbar;