import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import url from "../../../../constants/url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CartService from "../../../../Services/CartService";
import CourseCard from "./CourseCard";
import ClassCard from "./ClassCard";
import Curve from '../../Header/Curve';

const CartDetails = () => {
    let now = new Date().setHours(0, 0, 0, 0);
    const history = useHistory();
    const [data, setCourseData] = useState([]);
    const [loader, setLoader] = useState(1);
    const [PaymentData, setPaymentData] = useState([]);
    const [gSTData, setGSTData] = useState([]);
    const [MiddId, setMiddId] = useState("");
    const [total, setTotal] = useState(0);
    const [TotalLessAmount, setTotalLessAmount] = useState(0);

    const auth_info = localStorage.getItem("common-user-info");
    const auth_json = JSON.parse(auth_info);
    const auth_token = auth_json?.auth_token;

    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);

    const getAllCartList = async () => {

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}get-cart`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setLoader(0);
                if (result.status === "success") {
                    sessionStorage.setItem("item", result.data?.length);
                    setCourseData(result.data);
                    let total_ = [];
                    let finalTotal = [];
                    let finalLess = [];
                    let LESS_ = [];
                    if (result.data.length > 0) {
                        result.data.map((item) => {
                            if (
                                item?.online_course?.payable_price !== undefined ||
                                item?.class_course?.payable_price !== undefined
                            ) {
                                total_.push(item?.online_course?.payable_price);
                                total_.push(item?.class_course?.payable_price);
                                LESS_.push(item?.online_course?.regular_price);
                                LESS_.push(item?.class_course?.regular_price);
                            }
                        });
                    }
                    total_.forEach((element) => {
                        if (element !== undefined) {
                            finalTotal.push(element);
                        }
                    });
                    LESS_.forEach((element) => {
                        if (element !== undefined) {
                            finalLess.push(element);
                        }
                    });

                    let total_d = finalTotal.reduce((a, v) => (a = a + v), 0);
                    let LESS__d = finalLess.reduce((a, v) => (a = a + v), 0);
                    setTotal(total_d);
                    setTotalLessAmount(LESS__d);

                    setGSTData(result?.gst_data);
                }
            })
            .catch((error) => console.log("error", error));
    };
    useEffect(() => {
        if (auth_info && auth_json.type === "student") {
            getAllCartList();
        } else {
            setLoader(0)

        }
        if (localStorage.getItem('errormessage')) {
            toast.error(localStorage.getItem('errormessage'), {
                position: toast.POSITION.TOP_RIGHT,
            });
            localStorage.removeItem('errormessage');
        }
    }, []);

    const Checkout = async () => {

        var formdata = new FormData();
        formdata.append("mrp", Math.round(gSTData?.mrp));
        formdata.append("discount", Math.round(gSTData?.discount));
        formdata.append("payable_price", Math.round(gSTData?.payable_price));
        formdata.append("gst_amount", Math.round(gSTData?.gst_amount));
        formdata.append("total", Math.round(gSTData?.total));

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        fetch(`${url.apiUrl}payment`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                var raw = JSON.stringify({
                    body: {
                        requestType: result?.body?.requestType,
                        mid: result?.body?.mid,
                        websiteName: result?.body?.websiteName,
                        orderId: result?.body?.orderId,
                        txnAmount: {
                            value: total,
                            currency: "INR",
                        },
                        userInfo: {
                            custId: result?.body?.userInfo?.custId,
                        },
                        callbackUrl: result?.body?.callbackUrl,
                    },
                    head: {
                        signature: result?.head?.signature,
                    },
                });

                var ddd = `{"body":{"requestType":"${result?.body?.requestType
                    }","mid":"${result?.body?.mid}","websiteName":"${result?.body?.websiteName
                    }","orderId":"${result?.body?.orderId}","callbackUrl":"${result?.body?.callbackUrl
                    }","txnAmount":{"value":"${Number(
                        result?.body?.txnAmount?.value
                    )}","currency":"${result?.body?.txnAmount?.currency
                    }"},"userInfo":{"custId":${Number(
                        result?.body?.userInfo?.custId
                    )}}},"head":{"signature":"${result?.head?.signature}"}}`;
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: ddd,
                };

                fetch(
                    `https://securegw.paytm.in/theia/api/v1/initiateTransaction?mid=${result?.body?.mid}&orderId=${result?.body?.orderId}`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result_) => {
                        setMiddId(result?.body?.mid);

                        var config = {
                            root: "",
                            style: {
                                bodyBackgroundColor: "#fafafb",
                                bodyColor: "",
                                themeBackgroundColor: "#0FB8C9",
                                themeColor: "#ffffff",
                                headerBackgroundColor: "#284055",
                                headerColor: "#ffffff",
                                errorColor: "",
                                successColor: "",
                                card: {
                                    padding: "",
                                    backgroundColor: "",
                                },
                            },
                            data: {
                                orderId: result?.body?.orderId,
                                token: result_?.body?.txnToken,
                                tokenType: "TXN_TOKEN",
                                amount: 500,
                            },
                            payMode: {
                                labels: {},
                                filter: {
                                    exclude: [],
                                },
                                order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
                            },
                            website: result?.body?.websiteName,
                            flow: "DEFAULT",
                            merchant: {
                                mid: result?.body?.mid,
                                redirect: false,
                            },
                            handler: {
                                transactionStatus: function transactionStatus(paymentStatus) {
                                    if (paymentStatus.STATUS === "TXN_FAILURE") {
                                        localStorage.setItem("errormessage", paymentStatus.RESPMSG);
                                        toast.error(paymentStatus.RESPMSG, {
                                            position: toast.POSITION.TOP_RIGHT,
                                        });
                                        window.location.reload();
                                    } else {
                                        localStorage.removeItem('errormessage');
                                        if (result?.body?.callbackUrl) {
                                            var myHeaders = new Headers();
                                            myHeaders.append("AUTHTOKEN", auth_token);
                                            myHeaders.append("Content-Type", "application/json");
                                            var raw = JSON.stringify({
                                                BANKNAME: paymentStatus.BANKNAME,
                                                BANKTXNID: paymentStatus.BANKTXNID,
                                                CHECKSUMHASH: paymentStatus.CHECKSUMHASH,
                                                CURRENCY: paymentStatus.CURRENCY,
                                                GATEWAYNAME: paymentStatus.GATEWAYNAME,
                                                MID: paymentStatus.MID,
                                                ORDERID: paymentStatus.ORDERID,
                                                PAYMENTMODE: paymentStatus.PAYMENTMODE,
                                                RESPCODE: paymentStatus.RESPCODE,
                                                RESPMSG: paymentStatus.RESPMSG,
                                                STATUS: paymentStatus.STATUS,
                                                TXNAMOUNT: paymentStatus.TXNAMOUNT,
                                                TXNDATE: paymentStatus.TXNDATE,
                                                TXNID: paymentStatus.TXNID,
                                            });

                                            var requestOptions = {
                                                method: "POST",
                                                headers: myHeaders,
                                                body: raw,
                                            };

                                            fetch(`${url.apiUrl}payment-callback`, requestOptions)
                                                .then((response) => response.json())
                                                .then((result) => {
                                                    if (result.status === "success") {
                                                        localStorage.setItem(
                                                            "data",
                                                            JSON.stringify(result?.data)
                                                        );
                                                        history.push("/success");
                                                        window.location.reload();
                                                    } else {
                                                        toast.error("Payment Not Transfer", {
                                                            position: toast.POSITION.TOP_RIGHT,
                                                        });
                                                        setTimeout(() => {
                                                            window.location.reload();
                                                        }, 5000);
                                                    }
                                                })
                                                .catch((error) => console.log("error", error));
                                        }
                                        setPaymentData(paymentStatus);
                                    }
                                },
                                notifyMerchant: function notifyMerchant(eventName, data) {
                                    console.log("notify merchant about the payment state", eventName, data);

                                },
                            },
                        };

                        if (window.Paytm && window.Paytm.CheckoutJS) {
                            window.Paytm.CheckoutJS.init(config)
                                .then(function onSuccess() {
                                    window.Paytm.CheckoutJS.invoke();
                                })
                                .catch(function onError(error) {

                                    console.log("error", error)
                                });
                        }
                    })
                    .catch((error) => console.log("error", error));
            })
            .catch((error) => console.log("error", error));
    };

    const deleteCartApi = async (id) => {
        const serve = new CartService();
        if (window.confirm("Are you sure you want to delete this record?")) {
            try {
                const response = await serve.deleteCart(id);
                if (response) {
                    toast.success(response.message);
                    getAllCartList();

                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                }
            } catch (err) {
                throw err;
            }
        }
    };

    return (
        <>
            <ToastContainer />
            <div
                className="cover-image bg-background-1"
                data-bs-image-src="assets/images/banners/banjpgner1."
                style={{
                    background: 'url("assets/images/banners/banner1.webp") center center',
                }}
            >
                <div className="container header-text">
                    <h1>Cart Details </h1>
                </div>
            </div>
            <Curve />
            {loader ? (
                <div className="sptb bg-color-white">
                    <div className="container">
                        <div className="card">
                            <div className="card-body text-center">
                                <img src="/assets/images/loader.svg" alt="loader" />
                            </div>
                        </div>
                    </div>
                    <div className="Toastify"></div>
                </div>
            ) : (data?.length > 0 ? (
                <section className="sptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-header justify-content-between">
                                        <h4 className="card-title">Shopping Cart</h4>
                                        <Link
                                            to="/"
                                            className="btn btn-primary"
                                        >
                                            <i className="fa-solid fa-plus"></i> Add more item
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <div className="row match-height">
                                            {data.map((item, key) => (
                                                <>
                                                    {
                                                        item?.course_type == "online_course" ? (
                                                            <CourseCard item={item} key={key} deleteCartApi={deleteCartApi} />
                                                        ) : (
                                                            <ClassCard item={item} key={key} deleteCartApi={deleteCartApi} />

                                                        )
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Price Details</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="mb-4 text-center">
                                            <table className="table table-bordered table-hover text-nowrap">
                                                <tbody>
                                                    <tr>
                                                        <td className="font-weight-semibold text-start">
                                                            MRP :
                                                        </td>
                                                        <td className="text-end">
                                                            ₹ {Math.round(gSTData?.mrp)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-weight-semibold text-start">
                                                            Discount on MRP :
                                                        </td>
                                                        <td className="text-end">
                                                            ₹ {Math.round(gSTData?.discount)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-weight-semibold text-start">
                                                            Price :
                                                        </td>
                                                        <td className="text-end">
                                                            ₹ {Math.round(gSTData?.payable_price)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-weight-semibold text-start">
                                                            GST ({gSTData?.gst}%) :
                                                        </td>
                                                        <td className="text-end">
                                                            ₹ {Math.round(gSTData?.gst_amount)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-weight-semibold text-start">
                                                            <strong>Grand Total :</strong>
                                                        </td>
                                                        <td className="text-end">
                                                            <strong>₹ {Math.round(gSTData?.total)}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className="text-start">
                                            <strong>Note*</strong> Additional payment gateway charges
                                            will be applicable
                                        </p>
                                        <div className="mt-3">
                                            <Link
                                                to="#"
                                                className="btn btn-block btn-primary mb-3 mb-xl-0"
                                                onClick={Checkout}
                                            >
                                                Checkout
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
            ) : (
                <div className="sptb bg-color-white">
                    <div className="container">
                        <div className="card">
                            <div className="card-body text-center">
                                <img src="/assets/images/empty-shopping-cart-v2.jpg" alt="Empty Cart" />
                                <>
                                    <p className="text-center">
                                        Your cart is empty, please visit the Explore page to add courses !
                                    </p>
                                    <Link to="/explore" className="btn btn-primary">
                                        Explore
                                    </Link>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </>
    );
};

export default CartDetails;
