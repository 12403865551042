import Routes from "./Routes/Routes";
function ClassApp() {
  return (
    <>
      <Routes/>
    </>
  );
}

export default ClassApp;

