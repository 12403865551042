import { useState, useEffect } from "react";
import { createContext } from "react";

export const Context = createContext();
const { Provider } = Context;

export const ContextProvider = (props) => {
  const [active, setActive] = useState(false);
  return (
    <Provider
      value={{
        activities: [active, setActive],
      }}
    >
      {props.children}
    </Provider>
  );
};
