import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { calculateExperience } from "../../../utils/utils";
import NoDataAvailable from "../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const BestTeachers = (props) => {
    const history = useHistory();
    const [data, setTeacherData] = useState(props.data);
    useEffect(() => {
        if (props.data && props.data) setTeacherData(props.data);
    }, [props.data]);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <>
            <section className="sptb">
                <div className="container">
                    <div className="section-title">
                        <h2>Best Teachers</h2>
                        <div className="ms-auto">
                            <Link className="btn btn-primary" to="/teacher" >
                                View More <i className="fe fe-arrow-right" />
                            </Link>
                        </div>
                    </div>
                    <div className="match-height">
                        {data?.length >= 4 ? (
                            <Slider {...settings}>
                                {data?.map((item, i) => (
                                    <div className="col-md-12" key={i}>
                                        <Link to={`/teacher-detail/${item?.teacher_id}`}>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <div className="item-card7-img">
                                                            <img
                                                                src={item?.image}
                                                                className="cover-image teacher-student-image"
                                                                alt={item?.name}
                                                            />
                                                        </div>
                                                        <h4 className="mt-4 mb-2 leading-tight">
                                                            {item?.name}
                                                        </h4>
                                                        <h6 className="team-section badge">
                                                            {item?.total_experiance != 0 ? calculateExperience(item?.total_experiance) : "---"} Experience
                                                        </h6>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span>({item?.review_count} Reviews)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </Slider>

                        ) : (
                            <div className="row" >
                                {data?.length > 0 ? (
                                    data.map((item, i) => (
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
                                            <Link to={`/teacher-detail/${item?.teacher_id}`}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="text-center">
                                                            <div className="item-card7-img">
                                                                <img
                                                                    src={item?.image}
                                                                    className="cover-image teacher-student-image"
                                                                    alt={item?.name}
                                                                />
                                                            </div>
                                                            <h4 className="mt-4 mb-2 leading-tight">
                                                                {item?.name}
                                                            </h4>
                                                            <h6 className="team-section badge">
                                                                {item?.total_experiance != 0 ? calculateExperience(item?.total_experiance) : "---"} Experience
                                                            </h6>
                                                            <div className="d-flex justify-content-center">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= item?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <span>({item?.review_count} Reviews)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))

                                ) : (
                                    <NoDataAvailable />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default BestTeachers;
