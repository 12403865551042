import React, { useEffect, useState } from "react";
import { getInstituteBranchGetAllBranches } from "../../../../apis";
import url from "../../../../constants/url";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import AlumniService from "../../Services/AlumniService";

export default function AlumniForm({ props }) {
    const [branch, setBranch] = useState([]);
    const [boards, setboards] = useState([]);
    const [universities, setuniversities] = useState([]);
    const [classes, setClasses] = useState([]);
    const [sections, setsections] = useState([]);
    const [semester, setsemester] = useState([]);
    const [year, setYear] = useState([]);
    const [students, setStudents] = useState([]);
    const serve = new AlumniService();
    let currentYear = new Date().getFullYear();
    let earliestYear = 1970;

    const setYearList = () => {
        var year_arr = [];
        while (currentYear >= earliestYear) {
            year_arr.push(currentYear)
            currentYear -= 1;
        }
        setYear(year_arr)
    }

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;


    const getAllBraches = async () => {
        let res = await getInstituteBranchGetAllBranches();
        if (res.status === "success") {
            const arr =
                res.data &&
                res.data.map((v) => {
                    return { name: v.text, id: v.id };
                });
            setBranch(arr);
        }
    };
    const getAllStudents = async () => {
        try {
            let res = await serve.getStudentList()
            if (res.status === "success") {
                setStudents(res.data);
            }
        } catch (err) {
            throw err;
        }
    };

    const getBoards = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type: props?.values?.lectureSelected === "tuition"
                    ? props?.values?.tuitionType
                    : props?.values?.tuitionType,
                required_field: "board",
            }),
        };
        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    let found = result.data.find(
                        (cls) => cls.board === props?.values?.boardSelected
                    );
                    if (!found) {
                        props.setFieldValue(`boardSelected`, "");
                    }
                    setboards(result.data);
                } else {
                    props.setFieldValue(`boardSelected`, "");
                    setboards([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getUniversities = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type: props?.values?.lectureSelected === "tuition"
                    ? props?.values?.tuitionType
                    : props?.values?.tuitionType,
                required_field: "university",
            }),
        };
        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    let found = result.data.find(
                        (cls) => cls.id === props?.values?.universitySelected
                    );
                    if (!found) {
                        props.setFieldValue(`universitySelected`, "");
                    }
                    setuniversities(result.data);
                } else {
                    props.setFieldValue(`universitySelected`, "");
                    setuniversities([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getClass = async (body) => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(body),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.status === "success") {
                    let found = result.data.find(
                        (cls) => cls.class_name === props?.values?.classSelected
                    );
                    if (!found) {
                        props.setFieldValue(`classSelected`, "");
                    }
                    setClasses(result.data);
                } else {
                    props.setFieldValue(`classSelected`, "");
                    setClasses([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getsections = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: props?.values?.branchSelected,
                class_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.lectureSelected,
                required_field: "section",
                class_name: props?.values?.classSelected,
            }),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setsections(
                        result.data.map((section) => ({
                            name: section.section,
                            id: section.section,
                        }))
                    );
                } else {
                    props.setFieldValue(`sectionsSelected`, []);
                    setsections([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getsemesters = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "semester",
                class_name: props?.values?.classSelected,
                university: props?.values?.universitySelected,

            }),
        };

        fetch(url.apiUrl + "institute/lecture-class-name", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setsemester(
                        result.data.map((section) => ({
                            name: section.semester,
                            id: section.semester,
                        }))
                    );
                } else {
                    props.setFieldValue(`semesterSelected`, "");
                    setsemester([]);
                    toast.error(result.message);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getAllBraches();
        setYearList()
        getAllStudents()
    }, []);

    useEffect(() => {
        setClasses([]);
        props.setFieldValue(`subjectSelected`, "");

        props.setFieldValue(`semesterSelected`, "");
        if (props?.values?.lectureSelected === "school" || (props?.values?.lectureSelected === "tuition" && props?.values?.tuitionType === "school")) {
            props.setFieldValue(`universitySelected`, "");
            props.setFieldValue(`semesterSelected`, "");
            getBoards();
        }
        if (props?.values?.lectureSelected === "university" || (props?.values?.lectureSelected === "tuition" && props?.values?.tuitionType === "university")) {
            props.setFieldValue(`boardSelected`, "");
            props.setFieldValue(`sectionsSelected`, "");

            getUniversities();
        }
        if (props?.values?.lectureSelected == "preparation" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "preparation")) {
            props.setFieldValue(`classSelected`, "");
            setClasses([]);
            getClass({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "class_name",
            });
        }
    }, [props?.values?.lectureSelected, props?.values?.tuitionType]);

    useEffect(() => {
        setClasses([]);
        if (props?.values?.boardSelected && (props?.values?.lectureSelected == "school" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "school"))) {
            props.setFieldValue(`classSelected`, "");
            setClasses([]);
            getClass({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "class_name",
                section: props?.values?.sectionsSelected,
                board: props?.values?.boardSelected,
            });
        }
        if (props?.values?.universitySelected && (props?.values?.lectureSelected == "university" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "university"))) {
            props.setFieldValue(`classSelected`, "");
            setClasses([]);
            getClass({
                branch_ids: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,
                required_field: "class_name",
                university: props?.values?.universitySelected,
                semesters: props?.values?.semesterSelected,
            });
        }

    }, [props?.values?.boardSelected, props?.values?.universitySelected])

    // useEffect(() => {
    //     if (props?.values?.boardSelected != "" && props?.values?.classSelected != "" && props?.values?.lectureSelected === "school") {
    //         getsections();
    //     }
    //     if (props?.values?.universitySelected != "" && props?.values?.classSelected != "" && (props?.values?.lectureSelected === "university" || (props?.values?.lectureSelected == "tuition" && props?.values?.tuitionType == "university"))) {
    //         getsemesters();
    //     }

    // }, [props?.values?.classSelected]);
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <h4>Course Details</h4>
                    <hr />
                </div>
                <div className='col-lg-3 col-md-6 form-group'>
                    <label>
                        Branch <span className='text-danger'>*</span>
                    </label>
                    <select
                        className='form-select'
                        onChange={props.handleChange}
                        name={`branchSelected`}
                        value={props?.values?.branchSelected}
                    >
                        <option value=''>Select branch</option>
                        {branch &&
                            branch.map((b) => (
                                <option key={b.id} value={b.id}>
                                    {b.name}
                                </option>
                            ))}
                    </select>
                    {props.errors.branchSelected && props.touched.branchSelected && (
                        <p className='text-danger mb-0'>{props.errors.branchSelected}</p>
                    )}
                </div>
                {props.values.branchSelected && (
                    <>
                        <div className='col-md-3 form-group'>
                            <label>
                                Class Type <span className='text-danger'>*</span>
                            </label>
                            <select
                                className='form-select'
                                onChange={props.handleChange}
                                name={`lectureSelected`}
                                value={props?.values?.lectureSelected}
                            >
                                <option value="">Select class type</option>
                                <option value="school">School (Till 12th Standard)</option>
                                <option value="university">University</option>
                                <option value="preparation">Entrance Exam / Professional Courses</option>
                                <option value="tuition">Tuition</option>
                            </select>
                            {props.errors.lectureSelected && props.touched.lectureSelected && (
                                <p className='text-danger mb-0'>{props.errors.lectureSelected}</p>
                            )}
                        </div>
                        {console.log("props?.values", props?.values)}
                        {props?.values?.lectureSelected === "tuition" && (
                            <div className='col-md-3 form-group'>
                                <label>
                                    Tuition Type <span className='text-danger'>*</span>
                                </label>
                                <select
                                    className='form-select'
                                    onChange={props.handleChange}
                                    name={`tuitionType`}
                                    value={props?.values?.tuitionType}
                                >
                                    <option value=''>Select tuition type</option>
                                    <option value="school">School (Till 12th Standard)</option>
                                    <option value="university">University</option>
                                    <option value="preparation">Entrance Exam / Professional Courses</option>
                                </select>
                                {props.errors.tuitionType && props.touched.tuitionType && (
                                    <p className='text-danger mb-0'>{props.errors.tuitionType}</p>
                                )}
                            </div>
                        )}

                        {(props?.values?.lectureSelected === "school" ||
                            props?.values?.tuitionType === "school") && (
                                <div className='col-md-3 form-group'>
                                    <label>
                                        Board Name <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        onChange={props.handleChange}
                                        name={`boardSelected`}
                                        value={props?.values?.boardSelected}
                                        className='form-select'
                                    >
                                        <option value=''>Select board name</option>
                                        {boards.map((cls, idx) => (
                                            <option key={idx} value={cls.board}>
                                                {cls.board}
                                            </option>
                                        ))}
                                    </select>
                                    {props.errors.boardSelected && props.touched.boardSelected && (
                                        <p className='text-danger mb-0'>{props.errors.boardSelected}</p>
                                    )}
                                </div>
                            )}
                        {(props?.values?.lectureSelected === "university" ||
                            props?.values?.tuitionType === "university") && (
                                <div className='col-md-3 form-group'>
                                    <label>
                                        University Name <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        onChange={props.handleChange}
                                        name={`universitySelected`}
                                        value={props?.values?.universitySelected}
                                        className='form-select'
                                    >
                                        <option value=''>Select university name</option>
                                        {universities.map((cls, idx) => (
                                            <option key={idx} value={cls.id}>
                                                {cls.name}
                                            </option>
                                        ))}
                                    </select>
                                    {props.errors.universitySelected &&
                                        props.touched.universitySelected && (
                                            <p className='text-danger mb-0'>{props.errors.universitySelected}</p>
                                        )}
                                </div>
                            )}

                        <div className='col-md-3 form-group'>
                            <label>
                                Class Name <span className='text-danger'>*</span>
                            </label>
                            <select
                                onChange={props.handleChange}
                                name={`classSelected`}
                                value={props?.values?.classSelected}
                                className='form-select'
                            >
                                <option value=''>Select class name</option>
                                {classes.map((cls, idx) => (
                                    <option key={idx} value={cls.class_name}>
                                        {cls.class_name}
                                    </option>
                                ))}
                            </select>
                            {props.errors.classSelected && props.touched.classSelected && (
                                <p className='text-danger mb-0'>{props.errors.classSelected}</p>
                            )}
                        </div>
                        {props?.values?.lectureSelected === "school" && (
                            <div className='col-md-3 form-group'>
                                <label>
                                    Section <span className='text-danger'>*</span>
                                </label>
                                <select
                                    className='form-select'
                                    onChange={props.handleChange}
                                    name={`sectionsSelected`}
                                    value={props?.values?.sectionsSelected}
                                >
                                    <option value=''>Select Section</option>
                                    {sections &&
                                        sections.map((b) => (
                                            <option key={b.id} value={b.id}>
                                                {b.name}
                                            </option>
                                        ))}
                                </select>
                                {props.errors.sectionsSelected &&
                                    props.touched.sectionsSelected && (
                                        <p className='text-danger mb-0'>{props.errors.sectionsSelected}</p>
                                    )}
                            </div>
                        )}
                        {(props?.values?.lectureSelected === "university" ||
                            props?.values?.tuitionType === "university") && (
                                <div className='col-md-3 form-group'>
                                    <label>
                                        Semester <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        className='form-select'
                                        onChange={props.handleChange}
                                        name={`semesterSelected`}
                                        value={props?.values?.semesterSelected}
                                    >
                                        <option value=''>Select Semester</option>
                                        {semester &&
                                            semester.map((b) => (
                                                <option key={b.id} value={b.id}>
                                                    {b.name}
                                                </option>
                                            ))}
                                    </select>
                                    {props.errors.semesterSelected &&
                                        props.touched.semesterSelected && (
                                            <p className='text-danger mb-0'>{props.errors.semesterSelected}</p>
                                        )}
                                </div>
                            )}

                    </>)}


                {/* <div className="col-lg-4 col-md-6 form-group">
                    <label>Board <span className="text-danger">*</span></label>
                    <Select name="classroom" />
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>Section <span className="text-danger">*</span></label>
                    <Select name="classroom" />
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>Medium <span className="text-danger">*</span></label>
                    <Select name="classroom" />
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>University <span className="text-danger">*</span></label>
                    <Select name="classroom" />
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>Semester <span className="text-danger">*</span></label>
                    <Select name="classroom" />
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>Medium <span className="text-danger">*</span></label>
                    <Select name="classroom" />
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>Classroom Course <span className="text-danger">*</span></label>
                    <Select name="classroom" />
                </div> */}

                <h3>Uper section same as a classroom course</h3>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <h4>Student Details</h4>
                    <hr />
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>Batch Start Years <span className="text-danger">*</span></label>
                    <select
                        className='form-select'
                        onChange={props.handleChange}
                        name={`batch_start_year`}
                        value={props?.values?.batch_start_year}
                    >
                        <option value=''>Batch start years</option>
                        {year &&
                            year.map((yr, key) => (
                                <option key={key} value={yr}>
                                    {yr}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>Batch End Years <span className="text-danger">*</span></label>
                    <select
                        className='form-select'
                        onChange={props.handleChange}
                        name={`batch_end_year`}
                        value={props?.values?.batch_end_year}
                    >
                        <option value=''>Batch end years</option>
                        {year &&
                            year.map((yr, key) => (
                                <option key={key} value={yr}>
                                    {yr}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="col-lg-4 col-md-6 form-group">
                    <label>Name <span className="text-danger">*</span></label>
                    <select
                        className='form-select'
                        onChange={props.handleChange}
                        name={`student_id`}
                        value={props?.values?.student_id}
                    >
                        <option value=''>Student</option>
                        {students &&
                            students.map((student, key) => (
                                <option key={key} value={student.id}>
                                    {student.name}
                                </option>
                            ))}
                        <option value="other">
                            Other
                        </option>

                    </select>
                </div>
                {props?.values?.student_id == "other" && (
                    <>
                        <div className="col-lg-4 col-md-6 form-group">
                            <label>Profile Picture <span className="text-danger">*</span></label>
                            <input type="file" className="form-control" name="student" />
                        </div>
                        <div className="col-lg-4 col-md-6 form-group">
                            <label>Contact No <span className="text-danger">*</span></label>
                            <input type="tel" className="form-control" name="student" placeholder="Enter conatct no" />
                        </div>
                        <div className="col-lg-4 col-md-6 form-group">
                            <label>Email ID <span className="text-danger">*</span></label>
                            <input type="email" className="form-control" name="student" placeholder="Enter email id" />
                        </div>
                        <div className="col-lg-4 col-md-6 form-group">
                            <label>Current Company <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" name="student" placeholder="Enter current company" />
                        </div>
                        <div className="col-lg-4 col-md-6 form-group">
                            <label>Current Designation <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" name="student" placeholder="Enter current designation" />
                        </div>
                        <div className='col-md-4 form-group'>
                            <label htmlFor='reason'>About the Student <span className='text-danger'>*</span></label>
                            <Editor
                                textareaName='about'
                                placeholder='Enter about'
                                apiKey='9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d'
                                init={{
                                    plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "help",
                                        "wordcount",
                                        "spellchecker",
                                    ],

                                    toolbar:
                                        " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help |spellchecker ",
                                }}
                            />
                        </div>
                    </>
                )}
                <div className="mb-1 col-md-12 text-end">
                    <button type="submit" className="btn btn-primary me-2 ">Submit</button>
                    <Link to="/institute-dashboard/alumni" className="btn btn-danger ">Cancel</Link>
                </div>
            </div >
        </>
    )
}