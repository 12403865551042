import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import Select from "react-select";
import AlumniService from "../../Services/AlumniService";
import ReactPaginate from "react-paginate";
import url from "../../../../constants/url";

const Alumni = () => {
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;
    const serve = new AlumniService();

    const [alumni, setAlumni] = useState([])
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState();

    const [filters, setFilters] = useState({
        limit: 10,
        offset: 0,
    })

    const getAlumniListData = async (activity) => {
        try {
            let response = await serve.alumniList(activity);
            if (response) {
                setAlumni(response.data);
                setCount(response.count)
            }
        } catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        getAlumniListData(filters);
        getchBranch();
    }, []);

    const handlePageClick = async (data) => {
        let currentPage = data.selected * filters.limit;
        await getAlumniListData({ limit: filters.limit, offset: currentPage });
    };


    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);
    myHeaders.append("Content-Type", "application/json");
    const [LiveBranch, setLiveBranch] = useState([]);
    const getchBranch = async () => {
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "institute-branch/get-branches", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr =
                        result.data &&
                        result.data.map((v) => {
                            return { value: v.id, label: v.text }
                        });
                    setLiveBranch(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };


    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/class-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Alumni</li>
                </ol>
                <Link className="btn btn-primary" to="/institute-dashboard/add-alumni">
                    <i className="fa fa-plus"></i> Add Alumni
                </Link>
            </div>

            <div className="content-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Alumni Filter</h4>
                            </div>
                            <div className="card-body">
                                <Formik
                                    enableReinitialize={true}
                                >
                                    {(props) => (
                                        <form className="row" onSubmit={props.handleSubmit}>
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Branch</label>
                                                <Select name="branch"
                                                    onChange={(value) => {
                                                        props.setFieldValue("branch", value)
                                                        setFilters({ ...filters, branch: value })
                                                    }}
                                                    options={LiveBranch}
                                                    value={props.values?.branch}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Status</label>
                                                <select className="form-select" name="status"
                                                    onChange={e => {
                                                        props.setFieldValue(
                                                            "status",
                                                            e.target.value
                                                        );
                                                        setFilters({ ...filters, status: e.target.value })
                                                    }}>
                                                    <option>Select status</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                    <option value="pending">Pending</option>
                                                </select>
                                            </div>
                                            <div className="open-button col-md-3">
                                                <button type="submit" className="btn btn-primary"><i className="fe fe-search"></i> Search</button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Alumni List</h4>
                            </div>
                            <div className="card-body">
                                <div
                                    id="basic-1_wrapper"
                                    className="dataTables_wrapper no-footer"
                                >
                                    <div id="basic-1_filter" className="dataTables_filter">
                                        <label>
                                            Search:
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                            />
                                        </label>
                                    </div>
                                    <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Sr. No.</th>
                                                <th>Student Details</th>
                                                <th className="text-center text-nowrap">Contact No</th>
                                                <th>Branch Details</th>
                                                <th>Classroom Details</th>
                                                <th>Current Company Details</th>
                                                <th className="text-center text-nowrap">Session</th>
                                                <th className="text-center text-nowrap">Status</th>
                                                <th className="text-center text-nowrap">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td className="text-center" colSpan={9}>Loading..</td>
                                                </tr>
                                            ) : (alumni?.length === 0 ? (
                                                <tr>
                                                    <td className="text-center" colSpan={9}>No Data Available !</td>
                                                </tr>
                                            )
                                                : (
                                                    alumni?.map((item, index) => (
                                                        <tr key={item?.id}>
                                                            <th className="text-center text-nowrap">{index + 1}</th>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.student?.image} alt={item?.student?.name} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to="#"
                                                                            className="font-weight-semibold"
                                                                        >
                                                                            {item?.student?.name}
                                                                        </Link>
                                                                        <p className="text-muted mb-0 text-capitalize">
                                                                            {item?.student?.email}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">{item?.contact}</td>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.institute_branch?.image} alt={item?.institute_branch?.name} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to={"/institute-dashboard/branch-detail/" + item?.institute_branch?.id}
                                                                            className="font-weight-semibold"
                                                                        >
                                                                            {item?.institute_branch?.name}
                                                                        </Link>
                                                                        <p className="text-muted mb-0 text-capitalize">
                                                                            {item?.institute_branch?.email}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.class_course?.thumbnail} alt={item?.class_course?.title} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to={"/institute-dashboard/class-detail/1/" + item?.class_course?.id} className="font-weight-semibold" >
                                                                            {item?.class_course?.title}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="media">
                                                                    <img src="" alt="Branch image" className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to="#"
                                                                            className="font-weight-semibold text-capitalize"
                                                                        >
                                                                            {item?.current_company}
                                                                        </Link>
                                                                        <p className="text-muted mb-0 text-capitalize">
                                                                            {item?.current_designation}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">{item?.batch_start_year} - {item?.batch_end_year}</td>
                                                            <td className="text-center text-capitalize">{item?.status}</td>
                                                            <td className="text-center">
                                                                <Link title="Edit Aluimni" className="btn btn-primary btn-sm me-2" to="">
                                                                    <i className="fa fa-pen-to-square fs-16"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                    {alumni?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={Math.ceil(count / filters.limit)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Alumni;