import * as Yup from "yup";

//Validation
export const experienceSchema = Yup.object().shape({
    title: Yup.string().min(2, "Please write more than 2 characters !").required("Required"),
    company_name: Yup.string().min(2, "Please write more than 2 characters !").required("Required"),
    location: Yup.string().min(2, "Please write more than 2 characters !").required("Required"),
    employement_type: Yup.string().required("Required"),
    start_date: Yup.string().min(2, "Please write more than 2 characters !").required("Required"),
    description: Yup.string()
        .min(2, "Please write more than 2 characters !")
        .max(500, "Please write less than 500 characters !")
        .required("Required"),
    is_present: Yup.number().required("Required"), 
    end_date: Yup.string().when("is_present", {
        is: (is_present) => is_present === 0,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
    }),
});

