import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import url from "../../../../constants/url";

export default function PaymentHistory() {
    const { order_id } = useParams();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState({
        "mrp": 0,
        "discount": 0,
        "after_discount_price": 0,
        "gst": 0,
        "total_price": 0,
    })

    const apiCall = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token
            },
        };
        try {
            const response = await axios.get(
                `${url.apiUrl}student/get-orders/${order_id}`,
                config
            );
            if (response) {
                setData(response.data?.data);
                var mrp = 0, discount = 0, after_discount_price = 0, gst = 0, total_price = 0;
                response.data?.data.map((item) => {
                    if (item?.course_type == "class_course") {
                        mrp = mrp + Number(item?.class_course.regular_price ? item?.class_course?.regular_price : 0);
                    } else {
                        mrp = mrp + Number(item?.online_course.regular_price ? item?.online_course?.regular_price : 0);
                    }
                    mrp = mrp + Number((item?.mrp) ? item?.mrp : 0);
                    discount = discount + Number((item?.discount) ? item?.discount : 0);
                    after_discount_price = total_price + Number((item?.total_price) ? item?.total_price : 0);
                    gst = gst + Number((item?.gst) ? item?.gst : 0);
                    total_price = total_price + Number((item?.total_payable_price) ? item?.total_payable_price : 0);
                    setTotal({
                        "mrp": mrp,
                        "discount": discount,
                        "after_discount_price": after_discount_price,
                        "gst": gst,
                        "total_price": total_price,
                    });
                });

            }
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        apiCall();
    }, []);
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Payment History</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header ">
                            <h4 className="card-title">Student Purchase History </h4>
                        </div>
                        <div className="card-body">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Item Details</th>
                                            <th className="text-center">Item Type </th>
                                            <th className="text-center">MRP </th>
                                            <th className="text-center">Discount</th>
                                            <th className="text-center">After Discount Price</th>
                                            <th className="text-center">GST </th>
                                            <th className="text-center">Purchase Price </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data?.length > 0 ? (
                                            <>
                                                {data.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <th className="text-center">{i + 1}</th>
                                                            <td>
                                                                <div className="media">
                                                                    {item?.course_type === "class_course" ? (
                                                                        <img
                                                                            src={item?.class_course?.thumbnail}
                                                                            className="me-3 rounded-circle"
                                                                            alt=""
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={item?.online_course?.teaser_thumb_image}
                                                                            className="me-3 rounded-circle"
                                                                            alt=""
                                                                        />
                                                                    )}
                                                                    <div className="media-body">
                                                                        {item?.course_type === "class_course" ? (
                                                                            <>
                                                                                <Link to={`/student-dashboard/class-detail/${item?.class_course.id}`} className="font-weight-semibold" >
                                                                                    {item?.class_course.title}
                                                                                </Link>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Link to={`/student-dashboard/video-course-detail/${item?.online_course.id}`} className="font-weight-semibold" >
                                                                                    {item?.online_course.title}
                                                                                </Link>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">{item?.course_type === "class_course" ? "Classroom Course" : "Video Course"}</td>
                                                            <td className="text-center">₹ {item?.mrp}</td>
                                                            <td className="text-center">₹ {item?.is_discount ? item?.discount : "0"}</td>
                                                            <td className="text-center">₹ {item?.total_price}</td>
                                                            <td className="text-center">₹ {item?.gst}</td>
                                                            <td className="text-center">₹ {item?.total_payable_price}</td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <th className="text-end" colSpan={3}>TOTAL</th>
                                                    <th className="text-center">₹ {Math.round(total?.mrp)}</th>
                                                    <th className="text-center">₹ {Math.round(total?.discount)}</th>
                                                    <th className="text-center">₹ {Math.round(total?.after_discount_price)}</th>
                                                    <th className="text-center">₹ {Math.round(total?.gst)}</th>
                                                    <th className="text-center">₹ {Math.round(total?.total_price)}</th>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr><td className="text-center" colSpan={8}>No Data Available !</td></tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
