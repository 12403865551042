import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import axios from "axios";
import StudentList from "./StudentList";
const Student = () => {
    const [value, setValue] = useState({
        branch: "",
        teacher: "",
    });
    const [list, setList] = useState([]);
    const [count, setCount] = useState();
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
        branch_id: "",
    });
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;

    const apiCall = async (activity) => {
        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: token_authorization,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}teacher-student/get-all-student-list`,

                activity,
                config
            );
            if (response) {
                setList(response.data?.data);
                setCount(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };
    const GetAllStudents = async () => {
        const activity = {
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
            branch_id: search.branch_id

        };

        await apiCall(activity);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
            branch_id: search.branch_id
        };
        await apiCall(activity);
    };

    useEffect(() => {
        GetAllStudents();
    }, []);

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Student's List</li>
                </ol>
            </div>

            <div className="row">
                <StudentList
                    list={list}
                    search={search}
                    handlePageClick={handlePageClick}
                    setSearch={setSearch}
                    count={count}
                    offset={offset}
                />
            </div>
        </>
    );
};

export default Student;
