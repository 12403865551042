import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CourseList from "./CourseList";
import CourseFilter from "./CourseFilter";
import url from "../../../../constants/url";
import axios from "axios";
import ApproveCoursePopup from "./ApproveCoursePopup";
import CommonService from "../../Services/CommonService";
import { toast } from "react-toastify";

const Courses = () => {
    const [title, setTitle] = useState();
    const [status, setStatus] = useState("all");
    const [branch, setBranch] = useState("");
    const [teacherId, setTeacherId] = useState("");
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [count, setCount] = useState();
    const [courseData, setCourseData] = useState();
    const [aboutForm, setAboutForm] = useState(false);
    const [courseId, setCourseId] = useState(0);
    const serve = new CommonService();

    useEffect(() => {
        getAllCourseData();
    }, [search]);
    const apiCall = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}institute-course/get-all-online-courses`,
                activity,
                config
            );
            if (response) {
                setCourseData(response.data?.data);
                setCount(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };
    const getAllCourseData = async () => {
        let activity = {
            status: status,
            limit: search.perPage,
            branch_id: branch,
            teacher_id: teacherId,
            offset: search.start,
            query: search.searchTxt,
            added_by: "institute",
        };


        await apiCall(activity);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        let activity = {
            status: status,
            branch_id: branch,
            teacher_id: teacherId,
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
            added_by: "institute",
        };

        await apiCall(activity);
    };

    const aboutHandler = (id) => {
        setCourseId(id);
        setAboutForm(true);
    };

    const handleStatus = async (id, status) => {
        try {
            let response = await serve.changeCourseStatus({ course_id: id, status: (status === "active") ? "inactive" : "active" });
            if (response) {
                if (response.status === "success") {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    getAllCourseData();
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        } catch (err) {
            throw err;
        }
    }

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Video Course</li>
                </ol>
                <Link
                    className="btn btn-primary"
                    to="/institute-dashboard/video-course-add"
                >
                    <i className="fa fa-plus"></i> Add Video Course
                </Link>
            </div>

            <div className="row">
                <CourseFilter
                    setTeacherId={setTeacherId}
                    teacherId={teacherId}
                    branch={branch}
                    setBranch={setBranch}
                    status={status}
                    setStatus={setStatus}
                    getAllCourseData={getAllCourseData}
                />

                <CourseList
                    data={courseData && courseData}
                    handlePageClick={handlePageClick}
                    count={count}
                    search={search}
                    setSearch={setSearch}
                    offset={offset}
                    aboutHandler={aboutHandler}
                    handleStatus={handleStatus}
                />
            </div>
            <ApproveCoursePopup getAllCourseData={getAllCourseData} setAboutForm={setAboutForm} aboutForm={aboutForm} courseId={courseId} setCourseId={setCourseId} isMain={1} />
        </>
    );
};

export default Courses;
