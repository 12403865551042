import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";

export default function Payment() {
    const [data, setData] = useState([]);
    const [offset, setOffset] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
    });

    const apiCall = async (limit, offset) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token
            },
        };

        const data = {
            limit: limit,
            offset: offset,
        }
        try {
            const response = await axios.post(
                `${url.apiUrl}student/get-transactions`, data,
                config
            );
            if (response) {
                setData(response.data?.data);
                setTotalPages(response?.data.count / search.perPage)
            }
        } catch (err) {
            throw err;
        }
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage)
        await apiCall(search.perPage, currentPage);
    };
    useEffect(() => {
        setOffset(search.start)
        apiCall(search.perPage, search.start);
    }, [search]);
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Payment History</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header ">
                            <h4 className="card-title">Student Purchase History </h4>
                        </div>
                        <div className="card-body">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th className="text-center">Order ID</th>
                                            <th className="text-center">Purchase Date </th>
                                            <th className="text-center">Amount </th>
                                            <th className="text-center">Status </th>
                                            <th className="text-center">Item Details </th>
                                            <th className="text-center">Invoice </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data?.length > 0 ? (
                                            data.map((item, i) => {
                                                return (
                                                    <tr key={i + offset + 1}>
                                                        <th className="text-center">{i + offset + 1}</th>
                                                        <td className="text-center">{item?.orderId}</td>
                                                        <td className="text-center"><Moment format="DD-MM-YYYY hh:mm A">{item?.created_at}</Moment></td>
                                                        <td className="text-center">₹ {item?.total_payable_price}</td>
                                                        {item.status === "success" ? (
                                                            <td className="text-center text-uppercase text-success">{item?.status}</td>
                                                        ) : (
                                                            <td className="text-center text-danger">FAILED</td>
                                                        )}
                                                        <td className="text-center">
                                                            <Link to={`/student-dashboard/payment-history/${item.orderId}`} className="btn btn-primary btn-sm">
                                                                {item?.orders_count}
                                                            </Link>
                                                        </td>

                                                        <td className="text-center">
                                                            {item.status === "success" ? (
                                                                <Link to={`/student-dashboard/invoice/${item.orderId}`} className="btn btn-primary btn-sm">
                                                                    Download Now
                                                                </Link>
                                                            ) : ('---')}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr><td className="text-center" colSpan={7}>No Data Available !</td></tr>
                                        )}
                                    </tbody>
                                </table>
                                {data?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(totalPages)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


