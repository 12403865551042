import React, { useEffect, useState } from "react";
import axios from "axios";
import url from "../../../../constants/url";
import Moment from "react-moment";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const Review = (props) => {
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
    });
    const [data, setData] = useState([]);
    const [offset, setOffset] = useState(0);
    const apiCall = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}teacher/review`,

                activity,
                config
            );
            if (response) {
                setData(response.data?.data);
            }
        } catch (err) {
            throw err;
        }
    };

    const getAllReview = async () => {
        const activity = {
            status: search.status,
            limit: search.perPage,
            offset: search.start,
        };

        await apiCall(activity);
    };

    useEffect(() => {
        getAllReview();
    }, [search]);

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Reviews</h4>
                    </div>
                    <div className="card-body">
                        {data?.length > 0 ? (
                            data.map((item, i) => {
                                return (
                                    <div className="media mt-0 p-4 border br-5 review-media" key={i}>
                                        <div className="d-flex me-3">
                                            <a href="#">
                                                <img
                                                    className="border-radius-50 avatar-lg"
                                                    alt={item?.student?.name}
                                                    src={item?.student?.image}
                                                />
                                            </a>
                                        </div>
                                        <div className="media-body">
                                            <h4 className="mt-0 mb-3 font-weight-semibold">
                                                {item?.student?.name}
                                                <span className="d-inline-flex mb-2">
                                                    <div className="br-widget">
                                                        {rating.map((el, i) => (
                                                            <i
                                                                data-rating-value={el}
                                                                data-rating-text={el}
                                                                className={
                                                                    el <= item?.average_review
                                                                        ? "br-selected"
                                                                        : ""
                                                                }
                                                                key={i}
                                                            />
                                                        ))}
                                                    </div>
                                                </span>
                                            </h4>
                                            <small className="text-muted fs-14">
                                                <i className="fa fa-clock-o"></i> <Moment fromNow ago>{(item?.created_at)}</Moment>
                                            </small>
                                            <p className="mb-2 mt-2 education-mandal" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <NoDataAvailable/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Review;