import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import SubmitLoader from "../../../common/SubmitLoader"

export default function LearningManagementForm({
    type,
    heading,
    active,
    setActive,
}) {
    const [loader, setLoader] = useState(false);
    const [values, setValues] = useState({
        name: "",
        email: "",
        contact: "",
        message: "",
        subject: "",
    });

    const onSubmit = async (values) => {
        let obj = {
            name: values.name,
            contact_numbar: values.contact,
            email: values.email,
            discription: values.message,
            subject: values.subject,
            type: type,
        };

        setLoader(true);
        const config = {
            headers: {
                content: "application/json",
            },
        };
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASEURL + `enquiry`,
                obj,
                config
            );
            if (response?.data?.status === "success") {
                setLoader(false);
                toast.success("Successfully Submitted");
                setValues({
                    name: "",
                    email: "",
                    contact: "",
                    message: "",
                    subject: "",
                });
                setTimeout(() => {
                    setActive(false);
                }, 5000);
            } else {
            }
        } catch (err) {
            throw err;
        }
    };

    const VoucherSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Please enter valid email ID").required("Required"),

        message: Yup.string()
            .required("Required")
            .max(500, "Please write less than 500 characters !"),
        subject: Yup.string().required("Required"),
        contact: Yup.string()

            .required("Required")
            .min(13, "Please enter valid contact number")
            .max(13, "Please enter valid contact number"),
    });
    return (
        <div
            className={active ? "modal fade show" : "modal fade"}
            id="report"
            tabIndex="-1"
            aria-hidden="true"
            style={active ? { display: "block" } : { display: "none" }}
        >
            <div className="modal-dialog  modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="examplereportLongTitle">
                            {heading}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            onClick={() => setActive(false)}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <Formik
                            initialValues={values}
                            validationSchema={VoucherSchema}
                            onSubmit={onSubmit}
                            enableReinitialize={true}
                        >
                            {(props) => (
                                <form onSubmit={props.handleSubmit} className="row">
                                    <div className="form-group col-md-6">
                                        <label>
                                            Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            onChange={props.handleChange}
                                            value={props.values.name}
                                            placeholder="Please enter name"
                                        />
                                        {props.touched.name && props.errors.name ? (
                                            <div className=" bg-error">{props.errors.name}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Contact No. <span className="text-danger">*</span>
                                        </label>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            name="contact"
                                            className="form-control"
                                            value={props.values.contact}
                                            onChange={(e) => {
                                                props.setFieldValue("contact", e);
                                            }}
                                            defaultCountry="IN"
                                        />
                                        {props.touched.contact && props.errors.contact ? (
                                            <div className=" bg-error">{props.errors.contact}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Email ID <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            onChange={props.handleChange}
                                            value={props.values.email}
                                            placeholder="Please enter email id"
                                        />
                                        {props.touched.email && props.errors.email ? (
                                            <div className=" bg-error">{props.errors.email}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Subject <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="subject"
                                            onChange={props.handleChange}
                                            value={props.values.subject}
                                            placeholder="Please enter subject"
                                        />
                                        {props.touched.subject && props.errors.subject ? (
                                            <div className=" bg-error">{props.errors.subject}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>
                                            Message <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            name="message"
                                            onChange={props.handleChange}
                                            value={props.values.message}
                                            placeholder="Please enter message"
                                        />
                                        {props.touched.message && props.errors.message ? (
                                            <div className="bg-error">{props.errors.message}</div>
                                        ) : null}
                                    </div>
                                    <p className="text-end">
                                        {props.values.message?.length} / 500 Characters
                                    </p>
                                    <div className="modal-footer">
                                        {loader ? (
                                            <SubmitLoader />
                                        ) : (
                                            <>
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() => setActive(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </>
                                        )}

                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
