import React, { useState, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Context } from "../../../../../Context";
import SubmitLoader from "../../../../common/SubmitLoader"

const ChangePassword = () => {
    const { activities } = useContext(Context);
    const [active, setActive] = activities;
    const [Loader, setLoader] = useState(false);
    const [value, setValue] = useState({
        old: "",
        new: "",
        confirm: "",
    });
    let auth = JSON.parse(localStorage.getItem("common-user-info"));
    const onSubmit = async (values, { resetForm }) => {
        let obj = {
            password: values.old,
            new_password: values.new,
        };

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth && auth?.auth_token,
            },
        };
        if (sessionStorage.getItem("url") === "/teacher-dashboard") {
            setLoader(true);
            try {
                const response = await axios.post(
                    process.env.REACT_APP_API_BASEURL + `teacher/change-password`,
                    obj,
                    config
                );
                if (response?.data?.status === "success") {
                    setLoader(false);
                    resetForm();
                    toast.success("Password Changed Successfully ");
                    setValue({
                        old: "",
                        new: "",
                        confirm: "",
                    });
                    setTimeout(() => {
                        setActive(false);
                    }, 5000);
                } else {
                    resetForm();
                    setLoader(false);
                    toast.error(
                        response.data?.data?.password || response.data?.data?.new_password
                    );
                }
            } catch (err) {
                throw err;
            }
        } else {
            setLoader(true);
            try {
                const response = await axios.post(
                    process.env.REACT_APP_API_BASEURL + `teacher/change-password`,
                    obj,
                    config
                );
                if (response?.data?.status === "success") {
                    setLoader(false);
                    resetForm();
                    toast.success("Successfully submitted");
                    setValue({
                        old: "",
                        new: "",
                        confirm: "",
                    });
                } else {
                    resetForm();
                    setLoader(false);
                    toast.error(
                        response.data?.data?.password || response.data?.data?.new_password || response.data?.message
                    );
                }
            } catch (err) {
                throw err;
            }
        }
    };

    const ValidateSchema = Yup.object().shape({
        old: Yup.string().required("Required"),
        new: Yup.string()
            .min(6, "Password is too short - should be 6 chars minimum.")
            .required("Required"),
        confirm: Yup.string()
            .oneOf([Yup.ref("new"), null], "Passwords must match")
            .required("Required"),
    });
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Change Password
                    </li>
                </ol>
            </div>

            <Formik
                initialValues={value}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={ValidateSchema}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-lg-0">
                                    <div className="card-header">
                                        <h4 className="card-title">Change Password</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-md-4">
                                                <label>
                                                    Current Password <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="old"
                                                    onChange={props.handleChange}
                                                    value={props.values.old}
                                                    placeholder="Enter current password"
                                                />
                                                {props.touched.old && props.errors.old ? (
                                                    <div className="bg-error">
                                                        {props.errors.old}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>
                                                    New Password <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="new"
                                                    onChange={props.handleChange}
                                                    value={props.values.new}
                                                    placeholder="Enter new password"
                                                />
                                                {props.touched.new && props.errors.new ? (
                                                    <div className="bg-error">
                                                        {props.errors.new}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>
                                                    Confirm New Password <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="confirm"
                                                    placeholder="Confirm new password"
                                                    onChange={props.handleChange}
                                                    value={props.values.confirm}
                                                />
                                                {props.touched.confirm && props.errors.confirm ? (
                                                    <div className="bg-error">
                                                        {props.errors.confirm}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-md-12 text-end">
                                                {Loader ? (
                                                    <SubmitLoader />
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        Submit
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
            <ToastContainer />
        </>
    );
};

export default ChangePassword;
