import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import url from "../../../../constants/url";
import { duration } from "moment";

export default function LeftSectionFilters({
    setSkill,
    setLanguage,
    languages,
    language,
    skill,
    setDiscount,
    setCourseType,
    courseType,
    courseFilters,
    range,
    setRange,
    setDuration,
    board,
    setBoard,
    setSchedule,
    standard,
    setStandard,
    medium,
    setmedium,
    discount,
    schedule,
}) {
    const [LiveMedium, setLiveMedium] = useState([]);
    const [LiveStandard, setLiveStandard] = useState([]);
    const [LiveBoard, setLiveBoard] = useState([]);
    const [collapse, setCollapse] = useState({});
    const [active, setActive] = useState(false);

    const languageArray = (e) => {
        if (e.target.checked) {
            setLanguage([...language, e.target.value]);
        } else {
            setLanguage(language.filter((item) => item !== e.target.value));
        }
    };
    const SkillArray = (e) => {
        if (e.target.checked) {
            setSkill([...skill, e.target.value]);
        } else {
            setSkill(skill.filter((item) => item !== e.target.value));
        }
    };
    const PriceTypeArray = (e) => {
        if (e.target.checked) {
            if (e.target.value === "paid") {
                setActive(true);
            }
            setCourseType([e.target.value]);
        }
    };

    const collapsedHandler = (i) => {
        setCollapse((prev) => ({ ...prev, [i]: !prev?.[i] }));
    };

    useEffect(() => {
        // getMedium(); //remove Medium by client
        getStandard();
        getBoard();
    }, []);

    const boardHanlder = (e) => {
        if (e.target.checked) {
            setBoard([...board, e.target.value]);
        } else {
            setBoard(board.filter((item) => item !== e.target.value));
        }
    };

    const mediumHanlder = (e) => {
        if (e.target.checked) {
            setmedium([...medium, e.target.value]);
        } else {
            setmedium(medium.filter((item) => item !== e.target.value));
        }
    };
    const standardHanlder = (e) => {
        if (e.target.checked) {
            setStandard([...standard, e.target.value]);
        } else {
            setStandard(standard.filter((item) => item !== e.target.value));
        }
    };

    const getStandard = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(url.apiUrl + "get-standards", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLiveStandard(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    // get board
    const getBoard = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            limit: 10,
            offset: 0,
            title: "",
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };
        fetch(url.apiUrl + "get-board", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setLiveBoard(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    return (
        <div className="col-xl-3 col-lg-4 col-md-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Filter</h4>
                </div>
                <div className="panel-group">
                    <div className="panel panel-default p-0">
                        <div className="panel-heading">
                            <h4
                                className="panel-title"
                                onClick={() => {
                                    collapsedHandler(0);
                                }}
                            >
                                <a className={collapse[0]
                                    ? "accordion-toggle "
                                    : "accordion-toggle collapsed"
                                }
                                >
                                    Language
                                </a>
                            </h4>
                        </div>
                        <div className={
                            collapse[0]
                                ? "panel-collapse active collapse show"
                                : "panel-collapse active collapse "
                        }
                            style={collapse[0] ? { display: "block" } : { display: "block" }}
                        >
                            {collapse[0] ? (
                                <div className="panel-body h-300 overflow-auto">
                                    <div className="filter-product-checkbox">
                                        {languages?.length > 0 &&
                                            languages.map((v, i) => (
                                                <label
                                                    className="custom-control form-checkbox mb-2"
                                                    key={i}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        name="checkbox"
                                                        checked={language.includes(v.title)}
                                                        defaultValue={v.title}
                                                        onChange={(e) => {
                                                            languageArray(e);
                                                        }}
                                                    />
                                                    <span className="custom-control-label">
                                                        {v.title}
                                                    </span>
                                                </label>
                                            ))}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="panel panel-default p-0">
                        <div className="panel-heading">
                            <h4 className="panel-title" onClick={() => collapsedHandler(1)}>
                                <a
                                    className={
                                        collapse[1]
                                            ? "accordion-toggle "
                                            : "accordion-toggle collapsed"
                                    }
                                >
                                    Fees
                                </a>
                            </h4>
                        </div>
                        <div
                            className={
                                collapse[1]
                                    ? "panel-collapse active collapse show"
                                    : "panel-collapse active collapse "
                            }
                            style={collapse[1] ? { display: "block" } : { display: "block" }}
                        >
                            {collapse[1] ? (
                                <div className="panel-body">
                                    <div className="filter-product-checkbox">
                                        <label className="custom-control custom-radio mb-2">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="radio"
                                                defaultValue="free"
                                                checked={courseType.includes("free")}
                                                onChange={(e) => {
                                                    PriceTypeArray(e);
                                                }}
                                            />
                                            <span className="custom-control-label">Free</span>
                                        </label>
                                        <label className="custom-control custom-radio mb-0">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="radio"
                                                checked={courseType.includes("paid")}
                                                defaultValue="paid"
                                                onChange={(e) => {
                                                    PriceTypeArray(e);
                                                }}
                                            />
                                            <span className="custom-control-label">Paid</span>
                                            {active ? (
                                                <>
                                                    <div className="d-flex">
                                                        <label className="me-2">Min Price: </label>
                                                        <input
                                                            type="number"
                                                            defaultValue={range.min}
                                                            onChange={(e) =>
                                                                setRange({ ...range, min: e.target.value })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="d-flex mt-2">
                                                        <label className="me-2">Max Price: </label>
                                                        <input
                                                            type="number"
                                                            defaultValue={range.max}
                                                            onChange={(e) =>
                                                                setRange({ ...range, max: e.target.value })
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {/* {!courseFilters && (
                        <div className="panel panel-default p-0">
                            <div className="panel-heading">
                                <h4
                                    className="panel-title"
                                    onClick={() => collapsedHandler(2)}
                                >
                                    <a
                                        className={
                                            collapse[2]
                                                ? "accordion-toggle "
                                                : "accordion-toggle collapsed"
                                        }
                                        data-bs-toggle="collapse"
                                        data-parent="#accordion2"
                                        to="#collapsetwo"
                                        aria-expanded="false"
                                    >
                                        Duration
                                    </a>
                                </h4>
                            </div>
                            <div
                                id="collapsetwo"
                                className={
                                    collapse[2]
                                        ? "panel-collapse active collapse show"
                                        : "panel-collapse active collapse "
                                }
                                role="tabpanel"
                                aria-expanded="false"
                                style={
                                    collapse[2] ? { display: "block" } : { display: "block" }
                                }
                            >
                                {collapse[2] ? (
                                    <div className="panel-body">
                                        <div className="filter-product-checkbox">
                                            <label className="custom-control custom-radio mb-2">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    name="duration"
                                                    value="less_then_1"
                                                    checked={courseType.includes("less_then_1")}
                                                    onChange={(e) => {
                                                        setDuration(e.target.value);
                                                        PriceTypeArray(e);
                                                    }}
                                                />
                                                <span className="custom-control-label">
                                                    Less than 1 month
                                                </span>
                                            </label>
                                            <label className="custom-control custom-radio mb-2">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    name="duration"
                                                    checked={courseType.includes("less_then_2")}
                                                    value="less_then_2"
                                                    onChange={(e) => {
                                                        PriceTypeArray(e);
                                                    }}
                                                />
                                                <span className="custom-control-label me-4">
                                                    Less than 2 months
                                                </span>
                                            </label>
                                            <label className="custom-control custom-radio mb-2">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    name="duration"
                                                    checked={courseType.includes("less_then_3")}
                                                    value="less_then_3"
                                                    onChange={(e) => {
                                                        PriceTypeArray(e);
                                                    }}
                                                />
                                                <span className="custom-control-label">

                                                    Less than 3 months
                                                </span>
                                            </label>
                                            <label className="custom-control custom-radio mb-2">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    name="duration"
                                                    checked={courseType.includes("less_then_4")}
                                                    value="less_then_4"
                                                    onChange={(e) => {
                                                        PriceTypeArray(e);
                                                    }}
                                                />
                                                <span className="custom-control-label">

                                                    Less than 4 months
                                                </span>
                                            </label>
                                            <label className="custom-control custom-radio mb-2">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    name="duration"
                                                    checked={courseType.includes("less_then_5")}
                                                    value="less_then_5"
                                                    onChange={(e) => {
                                                        PriceTypeArray(e);
                                                    }}
                                                />
                                                <span className="custom-control-label">
                                                    Less than 5 months
                                                </span>
                                            </label>
                                            <label className="custom-control custom-radio mb-2">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    name="duration"
                                                    checked={courseType.includes("more_then_5")}
                                                    defaultValue="more_then_5"
                                                    onChange={(e) => {
                                                        PriceTypeArray(e);
                                                    }}
                                                />
                                                <span className="custom-control-label">
                                                    More than 5 Month
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    )} */}

                    <div className="panel panel-default p-0">
                        <div className="panel-heading">
                            <h4 className="panel-title" onClick={() => collapsedHandler(3)}>
                                <a
                                    className={
                                        collapse[3]
                                            ? "accordion-toggle "
                                            : "accordion-toggle collapsed"
                                    }
                                    data-bs-toggle="collapse"
                                    aria-expanded="true"
                                >
                                    Skill Level
                                </a>
                            </h4>
                        </div>
                        <div
                            id="collapseone"
                            className={
                                collapse[3]
                                    ? "panel-collapse active collapse show"
                                    : "panel-collapse active collapse "
                            }
                            role="tabpanel"
                            aria-expanded="false"
                            style={collapse[3] ? { display: "block" } : { display: "block" }}
                        >
                            {collapse[3] ? (
                                <div className="panel-body">
                                    <div className="filter-product-checkbox">
                                        <label className="custom-control form-checkbox mb-2">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="checkbox7"
                                                checked={skill.includes("beginner")}
                                                defaultValue="beginner"
                                                onChange={(e) => {
                                                    SkillArray(e);
                                                }}
                                            />
                                            <span className="custom-control-label">Beginner</span>
                                        </label>
                                        <label className="custom-control form-checkbox mb-2">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="checkbox8"
                                                checked={skill.includes("intermediate")}
                                                defaultValue="intermediate"
                                                onChange={(e) => {
                                                    SkillArray(e);
                                                }}
                                            />
                                            <span className="custom-control-label">Intermediate</span>
                                        </label>
                                        <label className="custom-control form-checkbox mb-0">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="checkbox9"
                                                checked={skill.includes("advanced")}
                                                defaultValue="advanced"
                                                onChange={(e) => {
                                                    SkillArray(e);
                                                }}
                                            />
                                            <span className="custom-control-label">Advanced</span>
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className="panel panel-default p-0">
                        <div className="panel-heading">
                            <h4 className="panel-title" onClick={() => collapsedHandler(5)}>
                                <a
                                    className={
                                        collapse[5]
                                            ? "accordion-toggle "
                                            : "accordion-toggle collapsed"
                                    }
                                    data-bs-toggle="collapse"
                                    data-parent="#accordion2"
                                    to="#collapsethree"
                                    aria-expanded="false"
                                >
                                    Discount
                                </a>
                            </h4>
                        </div>
                        <div
                            id="collapsethree"
                            className={
                                collapse[5]
                                    ? "panel-collapse active collapse show"
                                    : "panel-collapse active collapse "
                            }
                            role="tabpanel"
                            aria-expanded="false"
                            style={collapse[5] ? { display: "block" } : { display: "block" }}
                        >
                            {collapse[5] ? (
                                <div className="panel-body">
                                    <div className="filter-product-checkbox">
                                        <label className="custom-control custom-radio me-4">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="discount"
                                                checked={discount === "10"}
                                                value="10"
                                                onChange={(e) => {
                                                    setDiscount(e.target.value);
                                                }}
                                            />
                                            <span className="custom-control-label">Upto 10% off</span>
                                        </label>
                                        <label className="custom-control custom-radio me-4">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="discount"
                                                checked={discount === "20"}
                                                value="20"
                                                onChange={(e) => {
                                                    setDiscount(e.target.value);
                                                }}
                                            />
                                            <span className="custom-control-label">Upto 20% off</span>
                                        </label>
                                        <label className="custom-control custom-radio me-4">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="discount"
                                                checked={discount === "30"}
                                                value="30"
                                                onChange={(e) => {
                                                    setDiscount(e.target.value);
                                                }}
                                            />
                                            <span className="custom-control-label">Upto 30% off</span>
                                        </label>
                                        <label className="custom-control custom-radio me-4">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="discount"
                                                checked={discount === "40"}
                                                value="40"
                                                onChange={(e) => {
                                                    setDiscount(e.target.value);
                                                }}
                                            />
                                            <span className="custom-control-label">Upto 40% off</span>
                                        </label>
                                        <label className="custom-control custom-radio me-4">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="discount"
                                                value="50"
                                                checked={discount === "50"}
                                                onChange={(e) => {
                                                    setDiscount(e.target.value);
                                                }}
                                            />
                                            <span className="custom-control-label">Upto 50% off</span>
                                        </label>
                                        <label className="custom-control custom-radio me-4">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                name="discount"
                                                value="more_than_50"
                                                checked={discount === "more_than_50"}
                                                onChange={(e) => {
                                                    setDiscount(e.target.value);
                                                }}
                                            />
                                            <span className="custom-control-label">
                                                more than 50% off
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {!courseFilters && (
                        <>
                            <div className="panel panel-default p-0">
                                <div className="panel-heading">
                                    <h4
                                        className="panel-title"
                                        onClick={() => collapsedHandler(6)}
                                    >
                                        <a
                                            className={
                                                collapse[6]
                                                    ? "accordion-toggle "
                                                    : "accordion-toggle collapsed"
                                            }
                                            data-bs-toggle="collapse"
                                            data-parent="#accordion2"
                                            to="#collapsethree"
                                            aria-expanded="false"
                                        >
                                            Board
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="collapsethree"
                                    className={
                                        collapse[6]
                                            ? "panel-collapse active collapse show"
                                            : "panel-collapse active collapse "
                                    }
                                    role="tabpanel"
                                    aria-expanded="false"
                                    style={
                                        collapse[6] ? { display: "block" } : { display: "block" }
                                    }
                                >
                                    {collapse[6] ? (
                                        <div className="panel-body h-300 overflow-auto">
                                            <div className="filter-product-checkbox">
                                                {LiveBoard?.length > 0 &&
                                                    LiveBoard.map((v, i) => (
                                                        <label
                                                            className="custom-control form-checkbox me-4"
                                                            key={i}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                name={v.id}
                                                                checked={board.includes(v.title)}
                                                                value={v.title}
                                                                onChange={(e) => {
                                                                    boardHanlder(e);
                                                                }}
                                                            />
                                                            <span className="custom-control-label">
                                                                {v.title}
                                                            </span>
                                                        </label>
                                                    ))}
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            {/* <div className="panel panel-default p-0">
                                <div className="panel-heading">
                                    <h4
                                        className="panel-title"
                                        onClick={() => collapsedHandler(8)}
                                    >
                                        <a
                                            className={
                                                collapse[8]
                                                    ? "accordion-toggle "
                                                    : "accordion-toggle collapsed"
                                            }
                                            data-bs-toggle="collapse"
                                            data-parent="#accordion2"
                                            to="#collapsetwo"
                                            aria-expanded="false"
                                        >
                                            Class Schedule
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="collapsetwo"
                                    className={
                                        collapse[8]
                                            ? "panel-collapse active collapse show"
                                            : "panel-collapse active collapse "
                                    }
                                    role="tabpanel"
                                    aria-expanded="false"
                                    style={
                                        collapse[8] ? { display: "block" } : { display: "block" }
                                    }
                                >
                                    {collapse[8] ? (
                                        <div className="panel-body">
                                            <div className="filter-product-checkbox">
                                                <label className="custom-control form-checkbox mb-2">
                                                    <input
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name="schedule"
                                                        checked={schedule === "all_day"}
                                                        value="all_day"
                                                        onChange={(e) => {
                                                            setSchedule(e.target.value);
                                                        }}
                                                    />
                                                    <span className="custom-control-label">All Days</span>
                                                </label>
                                                <label className="custom-control form-checkbox mb-2">
                                                    <input
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name="schedule"
                                                        value="week_day"
                                                        checked={schedule === "week_day"}
                                                        onChange={(e) => {
                                                            setSchedule(e.target.value);
                                                        }}
                                                    />
                                                    <span className="custom-control-label">Weekdays</span>
                                                </label>
                                                <label className="custom-control form-checkbox mb-2">
                                                    <input
                                                        type="radio"
                                                        className="custom-control-input"
                                                        name="schedule"
                                                        checked={schedule === "week_end"}
                                                        defaultValue="week_end"
                                                        onChange={(e) => {
                                                            setSchedule(e.target.value);
                                                        }}
                                                    />
                                                    <span className="custom-control-label">Weekends</span>
                                                </label>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> */}
                            {/* <div className="panel panel-default p-0">
                                <div className="panel-heading">
                                    <h4
                                        className="panel-title"
                                        onClick={() => collapsedHandler(9)}
                                    >
                                        <a
                                            className={
                                                collapse[9]
                                                    ? "accordion-toggle "
                                                    : "accordion-toggle collapsed"
                                            }
                                            data-bs-toggle="collapse"
                                            data-parent="#accordion2"
                                            to="#collapsetwo"
                                            aria-expanded="false"
                                        >
                                            Standard
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="collapsetwo"
                                    className={
                                        collapse[9]
                                            ? "panel-collapse active collapse show"
                                            : "panel-collapse active collapse "
                                    }
                                    role="tabpanel"
                                    aria-expanded="false"
                                    style={
                                        collapse[9] ? { display: "block" } : { display: "block" }
                                    }
                                >
                                    {collapse[9] ? (
                                        <div className="panel-body">
                                            <div className="filter-product-checkbox">
                                                {LiveStandard?.length > 0 &&
                                                    LiveStandard.map((v, i) => (
                                                        <label
                                                            className="custom-control form-checkbox mb-2"
                                                            key={i}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                name={v.id}
                                                                value={v.title}
                                                                checked={standard.includes(v.title)}
                                                                onChange={(e) => {
                                                                    standardHanlder(e);
                                                                }}
                                                            />
                                                            <span className="custom-control-label">
                                                                {v.title}
                                                            </span>
                                                        </label>
                                                    ))}
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> */}
                            {/* <div className="panel panel-default p-0">
                                <div className="panel-heading">
                                    <h4
                                        className="panel-title"
                                        onClick={() => collapsedHandler(10)}
                                    >
                                        <a
                                            className={
                                                collapse[10]
                                                    ? "accordion-toggle "
                                                    : "accordion-toggle collapsed"
                                            }
                                            data-bs-toggle="collapse"
                                            data-parent="#accordion2"
                                            to="#collapsetwo"
                                            aria-expanded="false"
                                        >
                                            Medium
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="collapsetwo"
                                    className={
                                        collapse[10]
                                            ? "panel-collapse active collapse show"
                                            : "panel-collapse active collapse "
                                    }
                                    role="tabpanel"
                                    aria-expanded="false"
                                    style={
                                        collapse[10] ? { display: "block" } : { display: "block" }
                                    }
                                >
                                    {collapse[10] ? (
                                        <div className="panel-body">
                                            <div className="filter-product-checkbox">
                                                {LiveMedium?.length > 0 &&
                                                    LiveMedium.map((v, i) => (
                                                        <label
                                                            className="custom-control form-checkbox mb-2"
                                                            key={i}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                name={v.id}
                                                                checked={medium.includes(v.title)}
                                                                value={v.title}
                                                                onChange={(e) => {
                                                                    mediumHanlder(e);
                                                                }}
                                                            />
                                                            <span className="custom-control-label">
                                                                {v.title}
                                                            </span>
                                                        </label>
                                                    ))}
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> */}
                        </>
                    )}
                </div>
            </div >
        </div >
    );
}
