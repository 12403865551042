import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import url from '../../../../constants/url';
import Moment from "react-moment";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import utils, { replaceWord } from '../../../../utils/utils';
import NoDataAvailable from "../../../common/NoDataAvailable";

const BranchProfile = () => {
    let rating = [1, 2, 3, 4, 5];
    let now = new Date().setHours(0, 0, 0, 0);
    const [item, setBranch] = useState([]);
    const params = useParams();
    const id = params?.id;

    const getAllusers = async () => {
        var localdata = localStorage.getItem('common-user-info');
        var jsondata = JSON.parse(localdata);
        var token_authorization = jsondata?.auth_token;
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token_authorization);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}teacher/get-branch/${id}`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setBranch(result?.data)
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getAllusers();
    }, []);

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/teacher-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to="/teacher-dashboard/branches">Branch</Link></li>
                    <li className="breadcrumb-item active">Branch Profile</li>
                </ol>
            </div>
            <section className="app-user-view">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Branch Profile</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-12">
                                        <div className="item-card7-img">
                                            <img className="cover-image" src={item?.image} alt={item?.name} />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 mt-5 mt-xl-0">
                                        <h4>{item?.name}</h4>
                                        <p className="card-text mb-2">{item?.email}</p>
                                        <p className="card-text mb-2">
                                            <i className="fa fa-location-dot me-1"></i>
                                            {item?.address}, {item?.cities?.name}, {item?.states?.name} - {item?.pincode}
                                        </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 mt-5 mt-xl-0">
                                        <table className="user-details table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">Contact Person Name</td>
                                                    <td className="py-1 px-0">{item?.contact_person_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">Contact No</td>
                                                    <td className="py-1 px-0">{item?.contact}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">Year of Incorporation</td>
                                                    <td className="py-1 px-0">{item?.year_of_startup ? item?.year_of_startup : "---"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">Last Update</td>
                                                    <td className="py-1 px-0">
                                                        <Moment format="DD-MM-YYYY hh:mm A">{item?.updated_at}</Moment>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card">
                            <Tabs defaultActiveKey="class" id="uncontrolled-tab-example">
                                <Tab eventKey="class" title="Classroom Course">
                                    <div className="row">
                                        {item?.class_course?.length > 0 ? (
                                            item?.class_course.map((item, i) => (
                                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                    <Link to={`/teacher-dashboard/class-detail/${item?.id}`}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="item-card7-img">
                                                                    <img src={item?.thumbnail} alt={item?.title} className="cover-image" />
                                                                    {(item?.price == "paid" && (
                                                                        item?.is_discount == 1 && (
                                                                            (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                            (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                    )
                                                                    ) && (
                                                                            <Link to="#" className="bg-danger top left">
                                                                                {item?.discount_price}% off
                                                                            </Link>
                                                                        )}
                                                                    <Link to="#" className="bg-danger right top">Classroom Course</Link>
                                                                    {item?.class_type === "school" ? (
                                                                        <Link to="#" className="bg-danger right bottom">{item?.board}</Link>
                                                                    ) : (item?.class_type === "university" ? (
                                                                        <Link to="#" className="bg-danger right bottom">{item?.universities?.name}</Link>
                                                                    ) : (item?.class_type === "tuition" && (
                                                                        <>
                                                                            {item?.tuition_type === "school" ? (
                                                                                <Link to="#" className="bg-danger right bottom">{item?.board}</Link>
                                                                            ) : (item?.tuition_type === "university" && (
                                                                                <Link to="#" className="bg-danger right bottom">{item?.universities?.name}</Link>
                                                                            ))}
                                                                        </>
                                                                    )))}
                                                                </div>
                                                                <div className="item-card2-desc">
                                                                    <div className="d-inline-flex mb-2">
                                                                        <div className="br-widget">
                                                                            {rating.map((el, i) => (
                                                                                <i data-rating-value={el}
                                                                                    data-rating-text={el}
                                                                                    className={
                                                                                        el <= item?.average_review
                                                                                            ? "br-selected"
                                                                                            : ""
                                                                                    }
                                                                                    key={i}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                        <span>({item?.review_count} Reviews)</span>
                                                                    </div>
                                                                    <h4 className="mb-2 leading-tight2">{item?.title}</h4>
                                                                    <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}></p>

                                                                    <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                        <div className="d-flex justify-content-start">
                                                                            <i className="fa fa-shop me-2" />
                                                                            <span className="text-muted text-data-over-cut">{item?.institute.name}</span>
                                                                        </div>
                                                                        <div className="float-end d-flex justify-content-start">
                                                                            <i className="fa fa-building me-2" />
                                                                            <span className="text-muted text-capitalize text-data-over-cut">{replaceWord(item?.class_type)}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pt-2 d-flex justify-content-between">
                                                                        <div className="d-flex justify-content-start">
                                                                            <i className="fa fa-file me-2" />
                                                                            <span className="text-muted text-data-over-cut">
                                                                                {replaceWord(item?.lecture_type)}
                                                                            </span>
                                                                        </div>
                                                                        <div className="float-end">
                                                                            {item?.price === "paid" && (
                                                                                <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)} {(
                                                                                    item?.price == "paid" && (
                                                                                        item?.is_discount == 1 && (
                                                                                            (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                            (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                    )
                                                                                ) && (<del className="fs-14 text-muted"> ₹ {Math.round(item?.regular_price)} </del>
                                                                                    )}
                                                                                </h4>
                                                                            )}
                                                                            {item?.price === "free" && (
                                                                                <h4 className="mb-0">Free</h4>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </div>
                                </Tab>
                                <Tab eventKey="course" title="Video Course">
                                    <div className="row">
                                        {item?.online_course?.length > 0 ? (
                                            item?.online_course.map((item, i) => (
                                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12" key={i}>
                                                    <Link to={`/teacher-dashboard/video-course-detail/1/${item?.id}`}>
                                                        <div className="card" >
                                                            <div className="card-body">
                                                                <div className="item-card7-img">
                                                                    <img src={item?.teaser_thumb_image} alt={item?.title} className="cover-image" />
                                                                    {(
                                                                        item?.price == "paid" && (
                                                                            item?.is_discount == 1 && (
                                                                                (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                        )
                                                                    ) && (
                                                                            <Link to="#" className="bg-danger top left">
                                                                                {item?.discount_price}% off
                                                                            </Link>
                                                                        )}
                                                                    <Link to="#" className="bg-danger right top">
                                                                        Video Course
                                                                    </Link>
                                                                    {item?.is_professional === 0 && (
                                                                        <Link to="#" className="bg-danger right bottom">
                                                                            {item?.board}
                                                                        </Link>
                                                                    )}
                                                                </div>
                                                                <div className="item-card2-desc">
                                                                    <div className="d-inline-flex mb-2">
                                                                        <div className="br-widget">
                                                                            {rating.map((el, i) => (
                                                                                <i data-rating-value={el}
                                                                                    data-rating-text={el}
                                                                                    className={
                                                                                        el <= item?.average_review
                                                                                            ? "br-selected"
                                                                                            : ""
                                                                                    }
                                                                                    key={i}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                        <span>({item?.review_count} Reviews)</span>
                                                                    </div>
                                                                    <h4 className="mb-2 leading-tight2">
                                                                        {item?.title}
                                                                    </h4>
                                                                    <p className="leading-tight3 education-mandal">
                                                                        {item?.short_discription}
                                                                    </p>
                                                                    <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                        <div className="d-flex justify-content-start">
                                                                            <i className="fa fa-shop me-2" />
                                                                            <span className="text-muted text-data-over-cut">
                                                                                {item?.institute?.name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="float-end">
                                                                            <i className="fa fa-signal me-2" />
                                                                            <span className="text-muted text-capitalize">
                                                                                {item?.course_level}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pt-2 pb-2 border-bottom">
                                                                        <i className="fa fa-video me-2" />
                                                                        <span className="text-muted">
                                                                            {item?.lectures_count} Videos
                                                                        </span>
                                                                        <div className="float-end ">
                                                                            <i className="fa fa-clock-o me-2" />
                                                                            <span className="text-muted">
                                                                                {item?.total_duretion ? utils.toHHMMSS(item?.total_duretion) : 0} Hours
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pt-2 d-flex justify-content-between">
                                                                        <div className="d-flex justify-content-start">
                                                                            <i className="fa fa-microphone me-2" />
                                                                            <span className="text-muted text-data-over-cut">
                                                                                {item?.audio_language}
                                                                            </span>
                                                                        </div>
                                                                        <div className="float-end ">
                                                                            {item?.price === "paid" && (
                                                                                <h4 className="mb-0">
                                                                                    ₹ {Math.round(item?.payable_price)} {(
                                                                                        item?.price == "paid" && (
                                                                                            item?.is_discount == 1 && (
                                                                                                (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                                (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                                        )
                                                                                    ) && (
                                                                                            <del className="fs-14 text-muted">
                                                                                                ₹ {Math.round(item?.regular_price)}
                                                                                            </del>
                                                                                        )}
                                                                                </h4>
                                                                            )}
                                                                            {item?.price === "free" && (
                                                                                <h4 className="mb-0">Free</h4>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        ) : (
                                            <NoDataAvailable />
                                        )}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};
export default BranchProfile;