import React from "react";
const SocialLinks = () => {

    return (
        <>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="text-center">
                        <p className="font-weight-normal text-center fs-16">
                            Follow us on social media for updates and news
                            Education Mandal
                        </p>
                        <ul className="list-unstyled list-inline mt-4">
                            <li className="list-inline-item">
                                <a
                                    className="social-icons"
                                    href="https://www.facebook.com/profile.php?id=100090317913078&mibextid=LQQJ4d"
                                    target="_blank" rel="noreferrer"
                                >

                                    <i className="fe fe-facebook"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">

                                <a
                                    className="social-icons"
                                    href="https://wa.me/+919730063010"
                                    target="_blank" rel="noreferrer"
                                >

                                    <i className="fa-brands fa-whatsapp"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">

                                <a
                                    className="social-icons"
                                    href="https://www.linkedin.com/company/education-mandal/"
                                    target="_blank" rel="noreferrer"
                                >

                                    <i className="fe fe-linkedin"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">

                                <a
                                    className="social-icons"
                                    href="https://instagram.com/education_mandal?igshid=YmMyMTA2M2Y="
                                    target="_blank" rel="noreferrer"
                                >

                                    <i className="fe fe-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SocialLinks;
