import React from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
const StudentList = ({
    list,
    search,
    handlePageClick,
    setSearch,
    count,
    offset,handleSearch
}) => {
    return (
        <>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header ">
                        <h4 className="card-title">Student's List </h4>
                    </div>
                    <div className="card-body">
                        <div
                            id="basic-1_wrapper"
                            className="dataTables_wrapper no-footer"
                        >
                            <div id="basic-1_filter" className="dataTables_filter">
                                <label>
                                    Search:
                                    <input
                                        type="search"
                                        placeholder="Search..."
                                        onChange={(e) => { handleSearch(e.target.value)}}
                                    />
                                </label> 
                            </div>
                            <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                <thead>
                                    <tr>
                                        <th className="text-center">Sr. NO.</th>
                                        <th>Student Details</th>
                                        <th className="text-center">Contact No. </th>
                                        <th className="text-center">Gender </th>
                                        <th className="text-center">Purchase History </th>
                                        <th className="text-center">Register Date </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list?.length > 0 ?
                                        list.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th className="text-center">{i + offset + 1}</th>
                                                    <td>
                                                        <div className="media">
                                                            <img
                                                                src={
                                                                    item?.student?.image}
                                                                className="me-3 rounded-circle"
                                                                alt={item?.student?.name}
                                                            />
                                                            <div className="media-body">
                                                                <Link to={"/institute-dashboard/student-profile/" + item?.student?.id} className="font-weight-semibold">
                                                                    {item?.student?.name}
                                                                </Link>
                                                                <p className="mb-0 text-muted">
                                                                    {item?.student?.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        {item?.student?.contact}
                                                    </td>
                                                    <td className="text-capitalize text-center">
                                                        {item?.student?.gender}
                                                    </td>
                                                    <td className="text-center">
                                                        <Link title="View Purchase History"
                                                            className="btn btn-primary btn-sm"
                                                            to={`/institute-dashboard/student-purchase-history/${item?.student?.id}`}
                                                        >
                                                            {item?.student?.order.length}
                                                        </Link>
                                                    </td>
                                                    <td className="text-capitalize text-center">
                                                        {moment(item?.created_at).format("DD-MM-YYYY hh:mm A")}
                                                    </td>
                                                </tr>
                                            );
                                        }) : <tr><td className="text-center" colSpan={6}>No Data Available !</td></tr>}
                                </tbody>
                            </table>
                            {list?.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(count)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentList;
