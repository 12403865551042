import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AttendanceApi from "../../services/AttendanceApi";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { Formik } from "formik";
import moment from "moment";

const AttendanceViewTimeSlot = () => {
    const { id, subject } = useParams();
    const serve = new AttendanceApi();
    const [slotList, setSlotList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0);
    const [className, setClassName] = useState("");
    const [studentCount, setStudentCount] =  useState({absent_attendance: 0, leave_attendance: 0, present_attendance: 0, total_attendance: 0})
    const [filters, setFilters] = useState({
        class_id: id,
        subject: subject,
        limit: 10,
        offset: 0,
        query: ""
    });
    const [values, setValues] = useState({
        start_date: "",
        end_date: "",
        is_marked: "",
    });

    const getTimeSlotList = async (activity) => {
        try {
            setLoading(true)
            let response = await serve.getClassSubjectAttendanceList(activity);
            if (response) {
                setStudentCount({absent_attendance: response.absent_attendance, leave_attendance: response.leave_attendance, present_attendance: response.present_attendance, total_attendance:response.total_attendance })
                setLoading(false)
                setClassName(response.class_name)
                setCount(response.count);
                setSlotList(response.data);
            }
        } catch (err) {
            throw err;
        }
    }
    useEffect(() => {
        getTimeSlotList({
            class_id: id,
            subject: subject,
            limit: filters.limit,
            offset: filters.offset
        })
    }, [])

    const handlePageClick = async (attendanceLectures) => {
        let currentPage = attendanceLectures.selected * filters.limit;
        setOffset(currentPage);
        setFilters({
            ...filters,
            limit: filters.limit,
            offset: currentPage,
        })
        await getTimeSlotList({
            class_id: id,
            subject: subject,
            limit: filters.limit,
            offset: currentPage
        })
    };

    const FormSubmit = async (values) => {
        setFilters({
            ...filters,
            limit: filters.limit,
            offset: 0,
            start_date: values.start_date,
            end_date: values.end_date,
            is_marked: values.is_marked,
        })
        await getTimeSlotList({
            class_id: id,
            subject: subject,
            limit: filters.limit,
            offset: 0,
            start_date: values.start_date,
            end_date: values.end_date,
            is_marked: values.is_marked,
        })

    }

    const manageSearch = async (value) => {
        await getTimeSlotList({
            class_id: id,
            subject: subject,
            limit: filters.limit,
            offset: 0,
            start_date: values.start_date,
            end_date: values.end_date,
            is_marked: values.is_marked,
            query: value
        })
    }
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/attendance">Attendance List</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to={`/institute-dashboard/attendance-view-subject/${id}`}>View Attendance Subject</Link>
                    </li>
                    <li className="breadcrumb-item active">View Attendance Time Slots</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card bg-primary text-white">
                                <div className="card-body">
                                    <div className="counter-status dash3-counter d-flex">
                                        <div className="counter-icon bg-primary-dark my-auto br-100">
                                            <i className="fa-solid fa-calendar-week text-white leading-loose"></i>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <h5 className="mb-0 text-white">Total Attendance</h5>
                                            <h2 className="mb-0 text-white mt-2">{studentCount.total_attendance}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card bg-success">
                                <div className="card-body">
                                    <div className="counter-status dash3-counter d-flex">
                                        <div className="counter-icon bg-primary-dark my-auto br-100">
                                            <i className="fa-solid fa-calendar-week text-white leading-loose"></i>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <h5 className="mb-0 text-white">Total Present</h5>
                                            <h2 className="mb-0 text-white mt-2">{studentCount.present_attendance}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card bg-secondary">
                                <div className="card-body">
                                    <div className="counter-status dash3-counter d-flex">
                                        <div className="counter-icon bg-primary-dark my-auto br-100">
                                            <i className="fa-solid fa-calendar-week text-white leading-loose"></i>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <h5 className="mb-0 text-white">Total Absent</h5>
                                            <h2 className="mb-0 text-white mt-2">{studentCount.absent_attendance}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            <div className="card bg-orange">
                                <div className="card-body">
                                    <div className="counter-status dash3-counter d-flex">
                                        <div className="counter-icon bg-primary-dark my-auto br-100">
                                            <i className="fa-solid fa-calendar-week text-white leading-loose"></i>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <h5 className="mb-0 text-white">Total Leave</h5>
                                            <h2 className="mb-0 text-white mt-2">{studentCount.leave_attendance}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">View Attendance Time Slots for {className}</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={FormSubmit}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Start Date<span className="text-danger">*</span></label>
                                                <input type="date"
                                                    max={moment().format("YYYY-MM-DD")}
                                                    onChange={(e) => {
                                                        props.setFieldValue("start_date", e.target.value)
                                                        setFilters({ ...filters, start_date: e.target.value })
                                                    }}
                                                    value={props.values?.start_date}
                                                    name="start_date"
                                                    className="form-control" />
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>End Time <span className="text-danger">*</span></label>
                                                <input type="date"
                                                    max={moment().format("YYYY-MM-DD")}
                                                    onChange={(e) => {
                                                        props.setFieldValue("end_date", e.target.value)
                                                        setFilters({ ...filters, end_date: e.target.value })
                                                    }}
                                                    value={props.values?.end_date}
                                                    name="end_date" className="form-control" />
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label>Attendance Status <span className="text-danger">*</span></label>
                                                <select className="form-select" onChange={(e) => {
                                                    props.setFieldValue("is_marked", e.target.value)
                                                    setFilters({ ...filters, is_marked: e.target.value })
                                                }}>
                                                    <option>Select attendance status</option>
                                                    <option value={0}>Not Marked</option>
                                                    <option value={1}>Marked</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group open-button">
                                                <button type="submit" className="btn btn-primary">Search</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                            <div className="card mt-5">
                                <div className="card-header">
                                    <h4 className="card-title">View Attendance Time Slots</h4>
                                </div>
                                <div className="card-body">
                                    <div
                                        id="basic-1_wrapper"
                                        className="dataTables_wrapper no-footer"
                                    >
                                        <div id="basic-1_filter" className="dataTables_filter">
                                            <label>
                                                Search:
                                                <input
                                                    onChange={(e) => { manageSearch(e.target.value) }}
                                                    type="search"
                                                    placeholder="Search..."
                                                />
                                            </label>
                                        </div>
                                        <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Sr. No.</th>
                                                    <th className="text-center">Subject</th>
                                                    <th>Teacher Details</th>
                                                    <th className="text-center text-nowrap">Date</th>
                                                    <th className="text-center text-nowrap">Time</th>
                                                    <th className="text-center text-nowrap">Attendance Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? (
                                                    <tr>
                                                        <td className='text-center' colSpan={12}>
                                                            Loading..
                                                        </td>
                                                    </tr>
                                                ) : slotList.length === 0 ? (
                                                    <tr>
                                                        <td className='text-center' colSpan={12}>No Data Available !</td>
                                                    </tr>
                                                ) : (
                                                    slotList.map((item, i) => (
                                                        <tr key={i}>
                                                            <th className="text-center">
                                                                {i + offset + 1}
                                                            </th>
                                                            <td className="text-center">
                                                                {item.subject}
                                                            </td>
                                                            <td>
                                                                <div className="media">
                                                                    <img
                                                                        src={item?.teacher?.image}
                                                                        alt={item?.teacher?.name}
                                                                        className="me-3 rounded-circle"
                                                                    />
                                                                    <div className="media-body">
                                                                        <Link to=""
                                                                            className="font-weight-semibold"
                                                                        >
                                                                            {item?.teacher?.name}
                                                                        </Link>
                                                                        <p className="text-muted mb-0">
                                                                            {item?.teacher?.email}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">
                                                                <Moment format="DD-MM-YYYY">{item?.date}</Moment>
                                                            </td>
                                                            <td className="text-center">
                                                                {moment(item?.start_time, ["HH:mm"]).format("hh:mm A")} - {moment(item?.end_time, ["HH:mm"]).format("hh:mm A")}
                                                            </td>
                                                            {item.is_marked == "1" ? (
                                                                <td className="text-center text-capitalize">
                                                                    {item?.attendance_student?.attendance}
                                                                </td>
                                                            ) : (
                                                                <td className="text-center">Not Marked</td>
                                                            )}
                                                        </tr>
                                                    ))
                                                )}

                                            </tbody>
                                        </table>
                                        {slotList.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={Math.ceil(count / filters.limit)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AttendanceViewTimeSlot;
