import React, { useEffect, useState } from "react";
import url from "../../../../../constants/url";
import moment from "moment";
import Moment from "react-moment";
const BookedClassSlotView = ({ props, auth_token }) => {
    const [classSlots, setClassSlots] = useState([]);

    const getClassBookedSlot = async () => {
        if (auth_token) {
            var myHeaders = new Headers();
            myHeaders.append("AUTHTOKEN", auth_token);
            myHeaders.append("Content-Type", "application/json");
            var data = {
                branch_id: props?.values?.branchSelected,
                class_type: props?.values?.lectureSelected,
                tuition_type:
                    props?.values?.lectureSelected === "tuition"
                        ? props?.values?.tuitionType
                        : props?.values?.tuitionType,

                required_field: "teacher",
                class_name: props?.values?.classSelected,
                subject: props?.values?.subjectSelected,
            };

            let bodyData = {};
            if (props?.values?.lectureSelected === "school" || (props?.values?.lectureSelected === "tuition" && props?.values?.tuitionType === "school")) {
                var schoolData = {
                    section: props?.values?.sectionsSelected,
                    board: props?.values?.boardSelected,
                }
                bodyData = { ...data, ...schoolData };

            } else if (props?.values?.lectureSelected === "university" || (props?.values?.lectureSelected === "tuition" && props?.values?.tuitionType === "university")) {
                var universityData = {
                    university: props?.values?.universitySelected,
                    semester: props?.values?.semesterSelected,
                }
                bodyData = { ...data, ...universityData };

            } else {
                bodyData = data
            }

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(bodyData),
            };
            fetch(
                url.apiUrl +
                `institute/get-class-time-slot`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result && result?.data) {
                        setClassSlots(result?.data);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };
    useEffect(() => {
        if (props?.values?.subjectSelected && props?.values?.subjectSelected != "") {
            getClassBookedSlot();
        }
    }, [
        props?.values?.subjectSelected,
    ])
    return (
        <div className='card'>
            <div className='card-header'>
                <h4 className='card-title'>Classroom Slot - Booked</h4>
            </div>
            <div className='card-body h-400 overflow-auto'>
                {classSlots?.length === 0 ? (
                    <div className='d-flex justify-content-center'>
                        <p>No Data Available !</p>
                    </div>
                ) : (
                    <div className="row">
                        {classSlots?.map((TimeTableValue, index) => {
                            const { date, start_date, end_date, start_time, end_time, teacher } = TimeTableValue;
                            return (
                                <div className="col-md-6" key={index}>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h4 className='font-weight-semibold text-center mb-0'>
                                                Date : <Moment format="DD-MM-YYYY">{date}</Moment>
                                            </h4>
                                            {/* <h4 className='font-weight-semibold text-center mb-0'>
                                                Date : <Moment format="DD-MM-YYYY">{start_date}</Moment> to <Moment format="DD-MM-YYYY">{end_date}</Moment>
                                            </h4> */}
                                            <p className="text-center mb-0"><b>Teacher : </b>{teacher?.name}</p>
                                            <p className="text-center mb-0"> {moment(start_time, ["HH:mm"]).format("hh:mm A")} - {moment(end_time, ["HH:mm"]).format("hh:mm A")} </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
export default BookedClassSlotView;