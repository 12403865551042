import React, { useState, useEffect, useRef } from "react";
import url from "../../../../../constants/url";
import { FieldArray } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";

export default function AddMoreSchoolData({ props, handleFileInputChange, medium, isTuitionType }) {
    const [board, setBoard] = useState();
    const [sections, setSections] = useState([
        { title: 'A', id: 'A' },
        { title: 'B', id: 'B' },
        { title: 'C', id: 'C' },
        { title: 'D', id: 'D' },
        { title: 'E', id: 'E' },
        { title: 'F', id: 'F' },
        { title: 'G', id: 'G' },
        { title: 'H', id: 'H' },
        { title: 'I', id: 'I' },
        { title: 'J', id: 'J' },
        { title: 'K', id: 'K' },
        { title: 'L', id: 'L' },
        { title: 'M', id: 'M' },
        { title: 'N', id: 'N' },
        { title: 'O', id: 'O' },
        { title: 'P', id: 'P' },
        { title: 'Q', id: 'Q' },
        { title: 'R', id: 'R' },
        { title: 'S', id: 'S' },
        { title: 'T', id: 'T' },
        { title: 'U', id: 'U' },
        { title: 'V', id: 'V' },
        { title: 'W', id: 'W' },
        { title: 'X', id: 'X' },
        { title: 'Y', id: 'Y' },
        { title: 'Z', id: 'Z' }
    ]);

    const [allowClass, setAllowClass] = useState(true)
    const validClass = useRef(null)

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    useEffect(() => {
        getBoard();
    }, []);
    const getBoard = async (e) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            limit: 10,
            offset: 0,
            title: "",
        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };
        fetch(url.apiUrl + "get-board", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setBoard(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };


    const getDiscountedPrice = (index) => {
        let discount =
            parseFloat(props.values?.boardData?.[index]?.discount_price || 0) || 0;
        let price =
            parseFloat(props.values?.boardData?.[index]?.regular_price || 0) || 0;

        return Math.round(price - (discount * price) / 100);
    };

    return (
        <FieldArray
            name="boardData"
            render={(arrayHelpers) => (
                <>
                    {arrayHelpers?.form?.values?.boardData.map((v, index) => (
                        <React.Fragment key={index}>
                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>
                                        Board <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select"
                                        onChange={props.handleChange}
                                        name={`boardData.${index}.board`}
                                        value={props.values?.boardData?.[index]?.board}
                                    >
                                        <option value="">Select board</option>
                                        {board &&
                                            board.map((v, i) => (
                                                <option value={v.title} key={i}>
                                                    {v.title}
                                                </option>
                                            ))}
                                        <option value="other">Other</option>
                                    </select>
                                    {props.errors.boardData?.[index]?.board &&
                                        props.touched.boardData?.[index]?.board && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.board}
                                            </p>
                                        )}
                                </div>
                                {props.values?.boardData?.[index]?.board === "other" && (
                                    <div className="col-md-3 form-group">
                                        <label>
                                            Other Board <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter other board"
                                            onChange={props.handleChange}
                                            name={`boardData.${index}.otherBoard`}
                                            value={props.values?.boardData?.[index]?.otherBoard}
                                        />
                                        {props.errors.boardData?.[index]?.otherBoard &&
                                            props.touched.boardData?.[index]?.otherBoard && (
                                                <p className="bg-error">
                                                    {props.errors.boardData?.[index]?.otherBoard}
                                                </p>
                                            )}
                                    </div>
                                )}
                                <div className="col-md-3 form-group">
                                    <label>
                                        Class Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter class name"
                                        onChange={props.handleChange}
                                        name={`boardData.${index}.class`}
                                        value={props.values?.boardData?.[index]?.class}
                                    />
                                    {props.errors.boardData?.[index]?.class &&
                                        props.touched.boardData?.[index]?.class && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.class}
                                            </p>
                                        )}
                                </div>
                                {isTuitionType == "false" && (
                                    <div className="col-md-3 form-group">
                                        <label>
                                            Section <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            onChange={props.handleChange}
                                            name={`boardData.${index}.section`}
                                        // required
                                        >
                                            <option>Select section</option>
                                            {sections &&
                                                sections.map((v, i) => (
                                                    <option value={v.id}>
                                                        {v.title}
                                                    </option>
                                                ))}
                                        </select>
                                        {/* {props.values?.boardData?.[index]?.section === "" && (
                                            <p className="bg-error">
                                                Required
                                            </p>
                                        )} */}
                                        {props.errors.boardData?.[index]?.section &&
                                            props.touched.boardData?.[index]?.section && (
                                                <p className="bg-error">
                                                    {props.errors.boardData?.[index]?.section}
                                                </p>
                                            )}
                                    </div>
                                )}
                                <div className="col-md-3 form-group">
                                    <label>
                                        Medium <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select"
                                        onChange={props.handleChange}
                                        name={`boardData.${index}.medium`}
                                        value={props.values?.boardData?.[index]?.medium}
                                    >
                                        <option value="">Select medium</option>
                                        {medium &&
                                            medium.map((v, i) => (
                                                <option value={v.title} key={i}>
                                                    {v.title}
                                                </option>
                                            ))}
                                        <option value="other">Other</option>
                                    </select>
                                    {props.errors.boardData?.[index]?.medium &&
                                        props.touched.boardData?.[index]?.medium && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.medium}
                                            </p>
                                        )}
                                </div>
                                {props.values?.boardData?.[index]?.medium === "other" && (
                                    <div className="col-md-3 form-group">
                                        <label>
                                            Other Medium <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter other medium"
                                            onChange={props.handleChange}
                                            name={`boardData.${index}.otherMedium`}
                                            value={props.values?.boardData?.[index]?.otherMedium}
                                        />
                                        {props.errors.boardData?.[index]?.otherMedium &&
                                            props.touched.boardData?.[index]?.otherMedium && (
                                                <p className="bg-error">
                                                    {props.errors.boardData?.[index]?.otherMedium}
                                                </p>
                                            )}
                                    </div>
                                )}
                                <div className="col-md-3 form-group">
                                    <label>
                                        Thumbnail Image <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        accept="image/*"
                                        onChange={(e) => {
                                            handleFileInputChange(
                                                e,
                                                `boardData.${index}.thumbnail`,
                                                props,
                                                `boardData.${index}.image`
                                            );

                                        }}
                                        name={`boardData.${index}.thumbnail`}
                                    />
                                    <p className="fs-12 mb-0"><span className="text-danger">Note:</span> Image size: width = 368px and height = 200px</p>
                                    {props.errors.boardData?.[index]?.thumbnail &&
                                        props.touched.boardData?.[index]?.thumbnail && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.thumbnail}
                                            </p>
                                        )}
                                </div>
                                {props.values?.boardData?.[index]?.thumbnail && (
                                    <div className="col-md-3 form-group">
                                        <img
                                            src={props.values?.boardData?.[index]?.image || props.values?.boardData?.[index]?.thumbnail}
                                            alt="image"
                                            className="img-thumbnail"
                                        />
                                    </div>
                                )}

                                <div className="col-md-3 form-group">
                                    <label>
                                        Classroom Type <span className="text-danger"> *</span>
                                    </label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            name={`boardData.${index}.lecture_type`}
                                            checked={
                                                props.values?.boardData?.[index]?.lecture_type ===
                                                "video_conference"
                                            }
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    `boardData.${index}.lecture_type`,
                                                    e.target.value
                                                )
                                            }
                                            className="form-check-input"
                                            value="video_conference"
                                        />
                                        <label className="form-check-label">Online</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            name={`boardData.${index}.lecture_type`}
                                            checked={
                                                props.values?.boardData?.[index]?.lecture_type ===
                                                "manual"
                                            }
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    `boardData.${index}.lecture_type`,
                                                    e.target.value
                                                )
                                            }
                                            className="form-check-input"
                                            value="manual"
                                        />
                                        <label className="form-check-label">Offline</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            name={`boardData.${index}.lecture_type`}
                                            checked={
                                                props.values?.boardData?.[index]?.lecture_type ===
                                                "both"
                                            }
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    `boardData.${index}.lecture_type`,
                                                    e.target.value
                                                )
                                            }
                                            className="form-check-input"
                                            value="both"
                                        />
                                        <label className="form-check-label">Hybrid (Online + Offline)</label>
                                    </div>
                                    {props.errors.boardData?.[index]?.lecture_type &&
                                        props.touched.boardData?.[index]?.lecture_type && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.lecture_type}
                                            </p>
                                        )}
                                </div>
                                {(props.values?.boardData?.[index]?.lecture_type && props.values?.boardData?.[index]?.lecture_type != "manual") && (
                                    <div className="col-md-3 form-group">
                                        <label>Video Conference Link <span className="text-danger"> *</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter video conference link"
                                            name={`boardData.${index}.video_conference_link`}
                                            onChange={props.handleChange}
                                            value={props.values?.boardData?.[index]?.video_conference_link}
                                        />
                                        {props.errors.boardData?.[index]?.video_conference_link && props.touched.boardData?.[index]?.video_conference_link && (
                                            <p className="bg-error">{props.errors.boardData?.[index]?.video_conference_link}</p>
                                        )}

                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>
                                        Price Type <span className="text-danger"> *</span>
                                    </label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            value="free"
                                            onChange={props.handleChange}
                                            name={`boardData.${index}.price_type`}
                                            checked={
                                                props.values?.boardData?.[index]?.price_type === "free"
                                            }
                                        />
                                        <label className="form-check-label">Free</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            value="paid"
                                            onChange={props.handleChange}
                                            name={`boardData.${index}.price_type`}
                                            checked={
                                                props.values?.boardData?.[index]?.price_type === "paid"
                                            }
                                        />
                                        <label className="form-check-label">Paid</label>
                                    </div>
                                    {props.errors.boardData?.[index]?.price_type &&
                                        props.touched.boardData?.[index]?.price_type && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.price_type}
                                            </p>
                                        )}
                                </div>
                                {props.values?.boardData?.[index]?.price_type === "paid" && (
                                    <>
                                        <div className="col-md-3 form-group">
                                            <label>
                                                Regular Price <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter regular price"
                                                name={`boardData.${index}.regular_price`}
                                                onChange={props.handleChange}
                                                value={props.values?.boardData?.[index]?.regular_price}
                                            />
                                            {props.errors.boardData?.[index]?.regular_price &&
                                                props.touched.boardData?.[index]?.regular_price && (
                                                    <p className="bg-error">
                                                        {props.errors.boardData?.[index]?.regular_price}
                                                    </p>
                                                )}
                                        </div>
                                        <div className="col-md-3 form-group">
                                            <label>
                                                Discount Type <span className="text-danger"> *</span>
                                            </label>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="yes"
                                                    name={`boardData.${index}.is_discount`}
                                                    onChange={props.handleChange}
                                                    checked={
                                                        props.values?.boardData?.[index]?.is_discount ===
                                                        "yes"
                                                    }
                                                />
                                                <label className="form-check-label">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="no"
                                                    name={`boardData.${index}.is_discount`}
                                                    onChange={props.handleChange}
                                                    checked={
                                                        props.values?.boardData?.[index]?.is_discount ===
                                                        "no"
                                                    }
                                                />
                                                <label className="form-check-label">No</label>
                                            </div>
                                            {props.errors.boardData?.[index]?.is_discount &&
                                                props.touched.boardData?.[index]?.is_discount && (
                                                    <p className="bg-error">
                                                        {props.errors.boardData?.[index]?.is_discount}
                                                    </p>
                                                )}
                                        </div>
                                    </>
                                )}
                                {props.values?.boardData?.[index]?.is_discount === "yes" &&
                                    props.values?.boardData?.[index]?.price_type === "paid" && (
                                        <>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Discount Price (in %){" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter discount price"
                                                    name={`boardData.${index}.discount_price`}
                                                    onChange={props.handleChange}
                                                    value={
                                                        props.values?.boardData?.[index]?.discount_price
                                                    }
                                                />
                                                {props.errors.boardData?.[index]?.discount_price &&
                                                    props.touched.boardData?.[index]?.discount_price && (
                                                        <p className="bg-error">
                                                            {props.errors.boardData?.[index]?.discount_price}
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Price After Discount{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Price after discount "
                                                    disabled
                                                    name={`boardData.${index}.discount_price`}
                                                    value={getDiscountedPrice(index)}
                                                />
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Discount Start Date{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name={`boardData.${index}.discount_start_date`}
                                                    value={
                                                        props.values?.boardData?.[index]?.discount_start_date
                                                    }
                                                    onChange={props.handleChange}
                                                    min={moment().format("YYYY-MM-DD")}
                                                />
                                                {props.errors.boardData?.[index]?.discount_start_date &&
                                                    props.touched.boardData?.[index]
                                                        ?.discount_start_date && (
                                                        <p className="bg-error">
                                                            {
                                                                props.errors.boardData?.[index]?.discount_start_date
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <label>
                                                    Limited Offer <span className="text-danger"> *</span>
                                                </label>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        value="yes"
                                                        checked={
                                                            props.values?.boardData?.[index]
                                                                ?.limited_offer === "yes"
                                                        }
                                                        onChange={props.handleChange}
                                                        name={`boardData.${index}.limited_offer`}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="validationRadio7"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        type="radio"
                                                        className="form-check-input"
                                                        value="no"
                                                        checked={
                                                            props.values?.boardData?.[index]
                                                                ?.limited_offer === "no"
                                                        }
                                                        onChange={props.handleChange}
                                                        name={`boardData.${index}.limited_offer`}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="validationRadio8"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                                {props.errors.boardData?.[index]?.limited_offer &&
                                                    props.touched.boardData?.[index]?.limited_offer && (
                                                        <p className="bg-error">
                                                            {props.errors.boardData?.[index]?.limited_offer}
                                                        </p>
                                                    )}
                                            </div>
                                            {props.values?.boardData?.[index]?.limited_offer ===
                                                "yes" && (
                                                    <div className="col-md-3 form-group">
                                                        <label>
                                                            Discount End Date{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name={`boardData.${index}.discount_end_date`}
                                                            value={
                                                                props.values?.boardData?.[index]
                                                                    ?.discount_end_date
                                                            }
                                                            onChange={props.handleChange}
                                                            min={props.values?.boardData?.[index]?.discount_start_date || moment().format("YYYY-MM-DD")}
                                                        />
                                                        {props.errors.boardData?.[index]?.discount_end_date &&
                                                            props.touched.boardData?.[index]
                                                                ?.discount_end_date && (
                                                                <p className="bg-error">
                                                                    {
                                                                        props.errors.boardData?.[index]
                                                                            ?.discount_end_date
                                                                    }
                                                                </p>
                                                            )}
                                                    </div>
                                                )}
                                        </>
                                    )}
                            </div>
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>
                                        Class Description <span className="text-danger">*</span>
                                    </label>
                                    <Editor
                                        textareaName="course_discription"
                                        placeholder="Enter course decription"
                                        apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                        init={{
                                            plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "help",
                                                "wordcount",
                                            ],

                                            toolbar:
                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                        }}
                                        value={props.values?.boardData?.[index]?.description}
                                        onEditorChange={(e) =>
                                            props.handleChange({
                                                target: {
                                                    name: `boardData.${index}.description`,
                                                    value: e,
                                                },
                                            })
                                        }
                                    />
                                    {props.errors.boardData?.[index]?.description &&
                                        props.touched.boardData?.[index]?.description && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.description}
                                            </p>
                                        )}
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>
                                        What will students learn in this classroom? <span className="text-danger">*</span>
                                    </label>
                                    <Editor
                                        textareaName="course_discription"
                                        placeholder="Enter course decription"
                                        apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                        init={{
                                            plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "help",
                                                "wordcount",
                                            ],

                                            toolbar:
                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                        }}
                                        value={props.values?.boardData?.[index]?.curriculum}
                                        onEditorChange={(e) =>
                                            props.handleChange({
                                                target: {
                                                    name: `boardData.${index}.curriculum`,
                                                    value: e,
                                                },
                                            })
                                        }
                                    />
                                    {props.errors.boardData?.[index]?.curriculum &&
                                        props.touched.boardData?.[index]?.curriculum && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.curriculum}
                                            </p>
                                        )}
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>
                                        Requirements <span className="text-danger">*</span>
                                    </label>
                                    <Editor
                                        textareaName="course_discription"
                                        placeholder="Enter course decription"
                                        apiKey="9dy3muoqqxnunzzw6y8sk50yh714r1jssnizy6ycfvyg6u9d"
                                        init={{
                                            plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "help",
                                                "wordcount",
                                            ],

                                            toolbar:
                                                " undo redo | blocks | image code | formatselect | bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | help ",
                                        }}
                                        value={props.values?.boardData?.[index]?.requirements}
                                        onEditorChange={(e) =>
                                            props.handleChange({
                                                target: {
                                                    name: `boardData.${index}.requirements`,
                                                    value: e,
                                                },
                                            })
                                        }
                                    />
                                    {props.errors.boardData?.[index]?.requirements &&
                                        props.touched.boardData?.[index]?.requirements && (
                                            <p className="bg-error">
                                                {props.errors.boardData?.[index]?.requirements}
                                            </p>
                                        )}
                                </div>
                                {props.values.boardData?.length > 1 && (
                                    <div className="col-md-2 form-group open-button">
                                        <button
                                            className="btn btn-danger"
                                            type="button"
                                            onClick={() => {
                                                if (window.confirm(`Are you sure you want to delete the Classroom course ${index + 1}`)) {
                                                    arrayHelpers.remove(index)
                                                }
                                            }}
                                        >
                                            Remove Classroom {index + 1}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}

                    <button
                        className="btn btn-primary mb-4"
                        type="button"
                        onClick={() =>
                            arrayHelpers.push({
                                board: "",
                                class: "",
                                section: "",
                                lecture_type: "",
                                otherBoard: "",
                                otherSection: "",
                                regular_price: "",
                                price_type: "",
                                lecture_type: "",
                                is_discount: "",
                                discount_price: "",
                                discount_start_date: "",
                                limited_offer: "",
                                discount_end_date: "",
                                description: "",
                                title: "",
                                thumbnail: "",
                                requirements: "",
                                curriculum: "",
                            })
                        }
                    >
                        Add More Classroom
                    </button>
                </>
            )
            }
        />
    );
}
