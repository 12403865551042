import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import url from "../../../../constants/url";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Moment from "react-moment";
import { replaceWord } from "../../../../utils/utils";
import NoDataAvailable from "../../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const LineSeries = () => {
    const [review, setReview] = useState([]);
    const [achivement, setAchivement] = useState([]);
    const [data, setCourseData] = useState([]);
    const [classData, setclassData] = useState([]);
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const [offset, setOffset] = useState(0);
    const [classoffset, setClassOffset] = useState(0);
    const auth_token = local_storage.auth_token;
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [classsearch, setClassSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [courseCount, setCourseCount] = useState();
    const [classcourseCount, setClassCourseCount] = useState();
    const CardDetails = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}institute/dashboard`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setReview(result?.review);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const getStudentAchivementGetProfiles = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            limit: 10,
            offset: 0,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        fetch(`${url.apiUrl}institute-teacher/get-all-achievements`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setAchivement(result?.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const apiCall = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };

        try {
            const response = await axios.post(
                `${url.apiUrl}institute-course/get-all-online-courses`,
                activity,
                config
            );
            if (response) {
                setCourseData(response.data?.data);
                setCourseCount(response.data?.count);
                setTotalPages(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };
    const getAllCourseData = async () => {
        const activity = {
            status: "all",
            limit: search.perPage,
            offset: search.start,
            query: search.searchTxt,
        };
        await apiCall(activity);
    };
    const handlePageClick = async (data) => {
        let currentPage = data.selected * search.perPage;
        setOffset(currentPage);
        const activity = {
            status: "all",
            limit: search.perPage,
            offset: currentPage,
            query: search.searchTxt,
        };
        await apiCall(activity);
    };

    const getAllClassData = async () => {
        const activity = {
            status: "all",
            limit: classsearch.perPage,
            offset: classsearch.start,
            query: classsearch.searchTxt,
        };
        await apiCallForClass(activity);
    };

    const apiCallForClass = async (activity) => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;
        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}institute-course/get-all-class-courses`,

                activity,
                config
            );
            if (response) {
                setclassData(response.data?.data);
                setClassCourseCount(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };
    const handlePageClickForClass = async (data) => {
        let currentPage = data.selected * classsearch.perPage;
        setClassOffset(currentPage);
        const activity = {
            status: "all",
            limit: classsearch.perPage,
            offset: currentPage,
            query: classsearch.searchTxt,
        };
        await apiCallForClass(activity);
    };

    useEffect(() => {
        getAllCourseData();
    }, [search]);

    useEffect(() => {
        getAllClassData();
    }, [classsearch]);

    useEffect(() => {
        CardDetails();
        getStudentAchivementGetProfiles();
    }, []);

    return (
        <>
            <div className="row match-height">
                <div className="col-md-12">
                    <div className="card">
                        <Tabs defaultActiveKey="about" id="uncontrolled-tab-example">
                            <Tab eventKey="about" title="Review">
                                <div className="h-300 overflow-auto">
                                    {review?.length > 0 ? (
                                        review.map((item, key) => (
                                            <div
                                                className="media mt-0 mb-3 p-4 border br-5"
                                                key={key}
                                            >
                                                <div className="d-flex me-3">
                                                    <img
                                                        className="border-radius-50"
                                                        alt={item?.name}
                                                        src={item?.student?.image}
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h4 className="mt-0 mb-2">
                                                        {item?.name}
                                                        <small className="text-muted float-end">
                                                            <Moment format="DD-MM-YYYY hh:mm A">
                                                                {item?.created_at}
                                                            </Moment>
                                                        </small>
                                                    </h4>
                                                    <div className="d-inline-flex mb-2">
                                                        <div className="br-theme-fontawesome-stars me-2">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.overall_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="mb-2 education-mandal" dangerouslySetInnerHTML={{ __html: item?.comment }}>
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        < NoDataAvailable />
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey="achievement" title="achievement">
                                <div className="h-300 overflow-auto">
                                    {achivement?.length > 0 ? (
                                        achivement?.map((item, key) => (
                                            <div className="card" key={key}>
                                                <div className="card-body">
                                                    <ul className="d-flex mb-0">
                                                        <li>
                                                            <div className="activity1 bg-primary">
                                                                <i className="fe fe-shopping-bag"></i>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="font-weight-semibold">
                                                                {item?.title}
                                                            </div>
                                                        </li>
                                                        <div className="ms-auto text-muted">
                                                            <Moment format="DD-MM-YYYY">
                                                                {item?.date}
                                                            </Moment>
                                                        </div>
                                                    </ul>
                                                    <p className="text-muted mb-0 margin-left1 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <NoDataAvailable />
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <Tabs defaultActiveKey="class" id="uncontrolled-tab-example">
                            <Tab eventKey="class" title="Classroom Course">
                                <div
                                    id="basic-1_wrapper"
                                    className="dataTables_wrapper no-footer"
                                >
                                    <div id="basic-1_filter" className="dataTables_filter">
                                        <label>
                                            Search:
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                                onChange={(e) => {
                                                    setClassSearch({
                                                        ...classsearch,
                                                        searchTxt: e.target.value,
                                                    });
                                                }}
                                            />
                                        </label>
                                    </div>
                                    <table className="table table-bordered table-hover dataTable table-responsive-xxxl">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Sr. No.</th>
                                                <th>Class Details</th>
                                                <th>Branch Details</th>
                                                <th className="text-center">Class Type</th>
                                                <th className="text-center">Board/University</th>
                                                <th className="text-center">Section/Semester</th>
                                                <th className="text-center">Medium</th>
                                                <th className="text-center">Classroom Type</th>
                                                <th className="text-center">VC Link</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Students</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {classData?.length > 0 ? (
                                                classData.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th className="text-center">
                                                                {i + classoffset + 1}
                                                            </th>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.thumbnail} alt={item?.title} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to={"/institute-dashboard/class-detail/1/" + item?.id} className="font-weight-semibold">
                                                                            {item?.title}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={item?.institute_branch?.image} alt={item?.institute_branch?.name} className="me-3 rounded-circle" />
                                                                    <div className="media-body">
                                                                        <Link to={"/institute-dashboard/branch-detail/" + item?.branch_id} className="font-weight-semibold">
                                                                            {item?.institute_branch?.name}
                                                                        </Link>
                                                                        <p className="mb-0 text-muted">{item?.institute_branch?.email}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center text-capitalize">{replaceWord(item?.class_type)} {item?.class_type == "tuition" ? " : " + (replaceWord(item?.tuition_type)) : ""}</td>
                                                            <td className="text-center text-capitalize">{item?.board || item?.universities?.name || '---'}</td>
                                                            <td className="text-center text-capitalize">{item?.section || item?.semester || '---'}</td>
                                                            <td className="text-center text-capitalize">{item?.medium}</td>
                                                            <td className="text-center text-capitalize">{replaceWord(item?.lecture_type)}</td>
                                                            <td className="text-center">
                                                                {item?.video_conference_link ? (
                                                                    <a href={item?.video_conference_link} title="Join Google Meet" className="btn btn-primary" target="_blank" rel="noreferrer">
                                                                        Join Now
                                                                    </a>
                                                                ) : ("---")}
                                                            </td>
                                                            <td className="text-center text-capitalize">
                                                                {item?.status == "pending" && item?.is_submit == 1 ? "Submitted" : item?.status}
                                                            </td>
                                                            <td className="text-capitalize text-center">
                                                                <Link className="btn btn-primary" state={item} title="View Student"
                                                                    to={{pathname: `/institute-dashboard/student-course-class/${item?.type}/${item?.id}`,state: item,}}
                                                                >
                                                                    {item?.student_count}
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan={11}>No Data Available !
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {classData?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={classcourseCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClickForClass}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey="videocourse" title="Video Course">
                                <div id="basic-1_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="basic-1_filter" className="dataTables_filter">
                                        <label>Search:
                                            <input
                                                type="search"
                                                placeholder="Search..."
                                                onChange={(e) => {
                                                    setSearch({
                                                        ...search,
                                                        searchTxt: e.target.value,
                                                    });
                                                }}
                                                value={search.searchTxt}
                                            />
                                        </label>
                                    </div>
                                    <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Sr. No.</th>
                                                <th>Course Details</th>
                                                <th className="text-center">Course Category</th>
                                                <th>Branch Details</th>
                                                <th>Teacher Details</th>
                                                <th className="text-center">Students</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.length > 0 ? (
                                                data.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th className="text-center">{i + offset + 1}</th>
                                                            <td>
                                                                <div className="media">
                                                                    <img
                                                                        src={item?.teaser_thumb_image}
                                                                        alt={item?.title}
                                                                        className="me-3 rounded-circle"
                                                                    />
                                                                    <div className="media-body">
                                                                        <Link className="font-weight-semibold" to={"/institute-dashboard/video-course-detail/1/" + item?.id}>
                                                                            {item?.title}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-capitalize text-center">
                                                                {item?.category?.title}
                                                            </td>
                                                            <td className="text-nowrap">
                                                                <div className="media">
                                                                    <img
                                                                        src={item?.institute_branch?.image}
                                                                        alt={item?.institute_branch?.name}
                                                                        className="me-3 rounded-circle"
                                                                    />
                                                                    <div className="media-body">
                                                                        <Link className="font-weight-semibold"
                                                                            to={`/institute-dashboard/branch-detail/${item?.institute_branch_id}`}
                                                                        >
                                                                            {item?.institute_branch?.name}
                                                                        </Link>
                                                                        <p className="text-muted mb-0">
                                                                            {item?.institute_branch?.email}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="media">
                                                                    <img
                                                                        src={item?.teacher?.image}
                                                                        className="me-3 rounded-circle"
                                                                        alt={item?.teacher?.name}
                                                                    />
                                                                    <div className="media-body">
                                                                        <Link className="font-weight-semibold"
                                                                            to={"/institute-dashboard/teacher-detail/" + item?.teacher_id}
                                                                        >
                                                                            {item?.teacher?.name}
                                                                        </Link>
                                                                        <p className="mb-0 text-muted">
                                                                            {item?.teacher?.email}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-capitalize text-center">
                                                                <Link title="View Student"
                                                                    className="btn btn-primary"
                                                                    to={`/institute-dashboard/student-course-class/${item?.type}/${item?.id}`}
                                                                >
                                                                    {item?.student_count}
                                                                </Link>
                                                            </td>
                                                            <td className="text-capitalize text-center">
                                                                {item?.status}
                                                            </td>
                                                            <td className="text-center">
                                                                <Link title="Edit Video Course"
                                                                    className="btn btn-primary"
                                                                    to={"/institute-dashboard/video-course-edit/1/" + item?.id}
                                                                >
                                                                    <i className="fa fa-pen-to-square fs-16"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td className="text-center" colSpan={9}>
                                                        No Data Available !
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {data?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={Math.ceil(totalPages)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LineSeries;
