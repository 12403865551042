import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    ScheduleComponent,
    ViewsDirective,
    ViewDirective,
    Day,
    Week,
    TimelineViews,
    Inject,
    Resize,
    DragAndDrop,
    Month,
} from "@syncfusion/ej2-react-schedule";
import { Link, useHistory } from "react-router-dom";
import { extend } from "@syncfusion/ej2-base";
import { Formik } from "formik";
import * as Yup from "yup";
import SlotAddFrom from "./SlotAddFrom";
import {
    DatePickerComponent,
    DateTimePickerComponent,
    TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import moment from "moment";
import { getAllSubjects } from "../../../../../apis";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../../../../config/axios";

function applyCategoryColor(args, currentView) {
    var categoryColor = args.data.CategoryColor;
    if (!args.element || !categoryColor) {
        return;
    }
    if (currentView === "Agenda") {
        args.element.firstChild.style.borderLeftColor = categoryColor;
    } else {
        args.element.style.backgroundColor = categoryColor;
    }
}

const onPopupOpen = (args) => {
    if (args.type === "Editor") {
    }
};

const EditorTemplate = ({ propss, props }) => {
    const parentProps = props;


    useEffect(() => {
    }, [propss]);
    return propss !== undefined ? (
        <>
            <div className='row mt-3'>
                <div className='col-md-6'>
                    <DatePicker
                        label='Start Date'
                        format='YYYY-MM-DD'
                        value={moment.utc(parentProps?.values?.start_date, "YYYY-MM-DD")}
                        onChange={(e) =>
                            parentProps.setFieldValue("start_date", e.format("YYYY-MM-DD"))
                        }
                    />
                </div>
                <div className='col-md-6'>
                    <DatePicker
                        label='End Date'
                        format='YYYY-MM-DD'
                        value={moment.utc(parentProps?.values?.end_date, "YYYY-MM-DD")}
                        onChange={(e) =>
                            parentProps.setFieldValue("end_date", e.format("YYYY-MM-DD"))
                        }
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-6'>
                    <TimePicker
                        minutesStep={30}
                        views={["hours", "minutes"]}
                        label='Start Time'
                        value={moment.utc(
                            `${moment().format("YYYY-MM-DD")} ${parentProps?.values?.start_time
                            }`,
                            "YYYY-MM-DD HH:mm"
                        )}
                        onChange={(e) =>
                            parentProps.setFieldValue("start_time", e.format("HH:mm"))
                        }
                    />
                </div>
                <div className='col-md-6'>
                    <TimePicker
                        minutesStep={30}
                        views={["hours", "minutes"]}
                        label='End Time'
                        value={moment.utc(
                            `${moment().format("YYYY-MM-DD")} ${parentProps?.values?.end_time
                            }`,
                            "YYYY-MM-DD HH:mm"
                        )}
                        onChange={(e) =>
                            parentProps.setFieldValue("end_time", e.format("HH:mm"))
                        }
                    />
                </div>
            </div>
        </>
    ) : (
        <div></div>
    );
};

const fieldsData = {
};

const SlotAdd = () => {
    let scheduleObj = useRef(null);
    const [loader, setLoader] = useState(false);
    const onEventRendered = (args) => {
        applyCategoryColor(args, scheduleObj.current.currentView);
    };
    const history = useHistory();
    const [timeslots, setTimeslots] = useState([]);
    const [values, setValues] = useState({
        boardSelected: "",
        branchSelected: "",
        lectureSelected: "",
        tuitionType: "",
        universitySelected: "",
        classSelected: "",
        semesterSelected: "",
        teachers: "",
        sectionsSelected: "",
        subjectSelected: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        slot_type: "",
        time: ""
    });
    const ValidateSchema = Yup.object().shape({
        branchSelected: Yup.string().required("Required"),
        lectureSelected: Yup.string().required("Required"),
        boardSelected: Yup.string().when(["lectureSelected", "tuitionType"], {
            is: (lectureSelected, tuitionType) =>
                (lectureSelected && lectureSelected === "school") ||
                (lectureSelected === "tuition" && tuitionType === "school"),
            then: Yup.string().required("Required"),
        }),
        classSelected: Yup.string().required("Required"),
        sectionsSelected: Yup.string().when(["lectureSelected"], {
            is: (lectureSelected, tuitionType) => {
                return (
                    (lectureSelected && lectureSelected === "school") ||
                    (lectureSelected === "tuition" && tuitionType === "school")
                );
            },
            then: Yup.string().required("Required"),
        }),
        semesterSelected: Yup.string().when(["lectureSelected", "tuitionType"], {
            is: (lectureSelected, tuitionType) =>
                (lectureSelected && lectureSelected === "university") ||
                (lectureSelected === "tuition" && tuitionType === "university"),
            then: Yup.string().required("Semester is required"),
        }),
        tuitionType: Yup.string().when("lectureSelected", {
            is: (lectureSelected) => lectureSelected && lectureSelected === "tuition",
            then: Yup.string().required("Required"),
        }),
        universitySelected: Yup.string().when(["lectureSelected", "tuitionType"], {
            is: (lectureSelected, tuitionType) =>
                (lectureSelected && lectureSelected === "university") ||
                (lectureSelected === "tuition" && tuitionType === "university"),
            then: Yup.string().required("Required"),
        }),
        subjectSelected: Yup.string().required("Required"),
        teachers: Yup.string().required("Required"),
        start_date: Yup.date().required("Required"),
        end_date: Yup.date().required("End date is required"),

        slot_type: Yup.string().required("Required"),
        time: Yup.mixed()
            .when('slot_type', {
                is: ((slot_type) => slot_type && slot_type === "daily" || slot_type === "week_day" || slot_type === "week_end"),
                then: Yup.array().of(
                    Yup.object().shape({
                        start_time: Yup.string().required('Required'),
                        end_time: Yup.string().when('start_time', {
                            is: (start_time) => !!start_time,
                            then: Yup.string()
                                .required('Required')
                                .test({
                                    name: 'is-greater',
                                    message: 'End time must be greater than start time',
                                    test: function (end_time) {
                                        const start_time = this.resolve(Yup.ref('start_time'));
                                        return !start_time || !end_time || end_time > start_time;
                                    },
                                }),
                            otherwise: Yup.string(),
                        })

                    })
                ),
                otherwise: Yup.object().shape({
                    is_monday: Yup.string().required('Required'),
                    is_tuesday: Yup.string().required('Required'),
                    is_wednesday: Yup.string().required('Required'),
                    is_thursday: Yup.string().required('Required'),
                    is_friday: Yup.string().required('Required'),
                    is_saturday: Yup.string().required('Required'),
                    is_sunday: Yup.string().required('Required'),
                    monday: Yup.array().when('is_monday', {
                        is: '1',
                        then: Yup.array().of(
                            Yup.object().shape({
                                start_time: Yup.string().required('Required'),
                                end_time: Yup.string().when('start_time', {
                                    is: (start_time) => !!start_time,
                                    then: Yup.string()
                                        .required('Required')
                                        .test({
                                            name: 'is-greater',
                                            message: 'End time must be greater than start time',
                                            test: function (end_time) {
                                                const start_time = this.resolve(Yup.ref('start_time'));
                                                return !start_time || !end_time || end_time > start_time;
                                            },
                                        }),
                                    otherwise: Yup.string(),
                                })
                            })
                        ),
                        otherwise: Yup.array(),
                    }),
                    tuesday: Yup.array().when('is_tuesday', {
                        is: '1',
                        then: Yup.array().of(
                            Yup.object().shape({
                                start_time: Yup.string().required('Required'),
                                end_time: Yup.string().when('start_time', {
                                    is: (start_time) => !!start_time,
                                    then: Yup.string()
                                        .required('Required')
                                        .test({
                                            name: 'is-greater',
                                            message: 'End time must be greater than start time',
                                            test: function (end_time) {
                                                const start_time = this.resolve(Yup.ref('start_time'));
                                                return !start_time || !end_time || end_time > start_time;
                                            },
                                        }),
                                    otherwise: Yup.string(),
                                })
                            })
                        ),
                        otherwise: Yup.array(),
                    }),
                    wednesday: Yup.array().when('is_wednesday', {
                        is: '1',
                        then: Yup.array().of(
                            Yup.object().shape({
                                start_time: Yup.string().required('Required'),
                                end_time: Yup.string().when('start_time', {
                                    is: (start_time) => !!start_time,
                                    then: Yup.string()
                                        .required('Required')
                                        .test({
                                            name: 'is-greater',
                                            message: 'End time must be greater than start time',
                                            test: function (end_time) {
                                                const start_time = this.resolve(Yup.ref('start_time'));
                                                return !start_time || !end_time || end_time > start_time;
                                            },
                                        }),
                                    otherwise: Yup.string(),
                                })
                            })
                        ),
                        otherwise: Yup.array(),
                    }),
                    thursday: Yup.array().when('is_thursday', {
                        is: '1',
                        then: Yup.array().of(
                            Yup.object().shape({
                                start_time: Yup.string().required('Required'),
                                end_time: Yup.string().when('start_time', {
                                    is: (start_time) => !!start_time,
                                    then: Yup.string()
                                        .required('Required')
                                        .test({
                                            name: 'is-greater',
                                            message: 'End time must be greater than start time',
                                            test: function (end_time) {
                                                const start_time = this.resolve(Yup.ref('start_time'));
                                                return !start_time || !end_time || end_time > start_time;
                                            },
                                        }),
                                    otherwise: Yup.string(),
                                })
                            })
                        ),
                        otherwise: Yup.array(),
                    }),
                    friday: Yup.array().when('is_friday', {
                        is: '1',
                        then: Yup.array().of(
                            Yup.object().shape({
                                start_time: Yup.string().required('Required'),
                                end_time: Yup.string().when('start_time', {
                                    is: (start_time) => !!start_time,
                                    then: Yup.string()
                                        .required('Required')
                                        .test({
                                            name: 'is-greater',
                                            message: 'End time must be greater than start time',
                                            test: function (end_time) {
                                                const start_time = this.resolve(Yup.ref('start_time'));
                                                return !start_time || !end_time || end_time > start_time;
                                            },
                                        }),
                                    otherwise: Yup.string(),
                                })
                            })
                        ),
                        otherwise: Yup.array(),
                    }),
                    saturday: Yup.array().when('is_saturday', {
                        is: '1',
                        then: Yup.array().of(
                            Yup.object().shape({
                                start_time: Yup.string().required('Required'),
                                end_time: Yup.string().when('start_time', {
                                    is: (start_time) => !!start_time,
                                    then: Yup.string()
                                        .required('Required')
                                        .test({
                                            name: 'is-greater',
                                            message: 'End time must be greater than start time',
                                            test: function (end_time) {
                                                const start_time = this.resolve(Yup.ref('start_time'));
                                                return !start_time || !end_time || end_time > start_time;
                                            },
                                        }),
                                    otherwise: Yup.string(),
                                })
                            })
                        ),
                        otherwise: Yup.array(),
                    }),
                    sunday: Yup.array().when('is_sunday', {
                        is: '1',
                        then: Yup.array().of(
                            Yup.object().shape({
                                start_time: Yup.string().required('Required'),
                                end_time: Yup.string().when('start_time', {
                                    is: (start_time) => !!start_time,
                                    then: Yup.string()
                                        .required('Required')
                                        .test({
                                            name: 'is-greater',
                                            message: 'End time must be greater than start time',
                                            test: function (end_time) {
                                                const start_time = this.resolve(Yup.ref('start_time'));
                                                return !start_time || !end_time || end_time > start_time;
                                            },
                                        }),
                                    otherwise: Yup.string(),
                                })
                            })
                        ),
                        otherwise: Yup.array(),
                    }),
                }),

            }),
    });
    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    const data = extend(
        [],
        timeslots?.map((item) => ({
            ...item,
            Subject: item.subject,
            StartTime: moment(
                `${item?.start_date} ${item?.start_time}`,
                "YYYY-MM-DD HH:mm:ss"
            ).toDate(),
            EndTime: moment(
                `${item?.end_date} ${item?.end_time}`,
                "YYYY-MM-DD HH:mm:ss"
            ).toDate(),
        })),
        null,
        true
    );
    const handlePopupOpen = (e, props) => {
        if (e.type === "Editor") {
            props.setFieldValue(
                "start_date",
                moment(e.data.StartTime).format("YYYY-MM-DD")
            );
            props.setFieldValue(
                "end_date",
                moment(e.data.EndTime).format("YYYY-MM-DD")
            );
            props.setFieldValue(
                "start_time",
                moment(e.data.StartTime).format("HH:mm")
            );
            props.setFieldValue("end_time", moment(e.data.EndTime).format("HH:mm"));
        }
    };

    const handleActions = (e, props) => {
        if (e.requestType === "eventCreated") {
            props.submitForm();
        }
    };
    const submitSlot = async (value) => {
        try {
            setLoader(true);
            var data = {
                branch_id: value?.branchSelected,
                class_type: value?.lectureSelected,
                class_name: value?.classSelected,
                board: value?.boardSelected,
                section: value?.sectionsSelected,
                university: value?.universitySelected,
                semester: value?.semesterSelected,
                subject: value?.subjectSelected,
                start_date: value?.start_date,
                end_date: value?.end_date,
                start_time: value?.start_time,
                end_time: value?.end_time,
                teacher_id: value?.teachers,
                tuition_type: value?.tuitionType,
                slot_type: value?.slot_type,
                time: value?.time,
            };
            const response = await axios.post("institute/create-class-slot", data, {
                headers: { AUTHTOKEN: auth_token },
            });
            if (response.status === "success") {
                setLoader(false);
                toast.success("Slot Added Successfully");
                setTimeout(() => {
                    history.push("/institute-dashboard/slot");
                }, 5000);
            } else {
                setLoader(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message);
        } finally {
            setLoader(false);
        }
    };
    return (
        <>
            <ToastContainer />

            <div className='page-header'>
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard'>Dashboard</Link>
                    </li>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard/slot'>Time Slot</Link>
                    </li>
                    <li className='breadcrumb-item active'>Add Time Slot</li>
                </ol>
            </div>

            <div className='row'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4 className='card-title'>Add Time Slot</h4>
                        </div>
                        <div className='card-body'>
                            <Formik
                                initialValues={values}
                                onSubmit={submitSlot}
                                enableReinitialize={true}
                                validationSchema={ValidateSchema}
                            >
                                {(props) => {
                                    return (
                                        <form
                                            onSubmit={props.handleSubmit}
                                            timeslots={timeslots}
                                            setTimeslots={setTimeslots}
                                        >
                                            <div className='row'>
                                                <SlotAddFrom
                                                    props={props}
                                                    timeslots={timeslots}
                                                    setTimeslots={setTimeslots}
                                                    loader={loader}
                                                />
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SlotAdd;
