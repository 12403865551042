import * as Yup from "yup";


// About Validation
export const aboutSchema = Yup.object().shape({
    about: Yup.string()
        .min(6, "Please write More than 6 characters !")
        .max(500, "Please write less than 500 characters !")
        .required("Required"),
});


