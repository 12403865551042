import axios from "axios";
import url from "../constants/url";
const instance = axios.create({
    baseURL: url.apiUrl,
    timeout: 30000,
   
});

instance?.interceptors.request.use(function (config) {
    if (!!config.data) {
        for (let key of Object.keys(config?.data)) {
          
            if (config.data[key] === "" || config.data[key] === undefined)
                config.data[key] = null
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

instance?.interceptors.response.use(function (response) {
    if (response.status === 200) {
        return response.data;
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default instance;
