import React, { useState, useEffect } from 'react';
import url from '../../../../constants/url';

const CardStatistics = () => {
    const [data, setData] = useState([])
    const local_data = localStorage.getItem('common-user-info');
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token

    const getDashboardData = () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}teacher/dashboard`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result?.status === "success") {
                    setData(result?.data)
                }
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getDashboardData()
    }, [])

    return (
        <>
            <div className="row match-height">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="card">
                        <div className="card-body text-center">
                            <div className="dash3-counter d-flex">
                                <div className="counter-icon bg-primary-dark my-auto br-100">
                                    <i className="fa-solid fa-calendar-week leading-loose"></i>
                                </div>
                                <div className="ms-auto">
                                    <h5 className="mb-0">Total Sales</h5>
                                    <h2 className="counter mb-0">{Math.round(data?.sales) || 0}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="card">
                        <div className="card-body text-center">
                            <div className="dash3-counter d-flex">
                                <div className="counter-icon bg-primary-dark my-auto br-100">
                                    <i className="fa-solid fa-calendar-week leading-loose"></i>
                                </div>
                                <div className="ms-auto">
                                    <h5 className="mb-0">Total Student</h5>
                                    <h2 className="counter mb-0">{Math.round(data?.student) || 0}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="card">
                        <div className="card-body text-center">
                            <div className="dash3-counter d-flex">
                                <div className="counter-icon bg-primary-dark my-auto br-100">
                                    <i className="fa-solid fa-calendar-week leading-loose"></i>
                                </div>
                                <div className="ms-auto">
                                    <h5 className="mb-0">Total Revenue</h5>
                                    <h2 className="counter mb-0">{Math.round(data?.revenue) || 0}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="card">
                        <div className="card-body text-center">
                            <div className="dash3-counter d-flex">
                                <div className="counter-icon bg-primary-dark my-auto br-100">
                                    <i className="fa-solid fa-calendar-week leading-loose"></i>
                                </div>
                                <div className="ms-auto">
                                    <h5 className="mb-0">Total Count</h5>
                                    <h2 className="counter mb-0">{Math.round(data?.count) || 0}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CardStatistics;