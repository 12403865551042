import { ErrorMessage, Field, FieldArray } from "formik";
import moment from "moment";
import Moment from "react-moment";
import React, { useState } from "react";

const TimeSlotFilterComponents = ({ props }) => {
    const {
        values: { slot_type, start_date, end_date, start_time, end_time },
        setFieldValue,
    } = props;

    const [inputs, setInputs] = useState([]);
    const handleFilter = (str) => {
        if (str === "daily" || str === "week_day" || str === "week_end") {
            setFieldValue(`time`, [{ start_time: "", end_time: "" }]);
        } else if (str === "custom") {
            setFieldValue(`time`, {
                is_monday: 0,
                is_tuesday: 0,
                is_wednesday: 0,
                is_thursday: 0,
                is_friday: 0,
                is_saturday: 0,
                is_sunday: 0,
                monday: [
                    {
                        start_time: "",
                        end_time: "",
                    },
                ],
                tuesday: [
                    {
                        start_time: "",
                        end_time: "",
                    },
                ],
                wednesday: [
                    {
                        start_time: "",
                        end_time: "",
                    },
                ],
                thursday: [
                    {
                        start_time: "",
                        end_time: "",
                    },
                ],
                friday: [
                    {
                        start_time: "",
                        end_time: "",
                    },
                ],
                saturday: [
                    {
                        start_time: "",
                        end_time: "",
                    },
                ],
                sunday: [
                    {
                        start_time: "",
                        end_time: "",
                    },
                ],
            });
        }
    };

    return (
        <>
            {start_date && end_date && (
                <div className="card-header d-flex justify-content-between">
                    <h4 className="card-title">
                        From  <Moment format="DD-MM-YYYY">{start_date}</Moment> to <Moment format="DD-MM-YYYY">{end_date}</Moment>
                    </h4>
                </div>
            )}
            <div className="card-body">
                <div className='row'>
                    <div className='col-md-4 form-group'>
                        <label> Slot Type <span className='text-danger'>*</span></label>
                        <select
                            className='form-select'
                            name='slot_type'
                            onChange={(e) => {
                                setFieldValue(`slot_type`, e.target.value);
                                handleFilter(e.target.value);
                            }}
                        >
                            <option value=''>Select slot type</option>
                            <option value='daily'>Daily</option>
                            <option value='week_day'>Every Weekday (Monday to Friday)</option>
                            <option value='week_end'>Weekend (Saturday to Sunday)</option>
                            <option value='custom'> Custom  </option>
                        </select>
                        {props.errors.slot_type && (
                            <p className='text-danger mb-0'>{props.errors.slot_type}</p>
                        )}
                    </div>
                    <div className='col-md-8 form-group'>
                        {(slot_type === "daily" ||
                            slot_type === "week_day" ||
                            slot_type === "week_end") && (
                                <>
                                    <FieldArray name='time'>
                                        {({ push, remove }) => (
                                            <>
                                                {props.values.time.map((time, index) => (
                                                    <div className="row" key={index}>
                                                        <div className='col-md-3 form-group'>
                                                            <label htmlFor='start-date11' className='form-label'>
                                                                Start Time <span className='text-danger'>*</span>
                                                            </label>
                                                            <Field
                                                                name={`time[${index}].start_time`}
                                                                as={CustomInputComponent}
                                                                id='start_time'
                                                                placeholder='Start time'
                                                                required
                                                            />
                                                            {props.errors.time?.[index]?.start_time &&
                                                                props.touched.time?.[index]?.start_time && (
                                                                    <p className="bg-error">
                                                                        {props.errors.time?.[index]?.start_time}
                                                                    </p>
                                                                )}
                                                        </div>
                                                        <div className='col-md-3 form-group'>
                                                            <label htmlFor='start-date11' className='form-label'>
                                                                End Time <span className='text-danger'>*</span>
                                                            </label>
                                                            <Field
                                                                name={`time[${index}].end_time`}
                                                                as={CustomInputComponent}
                                                                id='end_time'
                                                                placeholder='End time'
                                                                required
                                                            />
                                                            {props.errors.time?.[index]?.end_time &&
                                                                props.touched.time?.[index]?.end_time && (
                                                                    <p className="bg-error">
                                                                        {props.errors.time?.[index]?.end_time}
                                                                    </p>
                                                                )}
                                                        </div>
                                                        <div className='col-md-3 form-group open-button'>
                                                            <button type='button' className="btn btn-danger" onClick={() => remove(index)}>
                                                                Remove
                                                            </button>
                                                        </div>


                                                    </div>
                                                ))}
                                                <button
                                                    type='button' className="btn btn-primary"
                                                    onClick={() => push({ start_time: "", end_time: "" })}
                                                >
                                                    Add Time
                                                </button>
                                            </>
                                        )}
                                    </FieldArray>
                                </>
                            )}

                    </div>
                </div>
                {slot_type === "custom" && <DaysComponent {...props} />}
            </div>
        </>
    );
};

export default TimeSlotFilterComponents;
const CustomInputComponent = (props) => (
    <input type='time' className='form-control' {...props} />
);
export function DaysComponent(props) {
    const {
        is_monday,
        is_tuesday,
        is_wednesday,
        is_thursday,
        is_friday,
        is_saturday,
        is_sunday,
    } = props?.values?.time;

    function handleChange(fieldName, eventCheck) {
        if (eventCheck) {
            props.setFieldValue(`time.${fieldName}`, 1);
        } else {
            props.setFieldValue(`time.${fieldName}`, 0);
        }
    }
    function handleCheck(isTrue) {
        if (isTrue === 1) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <>
            {props && props?.values?.time && (
                <React.Fragment>
                    <div className='row'>
                        <div className='col-md-3'>
                            <label>
                                Days
                            </label>
                            <div className='form-check form-check-inline'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='is_monday'
                                    name='is_monday'
                                    value={is_monday}
                                    onChange={(e) => {
                                        handleChange("is_monday", e.target.checked);
                                    }}
                                />
                                <label className='form-check-label' htmlFor='all_day'>
                                    Monday
                                </label>
                            </div>
                            {props.errors.time?.is_monday &&
                                props.touched.time?.is_monday && (
                                    <p className="bg-error">
                                        {props.errors.time?.is_monday}
                                    </p>
                                )}
                        </div>
                        <div className='col-md-9'>
                            {handleCheck(is_monday) && (
                                <FieldArray name={`time.monday`}>
                                    {({ push, remove }) => (
                                        <div>
                                            {props.values.time.monday.map((time, index) => (
                                                <div className="row" key={index}>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            Start Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.monday[${index}].start_time`}
                                                            as={CustomInputComponent}
                                                            id='start_time'
                                                            placeholder='Start time'
                                                            required
                                                        />
                                                        {props?.errors?.time?.monday?.[index]?.start_time &&
                                                            props?.touched?.time?.monday?.[index]?.start_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.monday?.[index]?.start_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            End Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.monday[${index}].end_time`}
                                                            as={CustomInputComponent}
                                                            id='end_time'
                                                            placeholder='End time'
                                                            required
                                                        />
                                                        {props?.errors?.time?.monday?.[index]?.end_time &&
                                                            props?.touched?.time?.monday?.[index]?.end_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.monday?.[index]?.end_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group open-button'>
                                                        <button type='button' className="btn btn-danger" onClick={() => remove(index)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                type='button' className="btn btn-primary"
                                                onClick={() => push({ start_time: "", end_time: "" })}
                                            >
                                                Add Time
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-3'>
                            <label>
                                Days
                            </label>
                            <div className='form-check form-check-inline'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='is_tuesday'
                                    name='is_tuesday'
                                    value={is_tuesday}
                                    onChange={(e) => {
                                        handleChange("is_tuesday", e.target.checked);
                                    }}
                                />
                                <label className='form-check-label' htmlFor='all_day'>
                                    Tuesday
                                </label>
                            </div>
                            {props.errors.time?.is_tuesday &&
                                props.touched.time?.is_tuesday && (
                                    <p className="bg-error">
                                        {props.errors.time?.is_tuesday}
                                    </p>
                                )}
                        </div>

                        <div className='col-md-9'>
                            {handleCheck(is_tuesday) && (
                                <FieldArray name={`time.tuesday`}>
                                    {({ push, remove }) => (
                                        <div>
                                            {props.values.time.tuesday.map((time, index) => (
                                                <div className="row" key={index}>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            Start Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.tuesday[${index}].start_time`}
                                                            as={CustomInputComponent}
                                                            id='start_time'
                                                            placeholder='Start time'
                                                        />
                                                        {props?.errors?.time?.tuesday?.[index]?.start_time &&
                                                            props?.touched?.time?.tuesday?.[index]?.start_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.tuesday?.[index]?.start_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            End Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.tuesday[${index}].end_time`}
                                                            as={CustomInputComponent}
                                                            id='end_time'
                                                            placeholder='End time'
                                                        />
                                                        {props?.errors?.time?.tuesday?.[index]?.start_time &&
                                                            props?.touched?.time?.tuesday?.[index]?.end_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.tuesday?.[index]?.start_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group open-button'>
                                                        <button type='button' className="btn btn-danger" onClick={() => remove(index)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                type='button' className="btn btn-primary"
                                                onClick={() => push({ start_time: "", end_time: "" })}
                                            >
                                                Add Time
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-3'>
                            <label>
                                Days
                            </label>
                            <div className='form-check form-check-inline'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='is_wednesday'
                                    name='is_wednesday'
                                    value={is_wednesday}
                                    onChange={(e) => {
                                        handleChange("is_wednesday", e.target.checked);
                                    }}
                                />
                                <label className='form-check-label' htmlFor='all_day'>
                                    Wednesday
                                </label>
                            </div>
                            {props.errors.time?.is_wednesday &&
                                props.touched.time?.is_wednesday && (
                                    <p className="bg-error">
                                        {props.errors.time?.is_wednesday}
                                    </p>
                                )}
                        </div>
                        <div className='col-md-9'>
                            {handleCheck(is_wednesday) && (
                                <FieldArray name={`time.wednesday`}>
                                    {({ push, remove }) => (
                                        <div>
                                            {props.values.time.wednesday.map((time, index) => (
                                                <div className="row" key={index}>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            Start Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.wednesday[${index}].start_time`}
                                                            as={CustomInputComponent}
                                                            id='start_time'
                                                            placeholder='Start time'
                                                        />
                                                        {props?.errors?.time?.wednesday?.[index]?.start_time &&
                                                            props?.touched?.time?.wednesday?.[index]?.start_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.wednesday?.[index]?.start_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            End Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.wednesday[${index}].end_time`}
                                                            as={CustomInputComponent}
                                                            id='end_time'
                                                            placeholder='End time'
                                                        />
                                                        {props?.errors?.time?.wednesday?.[index]?.end_time &&
                                                            props?.touched?.time?.wednesday?.[index]?.end_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.wednesday?.[index]?.end_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group open-button'>
                                                        <button type='button' className="btn btn-danger" onClick={() => remove(index)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                type='button' className="btn btn-primary"
                                                onClick={() => push({ start_time: "", end_time: "" })}
                                            >
                                                Add Time
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-3'>
                            <label>
                                Days
                            </label>
                            <div className='form-check form-check-inline'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='is_thursday'
                                    name='is_thursday'
                                    value={is_thursday}
                                    onChange={(e) => {
                                        handleChange("is_thursday", e.target.checked);
                                    }}
                                />
                                <label className='form-check-label' htmlFor='all_day'>
                                    Thursday
                                </label>
                            </div>
                            {props.errors.time?.is_thursday &&
                                props.touched.time?.is_thursday && (
                                    <p className="bg-error">
                                        {props.errors.time?.is_thursday}
                                    </p>
                                )}
                        </div>
                        <div className='col-md-9'>
                            {handleCheck(is_thursday) && (
                                <FieldArray name={`time.thursday`}>
                                    {({ push, remove }) => (
                                        <div>
                                            {props.values.time.thursday.map((time, index) => (
                                                <div className="row" key={index}>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            Start Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.thursday[${index}].start_time`}
                                                            as={CustomInputComponent}
                                                            id='start_time'
                                                            placeholder='Start time'
                                                        />
                                                        {props?.errors?.time?.thursday?.[index]?.start_time &&
                                                            props?.touched?.time?.thursday?.[index]?.start_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.thursday?.[index]?.start_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            End Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.thursday[${index}].end_time`}
                                                            as={CustomInputComponent}
                                                            id='end_time'
                                                            placeholder='End time'
                                                        />
                                                        {props?.errors?.time?.thursday?.[index]?.end_time &&
                                                            props?.touched?.time?.thursday?.[index]?.end_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.thursday?.[index]?.end_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group open-button'>
                                                        <button type='button' className="btn btn-danger" onClick={() => remove(index)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                type='button' className="btn btn-primary"
                                                onClick={() => push({ start_time: "", end_time: "" })}
                                            >
                                                Add Time
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-3'>
                            <label>
                                Days
                            </label>
                            <div className='form-check form-check-inline'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='is_friday'
                                    name='is_friday'
                                    value={is_friday}
                                    onChange={(e) => {
                                        handleChange("is_friday", e.target.checked);
                                    }}
                                />
                                <label className='form-check-label' htmlFor='all_day'>
                                    Friday
                                </label>
                            </div>
                            {props.errors.time?.is_friday &&
                                props.touched.time?.is_friday && (
                                    <p className="bg-error">
                                        {props.errors.time?.is_friday}
                                    </p>
                                )}
                        </div>
                        <div className='col-md-9'>
                            {handleCheck(is_friday) && (
                                <FieldArray name={`time.friday`}>
                                    {({ push, remove }) => (
                                        <div>
                                            {props.values.time.friday.map((time, index) => (
                                                <div className="row" key={index}>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            Start Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.friday[${index}].start_time`}
                                                            as={CustomInputComponent}
                                                            id='start_time'
                                                            placeholder='Start time'
                                                        />
                                                        {props?.errors?.time?.friday?.[index]?.start_time &&
                                                            props?.touched?.time?.friday?.[index]?.start_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.friday?.[index]?.start_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            End Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.friday[${index}].end_time`}
                                                            as={CustomInputComponent}
                                                            id='end_time'
                                                            placeholder='End time'
                                                        />
                                                        {props?.errors?.time?.friday?.[index]?.end_time &&
                                                            props?.touched?.time?.friday?.[index]?.end_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.friday?.[index]?.end_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group open-button'>
                                                        <button type='button' className="btn btn-danger" onClick={() => remove(index)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                type='button' className="btn btn-primary"
                                                onClick={() => push({ start_time: "", end_time: "" })}
                                            >
                                                Add Time
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-3'>
                            <label>
                                Days
                            </label>
                            <div className='form-check form-check-inline'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='is_saturday'
                                    name='is_saturday'
                                    value={is_saturday}
                                    onChange={(e) => {
                                        handleChange("is_saturday", e.target.checked);
                                    }}
                                />
                                <label className='form-check-label' htmlFor='all_day'>
                                    Saturday
                                </label>
                            </div>
                            {props.errors.time?.is_saturday &&
                                props.touched.time?.is_saturday && (
                                    <p className="bg-error">
                                        {props.errors.time?.is_saturday}
                                    </p>
                                )}
                        </div>
                        <div className='col-md-9'>
                            {handleCheck(is_saturday) && (
                                <FieldArray name={`time.saturday`}>
                                    {({ push, remove }) => (
                                        <div>
                                            {props.values.time.saturday.map((time, index) => (
                                                <div className="row" key={index}>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            Start Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.saturday[${index}].start_time`}
                                                            as={CustomInputComponent}
                                                            id='start_time'
                                                            placeholder='Start time'
                                                        />
                                                        {props?.errors?.time?.saturday?.[index]?.start_time &&
                                                            props?.touched?.time?.saturday?.[index]?.start_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.saturday?.[index]?.start_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            End Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.saturday[${index}].end_time`}
                                                            as={CustomInputComponent}
                                                            id='end_time'
                                                            placeholder='End time'
                                                        />
                                                        {props?.errors?.time?.saturday?.[index]?.end_time &&
                                                            props?.touched?.time?.saturday?.[index]?.end_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.saturday?.[index]?.end_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group open-button'>
                                                        <button type='button' className="btn btn-danger" onClick={() => remove(index)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                type='button' className="btn btn-primary"
                                                onClick={() => push({ start_time: "", end_time: "" })}
                                            >
                                                Add Time
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-3'>
                            <label>
                                Days
                            </label>
                            <div className='form-check form-check-inline'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='is_sunday'
                                    name='is_sunday'
                                    value={is_sunday}
                                    onChange={(e) => {
                                        handleChange("is_sunday", e.target.checked);
                                    }}
                                />
                                <label className='form-check-label' htmlFor='all_day'>
                                    Sunday
                                </label>
                            </div>
                            {props.errors.time?.is_sunday &&
                                props.touched.time?.is_sunday && (
                                    <p className="bg-error">
                                        {props.errors.time?.is_sunday}
                                    </p>
                                )}
                        </div>
                        <div className='col-md-9'>
                            {handleCheck(is_sunday) && (
                                <FieldArray name={`time.sunday`}>
                                    {({ push, remove }) => (
                                        <div>
                                            {props.values.time.sunday.map((time, index) => (
                                                <div className="row" key={index}>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            Start Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.sunday[${index}].start_time`}
                                                            as={CustomInputComponent}
                                                            id='start_time'
                                                            placeholder='Start time'
                                                        />
                                                        {props?.errors?.time?.sunday?.[index]?.start_time &&
                                                            props?.touched?.time?.sunday?.[index]?.start_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.sunday?.[index]?.start_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group'>
                                                        <label htmlFor='start-date11' className='form-label'>
                                                            End Time <span className='text-danger'>*</span>
                                                        </label>
                                                        <Field
                                                            name={`time.sunday[${index}].end_time`}
                                                            as={CustomInputComponent}
                                                            id='end_time'
                                                            placeholder='End time'
                                                        />
                                                        {props?.errors?.time?.sunday?.end_time &&
                                                            props?.touched?.time?.sunday?.end_time && (
                                                                <p className="bg-error">
                                                                    {props?.errors?.time?.sunday?.end_time}
                                                                </p>
                                                            )}
                                                    </div>
                                                    <div className='col-md-3 form-group open-button'>
                                                        <button type='button' className="btn btn-danger" onClick={() => remove(index)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                            <button
                                                type='button' className="btn btn-primary"
                                                onClick={() => push({ start_time: "", end_time: "" })}
                                            >
                                                Add Time
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </>
    );
}
