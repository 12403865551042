import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { replaceWord } from "../../../utils/utils";
import NoDataAvailable from "../../common/NoDataAvailable";
let rating = [1, 2, 3, 4, 5];

const ComingUpClasses = (props) => {
    const history = useHistory();
    const [data, setClassData] = useState(props.data);
    let now = new Date().setHours(0, 0, 0, 0);

    useEffect(() => {
        if (props.data && props.data) setClassData(props.data);
    }, [props.data]);

    const [UIValues, setUIValues] = useState({});
    const [selectedValue, setSelectedValue] = useState([]);
    const handleChangeGen = (event) => {
        UIValues[event.target.name] = event.target.value;
        setUIValues((state) => UIValues);
    };

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    return (
        <>
            <section className="sptb">
                <div className="container">
                    <div className="section-title">
                        <h2>Up Coming Classroom Courses</h2>
                        <div className="ms-auto">
                            <Link className="btn btn-primary" to="/classroom-course">
                                View More <i className="fe fe-arrow-right" />
                            </Link>
                        </div>
                    </div>
                    <div className="match-height">
                        {data?.length >= 3 ? (
                            <Slider {...settings}>
                                {data?.map((item, i) => (
                                    <div className="col-md-12" key={i}>
                                        <Link to={`/classroom-course-detail/${item?.id}`}>
                                            <div className="card">
                                                <div className="card-body ">
                                                    <div className="item-card7-img">
                                                        <img src={item?.thumbnail} alt={item?.title} className="cover-image" />
                                                        {(
                                                            item?.price == "paid" && (
                                                                item?.is_discount == 1 && (
                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                            )
                                                        ) && (
                                                                <Link to="#" className="bg-danger top left">
                                                                    {item?.discount_price}% Off
                                                                </Link>
                                                            )}
                                                        <Link to="#" className="bg-danger top right">
                                                            Classroom Course
                                                        </Link>
                                                        {item?.class_type === "school" ? (
                                                            <Link to="#" className="bg-danger right bottom">
                                                                {item?.board}
                                                            </Link>
                                                        ) : (item?.class_type === "university" ? (
                                                            <Link to="#" className="bg-danger right bottom">
                                                                {item?.universities?.name}
                                                            </Link>
                                                        ) : (item?.class_type === "tuition" && (
                                                            <>
                                                                {item?.tuition_type === "school" ? (
                                                                    <Link to="#" className="bg-danger right bottom">
                                                                        {item?.board}
                                                                    </Link>
                                                                ) : (item?.tuition_type === "university" && (
                                                                    <Link to="#" className="bg-danger right bottom">
                                                                        {item?.universities?.name}
                                                                    </Link>
                                                                ))}
                                                            </>
                                                        )))}
                                                    </div>
                                                    <div className="item-card2-desc">
                                                        <div className="d-inline-flex mb-2">
                                                            <div className="br-widget">
                                                                {rating.map((el, i) => (
                                                                    <i
                                                                        data-rating-value={el}
                                                                        data-rating-text={el}
                                                                        className={
                                                                            el <= item?.average_review
                                                                                ? "br-selected"
                                                                                : ""
                                                                        }
                                                                        key={i}
                                                                    />
                                                                ))}
                                                            </div>
                                                            <span>({item?.review_count} Reviews)</span>
                                                        </div>
                                                        <h4 className="mb-2 leading-tight2">
                                                            {item?.title}
                                                        </h4>
                                                        <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }}>
                                                        </p>
                                                        <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                            <div className="d-flex justify-content-start">
                                                                <i className="fa fa-shop me-2" />
                                                                <span className="text-muted text-data-over-cut">
                                                                    {item?.institute?.name}
                                                                </span>
                                                            </div>
                                                            <div className="float-end d-flex justify-content-start">
                                                                <i className="fa fa-building me-2" />
                                                                <span className="text-muted text-capitalize text-data-over-cut">
                                                                    {replaceWord(item?.class_type)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="pt-2">
                                                            <i className="fa fa-file me-2" />
                                                            <span className="text-muted">
                                                                {replaceWord(item?.lecture_type)}
                                                            </span>
                                                            <div className="float-end">
                                                                {item?.price === "paid" && (
                                                                    <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)} {(
                                                                        item?.price == "paid" && (
                                                                            item?.is_discount == 1 && (
                                                                                (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                        )
                                                                    ) && (<del className="fs-14 text-muted">₹ {Math.round(item?.regular_price)} </del>
                                                                        )}
                                                                    </h4>
                                                                )}
                                                                {item?.price === "free" && (
                                                                    <h4 className="mb-0">Free</h4>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="row" >
                                {data?.length > 0 ? (
                                    data.map((item, i) => (
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={i}>
                                            <Link to={`/classroom-course-detail/${item?.id}`}>
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="item-card7-img">
                                                            <img src={item?.thumbnail} alt={item?.title} className="cover-image" />
                                                            {(
                                                                item?.price == "paid" && (
                                                                    item?.is_discount == 1 && (
                                                                        (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                        (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                )
                                                            ) && (
                                                                    <Link to="#" className="bg-danger top left">
                                                                        {item?.discount_price}% Off
                                                                    </Link>
                                                                )}
                                                            <Link to="#" className="bg-danger right top">
                                                                Classroom Course
                                                            </Link>
                                                            {item?.class_type === "school" ? (
                                                                <Link to="#" className="bg-danger right bottom">
                                                                    {item?.board}
                                                                </Link>
                                                            ) : (item?.class_type === "university" ? (
                                                                <Link to="#" className="bg-danger right bottom">
                                                                    {item?.universities?.name}
                                                                </Link>
                                                            ) : (item?.class_type === "tuition" && (
                                                                <>
                                                                    {item?.tuition_type === "school" ? (
                                                                        <Link to="#" className="bg-danger right bottom">
                                                                            {item?.board}
                                                                        </Link>
                                                                    ) : (item?.tuition_type === "university" && (
                                                                        <Link to="#" className="bg-danger right bottom">
                                                                            {item?.universities?.name}
                                                                        </Link>
                                                                    ))}
                                                                </>
                                                            )))}
                                                        </div>
                                                        <div className="item-card2-desc">
                                                            <div className="d-inline-flex mb-2">
                                                                <div className="br-widget">
                                                                    {rating.map((el, i) => (
                                                                        <i
                                                                            data-rating-value={el}
                                                                            data-rating-text={el}
                                                                            className={
                                                                                el <= item?.average_review
                                                                                    ? "br-selected"
                                                                                    : ""
                                                                            }
                                                                            key={i}
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <span>({item?.review_count} Reviews)</span>
                                                            </div>
                                                            <h4 className="mb-2 leading-tight2">
                                                                {item?.title}
                                                            </h4>
                                                            <p className="leading-tight3 education-mandal" dangerouslySetInnerHTML={{ __html: item?.description }} />

                                                            <div className="pt-2 pb-2 border-bottom d-flex justify-content-between">
                                                                <div className="d-flex justify-content-start">
                                                                    <i className="fa fa-shop me-2" />
                                                                    <span className="text-muted text-data-over-cut">
                                                                        {item?.institute?.name}
                                                                    </span>
                                                                </div>
                                                                <div className="float-end d-flex justify-content-start">
                                                                    <i className="fa fa-building me-2" />
                                                                    <span className="text-muted text-capitalize text-data-over-cut">
                                                                        {replaceWord(item?.class_type)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="pt-2">
                                                                <i className="fa fa-file me-2" />
                                                                <span className="text-muted">
                                                                    {replaceWord(item?.lecture_type)}
                                                                </span>
                                                                <div className="float-end">
                                                                    {item?.price === "paid" && (
                                                                        <h4 className="mb-0"> ₹ {Math.round(item?.payable_price)} {(
                                                                            item?.price == "paid" && (
                                                                                item?.is_discount == 1 && (
                                                                                    (item?.is_limited_offer == 1 && (new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now && new Date(item?.discount_end_date).setHours(0, 0, 0, 0) >= now)) ||
                                                                                    (item?.is_limited_offer == 0 && new Date(item?.discount_start_date).setHours(0, 0, 0, 0) <= now))
                                                                            )
                                                                        ) && (<del className="fs-14 text-muted">₹ {Math.round(item?.regular_price)} </del>
                                                                            )}
                                                                        </h4>
                                                                    )}
                                                                    {item?.price === "free" && (
                                                                        <h4 className="mb-0">Free</h4>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))

                                ) : (
                                    <NoDataAvailable />
                                )}
                            </div>
                        )}
                    </div>
                </div >
            </section >
        </>
    );
};

export default ComingUpClasses;
