import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import Select from "react-select";
import { useState } from "react";
import url from "../../../../../../constants/url";
import { toast, ToastContainer } from "react-toastify";

export default function SlotList({ class_id, timeSchedule, filters, setFilters, loading, teachers, subjects, handleSearch }) {
    const status = [
        { label: 'Approved', value: 'active' },
        { label: 'Pending', value: 'pending' },
        { label: 'Declined', value: 'decline' },
    ]
    const [checkedItems, setCheckedItems] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [loadingOther, setLoadingOther] = useState(false)

    const local_data = localStorage.getItem("common-user-info");
    const local_storage = JSON.parse(local_data);
    const auth_token = local_storage.auth_token;

    var myHeaders = new Headers();
    myHeaders.append("AUTHTOKEN", auth_token);
    myHeaders.append("Content-Type", "application/json");

    const handleCheckboxChange = (event, id) => {
        const { checked } = event.target;
        setCheckedItems({ ...checkedItems, [id]: checked });
    };

    const handleSelectAll = () => {
        const updatedCheckedItems = {};
        if (!selectAll) {
            timeSchedule.forEach((item) => {
                updatedCheckedItems[item.id] = true;
            });
        }
        setCheckedItems(updatedCheckedItems);
        setSelectAll(!selectAll);
    };

    const manageMultipleDeleteSlot = async () => {
        var slot_ids = [];
        for (const [key, value] of Object.entries(checkedItems)) {
            if (value) {
                slot_ids.push(key)
            }
        }

        if (slot_ids.length === 0) {
            alert("Please Select Any Slot For Delete");
        } else {
            var activity = {
                class_id: class_id,
                deleted_slot_id: slot_ids.join(','),
            }
            DeleteSlot(activity);
        }
    }
    const manageDeleteSlot = async (id) => {
        var activity = {
            class_id: class_id,
            deleted_slot_id: id,
        }
        DeleteSlot(activity);
    }
    const DeleteSlot = async (activity) => {
        if (window.confirm(`Are you sure you want to delete slot`)) {
            setLoadingOther(true)
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(activity)
            };

            fetch(url.apiUrl + "institute/delete-class-slot", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        toast.success(result.message)
                        handleSearch()
                        setCheckedItems({})
                        setSelectAll(false);

                    }
                })
                .catch((error) => console.log("error", error))
                .finally(() => {
                    setLoadingOther(false)
                })
        }
    }



    return (
        <>
            <ToastContainer />
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">View Classroom Time Slot Teacher List</h4>
                <Link
                    className='btn btn-primary'
                    to='/institute-dashboard/slot-add' >
                    <i className='fa fa-plus'></i> Add Time Slot
                </Link>
            </div>
            <div className="card-body">
                <div className="row mb-5">
                    <div className="col-lg-3 col-md-6 form-group">
                        <label>
                            Subject
                        </label>
                        <Select name='institute_branch_id' options={subjects}
                            onChange={(e) => {
                                setFilters({ ...filters, subject: e.value })
                            }} />
                    </div>
                    <div className="col-lg-3 col-md-6 form-group">
                        <label>
                            Teacher Name
                        </label>
                        <Select name='institute_branch_id' options={teachers}
                            onChange={(e) => {
                                setFilters({ ...filters, teacher_id: e.value })
                            }} />
                    </div>
                    <div className="col-lg-3 col-md-6 form-group">
                        <label>
                            View Class Subject Status
                        </label>
                        <Select name='institute_branch_id' options={status} onChange={(e) => {
                            setFilters({ ...filters, status: e.value })
                        }}>
                            <option>Select status</option>
                            <option >Pending</option>
                            <option>Declined</option>
                        </Select>
                    </div>
                    <div className="col-lg-3 col-md-6 form-group">
                        <label>Start Date</label>
                        <input type="date" name="start_date" className="form-control"
                            onChange={(e) => {
                                setFilters({ ...filters, start_date: e.target.value })
                            }} />
                    </div>
                    <div className="col-lg-3 col-md-6 form-group">
                        <label>End Time</label>
                        <input type="date" name="end_date" className="form-control"
                            onChange={(e) => {
                                setFilters({ ...filters, end_date: e.target.value })
                            }} />
                    </div>
                    <div className="open-button col-md-3">
                        <button type="button" className="btn btn-primary" onClick={handleSearch}><i className="fe fe-search"></i> Search</button>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-2">
                        <button
                            className='btn btn-danger btn-sm'
                            type="button"
                            onClick={manageMultipleDeleteSlot}
                        >
                            Delete
                        </button>
                    </div>
                </div>
                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                    <thead>
                        <tr>
                            <th className="text-center">
                                <div className="checkbox checkbox-info">
                                    <label className="custom-control mt-2 checkbox-inline mb-0" htmlFor="checked">
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            name="selectall"
                                        />
                                    </label>
                                </div>
                            </th>
                            <th className="text-center">Sr. No.</th>
                            <th className="text-center">Subject</th>
                            <th>Teacher Details</th>
                            <th className="text-center">Date</th>
                            <th className="text-center">Start-End Time</th>
                            <th className="text-center">Teacher's Approved Time </th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td className="text-center" colSpan={8}>Loading..</td>
                            </tr>
                        ) : (!timeSchedule.length ? (
                            <tr>
                                <td className="text-center" colSpan={8}>No Data Available !</td>
                            </tr>
                        )
                            : (timeSchedule.map((time_slot, index) => (
                                <tr key={index}>
                                    <td className="text-center">
                                        <div className="checkbox checkbox-info">
                                            <label className="custom-control mt-2 checkbox-inline mb-0" htmlFor="checked">
                                                <input
                                                    type="checkbox"
                                                    checked={checkedItems[time_slot.id] || false}
                                                    onChange={(e) => handleCheckboxChange(e, time_slot.id)}
                                                />
                                            </label>
                                        </div>
                                    </td>
                                    <th className="text-center">{filters.offset + index + 1}</th>
                                    <td className="text-center">{time_slot?.subject}</td>
                                    <td>
                                        <div className="media">
                                            <img
                                                src={time_slot?.teacher?.image}
                                                alt={time_slot?.teacher?.name}
                                                className="me-3 rounded-circle"
                                            />
                                            <div className="media-body">
                                                <Link className="font-weight-semibold"
                                                    to={`/institute-dashboard/teacher-detail/${time_slot?.teacher?.id}`}
                                                >
                                                    {time_slot?.teacher?.name}
                                                </Link>
                                                <p className="mb-0">
                                                    {time_slot?.teacher?.email}

                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center"><Moment format="DD-MM-YYYY">{time_slot?.date}</Moment></td>
                                    <td className="text-center">{moment(time_slot?.start_time, ["HH:mm"]).format("hh:mm A")} - {moment(time_slot?.end_time, ["HH:mm"]).format("hh:mm A")}</td>
                                    <td className="text-center text-capitalize">
                                        {time_slot.teacher_status === "active" ? (
                                            <Moment format="DD-MM-YYYY hh:mm A">{time_slot?.teacher_approve_time}</Moment>
                                        ) : (
                                            time_slot.teacher_status === "decline" ?
                                                (<p className="text-center text-capitalize text-danger">
                                                    {time_slot.teacher_status} ({time_slot?.teacher_decline_reason})
                                                </p>)
                                                : (time_slot.teacher_status)
                                        )}
                                    </td>
                                    <td className="text-center text-nowrap">
                                        <Link className="btn btn-primary btn-sm me-2" to={`/institute-dashboard/slot-edit/${time_slot?.id}`}>
                                            <i className="fa fa-pen-to-square fs-16"></i>
                                        </Link>
                                        <button
                                            className='btn btn-danger btn-sm'
                                            type="button"
                                            onClick={() => manageDeleteSlot(time_slot?.id)}
                                        >
                                            <i className='fa fa-trash fs-16'></i>
                                        </button>
                                    </td>
                                </tr>
                            )))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}