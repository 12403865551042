import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    const [date, setDate] = useState();
    const getYear = () => setDate(new Date().getFullYear());
    useEffect(() => {
        getYear();
    }, [])
    const upArrowHandler = () => {
        window.scroll(0, 0);
    };
    return (
        <>
            <footer className="bg-dark">
                <div className="footer-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <img src="/assets/images/EduM_White_New.png" alt="Footer Logo" />
                                <ul className="list-unstyled mb-0 mt-4">
                                    <li>
                                        D 401, Bachraj Landmark, Near club one, Global City, Virar, Maharashtra - 401303
                                    </li>
                                    <li>
                                        <a href="mailto:info@educationmandal.com">info@educationmandal.com</a>
                                    </li>
                                </ul>
                                <ul className="list-unstyled mt-4 mb-lg-0 mb-5">
                                    <li className="list-inline-item">
                                        <a href="https://www.facebook.com/profile.php?id=100090317913078&mibextid=LQQJ4d" className="social-icons" target="_blank" rel="noreferrer">
                                            <i className="fe fe-facebook" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://wa.me/+919730063010" className="social-icons" target="_blank" rel="noreferrer">
                                            <i className="fa-brands fa-whatsapp"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/education-mandal/" className="social-icons" target="_blank" rel="noreferrer">
                                            <i className="fe fe-linkedin" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://instagram.com/education_mandal?igshid=YmMyMTA2M2Y=" className="social-icons" target="_blank" rel="noreferrer">
                                            <i className="fe fe-instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-6">
                                <h6 className="font-weight-normal text-white">
                                    Useful Links
                                </h6>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link to="/">
                                            <i className="fe fe-chevron-right" /> Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">
                                            <i className="fe fe-chevron-right" /> About us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/explore">
                                            <i className="fe fe-chevron-right" /> Explore
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/services">
                                            <i className="fe fe-chevron-right" /> Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/blog">
                                            <i className="fe fe-chevron-right" /> Blogs
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-6">
                                <h6 className="font-weight-normal text-white">
                                    Useful Links
                                </h6>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link to="/contact-us">
                                            <i className="fe fe-chevron-right" /> Contact us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/work-with-us">
                                            <i className="fe fe-chevron-right" /> Work with us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/careers-list">
                                            <i className="fe fe-chevron-right" /> Carrers
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/pricing">
                                            <i className="fe fe-chevron-right" /> Pricing
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/alumni-list">
                                            <i className="fe fe-chevron-right" /> Alumni
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="col-md-3 col-6">
                                <h6 className="font-weight-normal text-white">Support</h6>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link to="/privacy-policy">
                                            <i className="fe fe-chevron-right" /> Privacy Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-offer">
                                            <i className="fe fe-chevron-right" /> Terms & Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/disclaimer">
                                            <i className="fe fe-chevron-right" /> Disclaimer
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="footer-main bg-dark">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <ul className="payments mb-0">
                                    <li>
                                        <Link to="#" className="payments-icon text-white-50">
                                            <i className="fa fa-credit-card-alt" aria-hidden="true" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="payments-icon text-white-50">
                                            <i className="fa-brands fa-cc-mastercard"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="payments-icon text-white-50">
                                            <i className="fa-brands fa-cc-paypal"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 text-white">
                                Copyright © {date}
                                <a href="#" className="m-1 text-primary">
                                    Education Mandal.
                                </a>
                                Designed By
                                <a
                                    href="https://mstsglobal.com/"
                                    className="m-1 text-primary"
                                    target="_blank" rel="noreferrer"
                                >
                                    Mindstorm Techsolutions
                                </a>
                                All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer >
            <Link to="#" id="back-to-top" onClick={upArrowHandler}>
                <i className="fa fa-long-arrow-up"></i>
            </Link>
        </>
    );
};

export default Footer;
