import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Formik } from "formik";
import AttendanceApi from "../../services/AttendanceApi"
import Select from "react-select";

const AttendanceViewSubject = () => {

    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const serve = new AttendanceApi();
    const [subjectList, setSubjectList] = useState([]);
    const [filter, setFilter] = useState([]);
    const [className, setClassName] = useState("");

    const [values, setValues] = useState({
        subject: "",
        teacher_id: ""
    });
    const [subjects, setSubjects] = useState([]);

    const getTeacherAndSubjectData = async () => {
        try {
            let response = await serve.getClassSubjects(id);
            if (response) {
                setSubjects(response.data.map((value) => {
                    return { label: value, value: value }
                }));
            }
        } catch (err) {
            throw err;
        }
    }

    const getSubjectList = async (activity) => {
        try {
            setLoading(true)
            let response = await serve.getClassSubjectList(activity);
            if (response) {
                setSubjectList(response.data);
                setFilter(response.data);
                setClassName(response.class_name)
                setLoading(false)
            }
        } catch (err) {
            throw err;
        }
    }

    const FormSubmit = async (values) => {
        getSubjectList({
            class_id: id,
            teacher_id: values.teacher_id?.value,
            subject: values.subject?.value
        })

    }
    const manageSearch = (value) => {
        var filter_array = filter.filter((item, i) => {
            return (item.subject.toLowerCase().includes(value.toLowerCase()))
        })
        setSubjectList(filter_array)
    }

    useEffect(() => {
        getSubjectList({ class_id: id })
        getTeacherAndSubjectData()
    }, [])

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/attendance">Attendance List</Link>
                    </li>
                    <li className="breadcrumb-item active">View Attendance Subject</li>
                </ol>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">View Attendance Subject for {className}</h4>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={values}
                                onSubmit={FormSubmit}
                            >
                                {(props) => (
                                    <form onSubmit={props.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 form-group">
                                                <label htmlFor="subject">
                                                    Subject <span className="text-danger">*</span>
                                                </label>
                                                <Select name='subject'
                                                    options={subjects}
                                                    value={props.values?.subject}
                                                    onChange={(e) => {
                                                        props.setFieldValue(
                                                            "subject",
                                                            e
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 form-group open-button">
                                                <button type="submit" title="Search" className="btn btn-primary">
                                                    <i className="fe fe-search" /> Search
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                            <div className="card mt-5">
                                <div className="card-header">
                                    <h4 className="card-title">View Attendance Subject</h4>
                                </div>
                                <div className="card-body">
                                    <div
                                        id="basic-1_wrapper"
                                        className="dataTables_wrapper no-footer"
                                    >
                                        <div id="basic-1_filter" className="dataTables_filter">
                                            <label>
                                                Search:
                                                <input
                                                    type="search"
                                                    placeholder="Search..."
                                                    onChange={(e) => { manageSearch(e.target.value) }}
                                                />
                                            </label>
                                        </div>
                                        <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Sr. No.</th>
                                                    <th className="text-center">Subject</th>
                                                    <th>Teacher Details</th>
                                                    <th className="text-center text-nowrap">Overall</th>
                                                    <th className="text-center text-nowrap">Today Attendance</th>
                                                    <th className="text-center text-nowrap">View Attendance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? (
                                                    <tr>
                                                        <td className='text-center' colSpan={12}>
                                                            Loading..
                                                        </td>
                                                    </tr>
                                                ) : subjectList?.length === 0 ? (
                                                    <tr>
                                                        <td className='text-center' colSpan={12}>No Data Available !</td>
                                                    </tr>
                                                ) : (
                                                    subjectList?.map((item, i) => (
                                                        <tr key={i}>
                                                            <th className='text-center text-nowrap'>{i + 1}</th>
                                                            <td className="text-center">
                                                                {item?.subject}
                                                            </td>
                                                            <td>
                                                                <div className="media">
                                                                    <img
                                                                        src={item?.teacher?.image}
                                                                        alt={item?.teacher?.name}
                                                                        className="me-3 rounded-circle"
                                                                    />
                                                                    <div className="media-body">
                                                                        <Link to=""
                                                                            className="font-weight-semibold"
                                                                        >
                                                                            {item?.teacher?.name}
                                                                        </Link>
                                                                        <p className="text-muted mb-0">
                                                                            {item?.teacher?.email}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center">
                                                                {item?.marked_time_slot}/{item?.time_slot}
                                                            </td>
                                                            <td className="text-center">
                                                                {item?.today_marked_time_slot}/{item?.today_time_slot}
                                                            </td>
                                                            <td className="text-center">
                                                                <Link
                                                                    className="btn btn-primary me-2"
                                                                    to={`/student-dashboard/attendance-view-time-slot/${item.class_id}/${item?.subject}`}
                                                                >
                                                                    <i className="fa fa-eye"></i> View
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AttendanceViewSubject;
