import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import url from "../../../../constants/url";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import SubmitLoader from "../../../common/SubmitLoader"

const EditBranch = (props) => {
    const errors = {};
    let branchId = props?.match?.params?.id;
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;

    const [loader, setLoader] = useState(false);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const history = useHistory();
    const [formErrors, setFormErrors] = useState({});
    const current = new Date();
    const [image, setImage] = useState("");

    let current_month = current.getMonth() + 1;
    if (current_month.length > 1) {
        current_month = current_month;
    } else {
        current_month = "0" + current_month;
    }
    const date = `${current.getFullYear()}-${current_month}-${current.getDate()}`;

    const mapAccess = {
        mapboxApiAccessToken:
            "pk.eyJ1Ijoic2FuY2hpdHNoYXJtYSIsImEiOiJjbGZocmRoMm0yMTJmM3FwZ21nZ2NqdG91In0.Ngsso-JXR08GApV2N1rmUw",
    };

    function _suggestionSelect(result, lat, long, text) {
        setFormValues({ ...formValues, location: result, latitude: lat, longitude: long });
    }
    const GetBranchData = async () => {
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token_authorization);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(
            `${url?.apiUrl}institute-branch/get-branch/${branchId}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    result.data.states = {
                        value: result.data?.states?.id,
                        label: result.data?.states?.name,
                    }
                    result.data.cities = {
                        value: result.data?.cities?.id,
                        label: result.data?.cities?.name,
                    }
                    setFormValues(result.data);
                    CityListApi(result.data.state);
                    setImage(result?.data?.image);
                }
            })
            .catch((error) => console.log("error", error));
    };
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };
    const onBlurEvent = async (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const updateBranch = (e) => {
        e.preventDefault();

        var localdata = localStorage.getItem("common-user-info");
        var jsondata = JSON.parse(localdata);
        var token_authorization = jsondata?.auth_token;

        if (!formValues.name) {
            errors.name = "Required";
        }
        if (!formValues.contact) {
            errors.contact = "Required";
        } else {
            if (formValues.contact?.length !== 13) {
                errors.contact = "Please enter valid contact";
            }
        }
        if (!formValues.email) {
            errors.email = "Required";
        }
        if (!formValues.contact_person_name) {
            errors.contact_person_name = "Required";
        }
        if (typeof image != "string") {
            if (image.base64 === undefined || image.base64.length == 0) {
                errors.image = "Required";
            }
        }
        if (formValues.address === "") {
            errors.address = "Required";
        }
        if (formValues.location === "") {
            errors.location = "Required";
        }
        if (formValues.state === "") {
            errors.state = "Required";
        }
        if (formValues.city === "") {
            errors.city = "Required";
        }
        if (formValues.pincode === "") {
            errors.pincode = "Required";
        }else if (
            formValues.pincode?.length > 6 ||
            formValues.pincode?.length < 6
        ){
            errors.pincode = "Invalid Pincode";
        }
        if (!formValues.year_of_startup) {
            errors.year_of_startup = "Required";
        } else if (
            formValues.year_of_startup?.length > 4 ||
            formValues.year_of_startup?.length < 4
        ) {
            errors.year_of_startup = "Invalid Year of Incorporation";
        }

        setFormErrors(errors);
        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token_authorization);
        var formdata = new FormData();
        formdata.append("name", formValues.name);
        formdata.append("address", formValues.address);
        formdata.append("contact", formValues.contact);
        formdata.append("email", formValues.email);
        formdata.append("contact_person_name", formValues.contact_person_name);
        formdata.append("year_of_startup", formValues.year_of_startup);
        formdata.append("status", formValues.status);
        formdata.append("location", formValues.location);
        formdata.append("latitude", formValues.latitude);
        formdata.append("longitude", formValues.longitude);
        formdata.append("state", formValues?.states?.value || formValues.state);
        formdata.append("city", formValues?.cities?.value || formValues.cities);
        formdata.append("pincode", formValues.pincode);
        formdata.append("image", formValues?.image);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };
        if (Object.keys(errors).length < 1) {
            setLoader(true);
            fetch(`${url.apiUrl}institute-branch/update-branch/${props.match.params.id}`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        setLoader(false);
                        toast.success("Branch Edited Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTimeout(() => {
                            history.push("/institute-dashboard/branch");
                        }, 5000);
                    } else {
                        setLoader(false);
                        setFormErrors(result.data);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    useEffect(() => {
        GetBranchData();
        StateListApi();
    }, []);

    const StateListApi = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}state/101`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setState(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };
    const CityListApi = async (id) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}city/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setCity(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const handleFileInputChange = async (e) => {
        let { file } = e.target.files[0];

        file = e.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 200 || width > 368) {
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                    // setImage('');
                    setFormErrors({ ...formErrors, 'image': "Error: Image size: width = 368px and height = 200px" });
                    setFormValues({ ...formValues, 'image': "" });
                } else if (height < 200 || width < 368) {
                    alert(
                        "Image size: width = 368px and height = 200px"
                    );
                    // setImage('');
                    setFormErrors({ ...formErrors, 'image': "Error: Image size: width = 368px and height = 200px" });
                    setFormValues({ ...formValues, 'image': "" });
                } else {
                    getBase64(file)
                        .then((result) => {
                            file["base64"] = "true";
                            setFormValues({ ...formValues, 'image': file })
                            setImage(result);
                            delete formErrors.image;
                        })
                        .catch((err) => { });
                }
            };
        };
    };
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    return (
        <>
            <div className='page-header'>
                <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard/'>Dashboard</Link>
                    </li>
                    <li className='breadcrumb-item'>
                        <Link to='/institute-dashboard/branch'>Branch's List</Link>
                    </li>
                    <li className='breadcrumb-item active'>Edit Branch</li>
                </ol>
            </div>

            <div className='row'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4 className='card-title'>Edit Branch </h4>
                        </div>
                        <div className='card-body'>
                            <form>
                                <div className='row'>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Branch Name <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            className='form-control'
                                            id='classwise'
                                            name='name'
                                            defaultValue={formValues.name}
                                            onChange={handleChange}
                                            onBlur={onBlurEvent}
                                        />
                                        <p className='bg-error'>{formErrors.name}</p>
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Address <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='classwise'
                                            name='address'
                                            defaultValue={formValues.address}
                                            onChange={handleChange}
                                            onBlur={onBlurEvent}
                                            placeholder='Enter address.'
                                        />{" "}
                                        <p className='bg-error'>{formErrors.address}</p>
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Location <span className='text-danger'>*</span>
                                        </label>
                                        {formValues.location && (
                                            <MapboxAutocomplete
                                                publicKey={mapAccess.mapboxApiAccessToken}
                                                inputclassName='form-control '
                                                onSuggestionSelect={_suggestionSelect}
                                                country='IN'
                                                query={formValues.location}
                                                resetSearch={false}
                                                placeholder='Search location...'
                                            />
                                        )}
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            State <span className='text-danger'>*</span>
                                        </label>
                                        <Select
                                            value={formValues.states}
                                            name='stateName'
                                            onChange={(e) => {
                                                setFormValues({ ...formValues, states: e });
                                                CityListApi(e.value);
                                            }}
                                            options={state}
                                        />
                                        <p className='bg-error'>{formErrors.state}</p>
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            City <span className='text-danger'>*</span>
                                        </label>
                                        <Select
                                            name='city'
                                            value={formValues.cities}
                                            onChange={(e) => {
                                                setFormValues({ ...formValues, cities: e });
                                            }}
                                            options={city}
                                        />
                                        <p className='bg-error'>{formErrors.city}</p>
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Pincode <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            name='pincode'
                                            id='pinecode'
                                            className='form-control'
                                            defaultValue={formValues.pincode}
                                            placeholder='Enter pinecode'
                                            onChange={handleChange}
                                            onBlur={onBlurEvent}
                                        />
                                        <p className='bg-error'>{formErrors.pincode}</p>
                                    </div>

                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Contact Person Name <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            className='form-control'
                                            id='classwise'
                                            name='contact_person_name'
                                            defaultValue={formValues.contact_person_name}
                                            onChange={handleChange}
                                            onBlur={onBlurEvent}
                                        />
                                        <p className='bg-error'>
                                            {formErrors.contact_person_name}
                                        </p>
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Contact No. <span className='text-danger'>*</span>
                                        </label>
                                        <PhoneInputWithCountrySelect
                                            name='mobile'
                                            defaultCountry='IN'
                                            className='form-control'
                                            placeholder='Please enter contact no'
                                            onChange={(e) => {
                                                setFormValues({ ...formValues, contact: e });
                                            }}
                                            value={formValues.contact}
                                        />
                                        <p className='bg-error'>{formErrors.contact}</p>
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Email ID <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            className='form-control'
                                            id='classwise'
                                            name='email'
                                            disabled
                                            defaultValue={formValues.email}
                                            onChange={handleChange}
                                        />
                                        <p className='bg-error'>{formErrors.email}</p>
                                    </div>
                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Year of Incorporation <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='classwise'
                                            name='year_of_startup'
                                            defaultValue={formValues.year_of_startup}
                                            max={date}
                                            onChange={handleChange}
                                            onBlur={onBlurEvent}
                                            placeholder="Enter year of incorpration"
                                        />
                                        <p className='bg-error'>{formErrors.year_of_startup}</p>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>
                                            Profile Picture <span className='text-danger'> *</span>
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control'
                                            id='image'
                                            name='image'
                                            onChange={(e) => handleFileInputChange(e)}

                                        />
                                        <p className="fs-12 mb-0"><span className="text-danger">Note:</span> Image size: width = 368px and height = 200px</p>
                                        <p className="bg-error">
                                            {formErrors?.image}
                                        </p>
                                    </div>
                                    {image && (

                                        <div className="col-md-2 form-group">
                                            <img
                                                className='img-thumbnail'
                                                src={image}
                                                height='200px'
                                                width='368px'
                                                alt='Branch Image'
                                            />
                                        </div>
                                    )}

                                    <div className='col-md-4 form-group'>
                                        <label htmlFor='classwise'>
                                            Branch Status <span className='text-danger'>*</span>
                                        </label>
                                        <select
                                            className='form-select'
                                            name='status'
                                            value={formValues.status}
                                            onChange={handleChange}
                                        >
                                            <option value='active'>Active</option>
                                            <option value='inactive'>In-Active</option>
                                        </select>
                                    </div>

                                    <div className="col-md-12 text-end mt-5">
                                        {loader ? (
                                            <SubmitLoader />
                                        ) : (
                                            <>
                                                <button type="submit" className="btn btn-primary me-2" onClick={updateBranch}>
                                                    Submit
                                                </button>
                                                <Link
                                                    to="/institute-dashboard/branch"
                                                    className="btn btn-danger "
                                                >
                                                    Cancel
                                                </Link>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
            <ToastContainer />
        </>
    );
};

export default EditBranch;
