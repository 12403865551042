import React, { useEffect, useState } from "react";
import Routing from "./Routes/Routing";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <HMSRoomProvider>
          <Routing />
        </HMSRoomProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
