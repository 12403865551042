import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import { Formik } from "formik";
import { getInstituteBranchGetAllBranches } from "../../../../apis";
import AlumniForm from "./AlumniForm";

const AddAlumn = () => {

    const [values, setValues] = useState({
        branchSelected: "",
        boardSelected: "",
        lectureSelected: "",
        tuitionType: "",
        universitySelected: "",
        classSelected: "",
        semesterSelected: "",
        batch_start_year: "",
        batch_start_year: "",
        student_id: "",
        student_name: "",
        image: "",
        contact: "",
        email: "",
        student_about: "",
        current_company: "",
        current_designation: "",
    });


    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/institute-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to="/institute-dashboard/alumni">Alumni</Link></li>
                    <li className="breadcrumb-item active">Add Alumni</li>
                </ol>
            </div>

            <div className="content-body">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h4 className="card-title">Add Alumni</h4>
                                <p className="mb-0"><strong>If you'd like the Alumni to register themselves, please share the link with them:</strong> http://localhost:3000/student-alumni</p>
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={values}
                                // onSubmit={submitSlot}
                                // enableReinitialize={true}
                                // validationSchema={ValidateSchema}
                                >
                                    {(props) => {
                                        return (
                                            <form
                                                onSubmit={props.handleSubmit} >
                                                <AlumniForm props={props} />
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default AddAlumn;