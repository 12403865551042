import React, { useEffect, useState, useContext } from "react";
import LineSeries from "./LineSeries";
// import {
//     getInstitutGetProfile,
//     getInstituteCourseGetAlOnlineCourses,
// } from "../../../../apis";
import TotalCards from "./TotalCards";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Context } from "../../../../Context";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PasswordPopup from "../../../LoginArea/PasswordPopup";

const DashboardView = () => {
    const { activities } = useContext(Context);
    const [active, setActive] = activities;
    useEffect(() => {
        sessionStorage.setItem("url", window.location.pathname);
    }, []);
    const localizer = momentLocalizer(moment);

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                        Dashboard
                    </li>
                </ol>
            </div>
            <TotalCards />
            <LineSeries />
            {active ? <PasswordPopup /> : ""}
        </>
    );
};

export default DashboardView;
