import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BranchList from './BranchList';

const Branch = () => {
    const [filterstatus, setFilterstatus] = useState([]);
    const [filterloc, setFilterloc] = useState([]);
    function parentMethod(data) {
        setFilterstatus(data);
        setFilterloc(data)
    }

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Branch's List
                    </li>
                </ol>
                <Link className="btn btn-primary" to="/institute-dashboard/branch-add">
                    <i className="fa fa-plus"></i> Add Branch
                </Link>
            </div>

            <div className='row'>
                <BranchList statusFilter={filterstatus} locFilter={filterloc} />
            </div>
        </>
    );
};

export default Branch;