import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StudentProfile from './StudentProfile';
import StudentDetails from './StudentDetails';
import { getStudentGetProfile } from '../../../../apis'

const Profile = () => {

    const [studentdata, setStudentdata] = useState({})
    const getStudentGetProfiles = async () => {
        let res = await getStudentGetProfile()
        if (res.status === 'success') {
            setStudentdata(res.data)
        }

    }
    useEffect(() => {
        getStudentGetProfiles()
    }, [])
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/student-dashboard">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Profile</li>
                </ol>
            </div>

            <StudentProfile data={studentdata && studentdata} />
            <StudentDetails parentMethod={getStudentGetProfiles} data={studentdata && studentdata} />
        </>
    );
};

export default Profile;