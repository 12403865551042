import React, { useState } from "react";
import SubmitLoader from "../../../../common/SubmitLoader"
import Select from "react-select";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import {
    getInstituteTeacherNewRegister,
    getInstituteBranchGetAllBranches,
} from "../../../../../apis";
import url from "../../../../../constants/url";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";

function TeacherAddform() {
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const [branch, setBranch] = useState([]);
    const [value, setValue] = useState("");
    const [isError, setIsError] = useState(false);
    const [isLegnth, setIsLegnth] = useState(0);
    const [uIValues, setUIValues] = useState({
        address: "",
        institute_branch_id: "",
        email: "",
        gender: "",
        language: "",
        name: "",
    });

    const [branchSelected, setBranchSelected] = useState([]);
    const [languageSelected, setlanguageSelected] = useState([]);
    const [formError, setformError] = useState({
        address: "",
        institute_branch_id: "",
        contact: "",
        email: "",
        gender: "",
        language: "",
        name: "",
    });


    const handleChangeGen = async (e) => {
        if (e.target.name === "address") {
            if (e.target.value.length > 500) {
                setIsError(true);
            } else {
                setIsError(false);
            }
            setIsLegnth(e.target.value.length);
        }
        setUIValues({
            ...uIValues,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const handleSave = async () => {
        const errors = {};
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (!uIValues.institute_branch_id) {
            errors.institute_branch_id = "Please select at least one branch";
        }
        if (value === undefined || value === "") {
            setformError({ ...formError, contact: "Required" });
            errors.contact = "Required";
        } else {
            if (value.length !== 13) {
                setformError({
                    ...formError,
                    contact: "Please enter valid contact no",
                });
                errors.contact = "Please enter valid contact no";
            }
        }
        if (!uIValues.email) {
            errors.email = "Required";
        } else if (!uIValues.email.match(isValidEmail)) {
            errors.email = "Invalid email";
        }
        if (!uIValues.gender) {
            errors.gender = "Required";
        }
        if (!uIValues.name) {
            errors.name = "Required";
        }
        let lang = [],
            bran = [];
        if (languageSelected.length > 0) {
            for (let i = 0; i < languageSelected.length; i++) {
                lang.push(languageSelected[i].name);
            }
        }
        if (branchSelected.length > 0) {
            for (let i = 0; i < branchSelected.length; i++) {
                bran.push(branchSelected[i].id);
            }
        }
        setformError(errors);

        let reqData = new FormData();
        reqData.append(`name`, uIValues?.name);
        reqData.append(`contact`, value);
        reqData.append(`gender`, uIValues?.gender);
        reqData.append(`email`, uIValues?.email);
        reqData.append(`languages`, uIValues?.language);
        reqData.append(`address`, uIValues?.address);
        reqData.append(`institute_branch_id`, uIValues?.institute_branch_id);

        const local_data = localStorage.getItem("common-user-info");

        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;


        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: reqData,
        };
        if (Object.keys(errors).length < 1) {
            setLoader(true);
            fetch(`${url.apiUrl}institute-teacher/new-register`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        setLoader(false);
                        toast.success("Teacher Added Successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTimeout(() => {
                            history.push("/institute-dashboard/teacher");
                        }, 5000);
                    } else {
                        setLoader(false);
                        setformError(result.data);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    const getAllBraches = async () => {
        let res = await getInstituteBranchGetAllBranches();
        if (res.status === "success") {
            const arr =
                res.data &&
                res.data.map((v) => {
                    return { label: v.text, value: v.id };
                });
            setBranch(arr);
        }
    };

    useEffect(() => {
        getAllBraches();
    }, []);

    return (
        <div className='card'>
            <div className='card-header'>
                <h4 className='card-title'>Add Teacher</h4>
            </div>
            <div className='card-body'>
                <form>
                    <div className='row'>
                        <div className='col-lg-4 form-group'>
                            <label>
                                Branch <span className='text-danger'>*</span>
                            </label>
                            <Select
                                onChange={(selectOption) => {
                                    setBranchSelected(selectOption)
                                    const branchIds = selectOption.map((value => {
                                        return value.value
                                    }))
                                    setUIValues((preValue => ({ ...preValue, institute_branch_id: branchIds.join(',') })))
                                }}
                                options={branch}
                                value={branchSelected}
                                name='institute_branch_id'
                                isMulti
                            />
                            <p className='bg-error'>{formError?.institute_branch_id}</p>
                        </div>

                        <div className='col-lg-4 form-group'>
                            <label>
                                Name <span className='text-danger'>*</span>
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                name='name'
                                onChange={handleChangeGen}
                                placeholder='Enter name'
                            />
                            <p className='bg-error'>{formError.name}</p>
                        </div>
                        <div className='col-lg-4 form-group'>
                            <label>
                                Gender <span className='text-danger'>*</span>
                            </label>
                            <div className='form-controls-stacked d-md-flex'>
                                <label className='custom-control custom-radio me-4'>
                                    <input
                                        type='radio'
                                        className='custom-control-input'
                                        name='gender'
                                        label='Male'
                                        value='Male'
                                        onChange={handleChangeGen}
                                    />
                                    <span className='custom-control-label'>Male</span>
                                </label>
                                <label className='custom-control custom-radio me-4'>
                                    <input
                                        type='radio'
                                        className='custom-control-input'
                                        name='gender'
                                        label='Female'
                                        value='Female'
                                        onChange={handleChangeGen}
                                    />
                                    <span className='custom-control-label'>Female</span>
                                </label>

                                <label className='custom-control custom-radio'>
                                    <input
                                        type='radio'
                                        className='custom-control-input'
                                        name='gender'
                                        label='Other'
                                        value='Other'
                                        onChange={handleChangeGen}
                                    />
                                    <span className='custom-control-label'>Other</span>
                                </label>
                            </div>
                            <p className='bg-error'>{formError.gender}</p>
                        </div>
                        <div className='col-lg-4 form-group'>
                            <label>
                                Contact No. <span className='text-danger'>*</span>
                            </label>
                            <PhoneInputWithCountrySelect
                                name='contact'
                                value={value}
                                onChange={setValue}
                                defaultCountry='IN'
                                className='form-control'
                                placeholder='Enter contact no'
                            />
                            <p className='bg-error'>{formError.contact}</p>
                        </div>
                        <div className='col-lg-4 form-group'>
                            <label>
                                Email ID <span className='text-danger'>*</span>
                            </label>
                            <input
                                type='email'
                                className='form-control'
                                name='email'
                                onChange={handleChangeGen}
                                placeholder='Enter email id'
                            />
                            <p className='bg-error'>{formError.email}</p>
                        </div>
                    </div>
                        <div className='col-lg-12 text-end mt-5'>
                    {loader ? (
                        <SubmitLoader />
                    ) : (
                        <>
                            <button
                                type="button"
                                onClick={handleSave}
                                className='btn btn-primary me-2'
                            >
                                Submit
                            </button>
                            <Link
                                to='/institute-dashboard/teacher'
                                className='btn btn-danger'
                            >
                                Cancel
                            </Link>
                        </>
                    )}
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}

export default TeacherAddform;
