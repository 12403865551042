import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { replaceWord } from "../../../../utils/utils";

const Class = () => {
    const [classData, setclassData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [classoffset, setClassOffset] = useState(0);
    const [search, setSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [classsearch, setClassSearch] = useState({
        start: 0,
        perPage: 10,
        searchTxt: "",
        searchField: "",
    });
    const [classcourseCount, setClassCourseCount] = useState();

    useEffect(() => {
        getAllLectureData();
    }, [classsearch]);
    const apiCallForLecture = async (activity) => {

        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        const config = {
            headers: {
                content: "application/json",
                AUTHTOKEN: auth_token,
            },
        };
        try {
            const response = await axios.post(
                `${url.apiUrl}teacher-course/get-all-class-courses`,

                activity,
                config
            );
            if (response) {
                setclassData(response.data?.data);
                setClassCourseCount(response.data?.count / 10);
            }
        } catch (err) {
            throw err;
        }
    };
    const getAllLectureData = async () => {
        const activity = {
            status: "all",
            limit: classsearch.perPage,
            offset: classsearch.start,
            query: classsearch.searchTxt,
        };

        await apiCallForLecture(activity);
    };
    const handlePageClickForClass = async (data) => {
        let currentPage = data.selected * classsearch.perPage;
        setClassOffset(currentPage);
        const activity = {
            status: "all",
            limit: classsearch.perPage,
            offset: currentPage,
            query: classsearch.searchTxt,
        };
        await apiCallForLecture(activity);
    };

    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/teacher-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Classroom Course</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header ">
                            <h4 className="card-title">Classroom Course List </h4>
                        </div>
                        <div className="card-body ">
                            <div
                                id="basic-1_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <div id="basic-1_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"

                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setClassSearch({
                                                    ...classsearch,
                                                    searchTxt: e.target.value,
                                                });
                                            }}
                                        />
                                    </label> 
                                </div>
                                <table className="table table-bordered table-hover dataTable table-responsive-xl">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th>Class Details</th>
                                            <th>Branch Details</th>
                                            <th className="text-center">Class Type</th>
                                            <th className="text-center">Board/University</th>
                                            <th className="text-center">Section/Semester</th>
                                            <th className="text-center">Medium</th>
                                            <th className="text-center">Classroom Type</th>
                                            <th className="text-center">VC Link</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Students Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {classData?.length > 0 ? (
                                            classData.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th className="text-center">{i + classoffset + 1}</th>
                                                        <td>
                                                            <div className="media">
                                                                <img src={item?.thumbnail} alt={item?.class_name} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <Link to={"/teacher-dashboard/class-detail/" + item?.id} className="font-weight-semibold">
                                                                        {item?.class_name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="media">
                                                                <img src={item?.institute_branch?.image} alt={item?.class_name} className="me-3 rounded-circle" />
                                                                <div className="media-body">
                                                                    <Link to={"/teacher-dashboard/branch-detail/" + item?.institute_branch?.id} className="font-weight-semibold">
                                                                        {item?.institute_branch?.name}
                                                                    </Link>
                                                                    <p className="mb-0">{item?.institute_branch?.email}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-center text-capitalize">{replaceWord(item?.class_type)} {item?.class_type == "tuition" ? " : " + (replaceWord(item?.tuition_type)) : ""}</td>
                                                        <td className="text-center text-capitalize">{item?.board || item?.universities?.name || '---'}</td>
                                                        <td className="text-center text-capitalize">{item?.section || item?.semester || '---'}</td>
                                                        <td className="text-center text-capitalize">{item?.medium || '---'}</td>
                                                        <td className="text-center">{replaceWord(item?.lecture_type)}</td>
                                                        <td className="text-center">
                                                            {item?.video_conference_link ? (
                                                                <a href={item?.video_conference_link} className="btn btn-primary" target="_blank" rel="noreferrer">
                                                                    Join Now
                                                                </a>
                                                            ) : ("---")}
                                                        </td>
                                                        <td className="text-center text-capitalize">{item?.status}</td>
                                                        <td className="text-center">
                                                            <Link to={`/teacher-dashboard/student-course-class/${item?.type}/${item?.id}`} className="btn btn-primary btn-sm">
                                                                {item?.student_count}
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td className='text-center' colSpan={12}>No Data Available !</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {classData?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(classcourseCount)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickForClass}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Class;
