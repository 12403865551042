import React from "react";
const Popup = ({ title, data, openPopup, setOpenPopup, id }) => {

    return (
        <div
            className={openPopup[id] ? "modal fade show" : "modal fade"}
            id="view-details"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={openPopup[id] ? { display: "block" } : { display: "none" }}
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel33">{title}</h4>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setOpenPopup({ [id]: false })}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <video controls unmuted loop autoPlay controlsList="nodownload">
                            <source
                                src={data}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Popup;
