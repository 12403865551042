import React, { useEffect, useState } from "react";

const TotalSection = (props) => {
    const [data, setTotalData] = useState(props.data);
    useEffect(() => {
        if (props.data && props.data) setTotalData(props.data);
    }, [props.data]);
    return (
        <>
            <section className="cover-image sptb bg-background-color" data-bs-image-src="assets/images/banners/banner5.webp"
                style={{ background: 'url("assets/images/banners/banner5.webp") center center' }}>
                <div className="content-text mb-0 text-white info">
                    <div className="container">
                        <div className="row text-center">
                            <div className="mb-3 mt-3 col-md-4 col-6">
                                <div className="counter-status status">
                                    <div className="counter-icon text-success">
                                        <i className="fa-solid fa-newspaper"></i>
                                    </div>
                                    <h5>Total Courses</h5>
                                    <h2 className="counter mb-0">{data?.course}</h2>
                                </div>
                            </div>
                            <div className="mb-3 mt-3 col-md-4 col-6">
                                <div className="counter-status md-mb-0">
                                    <div className="counter-icon">
                                        <i className="fa-solid fa-graduation-cap"></i>
                                    </div>
                                    <h5>Total Teachers</h5>
                                    <h2 className="counter mb-0">{data?.teacher}</h2>
                                </div>
                            </div>
                            <div className="mb-3 mt-3 col-md-4 col-6">
                                <div className="counter-status status md-mb-0">
                                    <div className="counter-icon text-primary">
                                        <i className="fa fa-person-chalkboard" />
                                    </div>
                                    <h5>Total Institute</h5>
                                    <h2 className="counter mb-0">{data?.institute}</h2>
                                </div>
                            </div>
                            {/* <div className="mb-3 mt-3 col-md-3 col-6">
                                <div className="counter-status md-mb-0">
                                    <div className="counter-icon text-warning">
                                        <i className="fa-solid fa-users"></i>
                                    </div>
                                    <h5>Total Students</h5>
                                    <h2 className="counter mb-0">{data?.student}</h2>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TotalSection;

