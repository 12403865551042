
import Routes from './Routes/Routes';


function TeacherApp() {
    return (
        <div>
            <Routes />
        </div>

    );
}

export default TeacherApp;
