import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link, useParams } from "react-router-dom";
import ApplicantList from "./ApplicantList";

const Applicants = () => {
    const params = useParams();
    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">Applicants</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Applicants List</h4>
                        </div>
                        <div className="card-body">
                            <div className="card">
                                <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
                                    <Tab eventKey="all" title="All">
                                        <ApplicantList params={params} status={"all"} />
                                    </Tab>
                                    <Tab eventKey="pending" title="Pending">
                                        <ApplicantList params={params} status={"pending"} />
                                    </Tab>
                                    <Tab eventKey="short_listed" title="Shortlisted">
                                        <ApplicantList params={params} status={"short_listed"} />
                                    </Tab>
                                    <Tab eventKey="selected" title="Selected">
                                        <ApplicantList params={params} status={"selected"} />
                                    </Tab>
                                    <Tab eventKey="not_interested" title="Candidate Not Interested">
                                        <ApplicantList params={params} status={"not_interested"} />
                                    </Tab>
                                    <Tab eventKey="not_selected" title="Not Selected">
                                        <ApplicantList params={params} status={"not_selected"} />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Applicants;