import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import { replaceWord } from "../../../../utils/utils";
import url from "../../../../constants/url";

const Applicants = () => {
    const parms = useParams();
    const [data, setData] = useState([]);
    const getAllCareerData = async () => {
        var localdata = localStorage.getItem("common-user-info");
        var jsondata = JSON.parse(localdata);
        var token_authorization = jsondata?.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", token_authorization);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(`${url?.apiUrl}institute/career/${parms.is_main_career}/${parms.id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setData(result?.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getAllCareerData();
    }, []);


    return (
        <>
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/institute-dashboard/careers">Career List</Link>
                    </li>
                    <li className="breadcrumb-item active">Career Details</li>
                </ol>
            </div>

            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="product-slider">
                                <ul className="list-unstyled video-list-thumbs">
                                    <li className="mb-0">
                                        <div className="item-card7-img">
                                            <img className="cover-image" src={data?.thumb_image} alt={data?.title} />
                                            <a className="bg-danger right top" href="#">{data?.employee_type}</a>
                                            <a className="bg-danger right bottom" href="/"> {replaceWord(data?.class_type)} {data?.position}</a>
                                            {data?.position === "other" && (
                                                <a className="bg-danger right bottom" href="/">  {data?.other_position}</a>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {data?.status == "active" && (
                                <Link className="btn btn-block btn-primary mb-3 mb-xl-0" to={"/career-detail/" + data?.slug} >Go to website </Link>
                            )}

                        </div>
                        <div className="card-header"><h4 className="card-title">Job Info :</h4></div>
                        <table className="table table-hover dataTable">
                            <tbody>
                                <tr>
                                    <td className="text-nowrap font-weight-semibold">
                                        Branch Name
                                    </td>
                                    <td className="w-0 text-center"> : </td>
                                    <td className="text-end">
                                        <Link className="text-primary" to={`/institute-dashboard/branch-detail/${data?.institute_branch?.id}`}>
                                            {data?.institute_branch?.name}
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Location</td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end text-capitalize">{data?.location}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Salary</td>
                                    <td className="w-0 text-center">:</td>
                                    {data?.is_disclosed_salary === 1 ? (
                                        <td className="text-end text-capitalize">₹ {data?.salary}</td>
                                    ) : (
                                        <td className="text-end text-capitalize">Not Disclosed</td>
                                    )}
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Experience</td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end text-capitalize">{data?.experience}</td>
                                </tr>
                                {data?.status == "active" && (
                                    <tr>
                                        <td className="font-weight-semibold">Applicants</td>
                                        <td className="w-0 text-center">:</td>
                                        <td className="text-end text-capitalize">{data?.applications_count} Applicants</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="font-weight-semibold">Class Type</td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end text-capitalize">{replaceWord(data?.class_type)}</td>
                                </tr>
                                {data?.class_type === "school" ? (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Board</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.board}
                                            </td>
                                        </tr>
                                    </>
                                ) : (data?.class_type === "university" ? (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">University</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {data?.university}
                                            </td>
                                        </tr>
                                    </>
                                ) : (data?.class_type === "tuition" && (
                                    <>
                                        <tr>
                                            <td className="text-nowrap font-weight-semibold">Tuition Type</td>
                                            <td className="w-0 text-center"> : </td>
                                            <td className="text-end text-capitalize">
                                                {replaceWord(data?.tuition_type)}
                                            </td>
                                        </tr>
                                        {
                                            data?.tuition_type === "school" ? (
                                                <tr>
                                                    <td className="text-nowrap font-weight-semibold">Board</td>
                                                    <td className="w-0 text-center"> : </td>
                                                    <td className="text-end text-capitalize">
                                                        {data?.board}
                                                    </td>
                                                </tr>
                                            ) : (data?.tuition_type === "university" && (
                                                <>
                                                    <tr>
                                                        <td className="text-nowrap font-weight-semibold">University</td>
                                                        <td className="w-0 text-center"> : </td>
                                                        <td className="text-end text-capitalize">
                                                            {data?.university}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                    </>
                                )))}
                                <tr>
                                    <td className="font-weight-semibold">Medium </td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end text-capitalize">{data?.medium}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Start Date</td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end"><Moment format="DD-MM-YYYY">{data?.start_date}</Moment></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">End Date</td>
                                    <td className="w-0 text-center">:</td>
                                    {data?.is_end_job === 1 ? (
                                        <td className="text-end"><Moment format="DD-MM-YYYY">{data?.end_date}</Moment></td>
                                    ) : (
                                        <td className="text-end text-capitalize">Not Applicable</td>
                                    )}
                                </tr>
                                <tr>
                                    <td className="font-weight-semibold">Last Update</td>
                                    <td className="w-0 text-center">:</td>
                                    <td className="text-end"><Moment format="DD-MM-YYYY hh:mm A">{data?.updated_at}</Moment></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Job Name</h4>
                        </div>
                        <div className="card-body">
                            {data?.title}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header"><h4 className="card-title">Description</h4></div>
                        <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.description }}>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header"><h4 className="card-title">Requirements</h4></div>
                        <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.requirment }}>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header"><h4 className="card-title">Job Responsibilities</h4></div>
                        <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.job_responsibility }}>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header"><h4 className="card-title">About Company</h4></div>
                        <div className="card-body education-mandal" dangerouslySetInnerHTML={{ __html: data?.about_company }}>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Applicants;