import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import url from "../../../../constants/url";
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from "react-toastify";
import Moment from "react-moment";
import "react-toastify/dist/ReactToastify.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import SubmitLoader from "../../../common/SubmitLoader";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import Select from "react-select";

const StudentProfile = (props) => {
    const [aboutForm, setProfile] = useState(false);
    const [loader, setLoader] = useState(false);
    const { data } = props;
    const [formValues, setFormValues] = useState({});
    const [studentData, setStudentData] = useState({});
    const [value, setValue] = useState();
    const [selectedImage, setSelectedImage] = useState(false);
    const [blobImg, setBlobImg] = useState("assets/images/courses/0-1.jpg");
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [formValidation, setFormValidation] = useState({
        name: "",
        contact: "",
        address: "",
        image: "",
        location: "",
        states: "",
        cities: "",
        state: "",
        city: "",
        pincode: "",
        latitude: "",
        longitude: "",
    });

    const ChangeEvent = async (e) => {
        if (e.target.name === "image") {

        } else {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const getProfileData = async () => {
        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
        };

        fetch(`${url.apiUrl}student/get-profile`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    // setFormValues(result.data);
                    setFormValues({
                        name: result?.data?.name,
                        contact: result?.data?.contact,
                        address: result?.data?.address,
                        image: result?.data?.image,
                        location: result?.data?.location,
                        states: result?.data?.states && ({ label: result?.data?.states?.name, value: result?.data?.states?.value }) || "",
                        cities: result?.data?.cities && { label: result?.data?.cities?.name, value: result?.data?.cities?.value } || "",
                        state: result?.data?.state,
                        city: result?.data?.city,
                        pincode: result?.data?.pincode,
                        latitude: result?.data?.latitude,
                        longitude: result?.data?.longitude,
                    });
                    setStudentData(result.data);
                    setValue(result?.data?.contact);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const handleFileInputChange = async (e) => {
        let { file } = e.target.files[0];

        file = e.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 200 || width > 180) {
                    alert(
                        "Image size: width = 180px and height = 200px"
                    );
                    setFormValidation({ ...formValidation, 'image': "Image size: width = 180px and height = 200px" });
                    setFormValues({ ...formValues, 'image': "" });
                } else if (height < 200 || width < 180) {
                    alert(
                        "Image size: width = 180px and height = 200px"
                    );
                    setFormValidation({ ...formValidation, 'image': "Image size: width = 180px and height = 200px" });
                    setFormValues({ ...formValues, 'image': "" });
                } else {
                    getBase64(file)
                        .then((result) => {
                            file["base64"] = "true";

                            setBlobImg(result);
                            setSelectedImage(true);
                            setFormValues({ ...formValues, "image": file });

                            delete formValidation.image;
                        })
                        .catch((err) => { });
                }
            };
        };
    };
    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };


    const handleAboutUpdate = async (e) => {
        e.preventDefault();
        let errors = {};

        const local_data = localStorage.getItem("common-user-info");
        const local_storage = JSON.parse(local_data);
        const auth_token = local_storage.auth_token;

        var myHeaders = new Headers();
        myHeaders.append("AUTHTOKEN", auth_token);

        var formdata = new FormData();
        formdata.append("name", formValues.name);
        formdata.append("contact", value);
        formdata.append("address", formValues.address);
        formdata.append("image", formValues.image);
        formdata.append("location", formValues.location);
        formdata.append("state", formValues.states.value || formValues.state);
        formdata.append("city", formValues.cities.value || formValues.city);
        formdata.append("pincode", formValues.pincode);
        formdata.append("latitude", formValues.latitude);
        formdata.append("longitude", formValues.longitude);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        if (
            formValues.name === undefined ||
            formValues.name === "" ||
            formValues.name === null
        ) {
            errors.name = "Required";
        }
        if (value === undefined || value === "") {
            errors.contact = "Required";
        } else {
            if (value.length !== 13) {
                errors.contact = "Please enter valid contact";
            }
        }
        if (formValues.address === undefined || formValues.address === "" || formValues.address === null) {
            errors.address = "Required";
        }
        if (formValues.location === undefined || formValues.location === "" || formValues.location === null) {
            errors.location = "Required";
        }
        if (formValues.states === undefined || formValues.states === "" || formValues.states === null) {
            errors.states = "Required";
        }
        if (formValues.cities === undefined || formValues.cities === "" || formValues.cities === null) {
            errors.cities = "Required";
        }
        if (formValues.pincode === undefined || formValues.pincode === "" || formValues.pincode === null) {
            errors.pincode = "Required";
        }
        if (
            formValues.image === undefined ||
            formValues.image === "" ||
            formValues.image === null
        ) {
            errors.image = "Required";
        }
        if (typeof formValues.image != "string") {
            if (formValues.image.base64 === undefined || formValues.image.base64.length == 0) {
                errors.image = "Please enter valid image";
            }
        }
        setFormValidation(errors)
        if (Object.keys(errors).length === 0) {

            setLoader(true);
            fetch(`${url.apiUrl}student/edit-profile`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.status === "success") {
                        setLoader(false);
                        sessionStorage.setItem("header_image", result?.data?.image)
                        toast.success(result.message);
                        setTimeout(() => {
                            document.getElementById("about-close").click()
                        }, 5000);
                        getProfileData();
                        setFormValues(result.data);
                        window.location.reload();
                    } else {
                        setLoader(false);
                        setFormValidation(result.data);
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    useEffect(() => {
        getProfileData();
        StateListApi();
    }, []);

    function _suggestionSelect(result, lat, long) {
        setFormValues({ ...formValues, "location": result, "latitude": lat, "longitude": long });
    }

    const mapAccess = {
        mapboxApiAccessToken:
            "pk.eyJ1Ijoic2FuY2hpdHNoYXJtYSIsImEiOiJjbGZocmRoMm0yMTJmM3FwZ21nZ2NqdG91In0.Ngsso-JXR08GApV2N1rmUw",
    };

    const StateListApi = async (e) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}state/101`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setState(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const CityListApi = async (id) => {
        var requestOptions = {
            method: "GET",
        };

        fetch(`${url.apiUrl}city/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    const arr = result.data.map((v) => {
                        return { value: v.id, label: v.name };
                    });
                    setCity(arr);
                }
            })
            .catch((error) => console.log("error", error));
    };

    return (
        <>
            <section className="app-user-view">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h4 className="card-title">Student Profile</h4>

                                <div>
                                    <Link
                                        to="#"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setProfile(true);
                                        }}
                                    >
                                        <i className="fa-solid fa-pen-to-square"></i>
                                    </Link>
                                    <div
                                        className={
                                            aboutForm
                                                ? "modal fade show"
                                                : "modal fade"
                                        }
                                        style={aboutForm ? { display: "block" } : { display: "none" }}
                                    >
                                        <div className="modal-dialog modal-dialog-centered modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title" id="myModalLabel33">
                                                        Profile Update
                                                    </h4>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        id="about-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={() => setProfile(false)}
                                                    />
                                                </div>
                                                <form onSubmit={handleAboutUpdate}>
                                                    <div className="modal-body">
                                                        <div className="row mt-2">
                                                            <div className="col-lg-6 col-12 form-group">
                                                                <label htmlFor="name" >
                                                                    Name <span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="name"
                                                                    name="name"
                                                                    defaultValue={formValues.name}
                                                                    placeholder="Enter name"
                                                                    onChange={ChangeEvent}
                                                                />
                                                                <p className="bg-error">
                                                                    {formValidation.name}
                                                                </p>
                                                            </div>
                                                            <div className="col-lg-6 col-12 form-group">
                                                                <label>
                                                                    House No. / Street / Ward No
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    name="address"
                                                                    className="form-control"
                                                                    value={formValues.address}
                                                                    onChange={ChangeEvent}
                                                                    placeholder="Please enter house no. / street / ward no."
                                                                />
                                                                <p className="bg-error">
                                                                    {formValidation.address}
                                                                </p>
                                                            </div>
                                                            {/* {formValues.location && ( */}
                                                                <div className="col-lg-12 col-12 form-group">
                                                                    <label>
                                                                        Location <span className="text-danger">*</span>
                                                                    </label>
                                                                    <MapboxAutocomplete
                                                                        publicKey={mapAccess.mapboxApiAccessToken}
                                                                        inputclassName="form-control "
                                                                        onSuggestionSelect={(result, lat, long) => {
                                                                            _suggestionSelect(result, lat, long);
                                                                        }}
                                                                        query={formValues.location}
                                                                        country="IN"
                                                                        resetSearch={false}
                                                                        placeholder="Search Location..."
                                                                    />
                                                                    <p className="bg-error">
                                                                        {formValidation.location}
                                                                    </p>
                                                                </div>
                                                            {/* )} */}
                                                            <div className="col-lg-4 col-12 form-group">
                                                                <label>
                                                                    State <span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    value={formValues.states}
                                                                    name="state"
                                                                    onChange={(e) => {
                                                                        CityListApi(e.value);
                                                                        setFormValues({ ...formValues, "states": e });
                                                                    }}
                                                                    options={state}
                                                                />
                                                                <p className="bg-error">
                                                                    {formValidation.states}
                                                                </p>

                                                            </div>
                                                            <div className="col-lg-4 col-12 form-group">
                                                                <label>
                                                                    City <span className="text-danger">*</span>
                                                                </label>
                                                                <Select
                                                                    value={formValues.cities}
                                                                    onChange={(e) => {
                                                                        setFormValues({ ...formValues, "cities": e });
                                                                    }}
                                                                    options={city}
                                                                />
                                                                <p className="bg-error">
                                                                    {formValidation.cities}
                                                                </p>
                                                            </div>

                                                            <div className="col-lg-4 col-12 form-group">
                                                                <label>
                                                                    Pincode <span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    text="text"
                                                                    name="pincode"
                                                                    className="form-control"
                                                                    value={formValues.pincode}
                                                                    onChange={ChangeEvent}
                                                                    placeholder="Please enter pincode"
                                                                />
                                                                <p className="bg-error">
                                                                    {formValidation.pincode}
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="contact" >
                                                                    Contact No
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <PhoneInputWithCountrySelect
                                                                    name="contact"
                                                                    value={value}
                                                                    min={10}
                                                                    max={10}
                                                                    onChange={setValue}
                                                                    defaultCountry="IN"
                                                                    className="form-control"
                                                                />
                                                                <p className="bg-error">
                                                                    {formValidation.contact}
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="image" >
                                                                    Profile Picture
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control mb-0"
                                                                    id="image"
                                                                    name="image"
                                                                    placeholder=""
                                                                    onChange={(e) => handleFileInputChange(e)}
                                                                />
                                                                <p className="fs-12 mb-0"><span className="text-danger">Note:</span> Image size: width = 180px and height = 200px</p>
                                                                <p className="bg-error">
                                                                    {formValidation.image}
                                                                </p>
                                                            </div>
                                                            <div className="col-md-3 mt-2">
                                                                {selectedImage ? (
                                                                    <img src={blobImg} />
                                                                ) : (
                                                                    <img src={formValues.image} className="img-thumbnail" />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        {loader ? (
                                                            <SubmitLoader />
                                                        ) : (
                                                            <>
                                                                <button type="submit" className="btn btn-primary">
                                                                    Submit
                                                                </button>
                                                                <button
                                                                    type="reset"
                                                                    className="btn btn-danger "
                                                                    data-bs-dismiss="modal"
                                                                    onClick={() => setProfile(false)}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-1 col-lg-2 col-md-3 col-12">
                                        <div className="item-card7-img">
                                            <img className="cover-image" src={studentData?.image} alt={studentData.title}/>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-4 col-md-3 col-12 mt-5 mt-xl-0">
                                        <h4>{studentData?.name}</h4>
                                        <p className="card-text mb-2">
                                            {studentData?.email}
                                        </p>
                                        <p className="card-text mb-2">{studentData?.contact}</p>
                                        <p className="card-text mb-2">
                                            <i className="fa-solid fa-location-dot me-2"></i>
                                            {studentData?.address ? studentData?.address : "---"}{studentData?.state && ', ' + studentData?.states.name + ', ' + studentData?.cities?.name + ' - ' + studentData?.pincode}

                                        </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 mt-5 mt-xl-0">
                                        <table className="user-details table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Username / Referral Code
                                                    </td>
                                                    <td className="py-1 px-0 text-uppercase"> {studentData?.user_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="py-1 px-0 font-weight-semibold w-50">
                                                        Last Update
                                                    </td>
                                                    <td className="py-1 px-0"><Moment format="DD-MM-YYYY hh:mm A">{studentData?.updated_at}</Moment></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <ToastContainer />
        </>
    );
};

export default StudentProfile;
