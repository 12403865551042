import React, { useEffect, useState } from "react";
import url from "../../../../../src/constants/url";
const Payment = ({
    setBranch,
    setCourseType,
    getAllCourseData,
    setEndDate,
    setStartDate,
}) => {
    const [branchData, setBranchdata] = useState([]);
    const [teacherData, setTeacherdata] = useState([]);
    const [course, setCourse] = useState([])
    var localdata = localStorage.getItem("common-user-info");
    var jsondata = JSON.parse(localdata);
    var token_authorization = jsondata.auth_token;
    useEffect(() => {
        getBranchData();
    }, []);

    const getBranchData = async () => {
        let result = await fetch(`${url.apiUrl}teacher/get-branch-list`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                AUTHTOKEN: token_authorization,
            },
        });
        if (result?.status === 200) {
            let varResult = await result.json();
            if (varResult?.status == "success") {
                setBranchdata(varResult?.data);
            }
        }
    };

    return (
        <>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Payment Filter</h4>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <label>
                                        Branch
                                    </label>
                                    <select
                                        name="branch"
                                        id="branch"
                                        className="form-select"
                                        onChange={(e) => {
                                            setBranch(e.target.value);
                                        }}
                                    >

                                        <option selected="false" disabled>
                                            Select branch
                                        </option>
                                        {branchData &&
                                            branchData.map((v, i) => (
                                                <option value={v.id} key={i}>
                                                    {v.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>
                                        Course Type
                                    </label>
                                    <select
                                        name="course"
                                        id="course"
                                        className="form-select"
                                        onChange={(e) => setCourseType(e.target.value)}
                                    >
                                        <option>Select course Type</option>
                                        <option value="online_course">Video Course</option>
                                        <option value="class_course">Classroom Course</option>

                                    </select>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>
                                        Start Date
                                    </label>
                                    <input
                                        type="date"
                                        name="pinecode"
                                        id="pinecode"
                                        className="form-control"
                                        placeholder="Enter pinecode"
                                        onChange={(e) => {
                                            setStartDate(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>
                                        End Date
                                    </label>
                                    <input
                                        type="date"
                                        name="pinecode"
                                        id="pinecode"
                                        className="form-control"
                                        placeholder="Enter pinecode"
                                        onChange={(e) => {
                                            setEndDate(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 open-button">
                                    <button className="btn btn-primary" type="button" onClick={() => {
                                        getAllCourseData();
                                    }}><i className="fe fe-search"></i> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Payment;
