import React, { useState } from "react";
import * as Yup from "yup";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import url from "../../../../constants/url";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";

export default function StudentRegistrationFormField({ isLoader, setIsLoader }) {
    const [experienceValidation, setExperienceValidation] = useState({
        name: "",
        value: "",
        email: "",
        password: "",
        terms: "",
    });
    const history = useHistory();
    const [UIValues, setUIValues] = useState({
        name: "",
        contact: "",
        email: "",
        password: "",
        terms: "",
    });
    const onSubmit = async (values) => {
        setIsLoader(true);
        var formdata = new FormData();
        formdata.append("type", "student");
        formdata.append("name", values?.name);
        formdata.append("contact", values.contact);
        formdata.append("email", values?.email);
        formdata.append("password", values?.password);

        var requestOptions = {
            method: "POST",
            body: formdata,
        };

        fetch(`${url.apiUrl}student/register`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "success") {
                    setIsLoader(false);
                    Swal.fire({
                        title: result.status,
                        text: result.message,
                        icon: "success",
                        confirmButtonText: "OK",
                    }).then(function () {
                        // setUIValues({
                        //     name: "",
                        //     contact: "",
                        //     email: "",
                        //     password: "",
                        // });

                        var userdata = {
                            name: result.data.name,
                            email: result.data.email,
                            id: result.data.id,
                            type: result.data.type,
                            auth_token: result.data.auth_token,
                            mobile: result.data.contact,
                        };
                        localStorage.setItem("common-user-info", JSON.stringify(userdata));
                        history.push("/");
                    });
                } else {
                    setIsLoader(false);
                    setExperienceValidation(result.data);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const VoucherSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        password: Yup.string()
            .required("Required")
            .min(6, "Password is too short - should be 6 chars minimum."),
        email: Yup.string().email("Please enter valid email ID").required("Required"),
        terms: Yup.string().required("Required"),
        contact: Yup.string()
            .required("Required")
            .min(13, "Please enter valid contact number")
            .max(13, "Please enter valid contact number"),
    });

    return (
        <Formik
            initialValues={UIValues}
            validationSchema={VoucherSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {(props) => (
                <form id="register" tabIndex="500" onSubmit={props.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                Student Name <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={props.values.name}
                                onChange={props.handleChange}
                                placeholder="Please enter student name."
                            />

                            {props.touched.name && props.errors.name ? (
                                <div className=" bg-error">{props.errors.name}</div>
                            ) : (
                                <p className="bg-error">{experienceValidation?.name}</p>
                            )}
                        </div>
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                Email ID <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                autoComplete="false"
                                value={props.values.email}
                                onChange={props.handleChange}
                                placeholder="Please enter email id"
                            />
                            {props.touched.email && props.errors.email ? (
                                <div className=" bg-error">{props.errors.email}</div>
                            ) : (
                                <p className="bg-error">{experienceValidation?.email}</p>
                            )}
                        </div>
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                Contact No. <span className="text-danger">*</span>
                            </label>
                            <PhoneInputWithCountrySelect
                                name="contact"
                                value={props.values.contact}
                                // min={10}
                                // max={10}
                                onChange={(e) => {
                                    props.setFieldValue("contact", e);
                                }}
                                defaultCountry="IN"
                                className="form-control"
                                placeholder="Please enter contact no"
                            />
                            {props.touched.contact && props.errors.contact ? (
                                <div className=" bg-error">{props.errors.contact}</div>
                            ) : (
                                <p className="bg-error">{experienceValidation?.contact}</p>
                            )}
                        </div>
                        <div className="col-lg-6 col-12 form-group">
                            <label>
                                Password <span className="text-danger">*</span>
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={props.values.password}
                                onChange={props.handleChange}
                                placeholder="Please enter password."
                            />
                            {props.touched.password && props.errors.password ? (
                                <div className=" bg-error">{props.errors.password}</div>
                            ) : (
                                <p className="bg-error">{experienceValidation?.password}</p>
                            )}
                        </div>
                        <div className="col-lg-12 form-group">
                            <div className="checkbox checkbox-info">
                                <label
                                    className="custom-control mt-4 checkbox-inline mb-0"
                                    htmlFor="terms"
                                >
                                    <input
                                        type="checkbox"
                                        name="terms"
                                        width="10px"
                                        id="terms"
                                        value={props.values.terms}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                props.setFieldValue("terms", "true");
                                            } else {
                                                props.setFieldValue("terms", "");
                                            }
                                        }}
                                        className="custom-control-input col-md-12"
                                    />
                                    <span className="custom-control-label  ps-2">
                                        I agree with the
                                        <Link to="/all-policy" className="text-primary ms-1" target="_blank" rel="noreferrer">
                                            Terms & Conditions.
                                        </Link>
                                    </span>
                                </label>
                                {props.touched.terms && props.errors.terms ? (
                                    <div className=" bg-error">{props.errors.terms}</div>
                                ) : (
                                    <p className="bg-error">{experienceValidation?.terms}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-end mb-5">
                            <button type="submit" className="btn btn-primary">
                                <i className="fa-solid fa-arrow-right-to-bracket me-2"></i> Sign up
                            </button>
                        </div>
                    </div>
                    <p className="mb-0 text-end">
                        Already have an account?
                        <Link to={"/login"} className="text-primary ms-1"><i className="fa-solid fa-arrow-right-to-bracket me-2"></i> Sign In

                        </Link>
                    </p>
                </form>
            )}
        </Formik>
    );
}
